import React from "react";
import { Box, Flex } from "@theme-ui/components";

const InfoBox = ({ children, ...props }) => {
  return (
    <Flex {...props} sx={{ flexDirection: "row", alignItems: "center", ...props.sx }}>
      <Flex
        sx={{
          height: "1.35rem",
          width: "1.35rem",
          border: "3px solid",
          borderRightColor: "transparent",
          borderTopColor: "transparent",
          borderLeftColor: "border",
          borderBottomColor: "border",
          transform: "rotate(45deg)",
          position: "absolute",
          ml: "-0.5rem",
          bg: "white",
        }}></Flex>
      <Box
        sx={{
          minHeight: "7rem",
          border: "3px solid ",
          borderColor: "border",
          px: "2rem",
          py: "1rem",
        }}>
        {children}
      </Box>
    </Flex>
  );
};

export default InfoBox;
