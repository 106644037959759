import React, { useCallback, useMemo, useState } from "react";
import { Box, Flex, Text, Slider } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { debounce } from "lodash";

import Amount from "../amount";
import { OSIcon } from "../icons";
import { OSRadio } from "../base";
import AmountDescription from "../amount-description";

import { PRICE_TERMS_UNIT } from "../../common/Constants";

/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                       - Item price amount.
 * @property {Number} tax                         - Item price tax.
 * @property {String} type                        - Item price type.
 * @property {Number} termsAmount                 - Item price terms amount.
 * @property {Number} termsUnit                   - Item price terms unit.
 */
/**
 * @param {Object} props Component props.
 * @param {ItemPrice} props.rcPrice RECURRING charge.
 * @param {ItemPrice} props.otPrice ONE_TIME charge.
 * @param {String} props.description Description for the case whe only rcPrice or otPrice is displayed.
 * @param {Boolean} selected True if the price option is selected.
 * @param {Function} onSelect On select event callback.
 * @param {Boolean} showRadio True if the selection radio should be displayed.
 */
const PriceOffer = ({
  rcPrice,
  otPrice,
  minDownPaymentPrice,
  description,
  selected,
  onSelect,
  showRadio = true,
  onChangeDownPayment,
  deviceFullPrice = 0,
  isDevicePriceOffer = false,
  notLast,
  showUpfrontPayment,
  pricingOption,
  flexDeferredPrice,
  id,
  ...props
}) => {
  const intl = useIntl();

  const isRcPriceOnly = rcPrice && (!otPrice || otPrice.price <= 0);
  const isOtPriceOnly = otPrice && (!rcPrice || rcPrice.price < 0);
  const isRcPriceAndOtPrice = rcPrice && rcPrice.price >= 0 && otPrice && otPrice.price >= 0;
  const [initialPrice] = useState(otPrice);
  const minDownPayment = minDownPaymentPrice ? minDownPaymentPrice.price : 0;
  const isTaxFree = isOtPriceOnly && otPrice.tax === 0;
  const maxPaymentAmount = deviceFullPrice - (flexDeferredPrice?.price || 0);

  const debouncedChange = useMemo(
    () => debounce((value) => onChangeDownPayment(value), 16),
    [onChangeDownPayment]
  );

  const handleChange = useCallback((value) => debouncedChange(value), [debouncedChange]);

  return (
    <>
      <Box
        px={[2, 4, 4]}
        py={4}
        mb={notLast && 4}
        sx={{
          border: 1,
          borderColor: selected ? "secondary" : "cardBorderColor",
          borderRadius: "lg",
        }}>
        <Box
          onClick={() => !selected && onSelect()}
          sx={{
            bg: "secondaryBg",
            display: "grid",
            width: "100%",
            gridTemplateColumns: "1fr .125fr 1fr",
            cursor: "pointer",
            ":hover": !selected && {
              borderColor: "cardBorderHoverColor",
            },
          }}
          {...props}>
          {isRcPriceOnly && !isDevicePriceOffer && (
            <>
              <Flex
                className="track"
                id={id}
                sx={{
                  alignItems: "center",
                }}>
                {showRadio && (
                  <OSRadio
                    className="track"
                    id={id}
                    checked={selected}
                    onChange={onSelect}
                    mr={2}
                  />
                )}
                {!showRadio && <Box width="1.625rem" />}
                <Text variant="description">{description}</Text>
              </Flex>
              <Box />
              <Flex className="track" id={id} sx={{ justifyContent: "flex-end" }}>
                <Flex
                  className="track"
                  id={id}
                  sx={{ flexDirection: "column", justifyContent: "center" }}>
                  <Amount
                    className="track"
                    id={id}
                    amount={rcPrice.price}
                    isMonthly={rcPrice.termsUnit === PRICE_TERMS_UNIT.MONTHS}
                    description={
                      rcPrice.termsAmount > 0 &&
                      intl.formatMessage(
                        { id: "lbl.x-months" },
                        { noOfMonths: rcPrice.termsAmount }
                      )
                    }
                    color="ternary"
                  />
                </Flex>
              </Flex>
            </>
          )}

          {isOtPriceOnly && (
            <>
              <Flex className="track" id={id} sx={{ alignItems: "center" }}>
                {showRadio && (
                  <OSRadio
                    className="track"
                    id={id}
                    checked={selected}
                    onChange={onSelect}
                    mr={2}
                  />
                )}
                {!showRadio && <Box width="1.625rem" />}
                <Text variant="description">{description}</Text>
              </Flex>
              <Box />
              <Flex className="track" id={id} sx={{ justifyContent: "flex-end" }}>
                <Flex
                  className="track"
                  id={id}
                  sx={{ flexDirection: "column", justifyContent: "center" }}>
                  <Amount
                    className="track"
                    id={id}
                    amount={otPrice.price + otPrice.tax}
                    description={intl.formatMessage({ id: "lbl.upfront-payment" })}
                    color="ternary"
                  />
                </Flex>
              </Flex>
            </>
          )}

          {isRcPriceAndOtPrice && (
            <>
              <Flex className="track" id={id} sx={{ alignItems: "center" }}>
                {showRadio && (
                  <OSRadio
                    className="track"
                    id={id}
                    checked={selected}
                    onChange={onSelect}
                    mr={2}
                  />
                )}
                {!showRadio && <Box width="1.625rem" />}
                <Amount
                  className="track"
                  id={id}
                  amount={rcPrice.price}
                  isMonthly
                  description={
                    rcPrice.termsAmount > 0 && (
                      <AmountDescription
                        className="track"
                        id={id}
                        pricingOption={pricingOption}
                        termsAmount={rcPrice.termsAmount}
                      />
                    )
                  }
                  color="ternary"
                  descriptionColor="primary"
                />
              </Flex>
              <Flex
                className="track"
                id={id}
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <OSIcon name="plus" />
              </Flex>
              <Flex className="track" id={id} sx={{ justifyContent: "flex-end" }}>
                <Flex
                  className="track"
                  id={id}
                  sx={{ flexDirection: "column", justifyContent: "center" }}>
                  <Amount
                    className="track"
                    id={id}
                    amount={otPrice.price + otPrice.tax}
                    description={intl.formatMessage({ id: "lbl.upfront-payment" })}
                    color="ternary"
                    descriptionColor="primary"
                  />
                </Flex>
              </Flex>
            </>
          )}
        </Box>
        {isRcPriceAndOtPrice && showUpfrontPayment && (
          <Flex sx={{ flexDirection: "column" }} mt={2}>
            <Text sx={{ textAlign: "center", width: "24rem", alignSelf: "center" }}>
              {intl.formatMessage({ id: "lbl.increase-down-payment" })}
            </Text>
            <Flex sx={{ flexDirection: "row", justifyContent: "space-between" }}>
              <Text>-</Text>
              <Text>+</Text>
            </Flex>
            <Slider
              id="priceSlider"
              min={minDownPayment}
              defaultValue={initialPrice.price}
              max={maxPaymentAmount}
              step={25}
              onInput={(e) => {
                handleChange(parseFloat(e.target.value));
              }}
              sx={{
                bg: "hrTriangle",
                color: "ternary",
              }}
            />
            <Flex sx={{ flexDirection: "row", justifyContent: "space-between", py: 1 }}>
              <Amount type="normal_large" compact amount={minDownPayment} />
              <Amount type="normal_large" compact amount={maxPaymentAmount} />
            </Flex>
          </Flex>
        )}
      </Box>
      {isOtPriceOnly && !isTaxFree && (
        <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.tax-included" })}</Text>
      )}
    </>
  );
};

export default PriceOffer;
