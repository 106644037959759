import React from "react";
import { Box } from "@theme-ui/components";

const SecurePayment = ({ color = "svgStrokePrimary", size = "iconTiny", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 13"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
      <g transform="translate(0.5 0.5)">
        <g transform="translate(-8.5 -6.415)">
          <path
            d="M1.761,5.408V3.934a3.5,3.5,0,0,1,7,0V5.408"
            transform="translate(8.708 5.915)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <rect
            width="11"
            height="7"
            rx="0.894"
            transform="translate(8.5 11.415)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
          <path
            d="M7,6V3"
            transform="translate(18.5 7.916) rotate(90)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            opacity="0.4"
          />
        </g>
      </g>
    </svg>
  </Box>
);

export default SecurePayment;
