import messages_en from "./messages/en";
import messages_fr from "./messages/fr";

const FORMATS = {
  number: {
    USD: {
      style: "currency",
      currency: "USD",
    },
    CAD: {
      style: "currency",
      currency: "CAD",
    },
  },
};

const INITIAL_LOCALE = "en-CA";

const MESSAGES = {
  "en-CA": messages_en,
  "fr-CA": messages_fr,
};

const IntlConstants = { FORMATS, INITIAL_LOCALE, MESSAGES };
export default IntlConstants;
