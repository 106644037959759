import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Flex, Text } from "@theme-ui/components";
import { HIDDEN_ATTRIBUTES, PRICE_TYPE } from "../../common/Constants";
import { Heading5 } from "../../components/heading";

import Amount from "../../components/amount";
import CollapsibleHeader from "../../components/collapsible-header/CollapsibleHeader";
import { useWindowSize } from "../../hooks";

const AccessoriesSummary = ({ ...props }) => {
  const { summaryData } = props;
  const intl = useIntl();
  const windowSize = useWindowSize();

  const [collapsed, setCollapsed] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const [taxFree, setTaxFree] = useState(false);

  const summaryContainer = useRef(null);

  useLayoutEffect(() => {
    setHrTrianglePosition(summaryContainer.current.offsetWidth / 4);
    setHrWidth(
      summaryContainer && summaryContainer.current ? summaryContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  useEffect(() => {
    if (summaryData.itemList) {
      let accessoryWithTax = summaryData.itemList
        .flatMap((item) => item.prices)
        .find((price) => price.tax > 0);
      setTaxFree(!accessoryWithTax);
    }
  }, [summaryData.itemList]);

  const getItemAttributes = (itemAttributes) => {
    return (
      itemAttributes &&
      itemAttributes.length > 0 &&
      itemAttributes
        .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.code))
        .map((attr) => attr.value)
        .join(", ")
    );
  };

  const buildDeviceAmountComponent = (prices) => {
    const oneTimePrice = prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
    return (
      <Amount
        amount={oneTimePrice.price + oneTimePrice.tax}
        description={intl.formatMessage({ id: "lbl.upfront-payment" })}
        color="ternary"
      />
    );
  };

  return (
    <Box ref={summaryContainer} mb={!collapsed && 6}>
      <CollapsibleHeader
        primaryTitle={intl.formatMessage({ id: "lbl.accessories" })}
        hrWidth={hrWidth}
        hrTrianglePosition={hrTrianglePosition}
        collapsed={collapsed}
        onCollapsed={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (
        <Flex
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: ["column", "row", "row"],
          }}>
          <Box sx={{ width: ["100%", "70%", "33.33%"], px: [8, 8, 9] }}>
            <Heading5 mt="none">
              {intl.formatMessage({ id: "lbl.accessories" })}
              {!taxFree && (
                <Text variant="subtitle1" pl={2}>
                  {`(${intl.formatMessage({ id: "lbl.tax-included" })})`}
                </Text>
              )}
            </Heading5>

            {summaryData.itemList.map((item, index) => (
              <React.Fragment key={index}>
                <Flex
                  sx={{
                    mb: 3,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <Flex flex={1}>
                    <Text variant="summaryValue">{item.shortDescription}</Text>
                    <Text variant="summaryValue" mx={[4, 2, 2]}>
                      {getItemAttributes(item.attributes)}
                    </Text>
                  </Flex>
                  {buildDeviceAmountComponent(item.prices)}
                </Flex>
              </React.Fragment>
            ))}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default AccessoriesSummary;
