import React from "react";
import { Box, Flex, Text, Heading } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";

import Amount from "../amount";
import { Heading4 } from "../heading";
import { PERSONAL_INFORMATION_FLOW } from "../../common/Constants";

const LineDescription = ({
  description,
  labelId,
  amount,
  plusTax,
  includeTax,
  isTotal,
  collapsed,
  showZeroAmounts = false,
  isSpecialDiscount = false,
  ...props
}) => {
  const intl = useIntl();

  return (
    ((showZeroAmounts || amount) && (
      <Flex sx={{ ...props, flex: "1", justifyContent: "space-between" }} ml={4} mt={2}>
        {(!isTotal && (
          <>
            <Text variant="subtitle1" color={collapsed ? "ternary" : "windowBg"} mt={0}>
              {labelId ? (
                <FormattedMessage
                  textComponent="span"
                  id={labelId}
                  values={{
                    sup: (chunks) => (
                      <Text
                        as="sup"
                        mr={0}
                        sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              ) : (
                description
              )}
              {plusTax && ` (${intl.formatMessage({ id: "lbl.plus-tax" })})`}
              {includeTax && ` (${intl.formatMessage({ id: "lbl.tax-included" })})`}
            </Text>
            <Amount
              type="normal_large"
              amount={amount}
              color={collapsed ? "ternary" : "windowBg"}
            />
          </>
        )) || (
          <>
            <Heading variant="subtitle1" color={collapsed ? "ternary" : "windowBg"} mt={0}>
              {labelId ? (
                <FormattedMessage
                  textComponent="span"
                  id={labelId}
                  values={{
                    sup: (chunks) => (
                      <Text
                        as="sup"
                        mr={0}
                        sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              ) : (
                description
              )}
              {plusTax && ` (${intl.formatMessage({ id: "lbl.plus-tax" })})`}
              {includeTax && ` (${intl.formatMessage({ id: "lbl.tax-included" })})`}
            </Heading>
            <Amount type="bold_large" amount={amount} color="windowBg" />
          </>
        )}
      </Flex>
    )) ||
    (!amount && isSpecialDiscount && (
      <Flex sx={{ ...props, flex: "1", justifyContent: "space-between" }} ml={4} mt={2}>
        <Text variant="subtitle1" color={collapsed ? "ternary" : "windowBg"} mt={0}>
          {description}
        </Text>
      </Flex>
    )) ||
    null
  );
};

const CartTotal = ({
  showTotals = true,
  dueNowPrices,
  monthlyPrices,
  oneTimePrices,
  collapsed,
  plusTax,
  showShipping = false,
  specialDiscounts,
  flow,
  ...props
}) => {
  const hasValue = (obj) => {
    return Object.values(obj).find((value) => value && value.amount && value.amount !== 0);
  };

  const showDueNowCharges = dueNowPrices && hasValue(dueNowPrices);
  const showMonthlyCharges = monthlyPrices && hasValue(monthlyPrices);
  const showOneTimeCharges = oneTimePrices && hasValue(oneTimePrices);

  let desktopTotal = showTotals && (
    <Box sx={{ display: ["none", "none", "block"] }}>
      <Flex
        {...props}
        p={[8, 8, 9]}
        sx={{
          flexDirection: "column",
          backgroundColor: "ternary",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
          borderBottomLeftRadius: "xl",
          borderBottomRightRadius: "xl",
        }}>
        {showDueNowCharges && (
          <>
            <Flex sx={{ flex: 1 }}>
              <Heading4 color="windowBg">
                <FormattedMessage id="lbl.total-to-pay-now" />
              </Heading4>
            </Flex>

            <LineDescription
              labelId={"lbl.accessories"}
              amount={dueNowPrices.accessoriesPrice?.amount}
              includeTax={!plusTax && !dueNowPrices.accessoriesPrice?.isTaxFree}
              plusTax={plusTax}
            />
            <LineDescription
              labelId={
                flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
                  ? "lbl.new-device"
                  : "lbl.device"
              }
              amount={dueNowPrices.devicePrice?.amount}
              includeTax={
                !plusTax &&
                dueNowPrices.devicePrice?.taxComputed &&
                !dueNowPrices.devicePrice?.isTaxFree
              }
              plusTax={plusTax || !dueNowPrices.devicePrice?.taxComputed}
            />
            <LineDescription
              labelId={"lbl.old-device"}
              amount={dueNowPrices.oldEasyTabPrice?.amount}
              plusTax={plusTax || !dueNowPrices.oldEasyTabPrice?.taxComputed}
              includeTax={
                !plusTax &&
                dueNowPrices.oldEasyTabPrice?.taxComputed &&
                !dueNowPrices.oldEasyTabPrice?.isTaxFree
              }
            />
            <LineDescription
              labelId={"lbl.shipping"}
              amount={dueNowPrices.shippingPrice?.amount}
              plusTax={plusTax}
              showZeroAmounts={showShipping}
            />
            {!plusTax && <LineDescription labelId={"lbl.tax"} amount={dueNowPrices.tax} />}
            <LineDescription labelId={"lbl.total"} amount={dueNowPrices.total} isTotal />
          </>
        )}

        {showOneTimeCharges && (
          <>
            <Flex sx={{ justifyContent: "space-between" }} mt={4}>
              <Heading variant="subtitle2" color="windowBg">
                <FormattedMessage id="lbl.one-time-billed-charges" />
              </Heading>
            </Flex>
            <LineDescription
              labelId={"lbl.onetime"}
              amount={oneTimePrices.oneTimePrice?.amount}
              plusTax={plusTax}
            />
            <LineDescription
              description={oneTimePrices.connectionFeePrice?.description}
              amount={oneTimePrices.connectionFeePrice?.amount}
              plusTax={plusTax}
            />
            <LineDescription
              labelId={"lbl.additional-services"}
              amount={oneTimePrices.additionalOneTimePrice?.amount}
              plusTax={plusTax}
            />
            <LineDescription
              labelId={"lbl.discounts"}
              amount={oneTimePrices.discountsPrice?.amount}
              plusTax={plusTax}
            />
            {!plusTax && <LineDescription labelId={"lbl.tax"} amount={oneTimePrices.tax} />}
            <LineDescription labelId={"lbl.total"} amount={oneTimePrices.total} isTotal />
          </>
        )}

        {showMonthlyCharges && (
          <>
            <Flex mt={4} sx={{ flex: 1 }}>
              <Heading4 color="windowBg">
                <FormattedMessage id="lbl.monthly-charges" />
              </Heading4>
            </Flex>

            <LineDescription
              labelId={"lbl.rate-plan-and-features"}
              amount={monthlyPrices.ratePlanFeaturesPrice?.amount}
              plusTax={plusTax}
            />
            <LineDescription
              labelId={"lbl.discounts"}
              amount={monthlyPrices.discountsPrice?.amount}
              plusTax={plusTax}
            />
            {!plusTax && <LineDescription labelId={"lbl.tax"} amount={monthlyPrices.tax} />}
            <LineDescription
              labelId={"lbl.easytab-contribution"}
              amount={monthlyPrices.easyTabPrice?.amount}
              includeTax={
                !plusTax &&
                monthlyPrices.easyTabPrice?.taxComputed &&
                !monthlyPrices.easyTabPrice?.isTaxFree
              }
              plusTax={plusTax || !monthlyPrices.easyTabPrice?.taxComputed}
            />
            <LineDescription labelId={"lbl.total"} amount={monthlyPrices.total} isTotal />
          </>
        )}
        {specialDiscounts && specialDiscounts.length > 0 && (
          <>
            <Flex mt={4} sx={{ flex: 1 }}>
              <Heading4 color="windowBg">
                <FormattedMessage id="lbl.special-discounts" />
              </Heading4>
            </Flex>
            {specialDiscounts.map((discount, index) => (
              <LineDescription key={index} description={discount} isSpecialDiscount={true} />
            ))}
          </>
        )}
      </Flex>
    </Box>
  );
  let mobileTotal = !collapsed && showTotals && (
    <Box sx={{ display: ["block", "block", "none"] }}>
      <Flex
        {...props}
        p={[8, 8, 9]}
        sx={{
          flexDirection: "column",
          cursor: "pointer",
          backgroundColor: collapsed ? "secondaryBg" : "ternary",
          borderTopLeftRadius: "0",
          borderTopRightRadius: "0",
          borderBottomLeftRadius: "xl",
          borderBottomRightRadius: "xl",
        }}>
        <Flex sx={{ flexDirection: "column" }}>
          {showDueNowCharges && (
            <>
              <Flex sx={{ justifyContent: "space-between" }} mr={5}>
                <Heading4 color="windowBg">
                  <FormattedMessage id="lbl.total-to-pay-now" />
                </Heading4>
              </Flex>

              <LineDescription
                mr={5}
                labelId={"lbl.accessories"}
                amount={dueNowPrices.accessoriesPrice?.amount}
                includeTax={!plusTax && !dueNowPrices.accessoriesPrice?.isTaxFree}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                labelId={
                  flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
                    ? "lbl.new-device"
                    : "lbl.device"
                }
                amount={dueNowPrices.devicePrice?.amount}
                includeTax={
                  !plusTax &&
                  dueNowPrices.devicePrice?.taxComputed &&
                  !dueNowPrices.devicePrice?.isTaxFree
                }
                plusTax={plusTax || !dueNowPrices.devicePrice?.taxComputed}
              />
              <LineDescription
                mr={5}
                labelId={"lbl.old-device"}
                amount={dueNowPrices.oldEasyTabPrice?.amount}
                plusTax={plusTax || !dueNowPrices.oldEasyTabPrice?.taxComputed}
                includeTax={
                  !plusTax &&
                  dueNowPrices.oldEasyTabPrice?.taxComputed &&
                  !dueNowPrices.oldEasyTabPrice?.isTaxFree
                }
              />
              <LineDescription
                mr={5}
                labelId={"lbl.shipping"}
                amount={dueNowPrices.shippingPrice?.amount}
                plusTax={plusTax}
              />
              {!plusTax && <LineDescription mr={5} labelId={"lbl.tax"} amount={dueNowPrices.tax} />}
              <LineDescription mr={5} labelId={"lbl.total"} amount={dueNowPrices.total} isTotal />
            </>
          )}

          {showOneTimeCharges && (
            <>
              <Flex sx={{ justifyContent: "space-between" }} mr={5} mt={4}>
                <Heading4 color="windowBg">
                  <FormattedMessage id="lbl.one-time-billed-charges" />
                </Heading4>
              </Flex>

              <LineDescription
                mr={5}
                labelId={"lbl.onetime"}
                amount={oneTimePrices.oneTimePrice?.amount}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                description={oneTimePrices.connectionFeePrice?.description}
                amount={oneTimePrices.connectionFeePrice?.amount}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                labelId={"lbl.additional-services"}
                amount={oneTimePrices.additionalOneTimePrice?.amount}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                labelId={"lbl.discounts"}
                amount={oneTimePrices.discountsPrice?.amount}
                plusTax={plusTax}
              />
              {!plusTax && (
                <LineDescription mr={5} labelId={"lbl.tax"} amount={oneTimePrices.tax} />
              )}
              <LineDescription mr={5} labelId={"lbl.total"} amount={oneTimePrices.total} isTotal />
            </>
          )}

          {showMonthlyCharges && (
            <>
              <Flex sx={{ justifyContent: "space-between" }} mr={5} mt={4}>
                <Heading4 color="windowBg">
                  <FormattedMessage id="lbl.monthly-charges" />
                </Heading4>
              </Flex>

              <LineDescription
                mr={5}
                labelId={"lbl.rate-plan-and-features"}
                amount={monthlyPrices.ratePlanFeaturesPrice?.amount}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                labelId={"lbl.discounts"}
                amount={monthlyPrices.discountsPrice?.amount}
                plusTax={plusTax}
              />
              <LineDescription
                mr={5}
                labelId={"lbl.easytab-contribution"}
                amount={monthlyPrices.easyTabPrice?.amount}
                includeTax={
                  !plusTax &&
                  monthlyPrices.easyTabPrice?.taxComputed &&
                  !monthlyPrices.easyTabPrice?.isTaxFree
                }
                plusTax={plusTax || !monthlyPrices.easyTabPrice?.taxComputed}
              />
              {!plusTax && (
                <LineDescription mr={5} labelId={"lbl.tax"} amount={monthlyPrices.tax} />
              )}
              <LineDescription mr={5} labelId={"lbl.total"} amount={monthlyPrices.total} isTotal />
            </>
          )}

          {specialDiscounts && specialDiscounts.length > 0 && (
            <>
              <Flex sx={{ justifyContent: "space-between" }} mr={5} mt={4}>
                <Heading4 color="windowBg">
                  <FormattedMessage id="lbl.special-discounts" />
                </Heading4>
              </Flex>
              {specialDiscounts.map((discount, index) => (
                <LineDescription key={index} description={discount} isSpecialDiscount={true} />
              ))}
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
  return (
    <>
      {desktopTotal}

      {mobileTotal}
    </>
  );
};
export default CartTotal;
