import React, { useEffect, useState } from "react";
import { Flex, Button } from "@theme-ui/components";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import Layout from "../../components/layout";
import { OSContainer } from "../../components/base";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import NewCustomerForm from "./new-customer-form";
import ExistingCustomer from "./existing-customer";
import PageHeader from "./PageHeader";
import { PERSONAL_INFORMATION_FLOW, NEURAL_RESPONSE, canLogin } from "../../common/Constants";

const PersonalInformation = () => {
  const intl = useIntl();
  const personalInformationState = useSelector((state) => state.personalInformation);
  const [formValidated, setFormValidated] = useState(false);

  const parentCallback = (value) => {
    setFormValidated(value);
  };
  const authState = useSelector((state) => state.auth);

  const [flow, setFlow] = useState();
  useEffect(() => {
    if (PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER === personalInformationState.flow) {
      setFlow(personalInformationState.flow);
    }
    if (!authState.getToken.loading && canLogin(personalInformationState.flow)) {
      setFlow(personalInformationState.flow);
    }
  }, [personalInformationState.flow, authState.getToken.loading]);

  const onSetFlow = (flow) => {
    setFlow(flow);
  };

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.PERSONAL_INFORMATION.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.PERSONAL_INFORMATION.label })}
      checkSessionStatus={false}
      nextStepButton={() => (
        <Button
          id="submitNewCustomer"
          variant="default-next"
          disabled={
            flow !== PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER ||
            personalInformationState.savePersonalInformation.loading ||
            personalInformationState.neuralResponse === NEURAL_RESPONSE.ALERT ||
            !formValidated
          }
          type="submit"
          form="newCustomerForm">
          <FormattedMessage id="btn.next" />
        </Button>
      )}>
      <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
        <PageHeader selectedFlow={flow} onSelectFlow={onSetFlow} />
        <Flex sx={{ flexDirection: "column", width: "100%" }} px={[8, 8, 9]}>
          {flow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER && (
            <NewCustomerForm parentCallback={parentCallback} />
          )}
          {canLogin(flow) && <ExistingCustomer selectedFlow={flow} />}
        </Flex>
      </OSContainer>
    </Layout>
  );
};

export default PersonalInformation;
