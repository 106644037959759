import React, { useEffect, useState } from "react";
import { Flex, Box, Image, Text, Spinner, Button } from "@theme-ui/components";
import { FacadeMockup } from "../RestMockup";
import { useDispatch, useSelector } from "react-redux";
import { getItems as getDevices } from "../../../config/redux/slices";
import { StyledModalMessage } from "../../../components/modals";

var phones = [
  {
    sku: "TESTDEVICE1",

    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/082c0297cfe1c3d14cf04e3b8347bc56/h/o/hold.png",

    shortDescription: "Samsung Galaxy Z Fold2",
    paymentOption: "EASYTABFLEX",
    prices: [
      {
        price: 2500,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    skuDescription:
      "Meet the phone that's changing the shape of the future. The world's number one foldable, this cutting-edge smartphone puts powerful performance, foldable glass, and an all-day battery all in the palm of your hand.",

    attributes: [
      {
        code: "color",
        value: "Black",
      },
      {
        code: "capacity",
        value: "128GB",
      },
    ],
  },
  {
    sku: "TESTDEVICE1",

    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/082c0297cfe1c3d14cf04e3b8347bc56/a/l/alcatel-5033a.original.png",

    shortDescription: "Alcatel 5033E",
    paymentOption: "EASYTAB",
    prices: [
      {
        price: 169,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    attributes: [
      {
        code: "color",
        value: "Blue",
      },
      {
        code: "capacity",
        value: "64GB",
      },
    ],
    skuDescription:
      "Alcatel 1 is an all-in-one device offering everything you need: a sizable screen in a compact and lightweight device, distinctive design and a camera with comprehensive social media sharing features.",
  },
  {
    sku: "TESTDEVICE1",

    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/89ab149f9bcbef519f891c9f7d79b47d/z/t/zte.original_1.png",

    shortDescription: "ZTE BLADE A3 LITE",
    paymentOption: "RETAIL",
    prices: [
      {
        price: 165,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    attributes: [
      {
        code: "color",
        value: "Red",
      },
      {
        code: "capacity",
        value: "64GB",
      },
    ],
    skuDescription:
      "ZTE Blade A3 2019 is the latest smartphone launched by ZTE. The smartphone is integrated with features which is great for clicking selfies.",
  },
  {
    sku: "IP11BK64G",

    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/89ab149f9bcbef519f891c9f7d79b47d/l/g/lg-k22-plus-front.ecommerce_list_items_1.png",

    shortDescription: "LG K22 PLUS",
    paymentOption: "EASYTABFLEX",
    prices: [
      {
        price: 399,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    attributes: [
      {
        code: "color",
        value: "Green",
      },
      {
        code: "capacity",
        value: "128GB",
      },
    ],
    skuDescription:
      "Get the LG K22™ Boost Mobile smartphone – Embrace more of what you want and do life your way on this device that boasts a large screen and more features (compared to previous model), delivering enhanced functionality at a great value.",
  },
  {
    sku: "IP11BK64G",

    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/89ab149f9bcbef519f891c9f7d79b47d/s/2/s20_black_1_4.png",

    shortDescription: "Samsung Galaxy S20",
    paymentOption: "EASYTAB",
    prices: [
      {
        price: 699,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    attributes: [
      {
        code: "color",
        value: "White",
      },
      {
        code: "capacity",
        value: "256GB",
      },
    ],
    skuDescription:
      "Samsung Galaxy S20, the smallest of the S20 family, comes with a 6.2-inch display. Under the hood is Snapdragon 865/Exynos 990 chipset with 12GB RAM and 128GB storage. The phone is powered by a 4000mAh battery.",
  },
  {
    sku: "IP11BK64G",
    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/cache/89ab149f9bcbef519f891c9f7d79b47d/i/p/iphone11_black_1_1.png",

    shortDescription: "iPhone 11",
    paymentOption: "RETAIL",
    prices: [
      {
        price: 800,
        type: "ONE_TIME",
        termsAmount: -1,
        termsUnit: "NONE",
        fullPrice: 0,
        description: null,
      },
      {
        price: 30,
        type: "PLAN_RECURRING",
        termsAmount: 12,
        termsUnit: "MONTHS",
        fullPrice: 0,
        description: null,
      },
    ],
    attributes: [
      {
        code: "color",
        value: "Grey",
      },
      {
        code: "capacity",
        value: "64GB",
      },
    ],
    skuDescription:
      "Iphone11 Get a great deal on the Apple iPhone 11 in colors purple or black. See all the features, specs. Device information provided by the manufacturer.",
  },
];

const DeviceMockup = () => {
  const { getItems } = useSelector((state) => state.items);

  const [cartId, setCartId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [currentDevice, setCurrentDevice] = useState(null);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
  });

  const changeDevice = async (index) => {
    setLoading(true);
    const newItem = {
      ...currentDevice,
      ...phones[index],
    };
    try {
      await FacadeMockup.POST("/checkout", {
        cartId: cartId,
        items: [newItem],
      });

      window.top.postMessage(newItem, "*");
    } catch (error) {
      setLoading(false);
      setModal({ isOpen: true, message: error.description });
    }
  };

  useEffect(() => {
    const parseParams = () => {
      const temp = window.location.search.split("&");

      setCartId(temp[0].split("=")[1]);
      setDeviceId(temp[1].split("=")[1]);
    };

    if (window.location.search) parseParams();
  }, []);

  useEffect(() => {
    if (getItems.items.length === 0 && !getItems.loading) {
      dispatch(getDevices());
    }

    if (deviceId) {
      const found = getItems.items.find((item) => item.itemId == deviceId);
      setCurrentDevice(found);
    }
  }, [deviceId, dispatch, getItems]);

  const clearModal = () => setModal({ isOpen: false, message: "" });

  if (!currentDevice || loading) {
    return (
      <Flex
        sx={{
          width: "100%",
          height: "20rem",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}>
        <Spinner />
      </Flex>
    );
  }
  return (
    <Flex
      px="10rem"
      bg="WhiteSmoke"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        borderRadius: "10px",
      }}>
      <StyledModalMessage
        isOpen={modal.isOpen}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        <Button variant="simple-action" onClick={clearModal}>
          Ok
        </Button>
      </StyledModalMessage>

      <Flex mb="4rem" sx={{ width: "100%", alignItems: "center" }}>
        <Text ml="auto" mr="auto" sx={{ fontSize: "4xl", fontWeight: "medium" }}>
          Choose a different device
        </Text>
      </Flex>

      <Flex sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2rem" }}>
          {phones.map((phone, index) => (
            <Flex
              onClick={() => changeDevice(index)}
              bg="white"
              key={index}
              sx={{
                border: "1px solid",
                borderColor: "border",
                borderRadius: "0.5rem",
                width: "17rem",
                height: "22rem",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                cursor: "pointer",
                ":hover": {
                  borderColor: "cardBorderHoverColor",
                },
              }}>
              <Flex
                key={index}
                sx={{
                  width: "11rem",
                  height: "15rem",
                  justifyContent: "center",
                }}>
                <Image
                  src={phone.media}
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Flex>
              <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
                <Text sx={{ fontSize: "sm" }}>{phone.shortDescription}</Text>
                <Text mt="0.5rem" sx={{ fontWeight: "semibold", fontSize: "xl" }}>
                  ${phone.prices[0].price.toFixed(2)}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

export default DeviceMockup;
