import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { Box, Flex, Text } from "@theme-ui/components";
import { AddButton, NumberSpinner, OSSwitch } from "../../../components/base";
import { changeMultipleInstanceService, selectService } from "../../../config/redux/slices";
import FeatureGroups from "./FeatureGroups";
import * as PlanUtils from "./PlanUtils";
import ServiceFee from "./ServiceFee";

const ServiceGroup = ({
  services,
  parentProductCode,
  isMainPlan,
  onSelectService,
  plusTax = true,
}) => {
  const dispatch = useDispatch();
  const easyUpConfigServices = useSelector(
    (state) => state.additionalServices.getEasyUpConfigServices.easyUpConfigServices
  );
  const dependentEasyUpSrvSelected = useSelector(
    (state) => state.additionalServices.dependentEasyUpSrvSelected
  );

  /**
   * Render a service row, containing the service name and the switch/checkbox component.
   * @param {Number} index The service index.
   * @param {String} serviceId The service id.
   * @param {Boolean} isActive The service active flag.
   * @param {Object} service The service object.
   * @returns {JSX.Element}
   **/
  const renderServiceRow = (index, serviceId, isActive, service) => {
    const isMultipleInstanceService = PlanUtils.isMultipleInstanceService(service);
    const useSwitchComponent = !isMultipleInstanceService;

    const cannotDisableService = PlanUtils.cannotDisableService(service, _.toNumber(serviceId) > 0);
    const nrOfNewInstances = service.instances.filter((instance) => instance.selected).length;
    const isEasyUpDisabled =
      !dependentEasyUpSrvSelected && easyUpConfigServices.easyUpServices?.includes(service.code);

    let srvControlComponent = (
      <>
        {isMultipleInstanceService && nrOfNewInstances === 0 && (
          <AddButton
            className="track"
            id={"add-" + parentProductCode + "-" + service.code}
            ml="auto"
            mr={[2.5, 1, 0]}
            onClick={() => {
              onMultipleInstanceNumberChange(
                service,
                parentProductCode,
                service.minNumberOfInstances
              );
            }}
          />
        )}
        {isMultipleInstanceService && nrOfNewInstances > 0 && (
          <NumberSpinner
            className="track"
            id={"add-" + parentProductCode + "-" + service.code}
            defaultValue={nrOfNewInstances}
            ml="auto"
            maxValue={service.maxNumberOfInstances !== -1 ? service.maxNumberOfInstances : 99}
            minValue={0}
            onNumberChange={(value) =>
              onMultipleInstanceNumberChange(service, parentProductCode, value)
            }
          />
        )}
        {useSwitchComponent && (
          <OSSwitch
            id={
              "add-" + parentProductCode + "-" + service.code + "-" + service.instances[0].selected
            }
            name="serviceValue"
            disabled={cannotDisableService || isEasyUpDisabled}
            ml="auto"
            checked={service.instances[0].selected}
            onChange={() => onServiceUpdate(service, parentProductCode)}
          />
        )}
      </>
    );

    return (
      <Flex sx={{ flexDirection: "column" }} key={index + "_" + serviceId}>
        {PlanUtils.canDisplayService(service) && (
          <Flex mb={!isMainPlan && 6} sx={{ alignItems: "center" }}>
            <Text pr={5} variant={"lineDescription"} sx={{ flex: 1, textAlign: "left" }}>
              {service.osDescription ? service.osDescription : service.description}
            </Text>
            <Flex
              sx={{
                gap: [5, 20, 40],
                justifyContent: "flex-end",
                alignItems: "center",
              }}>
              <ServiceFee rcFee={service.rcFee} setupFee={service.setupFee} plusTax={plusTax} />
              {service.visible && srvControlComponent}
            </Flex>
          </Flex>
        )}
        {useSwitchComponent && (
          <FeatureGroups
            service={service}
            parentProductCode={parentProductCode}
            isMainPlan={isMainPlan}
          />
        )}
        {isMainPlan && index < services.length - 1 && <Box mb={6} />}
      </Flex>
    );
  };

  /**
   * Update service state at service update event.
   * @param {String} serviceCode
   * @param {String} productCode
   */
  const onServiceUpdate = (service, productCode) => {
    dispatch(
      selectService({
        serviceCode: service.code,
        productCode,
      })
    );
    const counter = !service.instances[0].selected ? 1 : -1;
    onSelectService(service.rcFee, service.setupFee, counter);
  };

  /**
   * Update service state when the new number of instances is updated.
   * @param serviceCode
   * @param parentProductCode
   * @param counter
   */
  const onMultipleInstanceNumberChange = (service, parentProductCode, counter) => {
    dispatch(
      changeMultipleInstanceService({ serviceCode: service.code, parentProductCode, counter })
    );
    const selectedInstNo = counter >= service.instances.length ? 1 : -1;
    onSelectService(service.rcFee, service.setupFee, selectedInstNo);
  };

  useEffect(() => {
    const isEasyUpServiceSelected = (service) => {
      return (
        easyUpConfigServices.easyUpServices?.includes(service.code) && service.instances[0].selected
      );
    };

    !dependentEasyUpSrvSelected &&
      services
        .filter((service) => isEasyUpServiceSelected(service))
        .forEach((service) => {
          onSelectService(service.rcFee, service.setupFee, -1);
        });
  }, [
    parentProductCode,
    services,
    dependentEasyUpSrvSelected,
    easyUpConfigServices,
    onSelectService,
  ]);

  let rows = [];
  _.each(services, (service, index) => {
    const serviceId = _.keys(service.instances)[0];
    const isActive = service.instances[serviceId] && service.instances[serviceId].status;

    rows.push(renderServiceRow(index, serviceId, isActive, service));
  });

  if (rows.length === 0) {
    return null;
  }

  return <>{rows}</>;
};

export default ServiceGroup;
