import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { useWindowSize } from "../../hooks";
import { canLogin, PERSONAL_INFORMATION_FLOW } from "../../common/Constants";
import HRTriangle from "../../components/HRTriangle";

const Option = forwardRef(({ title, selected, id, className, ...props }, ref) => {
  const intl = useIntl();

  return (
    <Flex
      id={id}
      className={className}
      ref={ref}
      bg="cardBg"
      p={5}
      sx={{
        width: "100%",
        maxWidth: ["100%", "100%", "17.625rem"],
        border: 1,
        borderColor: selected ? "secondary" : "cardBorderColor",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: "pointer",
        ":hover": !selected && {
          borderColor: "cardBorderHoverColor",
        },
      }}
      {...props}>
      <Flex
        id={id}
        className={className}
        sx={{ flexDirection: "column", justifyContent: "center", textAlign: "center" }}
        py={3}>
        <Text variant="description">{intl.formatMessage({ id: "lbl.i-am" })}</Text>
        <Text variant="heading2">{title}</Text>
      </Flex>
    </Flex>
  );
});

const PageHeader = ({ selectedFlow, onSelectFlow }) => {
  const intl = useIntl();
  const windowSize = useWindowSize();

  const headerContainer = useRef(null);
  const pickOption = useRef(null);
  const keepOption = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);

  useLayoutEffect(() => {
    if (selectedFlow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER) {
      setHrTrianglePosition(
        pickOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          pickOption.current.offsetWidth / 2
      );
    }

    if (canLogin(selectedFlow)) {
      setHrTrianglePosition(
        keepOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          keepOption.current.offsetWidth / 2
      );
    }
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
  }, [selectedFlow, windowSize.width]);

  return (
    <>
      <Flex
        sx={{ flexDirection: ["column", "column", "row"] }}
        mb={selectedFlow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER && 9.5}
        px={[8, 8, 9]}
        ref={headerContainer}>
        <Flex
          sx={{
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-start",
            maxWidth: ["auto", "auto", "14.375rem"],
          }}>
          <Text variant="heading3normal">
            {intl.formatMessage({ id: "lbl.enter-your-details" })}
          </Text>
        </Flex>

        <Flex ml={["none", "none", 16]} mt={[5, 5, "none"]}>
          <Option
            ref={pickOption}
            title={intl.formatMessage({ id: "lbl.a-new-customer" })}
            onClick={() =>
              selectedFlow !== PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER &&
              onSelectFlow(PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER)
            }
            selected={selectedFlow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER}
          />

          <Option
            id="existingCustomer"
            className="track"
            ref={keepOption}
            title={intl.formatMessage({ id: "lbl.an-existing-client" })}
            onClick={() => {
              selectedFlow !== PERSONAL_INFORMATION_FLOW.EXISTING_ADD &&
                onSelectFlow(PERSONAL_INFORMATION_FLOW.EXISTING_ADD);
            }}
            selected={selectedFlow === PERSONAL_INFORMATION_FLOW.EXISTING_ADD}
            ml={4}
          />
        </Flex>
      </Flex>

      {selectedFlow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER && (
        <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
      )}
    </>
  );
};

export default PageHeader;
