import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Flex, Text } from "@theme-ui/components";
import { PRICE_TYPE } from "../../../common/Constants";
import Amount from "../../amount";
import { Heading6 } from "../../heading";

const OldDeviceInstallment = ({ item, showPrices, plusTax }) => {
  const intl = useIntl();

  const oldInstallmentPrice = item.prices.find(
    (price) => price.type === PRICE_TYPE.OLD_INSTALLMENT
  );
  const getDevicePriceTaxInfo = () => {
    let price = item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
    if (!price) {
      price = item.prices.find((price) => price.type === PRICE_TYPE.RECURRING);
    }
    if (price && price.extraInfo && price.extraInfo.length > 0) {
      return Object.fromEntries(price.extraInfo.split(";").map((elem) => elem.split("=")))[
        "IS_TAX_FREE"
      ];
    }
    return null;
  };

  if (oldInstallmentPrice && oldInstallmentPrice.price > 0) {
    return (
      <Flex sx={{ flexDirection: "column" }} mt={4}>
        <Flex mb={2}>
          <Flex mt={2} sx={{ flexDirection: "column", flex: 1 }}>
            <Heading6 color="ternary" mb={1.5}>
              <FormattedMessage
                textComponent="span"
                id="lbl.old-easy-tab"
                values={{
                  sup: (chunks) => (
                    <Text
                      as="sup"
                      mr={0}
                      sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Heading6>
          </Flex>

          {showPrices && (
            <Flex sx={{ flexDirection: "column" }} ml={2} mr={[8, 8, 0]}>
              <Amount
                type="semibold_large"
                amount={oldInstallmentPrice.price}
                description={intl.formatMessage({ id: "lbl.upfront" })}
              />
              {!plusTax && getDevicePriceTaxInfo() && getDevicePriceTaxInfo() !== "Y" && (
                <Text variant="taxDescription">
                  {intl.formatMessage({ id: "lbl.tax-included" })}
                </Text>
              )}
              {(plusTax || !getDevicePriceTaxInfo()) && (
                <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  }
  return null;
};

export default OldDeviceInstallment;
