import React from "react";
import { Box } from "@theme-ui/components";

const ArrowLeft = ({ color = "svgStrokePrimary", size = "iconTiny", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.802 14.003"
    {...props}
    sx={{ width: size, height: size, fill: color, flexShrink: 0 }}>
    <g transform="translate(-0.098 0)">
      <path
        d="M16.1,6.3H2.138L7.485,1.207A.7.7,0,0,0,6.519.193L.412,6.01A1.4,1.4,0,0,0,.424,8l6.094,5.8A.7.7,0,0,0,7.484,12.8L2.114,7.7H16.1a.7.7,0,1,0,0-1.4Z"
        transform="translate(0.098 0)"
      />
    </g>
  </Box>
);

export default ArrowLeft;
