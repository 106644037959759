import qs from "qs";
import Storage from "store2";
import { ErrorCode } from "../../common/Constants";
import { OSMindException } from "../../exceptions/OSMindException";

const ABORT_TIMEOUT = 15000;

class RestMockup {
  constructor(apiUrl = "", { headers = {} } = {}) {
    // if (!apiUrl) {
    //   throw new Error("Missing apiUrl!");
    // }

    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (Storage.has("access_token")) {
      this.headers.Authorization = Storage.get("access_token");
    }

    Object.assign(this.headers, headers);
    this.apiUrl = apiUrl;
  }

  _fullRoute(url) {
    return `${this.apiUrl}${url}`;
  }

  async _fetch(route, method, body, params) {
    if (!route) {
      throw new Error("Route is undefined!");
    }

    var fullRoute = route.startsWith("http") ? route : this._fullRoute(route);

    const {
      isQuery = false,
      isForm = false,
      expectedResponse = "json",
      noTimeout = false,
      abortHandle = null,
      noAuthorization = false,
      authorization = null,
    } = params;

    if (isQuery && body) {
      const query = qs.stringify(body);
      fullRoute = `${fullRoute}?${query}`;
      body = undefined;
    }
    let localAbortController = new AbortController();
    // client requested an abort handle
    if (abortHandle) {
      abortHandle.abort = () => localAbortController.abort();
    }
    let timeoutId;
    if (!noTimeout) {
      let triggerAbort = (abortControllerObj) => {
        abortControllerObj.signal.source = "TIMEOUT";
        abortControllerObj.abort();
      };
      timeoutId = setTimeout(() => triggerAbort(localAbortController), ABORT_TIMEOUT);
    }

    if (isForm) {
      this.headers["Content-Type"] = "application/x-www-form-urlencoded";
    } else {
      this.headers["Content-Type"] = "application/json";
    }

    if (authorization) {
      this.headers.Authorization = authorization.type + " " + authorization.token;
    }

    if (noAuthorization) {
      delete this.headers.Authorization;
    }

    if (!noAuthorization && !this.headers.Authorization && Storage.has("access_token")) {
      this.headers.Authorization = Storage.get("access_token");
    }

    let opts = {
      method,
      headers: this.headers,
      signal: localAbortController.signal,
    };
    if (body) {
      Object.assign(opts, { body: isForm ? qs.stringify(body) : JSON.stringify(body) });
    }

    console.log("FacadeAPI Call: " + JSON.stringify(fullRoute));
    console.log("REQUEST: " + JSON.stringify(opts));

    try {
      // Perform fetch
      const response = await fetch(fullRoute, opts);
      const isOK = response.ok;

      if (isOK) {
        if ("json" === expectedResponse) {
          const responseJson = await response.json();
          if (response.headers.has("Authorization")) {
            this.headers.Authorization = response.headers.get("Authorization");
            Storage.set("access_token", response.headers.get("Authorization"));
          }
          console.log("RESPONSE: " + JSON.stringify(fullRoute) + JSON.stringify(responseJson));
          return responseJson;
        } else if ("blob" === expectedResponse) {
          const responseBlob = await response.blob();
          console.log("RESPONSE: " + JSON.stringify(responseBlob));
          return responseBlob;
        } else if ("text" === expectedResponse) {
          const responseText = await response.text();
          console.log("RESPONSE: " + responseText);
          return responseText;
        } else {
          // no response body
        }
      } else {
        return response.json().then(
          (err) => {
            console.log("ERROR: " + JSON.stringify(err));
            throw err;
          },
          () => {
            console.log("NO RESPONSE ERROR");
            throw new OSMindException(ErrorCode.API_OFFLINE);
          }
        );
      }
    } catch (error) {
      console.log("FETCH ERROR: " + error);
      if (error.name === "AbortError" && localAbortController.signal.source === "TIMEOUT") {
        throw OSMindException(ErrorCode.ABORT_ON_TIMEOUT_ERROR);
      }
      throw error;
    } finally {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }
  }

  async GET(route, query, params = {}) {
    return await this._fetch(route, "GET", query, { isQuery: true, ...params });
  }

  async POST(route, body, params = {}) {
    return await this._fetch(route, "POST", body, params);
  }

  async PUT(route, body, params = {}) {
    return await this._fetch(route, "PUT", body, params);
  }

  async DELETE(route, body, params = {}) {
    return await this._fetch(route, "DELETE", body, params);
  }
}

export const FacadeMockup = new RestMockup();
