import React from "react";
import { Heading, Box, Flex, Text } from "@theme-ui/components";
import { OSIcon } from "../../components/icons";
import Separator from "../separator";
import HRTriangle from "../HRTriangle";

const CollapsibleHeader = ({
  primaryTitle,
  secondaryTitle,
  collapsed,
  headerDetails,
  hrWidth,
  hrTrianglePosition,
  onCollapsed,
}) => {
  const deviceShortDesc = headerDetails && headerDetails[0];
  const planShortDesc = headerDetails && headerDetails[1];
  return (
    <Box mb={!collapsed && 4}>
      <Box px={[8, 8, 9]}>
        <Flex
          sx={{
            alignItems: ["flex-start", "flex-start", "center"],
            justifyContent: "space-between",
            flexDirection: ["row", "row", "row"],
          }}
          mb={3}>
          <Flex
            sx={{
              alignItems: ["flex-start", "flex-start", "center"],
              justifyContent: "space-between",
              width: ["100%", "100%", "90%"],
              flexDirection: ["column", "column", "row"],
            }}>
            <Flex
              sx={{
                width: ["100%", "100%", collapsed ? "70%" : "100%"],
                flexDirection: ["column", "row", "row"],
              }}>
              <Heading variant="CollapsibleHeader" color={collapsed ? "ternary" : "primary"}>
                {primaryTitle}
              </Heading>
              <Heading
                variant="CollapsibleHeaderLight"
                ml={[0, 2, 2]}
                color={collapsed ? "ternary" : "primary"}>
                {secondaryTitle}
              </Heading>
            </Flex>

            {collapsed && (
              <Flex
                mt={[2, 2, 0]}
                sx={{
                  width: ["100%", "100%", "30%"],
                  alignSelf: ["flex-start", "flex-start", "center"],
                  justifyContent: ["flex-start", "flex-start", "flex-start"],
                }}>
                <Text
                  sx={{
                    width: "30%",
                    fontWeight: "medium",
                    fontSize: ["sm", "sm", "sm"],
                  }}
                  color="primary">
                  {deviceShortDesc}
                </Text>
                <Text
                  sx={{
                    width: "70%",
                    fontWeight: "medium",
                    fontSize: ["sm", "sm", "sm"],
                  }}
                  ml={[6, 4, 6]}
                  color="primary">
                  {planShortDesc}
                </Text>
              </Flex>
            )}
          </Flex>

          <Flex
            sx={{
              width: ["10%", "10%", "10%"],
              alignSelf: "center",
              justifyContent: "flex-end",
            }}
            onClick={onCollapsed}>
            <OSIcon name="chevron" rotate={collapsed ? "-90" : "90"} display="block" />
          </Flex>
        </Flex>
      </Box>
      {collapsed ? (
        <Separator variant="gray-border" my={3} />
      ) : (
        <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
      )}
    </Box>
  );
};

export default CollapsibleHeader;
