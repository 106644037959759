import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Flex, Text } from "@theme-ui/components";
import dayjs from "dayjs";
import { FormInput } from "../../components/base";
import CollapsibleHeader from "../../components/collapsible-header/CollapsibleHeader";
import { OSIcon } from "../../components/icons";
import { getDraftContract, updateEmail } from "../../config/redux/slices";
import { useWindowSize } from "../../hooks";
import Tooltip from "../../components/tooltip/Tooltip";
import { clearUpdateEmail } from "../../config/redux/slices/PersonalInformationSlice";

const PersonalInformationSummary = ({ ...props }) => {
  const { summaryData } = props;
  const intl = useIntl();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const { register, errors } = useForm({ mode: "onTouched" });
  const reactHookFormHandle = { register, errors };

  const [collapsed, setCollapsed] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const [isEditEmail, setIsEditEmail] = useState(false);
  const [emailAddress, setEmailAddress] = useState(summaryData.emailAddress);
  const updateEmailState = useSelector((state) => state.personalInformation.updateEmail);

  const summaryContainer = useRef(null);

  useLayoutEffect(() => {
    setHrTrianglePosition(summaryContainer.current.offsetWidth / 4);
    setHrWidth(
      summaryContainer && summaryContainer.current ? summaryContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  const changeEmailHandler = () => {
    if (!isEditEmail) {
      setIsEditEmail(true);
    } else {
      emailAddress &&
        summaryData.emailAddress !== emailAddress &&
        dispatch(updateEmail({ newEmail: emailAddress }));
      setIsEditEmail(false);
    }
  };

  useEffect(() => {
    if (updateEmailState.success) {
      dispatch(getDraftContract());
      dispatch(clearUpdateEmail());
    }
  }, [dispatch, updateEmailState.success]);

  const isValidBirthDate =
    summaryData.dobDate &&
    new Date(summaryData.dobDate).toDateString() !== new Date(null).toDateString();

  return (
    <Box ref={summaryContainer} mb={!collapsed && 6}>
      <CollapsibleHeader
        primaryTitle={intl.formatMessage({ id: "header.personal-information" })}
        hrWidth={hrWidth}
        hrTrianglePosition={hrTrianglePosition}
        collapsed={collapsed}
        onCollapsed={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (
        <Flex
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: ["column", "row", "row"],
            px: [8, 8, 9],
            display: "grid",
            gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"],
            gap: [0, 6, 20],
          }}>
          <Flex
            sx={{
              flexDirection: ["column", "column", "row"],
              width: ["100%", "100%", "100%"],
              justifyContent: "space-between",
              gap: [0, 0, 20],
            }}>
            <Flex
              sx={{
                flexDirection: "column",
                width: ["100%", "100%", "100%"],
                alignItems: "flex-start",
              }}>
              <Flex sx={{ alignItems: "center", display: "block", width: "100%" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.name",
                  })}
                  :
                </Text>
                <Text
                  variant="summaryValue"
                  sx={{
                    overflowWrap: "break-word",
                  }}>
                  {`${summaryData.firstName} ${summaryData.lastName}`}
                </Text>
              </Flex>
              <Flex sx={{ alignItems: "center" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.date-of-birth",
                  })}
                  :
                </Text>
                <Text variant="summaryValue">
                  {isValidBirthDate && dayjs(summaryData.dobDate).format("LL")}
                </Text>
              </Flex>
            </Flex>

            <Flex
              sx={{
                flexDirection: "column",
                width: ["100%", "100%", "100%"],
                mt: [2, 4, "none"],
                alignItems: "flex-start",
              }}>
              <Flex sx={{ display: "block", alignItems: "center" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.identity-type",
                  })}
                  :
                </Text>
                <Text variant="summaryValue">{summaryData.identityTypeDesc}</Text>
              </Flex>
              <Flex sx={{ display: "block", alignItems: "center" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.identity-number",
                  })}
                  :
                </Text>
                <Text variant="summaryValue">{summaryData.identityNumber}</Text>
              </Flex>
            </Flex>
          </Flex>

          <Flex
            sx={{
              flexDirection: ["column", "column", "row"],
              width: "100%",
              mt: [2, 0, 0],
              gap: [0, 0, 20],
              alignItems: "space-between",
            }}>
            <Flex
              sx={{
                display: "block",
                width: ["100%", "100%", "100%"],
                alignItems: "flex-start",
                flexDirection: "column",
              }}>
              <Text variant="summaryLabel">
                {intl.formatMessage({
                  id: "lbl.home-address",
                })}
                :
              </Text>
              <Text variant="summaryValue">{summaryData.address}</Text>
              <Text variant="summaryValue" mr={1}>
                , {`${summaryData.city} ${summaryData.state} ${summaryData.postalCode}`}
              </Text>
            </Flex>

            <Flex
              mt={[2, 4, 0]}
              sx={{
                display: "block",
                width: ["100%", "100%", "100%"],
                flexDirection: "column",
                alignItems: "flex-start",
              }}>
              <Flex sx={{ display: "block", alignItems: "center" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.primary-number",
                  })}
                  :
                </Text>
                <Text variant="summaryValue">{summaryData.phoneNumber}</Text>
              </Flex>
              <Flex sx={{ display: "block", alignItems: "center" }}>
                <Text variant="summaryLabel">
                  {intl.formatMessage({
                    id: "lbl.alternate-phone-number",
                  })}
                  :
                </Text>
                <Text variant="summaryValue">{summaryData.alternatePhoneNumber}</Text>
              </Flex>
              <Flex sx={{ display: "block", alignItems: "center" }}>
                {!isEditEmail ? (
                  <Flex display="block" sx={{ alignItems: "center" }}>
                    <Text variant="summaryLabel">
                      {intl.formatMessage({
                        id: "lbl.email-address",
                      })}
                      :
                    </Text>
                    <Tooltip
                      display="flex"
                      position="custom"
                      customProps={{
                        desktop: { mt: "5rem", ml: "-2rem" },
                        tablet: { mt: "4rem", ml: "-2rem" },
                        mobile: { mt: "4rem", ml: "-2rem" },
                      }}
                      value={summaryData.emailAddress}
                      sx={{
                        maxWidth: "12rem",
                      }}>
                      <Text
                        variant="summaryLabel"
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}>
                        {summaryData.emailAddress}
                      </Text>
                    </Tooltip>
                    <Box id="editEmail">
                      <OSIcon name="edit-icon" onClick={changeEmailHandler} />
                    </Box>
                  </Flex>
                ) : (
                  <Flex sx={{ alignItems: "center" }}>
                    <FormInput
                      isMandatory
                      label={intl.formatMessage({
                        id: "lbl.email-address",
                      })}
                      labelVariant="labelSmall"
                      errorProps={{ sx: { textAlign: "left", width: "12rem" } }}
                      defaultValue={summaryData.emailAddress}
                      {...reactHookFormHandle}
                      validations={{
                        pattern: {
                          value: /^([A-Za-z0-9._%-]+)@([A-Za-z0-9.-]+\.[a-zA-Z]{2,})$/i,
                          message: intl.formatMessage({ id: "err.email-pattern-invalid" }),
                        },
                      }}
                      maxLength="80"
                      name="emailAddress"
                      type="text"
                      onChange={(event) => setEmailAddress(event.target.value)}
                    />
                    <Button
                      id="submitChangeEmail"
                      variant="base-button"
                      ml={[2, 2, 3]}
                      px={[2, 2, 3]}
                      backgroundColor="ternary"
                      mb={errors.emailAddress && errors.emailAddress.message && 10}
                      mt={isEditEmail && 6}
                      disabled={errors.emailAddress}
                      onClick={changeEmailHandler}>
                      <FormattedMessage id="btn.save" />
                    </Button>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default PersonalInformationSummary;
