import React from "react";
import { Box } from "@theme-ui/components";

const InsuranceCard = ({ color = "svgStrokePrimary", size = "iconMedium", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25 18"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <g transform="translate(0.5 0.5)">
      <g>
        <g transform="translate(0)">
          <g>
            <g>
              <path
                d="M336.5,222h24"
                transform="translate(-336.5 -217.561)"
                fill="none"
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="2"
                opacity="0.4"
              />
              <path
                d="M357.978,226H349.5"
                transform="translate(-337.561 -217.5)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                opacity="0.4"
              />
              <path
                d="M352.679,229H349.5"
                transform="translate(-337.183 -217.312)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                opacity="0.4"
              />
              <path
                d="M336.5,220.125A2.106,2.106,0,0,1,338.587,218h19.826a2.106,2.106,0,0,1,2.087,2.125v12.75A2.106,2.106,0,0,1,358.413,235H338.587a2.106,2.106,0,0,1-2.087-2.125Z"
                transform="translate(-336.5 -218)"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                fillRule="evenodd"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Box>
);

export default InsuranceCard;
