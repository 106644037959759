import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex, Text, Button } from "@theme-ui/components";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Storage from "store2";

import Header from "../header";
import Tooltip from "../tooltip";
import { Heading1 } from "../heading";
import ShoppingCart from "../shopping-cart";
import { OSIcon } from "../icons";
import { CHECKOUT_STEPS } from "../header/Constants";
import { PERSONAL_INFORMATION_FLOW, SESSION_STATUS } from "../../common/Constants";
import {
  clearGetBundleDiscounts,
  clearGetSessionResponse,
  clearLoadPersonalInfoApiResponse,
  clearLogoutSession,
  deleteGroupOperations,
  getSession,
  loadPersonalInformation,
  logoutSession,
  checkAndRefreshToken,
} from "../../config/redux/slices";
import { StyledModalMessage } from "../modals";
import GetErrorDescription from "../get-error-description";
import { getPreviousStep } from "../../common/StepsUtilities";
import LangCode from "../../config/intl/LangCode";
import { OSIntlContext } from "../../contexts/OSIntlContext";
import { storefrontURLs } from "../../config/security/Deployment";
import ErrorHandler from "../error-handler";

const Layout = ({
  children,
  paymentSuccess,
  checkoutStep,
  showSteps = true,
  showCart = true,
  loadSession = true,
  pageTitle,
  nextStepButton: Next,
  isMultipleTabHeaderPage = false,
  isInitialTab = true,
  parentCallback = null,
  checkSessionStatus = true,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const intlParams = useContext(OSIntlContext);

  const personalInformationData = useSelector((state) => state.personalInformation);
  const { status, sessionId, ...sessionState } = useSelector((state) => state.session);
  const { planFlags } = useSelector((state) => state.items);
  const [showBackWarningMsg, setShowBackWarningMsg] = useState(false);

  const { loggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    if (sessionId && loadSession) {
      loggedIn && dispatch(checkAndRefreshToken());
      dispatch(getSession());
    }
  }, [dispatch, loadSession, loggedIn, sessionId]);

  useEffect(() => {
    if (sessionState.deleteSession.success) {
      Storage.clear();
      window.location.replace(storefrontURLs.base);
    }
  }, [sessionState.deleteSession.success]);

  useEffect(() => {
    if (loadSession && sessionState.getSession.error) {
      dispatch(clearLoadPersonalInfoApiResponse());
      history.push("/error");
    }
  }, [dispatch, history, loadSession, sessionState.getSession.error]);

  useEffect(() => {
    if (loadSession && checkSessionStatus && sessionState.getSession.success) {
      dispatch(clearGetSessionResponse());
      switch (status) {
        case SESSION_STATUS.VALIDATE_IDENTITY:
          console.log("session started, redirecting to validate identity");
          history.replace("/validate-identity");
          break;
        case SESSION_STATUS.VALIDATE_PIN:
          console.log("session started, redirecting to validate pin");
          history.replace("/validate-pin");
          break;
        case SESSION_STATUS.FINISHED:
          history.replace("/error");
          break;
      }
    }
  }, [checkSessionStatus, dispatch, history, loadSession, sessionState.getSession.success, status]);

  useEffect(() => {
    if (!personalInformationData.loaded && sessionId && loadSession) {
      dispatch(loadPersonalInformation());
    }
  }, [dispatch, loadSession, personalInformationData.loaded, sessionId]);

  useEffect(() => {
    if (personalInformationData.loadPersonalInformation.success) {
      intlParams.switchLanguage(LangCode[personalInformationData.language]);
      dispatch(clearLoadPersonalInfoApiResponse());
    }
  }, [
    dispatch,
    intlParams,
    personalInformationData.language,
    personalInformationData.loadPersonalInformation.success,
  ]);

  const doClearLogoutSession = useCallback(() => {
    dispatch(clearLogoutSession());
  }, [dispatch]);

  const resetShowBackWarningMsg = () => {
    setShowBackWarningMsg(false);
  };

  useEffect(() => {
    if (sessionState.logoutSession.success) {
      doClearLogoutSession();
      if (status !== SESSION_STATUS.FINISHED)
        history.push(CHECKOUT_STEPS.PERSONAL_INFORMATION.route);
    }
  }, [doClearLogoutSession, status, history, sessionState.logoutSession.success]);

  const onLogout = () => {
    dispatch(logoutSession());
  };

  const goToPreviousStep = (confirmedMessage) => {
    let hugBackFlow =
      personalInformationData.flow &&
      personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
      checkoutStep === 3 &&
      isInitialTab;
    if (hugBackFlow && !confirmedMessage) {
      setShowBackWarningMsg(true);
      return;
    }
    if (hugBackFlow) {
      dispatch(deleteGroupOperations());
      dispatch(clearGetBundleDiscounts());
    }
    setShowBackWarningMsg(false);
    if (isMultipleTabHeaderPage && !isInitialTab) {
      parentCallback(true);
    } else {
      if (checkoutStep === CHECKOUT_STEPS.PHONE_NUMBER.index) {
        dispatch(clearGetBundleDiscounts());
      }
      history.push(getPreviousStep(planFlags, checkoutStep, personalInformationData.flow).route);
    }
  };

  const isPaymentFinished =
    checkoutStep === CHECKOUT_STEPS.PAYMENT_INFORMATION.index && paymentSuccess === true;

  const isBackAllowed =
    personalInformationData.flow &&
    ((personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
      (checkoutStep > 2 || (checkoutStep === 2 && !isInitialTab))) ||
      (personalInformationData.flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
        (checkoutStep > 3 || (checkoutStep === 3 && !isInitialTab))));

  const isLogoutDisplayed =
    personalInformationData.flow &&
    ((personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_ADD &&
      checkoutStep >= CHECKOUT_STEPS.DEVICE_AND_PLAN.index) ||
      (personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
        checkoutStep >= CHECKOUT_STEPS.UPDATE_DEVICE.index)) &&
    status !== SESSION_STATUS.FINISHED;

  return (
    <Box bg="windowBg" sx={{ minHeight: "100vh" }} pb={1}>
      <Header checkoutStep={checkoutStep} showSteps={showSteps} paymentSuccess={paymentSuccess} />

      <StyledModalMessage
        isOpen={
          (sessionState.getSession.loading || !sessionState.getSession.error) &&
          personalInformationData.loadPersonalInformation.error
        }
        message={
          <GetErrorDescription error={personalInformationData.loadPersonalInformation.error} />
        }
        onRequestClose={() => dispatch(clearLoadPersonalInfoApiResponse())}
        type="error">
        <Button
          variant="simple-action"
          onClick={() => {
            dispatch(loadPersonalInformation());
          }}>
          <FormattedMessage id="lbl.try_again" />
        </Button>
      </StyledModalMessage>

      {/*logout error */}
      <ErrorHandler
        error={sessionState.logoutSession.error}
        closeHandler={doClearLogoutSession}
        clickHandler={doClearLogoutSession}
        message={<GetErrorDescription />}
      />

      {/*Back button warning*/}
      <StyledModalMessage
        isOpen={showBackWarningMsg}
        onRequestClose={resetShowBackWarningMsg}
        message={intl.formatMessage({ id: "lbl.back-warning" })}>
        <Button mr={20} variant="simple-action" onClick={() => goToPreviousStep(true)}>
          <FormattedMessage id="btn.ok" />
        </Button>
        <Button variant="simple-action" onClick={resetShowBackWarningMsg}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </StyledModalMessage>

      <Box sx={{ width: "100%", maxWidth: "108.25rem" }} mx="auto" px={4} mb={[12, 12, 15]}>
        {/** MOBILE/TABLET */}
        <Flex
          sx={{
            alignItems: "flex-end",
            justifyContent: isBackAllowed && !isPaymentFinished ? "space-between" : "flex-end",
          }}>
          {isBackAllowed && !isPaymentFinished && (
            <Box
              mt={8}
              onClick={() => goToPreviousStep()}
              sx={{ alignItems: "center", cursor: "pointer", display: ["flex", "flex", "none"] }}>
              <OSIcon name="arrow-left" mr={2.5} />
              <Text variant="attribute" sx={{ color: "#000" }}>
                {intl.formatMessage({ id: "btn.back" })}
              </Text>
            </Box>
          )}
          {isLogoutDisplayed && (
            <Flex sx={{ display: ["flex", "flex", "none"], alignItems: "inherit" }} mt={8}>
              <Text
                variant="attribute"
                sx={{
                  fontWeight: "semibold",
                  whiteSpace: "break-spaces",
                  overflowWrap: "break-word",
                }}
                color="secondary"
                mr={4}>
                {personalInformationData.firstName} {personalInformationData.lastName}
              </Text>
              <Box sx={{ cursor: "pointer" }}>
                <Tooltip position="top" value={intl.formatMessage({ id: "lbl.logout" })}>
                  <OSIcon name="logout" onClick={onLogout} />
                </Tooltip>
              </Box>
            </Flex>
          )}
        </Flex>

        {/** DESKTOP */}
        <Flex sx={{ alignItems: "flex-end", justifyContent: "space-between" }}>
          <Heading1 sx={{ display: ["none", "none", "inline-block"] }}>{pageTitle}</Heading1>

          {isLogoutDisplayed && (
            <Flex sx={{ alignItems: "center", display: ["none", "none", "flex"] }} mr={4}>
              <Text
                variant="heading5"
                color="secondary"
                mr={4}
                sx={{
                  whiteSpace: "break-spaces",
                  overflowWrap: "break-word",
                }}>
                {personalInformationData.firstName} {personalInformationData.lastName}
              </Text>
              <Box sx={{ cursor: "pointer" }}>
                <Tooltip position="top" value={intl.formatMessage({ id: "lbl.logout" })}>
                  <OSIcon name="logout" onClick={onLogout} />
                </Tooltip>
              </Box>
            </Flex>
          )}
        </Flex>

        <Flex sx={{ flexDirection: ["column-reverse", "column-reverse", "row"] }}>
          <Flex
            sx={{
              width: "100%",
              maxWidth: showCart ? "79.125rem" : "100%",
              flexDirection: "column",
            }}>
            {/** MOBILE/TABLET */}
            <Heading1 sx={{ display: ["inline-block", "inline-block", "none"] }}>
              {pageTitle}
            </Heading1>

            {children}

            {/** DESKTOP */}
            <Box
              sx={{
                display: ["none", "none", "flex"],
                alignItems: "center",
                justifyContent: isBackAllowed && !isPaymentFinished ? "space-between" : "flex-end",
              }}
              mt={7.5}>
              {isBackAllowed && !isPaymentFinished && (
                <Flex
                  onClick={() => {
                    goToPreviousStep();
                  }}
                  sx={{ cursor: "pointer", alignItems: "center" }}>
                  <OSIcon name="arrow-left" mr={2.5} />
                  <Text variant="attribute">{intl.formatMessage({ id: "btn.back" })}</Text>
                </Flex>
              )}
              {Next && <Next />}
            </Box>

            {/** MOBILE/TABLET */}
            <Box
              mt={7.5}
              sx={{
                textAlign: "center",
                display: ["flex", "flex", "none"],
                justifyContent: isBackAllowed && !isPaymentFinished ? "space-between" : "flex-end",
              }}>
              {isBackAllowed && !isPaymentFinished && (
                <Flex
                  onClick={() => {
                    goToPreviousStep();
                  }}
                  sx={{ cursor: "pointer", alignItems: "center" }}>
                  <OSIcon name="arrow-left" mr={2.5} />
                  <Text variant="attribute">{intl.formatMessage({ id: "btn.back" })}</Text>
                </Flex>
              )}
              {Next && <Next />}
            </Box>
          </Flex>

          {showCart && (
            <ShoppingCart
              showShipping={checkoutStep > CHECKOUT_STEPS.SHIPPING_DETAILS.index}
              plusTax={checkoutStep === CHECKOUT_STEPS.PERSONAL_INFORMATION.index}
              showAllItems={
                personalInformationData.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE &&
                checkoutStep <= CHECKOUT_STEPS.UPDATE_DEVICE.index
              }
              flow={personalInformationData.flow}
              sx={{
                width: "100%",
                maxWidth: ["auto", "auto", "25.25rem"],
                height: "0%",
              }}
              ml={["none", "none", 7.5]}
              mt={[8, 8, "none"]}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default Layout;
