import React from "react";
import { Box, Flex, Text } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";

import { OSContainer } from "../../../components/base";
import { Heading3, Heading4 } from "../../../components/heading";
import { OSIcon } from "../../../components/icons";
import Amount from "../../../components/amount";

const OldDevice = ({ device, taxIncluded, ...props }) => {
  const intl = useIntl();

  return (
    <Box mt={4} sx={{ width: "100%" }} {...props}>
      <Flex
        mt={[2, 2, "none"]}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: ["100%", "100%", "auto"],
        }}>
        <Heading3 color="primary">{intl.formatMessage({ id: "lbl.old-device" })}</Heading3>
      </Flex>
      <OSContainer variant="device-and-plan-section">
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["center", "center", "flex-start"],
            minWidth: ["50%", "25%", "20%"],
            flexDirection: ["column", "column", "row"],
            flex: 1,
          }}>
          <Flex
            sx={{
              width: ["100%", "100%", "30%"],
              justifyContent: "center",
            }}>
            <Flex
              sx={{
                justifyContent: "flex-start",
                minWidth: "7rem",
                width: "7rem",
              }}>
              <OSIcon name="no-device" />
            </Flex>
            <Flex sx={{ justifyContent: "center", flexWrap: "wrap" }}>
              <Heading4 sx={{ textAlign: "center", minWidth: "7.125rem" }}>{device.model}</Heading4>
            </Flex>
          </Flex>
          <Box
            mt={[5, 5, "none"]}
            sx={{
              alignItems: ["center", "center", "flex-start"],
              flexDirection: "column",
              width: ["100%", "100%", "70%"],
            }}
            mx={["none", "none", 8]}>
            <Text variant="lineDescription">
              <FormattedMessage
                id="lbl.pay-old-phone"
                values={{
                  sup: (chunks) => (
                    <Text
                      as="sup"
                      mr={0}
                      sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Text>
          </Box>
        </Flex>
        <Flex
          mt={[5, 5, "none"]}
          ml={[0, 0, "auto"]}
          mb={4}
          sx={{
            flexDirection: "column",
            minWidth: ["15rem", "70%", "30%"],
          }}>
          <Flex
            px={[2, 4, 4]}
            py={4}
            sx={{
              border: 1,
              borderColor: "secondary",
              borderRadius: "lg",
              justifyContent: "center",
            }}>
            <Amount
              amount={device.remainingAmount}
              description={intl.formatMessage({ id: "lbl.upfront-payment" })}
              color="ternary"
              descriptionColor="primary"
            />
          </Flex>
          {taxIncluded && (
            <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.tax-included" })}</Text>
          )}
        </Flex>
      </OSContainer>
    </Box>
  );
};
export default OldDevice;
