import React from "react";
import { useIntl } from "react-intl";
import { Flex, Image, Text } from "@theme-ui/components";
import {
  EXTRA_INFO,
  HIDDEN_ATTRIBUTES,
  PRICE_TERMS_UNIT,
  PRICE_TYPE,
} from "../../../common/Constants";
import Amount from "../../amount";
import { Heading6 } from "../../heading";
import { getExtraInfoValues } from "../../../common/StringUtilities";
import AmountDescription from "../../amount-description";

/**
 * @typedef {Object} ItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                        - The price.
 * @property {String} type                         - Price type.
 * @property {Number} termsAmount                  - Price terms - amount.
 * @property {String} termsUnit                    - Price terms - unit.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {ItemPrice[]} prices                 - List of item's prices.
 * @property {ItemAttribute[]} attributes         - List of item's attributes.
 */
/**
 * Renders a device item.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 */
const Device = ({ item, plusTax, showPrices }) => {
  const intl = useIntl();

  const recurringPrice = item.prices.find((price) => price.type === PRICE_TYPE.RECURRING);
  const oneTimePrice = item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);

  const hasRecurringPrice = recurringPrice !== undefined;
  const hasOneTimePrice = oneTimePrice !== undefined;

  const isFullPriceDevice = !hasRecurringPrice && hasOneTimePrice;
  const hasDownPayment = !isFullPriceDevice && hasOneTimePrice;

  const getDevicePriceTaxInfo = () => {
    let price = oneTimePrice ? oneTimePrice : recurringPrice;
    if (price && price.extraInfo && price.extraInfo.length > 0) {
      return Object.fromEntries(price.extraInfo.split(";").map((elem) => elem.split("=")))[
        "IS_TAX_FREE"
      ];
    }
    return null;
  };

  const pricingOption =
    item?.extraInfo?.length > 0
      ? getExtraInfoValues(item.extraInfo)[EXTRA_INFO.SELECTED_PRICING_OPTION]
      : "";

  return (
    item.sku !== null && (
      <Flex sx={{ flexDirection: "column" }}>
        <Flex mb={2}>
          <Flex sx={{ flex: 1 }}>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "4.5625rem",
                width: "4.5625rem",
                minWidth: "4.5625rem",
              }}
              mr={3}>
              <Image
                src={item.thumbnailURL}
                alt="device"
                sx={{ objectFit: "contain", width: "4.5625rem", height: "4.5625rem" }}
              />
            </Flex>
            <Flex sx={{ flexDirection: "column" }}>
              {/* Device description */}
              <Heading6 color="ternary" mb={1.5}>
                {item.shortDescription}
              </Heading6>

              {/* Device attributes */}
              {item.attributes && item.attributes.length > 0 && (
                <Text color="ternary" variant="summaryLabel" mr={0}>
                  (
                  {item.attributes
                    .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.code))
                    .map((attr) => attr.value)
                    .join(", ")}
                  )
                </Text>
              )}
            </Flex>
          </Flex>

          {showPrices && (
            <Flex sx={{ flexDirection: "column" }} ml={2} mr={[8, 8, 0]}>
              {/* Device sold at full price */}
              {isFullPriceDevice && (
                <Amount
                  type="semibold_large"
                  description={!plusTax && intl.formatMessage({ id: "lbl.upfront-payment" })}
                  amount={oneTimePrice.price + oneTimePrice.tax}
                />
              )}

              {/* Device sold with installments, based on specific number of months */}
              {!isFullPriceDevice &&
                recurringPrice &&
                recurringPrice.termsUnit === PRICE_TERMS_UNIT.MONTHS && (
                  <Amount
                    type="semibold_large"
                    amount={recurringPrice.price}
                    description={
                      recurringPrice.termsAmount > 0 && (
                        <AmountDescription
                          pricingOption={pricingOption}
                          termsAmount={recurringPrice.termsAmount}
                        />
                      )
                    }
                    isMonthly
                  />
                )}

              {/* Device has a down payment specified */}
              {hasDownPayment && (
                <Amount
                  type="semibold_large"
                  amount={oneTimePrice.price + oneTimePrice.tax}
                  description={intl.formatMessage({ id: "lbl.upfront" })}
                  mt={3}
                />
              )}
              {!plusTax && getDevicePriceTaxInfo() && getDevicePriceTaxInfo() !== "Y" && (
                <Text variant="taxDescription">
                  {intl.formatMessage({ id: "lbl.tax-included" })}
                </Text>
              )}
              {(plusTax || !getDevicePriceTaxInfo()) && (
                <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  );
};

export default Device;
