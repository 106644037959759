import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getCountries = createAsyncThunk("countries/getCountries", async () => {
  const response = await FacadeAPI.GET("/address/countries");
  return response;
});
const sortAlphabetically = (a, b) => {
  var nameA = a.name.toUpperCase(); // ignore upper and lowercase
  var nameB = b.name.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};
const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    loading: false,
    error: null,

    countries: [],
  },
  reducers: {},
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.loading = true;
    },
    [getCountries.fulfilled]: (state, action) => {
      state.loading = false;
      state.countries = action.payload.sort(sortAlphabetically);
    },
    [getCountries.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default countriesSlice.reducer;
