const SHIPPING_FLOW = {
  NONE: "NONE",
  HOME_DELIVERY: "HOME",
  PICK_IN_STORE: "STORE",
  NOT_NEEDED: "NOT_NEEDED",
};

const SHIPPING_ADDRESS = {
  DEFAULT: "DEFAULT",
  NEW: "NEW",
};

const SHIPPMING_METHODS = {
  NONE: "NONE",
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
};

export { SHIPPING_FLOW, SHIPPING_ADDRESS, SHIPPMING_METHODS };
