import React, { useEffect, useRef } from "react";

const OutsideClickHandler = ({ children, onClickOutside }) => {
  const refEvent = useRef();

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!refEvent.current.contains(e.target)) {
        e.stopPropagation();
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleMouseDown, true);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown, true);
    };
  }, [onClickOutside]);

  return <div ref={refEvent}>{children}</div>;
};
const getColor = (isSelected, isSelectedStartOrEnd, isWithinHoverRange, isDisabled) => {
  return ({
    selectedFirstOrLastColor,
    normalColor,
    selectedColor,
    rangeHoverColor,
    disabledColor,
  }) => {
    if (isSelectedStartOrEnd) {
      return selectedFirstOrLastColor;
    } else if (isSelected) {
      return selectedColor;
    } else if (isWithinHoverRange) {
      return rangeHoverColor;
    } else if (isDisabled) {
      return disabledColor;
    } else {
      return normalColor;
    }
  };
};

const datepickerContextDefaultValue = {
  focusedDate: null,
  isDateFocused: () => false,
  isDateSelected: () => false,
  isDateHovered: () => false,
  isDateBlocked: () => false,
  isFirstOrLastSelectedDate: () => false,
  onDateFocus: () => {},
  onDateHover: () => {},
  onDateSelect: () => {},
};
const DatepickerContext = React.createContext(datepickerContextDefaultValue);

export { OutsideClickHandler, getColor, DatepickerContext };
