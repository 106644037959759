import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import { PERSONAL_INFORMATION_FLOW } from "../../../common/Constants";
const INITIAL_STATE = {
  getExistingLines: {
    loading: false,
    error: null,
    success: false,
    existingLines: [],
  },
};

export const getExistingLines = createAsyncThunk(
  "account/existingLines",
  async ({ getExistingLinesRequest }, { getState, rejectWithValue }) => {
    if (getState().personalInformation.flow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER) {
      return [];
    }
    let result;
    try {
      result = await FacadeAPI.PUT(`/account/serviceAgreements`, getExistingLinesRequest);
    } catch (err) {
      return rejectWithValue(err);
    }
    return result;
  }
);

const existingLinesSlice = createSlice({
  name: "existingLines",
  initialState: INITIAL_STATE,
  reducers: {
    clearGetExistingLinesResponse(state) {
      return {
        ...state,
        getExistingLines: INITIAL_STATE.getExistingLines,
      };
    },
  },
  extraReducers: {
    [getExistingLines.pending]: (state) => {
      state.getExistingLines.loading = true;
      state.getExistingLines.success = false;
    },
    [getExistingLines.fulfilled]: (state, action) => {
      state.getExistingLines.loading = false;
      state.getExistingLines.success = true;
      state.getExistingLines.existingLines = action.payload;
    },
    [getExistingLines.rejected]: (state, action) => {
      state.getExistingLines.loading = false;
      state.getExistingLines.error = action.error;
    },
  },
});
export const { clearGetExistingLinesResponse } = existingLinesSlice.actions;
export default existingLinesSlice.reducer;
