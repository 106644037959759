import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Text, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import qs from "qs";

import Attribute from "../../../components/attribute";
import { OSContainer } from "../../../components/base";
import { OSIcon } from "../../../components/icons";
import { Heading4 } from "../../../components/heading";
import HTMLRenderer from "../../../components/html-renderer";
import { deleteItem, deleteSession } from "../../../config/redux/slices";
import { StyledModalMessage } from "../../../components/modals";
import Amount from "../../../components/amount";
import { storefrontURLs } from "../../../config/security/Deployment";

const Accessory = ({
  item,
  priceOffer,
  setChangeAccessory,
  numberOfBundles,
  numberOfAccessories,
  ...props
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showIFrame, setShowIFrame] = useState(false);
  const { externalCartId } = useSelector((state) => state.session);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    onClick: null,
  });

  const onRemove = async () => {
    try {
      await dispatch(deleteItem({ itemId: item.id }));
    } catch (err) {
      console(err);
    }
  };

  const onDelete = async () => {
    await dispatch(deleteSession());
  };

  useEffect(() => {
    const handler = (event) => {
      if (storefrontURLs.base.includes(event.origin)) {
        location.reload();
      }
    };
    window.addEventListener("message", handler, false);
    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, []);

  const onChange = () => {
    setChangeAccessory(item);
    setShowIFrame(true);
  };

  const clearModal = () => setModal({ isOpen: false, message: "", onClick: null });

  const ModalButtons = () => {
    return (
      <>
        <Button
          mr={20}
          variant="simple-action"
          onClick={() => {
            modal.onClick();
            clearModal();
          }}>
          <FormattedMessage id="btn.ok" />
        </Button>

        <Button
          variant="simple-action"
          onClick={() => {
            clearModal();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </>
    );
  };

  return (
    <Box mt={4} sx={{ width: "100%" }} {...props}>
      <StyledModalMessage
        isOpen={modal.isOpen}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        <ModalButtons />
      </StyledModalMessage>
      {!showIFrame && (
        <Flex
          mt={[2, 2, "none"]}
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: ["100%", "100%", "auto"],
          }}>
          <Flex
            onClick={() =>
              setModal({
                isOpen: true,
                message: intl.formatMessage({ id: "lbl.change-accesory-msg" }),
                onClick: onChange,
              })
            }
            sx={{ cursor: "pointer", alignItems: "center" }}>
            <Text
              variant="subtitle1"
              mt={0}
              color="ternary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}>
              <FormattedMessage id="lbl.choose-different-accessory" />
            </Text>
            <OSIcon name="chevron-right" ml={2} />
          </Flex>
          <Flex
            onClick={() =>
              setModal({
                isOpen: true,
                message: intl.formatMessage({ id: "lbl.remove-accesory-msg" }),
                onClick: numberOfBundles > 1 || numberOfAccessories > 1 ? onRemove : onDelete,
              })
            }
            mr={["none", "none", 10]}
            sx={{ cursor: "pointer", alignItems: "center" }}>
            <Text
              variant="subtitle1"
              mt={0}
              ml={8}
              color="ternary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}>
              {intl.formatMessage({ id: "lbl.remove-item" })}
            </Text>
            <OSIcon name="trash" ml={2} />
          </Flex>
        </Flex>
      )}
      {showIFrame ? (
        <Flex mt={5} bg="whiteSmoke" sx={{ borderRadius: "2lg", width: "100%" }}>
          <iframe
            src={
              storefrontURLs.accessories +
              `?` +
              qs.stringify({ cartId: externalCartId, itemId: item.itemId })
            }
            width="100%"
            height={window.innerHeight}
            style={{ border: 0 }}
          />
        </Flex>
      ) : (
        <OSContainer variant="device-and-plan-section">
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: ["center", "center", "flex-start"],
              minWidth: ["50%", "25%", "20%"],
              flexDirection: "column",
              flex: 1,
            }}>
            <Flex
              sx={{
                width: "100%",
              }}>
              <Flex
                ml={["0", "6rem", "2rem"]}
                sx={{
                  justifyContent: "flex-start",
                  minWidth: "7rem",
                  width: "7rem",
                }}>
                <Image src={item.thumbnailURL} alt="accessory" sx={{ objectFit: "contain" }} />
              </Flex>

              <Flex ml={[5, 5, 10]} sx={{ flexWrap: "wrap" }}>
                <Heading4 sx={{ minWidth: "5rem", textAlign: "center" }}>
                  {item.shortDescription}
                </Heading4>
                {item.attributes.length > 0 && (
                  <Flex
                    mt={[5, 5, 6]}
                    sx={{
                      gap: 1.5,
                      alignItems: "center",
                      flexWrap: "wrap",
                      width: "100%",
                      minWidth: ["15rem", "100%", "100%"],
                    }}>
                    {item.attributes.map((attribute, index) => (
                      <Attribute key={index} value={attribute.value} />
                    ))}
                  </Flex>
                )}
                <Flex
                  mt={[5, 5, 5]}
                  sx={{
                    display: ["none", "flex"],
                    alignItems: ["center", "center", "flex-start"],
                    width: ["100%", "100%", "90%"],
                  }}>
                  <Text variant="lineDescription">
                    <HTMLRenderer htmlString={item.skuDescription} />
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            mt={[5, 5, "none"]}
            ml={[0, 0, "auto"]}
            mb={4}
            sx={{
              flexDirection: "column",
              minWidth: ["15rem", "70%", "30%"],
            }}>
            <Flex
              px={[2, 4, 4]}
              py={4}
              sx={{
                mt: 5,
                border: 1,
                borderColor: "secondary",
                borderRadius: "lg",
                justifyContent: "center",
              }}>
              <Amount
                amount={priceOffer.price + priceOffer.tax}
                description={intl.formatMessage({ id: "lbl.upfront-payment" })}
                color="ternary"
                descriptionColor="primary"
              />
            </Flex>
            {priceOffer.tax > 0 && (
              <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.tax-included" })}</Text>
            )}
          </Flex>
        </OSContainer>
      )}
    </Box>
  );
};
export default Accessory;
