import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { Button } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { OAuthCredentials } from "../../config/security/Deployment";
import { StyledModalMessage } from "../modals";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSavePersonalInfoApiResponse,
  doLogin,
  getToken,
  savePersonalInformation,
} from "../../config/redux/slices";
import { canLogin } from "../../common/Constants";
import Spinner from "../spinner/Spinner";
import GetErrorDescription from "../get-error-description";
import ErrorHandler from "../../components/error-handler";
import { doLogout } from "../../config/redux/slices/AuthSlice";
import LangCode from "../../config/intl/LangCode";

import { OSIntlContext } from "../../contexts/OSIntlContext";

const AuthorizationHandler = ({ selectedFlow, children }) => {
  const { locale, switchLanguage } = useContext(OSIntlContext);
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  let { code, error, error_description } = qs.parse(search, { ignoreQueryPrefix: true });
  const [errorDisplay, setErrorDisplay] = useState({ show: error, description: error_description });
  const [authCode, setAuthCode] = useState(code);
  const authState = useSelector((state) => state.auth);
  const personalInformationState = useSelector((state) => state.personalInformation);
  const { provider } = useSelector((state) => state.branding);
  const { planFlags } = useSelector((state) => state.items);
  const { sessionId } = useSelector((state) => state.session);

  const getAuthorization = useCallback(() => {
    const { authorizationURL, clientID, redirectURI, scope } = OAuthCredentials;
    window.location.href =
      authorizationURL +
      "?" +
      qs.stringify({
        response_type: "code",
        client_id: clientID,
        redirect_uri: window.location.origin + redirectURI,
        scope: scope,
        state: history.location.hash,
        provider: provider,
        code_challenge: authState.codeVerifier,
        lang: locale,
      });
  }, [authState.codeVerifier, history.location.hash, locale, provider]);

  useEffect(() => {
    console.log("selectedFlow " + selectedFlow);
    if (!authCode && !authState.loggedIn && canLogin(selectedFlow)) {
      getAuthorization();
    }
  }, [authCode, authState.loggedIn, getAuthorization, selectedFlow]);

  useEffect(() => {
    if (authCode && !authState.loggedIn) {
      const { tokenURL, clientID, clientSecret, redirectURI } = OAuthCredentials;
      dispatch(
        getToken({
          route: tokenURL,
          isForm: true,
          authorization: { type: "Basic", token: btoa(clientID + ":" + clientSecret) },
          body: {
            grant_type: "authorization_code",
            code: authCode,
            redirect_uri: window.location.origin + redirectURI,
            client_id: clientID,
            code_verifier: authState.codeVerifier,
          },
        })
      );
    }
  }, [dispatch, authCode, authState.codeVerifier, history, authState.loggedIn]);

  useEffect(() => {
    if (authState.getToken.success) {
      dispatch(
        doLogin({
          accessToken: authState.getToken.response.access_token,
          refreshToken: authState.getToken.response.refresh_token,
        })
      );
    }
  }, [history, authState.getToken.success, authState.getToken.response, dispatch]);

  useEffect(() => {
    // we have logged in and we have an accountId -> save details on session
    if (authState.loggedIn && authState.accountId && sessionId) {
      let request = {
        flow: selectedFlow,
        accountId: authState.accountId,
      };

      dispatch(savePersonalInformation(request));
    }
  }, [history, authState.loggedIn, dispatch, selectedFlow, authState.accountId, sessionId]);

  useEffect(() => {
    // information regarding account (firstname, lastname) was loaded and saved on session
    // clear response
    if (authState.loggedIn && personalInformationState.savePersonalInformation.success) {
      switchLanguage(LangCode[personalInformationState.language]);
      dispatch(clearSavePersonalInfoApiResponse());
      // information regarding account was saved&reloaded
      // it's safe to proceed to next step
      history.replace("/validate-pin");
    }
  }, [
    authState.loggedIn,
    dispatch,
    history,
    personalInformationState.savePersonalInformation.success,
    planFlags,
    selectedFlow,
    personalInformationState.language,
    switchLanguage,
  ]);

  useEffect(() => {
    if (authState.getToken.error) {
      setAuthCode(null);
      setErrorDisplay({
        show: true,
        description: authState.getToken.error.error_description ?? <GetErrorDescription />,
      });
    }
  }, [authState.getToken.error, intl]);

  const errorHandler = () => {
    setAuthCode(null);
    dispatch(doLogout());
  };

  return (
    <>
      {!authCode && children}

      <ErrorHandler
        error={personalInformationState.savePersonalInformation.error}
        clickHandler={errorHandler}
        closeHandler={errorHandler}
      />

      <StyledModalMessage
        isOpen={errorDisplay.show}
        message={errorDisplay.description}
        onRequestClose={() => setErrorDisplay({ show: false })}
        type="error">
        <Button
          variant="simple-action"
          onClick={() => {
            setErrorDisplay({ show: false });
            errorHandler();
          }}>
          <FormattedMessage id="lbl.try_again" />
        </Button>
      </StyledModalMessage>

      <Spinner
        isOpen={
          authState.getToken.loading || personalInformationState.savePersonalInformation.loading
        }
      />
    </>
  );
};
export default AuthorizationHandler;
