import React from "react";
import { Box, Flex } from "@theme-ui/components";

import { Heading3 } from "../heading";
import { OSContainer } from "../base";
import BaseSkeleton from "./BaseSkeleton";

const UpdateDeviceSkeleton = ({ title, ...props }) => {
  return (
    <Box sx={{ width: "100%" }} {...props}>
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          justifyContent: "space-between",
          flexDirection: ["column", "column", "row"],
        }}>
        <Heading3 color="primary">{title}</Heading3>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["space-between", "space-between", "flex-start"],
            mt: [2, 2, "none"],
            width: ["100%", "100%", "auto"],
          }}>
          <Flex sx={{ alignItems: "center" }}>
            <BaseSkeleton width="25rem" height="1.25rem" />
          </Flex>
        </Flex>
      </Flex>
      <OSContainer
        variant="page-section"
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          flexDirection: ["column", "column", "row"],
        }}
        mt={5}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["center", "center", "flex-start"],
            minWidth: ["100%", "100%", "22rem"],
          }}>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              minWidth: "8rem",
              width: "8rem",
              my: "1.25rem",
            }}>
            <BaseSkeleton width="10.5rem" height="12rem" />
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              ml: 6,
              gap: 1.5,
            }}>
            <BaseSkeleton width="100%" height="1.625rem" />
            <BaseSkeleton width="6.5rem" height="2.0625rem" />
            <BaseSkeleton width="6.5rem" height="2.0625rem" />
            <BaseSkeleton width="6.5rem" height="2.0625rem" />
          </Flex>
        </Flex>
        <Flex
          sx={{
            alignItems: ["center", "center", "flex-start"],
            flexDirection: "column",
            mt: [5, 5, "none"],
            mx: ["none", "none", 8],
            width: "100%",
          }}
        />
        <Flex
          sx={{
            flexDirection: "column",
            minWidth: ["100%", "100%", "22rem"],
            my: "1.25rem",
            gap: 1,
          }}>
          <BaseSkeleton width="100%" height="2.0625rem" />
          <BaseSkeleton width="100%" height="2.0625rem" />
        </Flex>
      </OSContainer>
    </Box>
  );
};

export default UpdateDeviceSkeleton;
