import React from "react";
import { Box, Flex, Image, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { getMobileStepsData } from "../../common/StepsUtilities";

const HrSeparator = ({ flexGrow, completed, hide = false }) => {
  return (
    <Text
      as="span"
      sx={{
        flexGrow: flexGrow,
        borderBottom: !hide && (completed ? "4px solid" : "1px solid"),
        borderColor: completed ? "bulletBgPrimary" : "wizardNotCompleted",
      }}
    />
  );
};

const StepTitle = ({ title, align }) => {
  return (
    <Text
      variant="attribute"
      mt={2.5}
      px={2}
      color="invertedTextColor"
      sx={{ textAlign: align, display: "block" }}>
      {title}
    </Text>
  );
};

const FILLED_BULLET_SIZE = "2.5rem";

const ACTIVE_BULLET = (
  <Flex
    bg="bulletBgPrimary"
    sx={{
      width: FILLED_BULLET_SIZE,
      heigh: FILLED_BULLET_SIZE,
      minWidth: FILLED_BULLET_SIZE,
      minHeight: FILLED_BULLET_SIZE,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "full",
      userSelect: "none",
    }}>
    <Flex
      bg="windowBg"
      sx={{
        width: "1.25rem",
        heigh: "1.25rem",
        minWidth: "1.25rem",
        minHeight: "1.25rem",
        borderRadius: "full",
        userSelect: "none",
      }}
    />
  </Flex>
);

let NOT_COMPLETED_BULLET = (
  <Flex
    bg="wizardNotCompleted"
    sx={{
      width: "1rem",
      heigh: "1rem",
      minWidth: "1rem",
      minHeight: "1rem",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "full",
      userSelect: "none",
    }}
  />
);

let COMPLETED_BULLET = (
  <Flex
    bg="bulletBgPrimary"
    sx={{
      width: FILLED_BULLET_SIZE,
      heigh: FILLED_BULLET_SIZE,
      minWidth: FILLED_BULLET_SIZE,
      minHeight: FILLED_BULLET_SIZE,
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "full",
      userSelect: "none",
    }}>
    <Text variant="headerStep" mt="-0.125rem" color="windowBg">
      {"\u2713"}
    </Text>
  </Flex>
);
/**
 * Header Component.
 *
 * @param {*} param0.checkoutStep Checkout step number.
 */
const HeaderMobile = ({
  checkoutStep: checkoutStepStaticIdx,
  showSteps = true,
  paymentSuccess,
}) => {
  const intl = useIntl();
  const brandingData = useSelector((state) => state.branding.data);
  const { planFlags } = useSelector((state) => state.items);
  const { flow } = useSelector((state) => state.personalInformation);

  let stepsData = getMobileStepsData(planFlags, checkoutStepStaticIdx, flow);

  let leftStep = (
    <Flex
      name="completedStep"
      sx={{ flex: 1 / 3, flexDirection: "column", alignItems: "flex-start" }}>
      {stepsData && stepsData.left && (
        <>
          <Flex
            sx={{
              width: "100%",
              flexDirection: "row",
              height: FILLED_BULLET_SIZE,
              alignItems: "center",
            }}>
            <HrSeparator flexGrow={1 / 3} completed hide={!stepsData.left.hasPrevious} />

            {COMPLETED_BULLET}

            <HrSeparator flexGrow={1} completed />
          </Flex>
          <StepTitle title={intl.formatMessage({ id: stepsData.left.label })} align="left" />
        </>
      )}
    </Flex>
  );

  let centerStep = stepsData && stepsData.center && (
    <Flex name="activeStep" sx={{ flex: 1 / 3, flexDirection: "column", alignItems: "center" }}>
      <Flex
        sx={{
          width: "100%",
          flexDirection: "row",
          height: FILLED_BULLET_SIZE,
          alignItems: "center",
        }}>
        <HrSeparator flexGrow={1} completed hide={!stepsData.left} />
        {paymentSuccess ? COMPLETED_BULLET : ACTIVE_BULLET}
        <HrSeparator
          flexGrow={1}
          completed={stepsData.center.isCompleted}
          hide={!stepsData.right}
        />
      </Flex>

      <StepTitle title={intl.formatMessage({ id: stepsData.center.label })} align="center" />
    </Flex>
  );

  let rightStep = (
    <Flex name="nextStep" sx={{ flex: 1 / 3, flexDirection: "column", alignItems: "flex-end" }}>
      {stepsData && stepsData.right && (
        <>
          <Flex
            sx={{
              width: "100%",
              flexDirection: "row",
              height: FILLED_BULLET_SIZE,
              alignItems: "center",
            }}>
            <HrSeparator flexGrow={1} completed={stepsData.center.isCompleted} />

            {stepsData.center.isCompleted ? ACTIVE_BULLET : NOT_COMPLETED_BULLET}

            <HrSeparator flexGrow={1 / 3} hide={!stepsData.right.hasNext} />
          </Flex>

          <StepTitle title={intl.formatMessage({ id: stepsData.right.label })} align="right" />
        </>
      )}
    </Flex>
  );
  return (
    <>
      <Flex
        bg="headerBg"
        px={4}
        py={5}
        sx={{ justifyContent: "center", alignItems: "center", width: "100%" }}>
        <Box>
          <Image src={brandingData.logo.mobileValue} sx={{ width: "14rem" }} />
        </Box>
      </Flex>

      <Flex
        px={4}
        py={5}
        sx={{
          width: "100%",
          height: "40",
          justifyContent: "space-around",
          alignItems: "flex-start",
          boxShadow: "md",
          backgroundColor: "headerBg",
          backgroundImage: brandingData.headerBackgroundImage
            ? `url(${brandingData.headerBackgroundImage})`
            : "",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        {showSteps && (
          <>
            {leftStep}

            {centerStep}

            {rightStep}
          </>
        )}
      </Flex>
    </>
  );
};
export default HeaderMobile;
