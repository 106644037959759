import { FormattedMessage } from "react-intl";

export const TU_RESPONSE = {
  DEVICE_RISK_FAILED: "DEVICE_RISK_FAILED",
  EBVS_FAILED: "EBVS_FAILED",
  HRFA_FAILED: "HRFA_FAILED",
  QUESTIONS_FAILED: "QUESTIONS_FAILED",
  QUESTIONS_TIMEOUT: "QUESTIONS_TIMEOUT",
  IDCHECK_REQUIRED: "IDCHECK_REQUIRED",
  LOCKOUT: "LOCKOUT",
  FAILED: "FAILED",
  CONNECTION_ERROR: "CONNECTION_ERROR",
  SUCCESS: "SUCCESS",
};

export const getResponseMessage = (responseCode) => {
  switch (responseCode) {
    case TU_RESPONSE.DEVICE_RISK_FAILED:
    case TU_RESPONSE.FAILED:
      return <FormattedMessage id="err.tu-error-order" />;
    case TU_RESPONSE.EBVS_FAILED:
    case TU_RESPONSE.HRFA_FAILED:
    case TU_RESPONSE.QUESTIONS_FAILED:
    case TU_RESPONSE.CONNECTION_ERROR:
      return <FormattedMessage id="err.tu-verify-id" />;
    case TU_RESPONSE.LOCKOUT:
      return <FormattedMessage id="err.tu-lockout" />;
    case TU_RESPONSE.QUESTIONS_TIMEOUT:
      return <FormattedMessage id="err.tu-questions-timeout" />;
    default:
      return <FormattedMessage id="err.generic" />;
  }
};
