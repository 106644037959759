import React, { forwardRef } from "react";
import { Radio, Box } from "@theme-ui/components";

const OSRadio = forwardRef(
  ({ id, className, checked, sx, variant = "radio", onChange, ...props }, ref) => (
    <Box className={className} id={id} {...props}>
      <Radio
        className={className}
        id={id}
        ref={ref}
        checked={checked}
        variant={variant}
        sx={sx}
        onChange={onChange}
      />
    </Box>
  )
);

export default OSRadio;
