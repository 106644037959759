import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getAccountUDFs = createAsyncThunk("accountUDFs/getAccountUDFs", async () => {
  return await FacadeAPI.GET("/accountDetails/accountUDFs");
});

const accountUDFsSlice = createSlice({
  name: "accountUDFs",
  initialState: {
    loading: false,
    error: null,

    accountUDFs: [],
  },
  reducers: {},
  extraReducers: {
    [getAccountUDFs.pending]: (state) => {
      state.loading = true;
    },
    [getAccountUDFs.fulfilled]: (state, action) => {
      state.loading = false;
      state.accountUDFs = action.payload;
    },
    [getAccountUDFs.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export default accountUDFsSlice.reducer;
