import React from "react";
import { Box } from "@theme-ui/components";

const NoDevice = ({ color = "svgStrokePrimary", size = "iconVeryLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <g transform="translate(47)">
      <g transform="translate(-32 6)" fill="none" stroke={color} strokeWidth="1">
        <rect width="25" height="42" rx="3" stroke="none" />
        <rect x="1" y="1" width="23" height="40" rx="2" fill="none" />
      </g>
      <path
        d="M-23.044,10.123h7"
        transform="translate(0.044 0.877)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1"
        opacity="0.4"
      />
      <path
        d="M-23.044,10.123H-1.949"
        transform="translate(-7.051 4.877)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1"
        opacity="0.4"
      />
      <path
        d="M-23.044,10.123H-1.949"
        transform="translate(-7.051 26.877)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1"
        opacity="0.4"
      />
      <g transform="translate(-21.384 39.616)" fill="none" opacity="0.4">
        <path
          d="M1.884,3.769A1.884,1.884,0,1,1,3.769,1.884,1.884,1.884,0,0,1,1.884,3.769Z"
          stroke="none"
        />
        <path
          d="M 1.88433837890625 3.768678426742554 C 0.843648374080658 3.768678426742554 -1.6210937019423e-06 2.925028324127197 -1.6210937019423e-06 1.88433837890625 C -1.6210937019423e-06 0.843648374080658 0.843648374080658 -1.6210937019423e-06 1.88433837890625 -1.6210937019423e-06 C 2.925028324127197 -1.6210937019423e-06 3.768678426742554 0.843648374080658 3.768678426742554 1.88433837890625 C 3.767478466033936 2.924548387527466 2.924548387527466 3.767478466033936 1.88433837890625 3.768678426742554 Z"
          stroke="none"
          fill="#332a86"
        />
      </g>
    </g>
  </Box>
);

export default NoDevice;
