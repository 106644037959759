import React from "react";
import { Box } from "@theme-ui/components";
import { Branding } from "../../../config/branding/Branding";

/**
 * OSSection - regular Box enhanced with branding capabilities regarding visibility
 * @param name
 * @param children
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const OSSection = ({ name, children, ...props }) => {
  /**
   * An element will be hidden when it's section or the element itself will be hidden
   * @param name
   * @param section
   * @returns {boolean}
   */
  const isHidden = (section) => {
    let sectionBranding = Branding[section];
    if (!sectionBranding || Object.keys(sectionBranding).length === 0) return false;

    if (sectionBranding.visible === false) return true;
  };

  if (isHidden(name)) {
    return null;
  }

  return (
    <Box name={name} section={name} {...props}>
      {children}
    </Box>
  );
};

export default OSSection;
