const OAuthIssuerURL = new URL(
  window._env_ ? window._env_.OAUTH2_TOKEN_ISSUER : "https://eastlinkos880.mindsoft.com:4000"
).toString();

const OAuthCredentials = {
  authorizationURL: OAuthIssuerURL + "oauth/authorize",
  tokenURL: OAuthIssuerURL + "oauth/token",
  clientID: "ONLINESTORE",
  scope: "subscriber category:residential selfcareprofile:SELFCAREFULL",
  redirectURI: "/personal-information",
};

const storefrontBaseURL =
  window._env_ && window._env_.STOREFRONT_URLS
    ? window._env_.STOREFRONT_URLS.base
    : "https://eastlinkos880.mindsoft.com/iFrameMockup";

const storefrontURLs = {
  base: storefrontBaseURL,

  devices:
    storefrontBaseURL +
    (window._env_ && window._env_.STOREFRONT_URLS
      ? window._env_.STOREFRONT_URLS.devices
      : "/cart/device-list"),

  accessories:
    storefrontBaseURL +
    (window._env_ && window._env_.STOREFRONT_URLS
      ? window._env_.STOREFRONT_URLS.accessories ?? ""
      : "/cart/accessory"),

  plans:
    storefrontBaseURL +
    (window._env_ && window._env_.STOREFRONT_URLS
      ? window._env_.STOREFRONT_URLS.plans
      : "/cart/plan-builder"),

  finishSale:
    storefrontBaseURL +
    (window._env_ && window._env_.STOREFRONT_URLS
      ? window._env_.STOREFRONT_URLS.finishSale
      : "/cart/finish-checkout"),
};

const GTMContainerID =
  window._env_ && window._env_.GTM_CONTAINER_ID ? window._env_.GTM_CONTAINER_ID : "GTM-MTJP2RN";

export { OAuthCredentials, storefrontURLs, GTMContainerID };
