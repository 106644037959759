import React, { useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Text } from "@theme-ui/components";
import { useHistory } from "react-router-dom";
import {
  ITEM_ATTRIBUTE_CODE,
  ITEM_CATEGORY,
  PRICE_TERMS_UNIT,
  PRICE_TYPE,
  PERSONAL_INFORMATION_FLOW,
  ACCOUNT_STATUS,
  EXTRA_INFO,
} from "../../common/Constants";
import { getNextStep } from "../../common/StepsUtilities";
import { OSContainer } from "../../components/base";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import Layout from "../../components/layout";
import { PORTIN_TYPE } from "../../components/portin-type/Constants";
import TotalPrices from "../../components/total-prices";
import { StyledModalMessage } from "../../components/modals";
import {
  getIdentityTypes,
  getItems,
  getPhoneNumberObj,
  getStores,
  setTaxIncreased,
  getExistingLines,
} from "../../config/redux/slices";
import { FLOW } from "../phone-number/Constants";
import AccessoriesSummary from "./AccessoriesSummary";
import DeviceAndPlanSummary from "./DeviceAndPlanSummary";
import DocumentsSummary from "./DocumentsSummary";
import PersonalInformationSummary from "./PersonalInformationSummary";
import ShippingDetailsSummary from "./ShippingDetailsSummary";
import Spinner from "../../components/spinner/Spinner";
import { SHIPPING_FLOW } from "../shipping-details/Constants";
import { getNoOfDeferredMonths } from "../../components/plan-price-offer/PlanPriceOffer";
import { getExtraInfoValues } from "../../common/StringUtilities";

const Summary = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { planFlags } = useSelector((state) => state.items);
  const itemsState = useSelector((state) => state.items);
  const identityTypesState = useSelector((state) => state.identityTypes);
  const personalInfoState = useSelector((state) => state.personalInformation);
  const phoneNumberState = useSelector((state) => state.phoneNumber);
  const shippingDetailsState = useSelector((state) => state.shippingDetails);
  const existingLinesState = useSelector((state) => state.existingLines);

  const [aprovedTermsAndConditions, setApprovedTermsAndConditions] = useState(false);

  useEffect(() => {
    !itemsState.getItems.success && dispatch(getItems());
  }, [dispatch, itemsState.getItems.success]);

  useEffect(() => {
    !identityTypesState.success && dispatch(getIdentityTypes());
  }, [dispatch, identityTypesState.success]);

  useEffect(() => {
    !phoneNumberState[0] &&
      !phoneNumberState.getPhoneNumberObj.success &&
      dispatch(getPhoneNumberObj());
  }, [dispatch, phoneNumberState, phoneNumberState.getPhoneNumberObj.success]);

  useEffect(() => {
    !shippingDetailsState.getStores.success && dispatch(getStores());
  }, [dispatch, shippingDetailsState.getStores.success]);

  //Load existing lines.
  useEffect(() => {
    let getExistingLinesRequest = {
      masterAccountId: personalInfoState.accountId,
      accountStatuses: [ACCOUNT_STATUS.ACTIVE, ACCOUNT_STATUS.SUSPENDED],
    };

    if (personalInfoState.loaded && !existingLinesState.getExistingLines.success)
      dispatch(getExistingLines({ getExistingLinesRequest }));
  }, [
    personalInfoState.loaded,
    personalInfoState.accountId,
    existingLinesState.getExistingLines.success,
    dispatch,
  ]);

  const { dueNowCharges, monthlyCharges, oneTimeCharges, specialDiscounts } = useMemo(() => {
    let accessoriesPrice = 0;
    let devicePrice = 0;
    let shippingPrice = 0;
    let ratePlanFeaturesPrice = 0;
    let easyTabPrice = 0;
    let oldEasyTabPrice = 0;
    let oneTimePrice = 0;
    let dueNowTax = 0;
    let monthlyTax = 0;
    let oneTimeTax = 0;
    let discountsMonthlyCharges = 0;
    let discountsOneTimeCharges = 0;
    let specialDiscounts = [];
    let isDeviceWithTax = false;
    let isAccessoryTaxFree = false;
    let connectionFeeCharges = {};
    let additionalOneTimePrice = 0;

    let lineNo = 0;
    itemsState.getItems.items.map((item) => {
      if (item.category === ITEM_CATEGORY.BUNDLE) {
        lineNo++;
        const lineTitle =
          `${item?.contactFirstName} ${item?.contactLastName}`.trim() ||
          `${intl.formatMessage({ id: "lbl.line" })} ${lineNo}`;

        devicePrice += item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        easyTabPrice += item.prices
          .filter(
            (price) =>
              PRICE_TYPE.RECURRING === price.type && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        let devicePrices = item.prices.filter(
          (price) => PRICE_TYPE.ONE_TIME === price.type || PRICE_TYPE.RECURRING === price.type
        );
        let deviceTaxInfo =
          devicePrices.length > 0 &&
          devicePrices[0].extraInfo &&
          devicePrices[0].extraInfo.length > 0 &&
          Object.fromEntries(devicePrices[0].extraInfo.split(";").map((elem) => elem.split("=")))[
            "IS_TAX_FREE"
          ];
        if (item.sku && (!deviceTaxInfo || deviceTaxInfo === "N")) {
          isDeviceWithTax = true;
        }

        oldEasyTabPrice += item.prices
          .filter((price) => PRICE_TYPE.OLD_INSTALLMENT === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        discountsMonthlyCharges += item.prices
          .filter(
            (price) =>
              (PRICE_TYPE.PLAN_SERVICE_DISCOUNT === price.type ||
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING === price.type) &&
              price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => priceObj.price)
          .reduce((prev, curr) => prev + curr, 0);

        discountsOneTimeCharges += item.prices
          .filter(
            (price) =>
              (PRICE_TYPE.PLAN_SERVICE_DISCOUNT === price.type ||
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING === price.type) &&
              price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => priceObj.price)
          .reduce((prev, curr) => prev + curr, 0);

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_RECURRING,
                PRICE_TYPE.PLAN_ADDITIONAL_RECURRING,
                PRICE_TYPE.PLAN_E911,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            if (
              priceObj.type !== PRICE_TYPE.PLAN_SERVICE_DISCOUNT &&
              priceObj.type !== PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING
            ) {
              ratePlanFeaturesPrice += priceObj.price;
            }
            monthlyTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_ONE_TIME,
                PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME,
                PRICE_TYPE.PLAN_CONNECTION_FEE,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            if (priceObj.type === PRICE_TYPE.PLAN_ONE_TIME) {
              oneTimePrice += priceObj.price;
            }
            oneTimeTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              [PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME, PRICE_TYPE.PLAN_CONNECTION_FEE].includes(
                price.type
              ) && price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .map((priceObj) => {
            if (priceObj.type === PRICE_TYPE.PLAN_CONNECTION_FEE) {
              connectionFeeCharges = {
                ...connectionFeeCharges,
                description: priceObj.description,
                amount: (connectionFeeCharges.amount ?? 0) + priceObj.price,
              };
            }
            if (priceObj.type === PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME) {
              additionalOneTimePrice += priceObj.price;
            }
          });

        item.prices
          .filter(
            (price) =>
              PRICE_TYPE.PLAN_SERVICE_IVD === price.type ||
              PRICE_TYPE.PLAN_SERVICE_IVD_EXISTING === price.type
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            specialDiscounts = [...specialDiscounts, `${lineTitle}: ${priceObj.description}`];
          });
      }

      if (item.category === ITEM_CATEGORY.ACCESSORY) {
        accessoriesPrice += item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);
        isAccessoryTaxFree = item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .find((priceObj) => priceObj.tax === 0);
      }

      if (item.category === ITEM_CATEGORY.SHIPPING) {
        item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => {
            shippingPrice += priceObj.price;
            dueNowTax += priceObj.tax;
          });
      }
    });

    return {
      dueNowCharges: {
        accessoriesPrice: { amount: accessoriesPrice, isTaxFree: isAccessoryTaxFree },
        devicePrice: { amount: devicePrice, isTaxFree: !isDeviceWithTax },
        oldEasyTabPrice: { amount: oldEasyTabPrice, isTaxFree: !isDeviceWithTax },
        shippingPrice: { amount: shippingPrice },
        tax: dueNowTax,
        total: accessoriesPrice + devicePrice + shippingPrice + dueNowTax + oldEasyTabPrice,
      },
      monthlyCharges: {
        ratePlanFeaturesPrice: { amount: ratePlanFeaturesPrice },
        easyTabPrice: { amount: easyTabPrice, isTaxFree: !isDeviceWithTax },
        discountsPrice: { amount: discountsMonthlyCharges },
        tax: monthlyTax,
        total: ratePlanFeaturesPrice + easyTabPrice + monthlyTax + discountsMonthlyCharges,
      },
      oneTimeCharges: {
        oneTimePrice: { amount: oneTimePrice },
        connectionFee: connectionFeeCharges,
        additionalOneTime: { amount: additionalOneTimePrice },
        discountsPrice: { amount: discountsOneTimeCharges },
        tax: oneTimeTax,
        total:
          oneTimePrice +
          additionalOneTimePrice +
          connectionFeeCharges.amount +
          oneTimeTax +
          discountsOneTimeCharges,
      },
      specialDiscounts: specialDiscounts,
    };
  }, [intl, itemsState.getItems.items]);

  const buildShippingSummaryComponent = (item, index) => {
    const store = shippingDetailsState.getStores.stores.find((store) => store.code === item.sku);
    const shippingPrice = item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
    const shippingDesc = item.attributes.find(
      (attr) => attr.code === ITEM_ATTRIBUTE_CODE.SHIPPING_DESCRIPTION
    );

    return (
      <React.Fragment key={index}>
        <ShippingDetailsSummary
          summaryData={{
            needsFulfillment: planFlags.needsFulfillment,
            shippingAddress: getShippingAddress(item),
            store: store,
            shortDescription: item.shortDescription,
            shippingDescription: shippingDesc?.value,
            shippingPrice: shippingPrice.price,
            shippingTax: shippingPrice.tax,
          }}
        />
      </React.Fragment>
    );
  };

  const getShippingAddress = (item) => {
    const isNewAddress = item.attributes.find(
      (attr) => attr.code === ITEM_ATTRIBUTE_CODE.IS_NEW_ADDRESS
    );

    let shippingAddress = {
      firstName: personalInfoState.firstName,
      lastName: personalInfoState.lastName,
      address: personalInfoState.address,
      city: personalInfoState.city,
      state: personalInfoState.province,
      postalCode: personalInfoState.postalCode,
      phoneNumber: personalInfoState.phoneNumber,
    };

    if (isNewAddress?.value === "Y") {
      const firstName = item.attributes.find(
        (attr) => attr.code === ITEM_ATTRIBUTE_CODE.FIRST_NAME
      );
      const lastName = item.attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.LAST_NAME);
      const address = item.attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.ADDRESS);
      const city = item.attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.CITY);
      const state = item.attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.STATE);
      const postalCode = item.attributes.find(
        (attr) => attr.code === ITEM_ATTRIBUTE_CODE.POSTAL_CODE
      );
      shippingAddress = {
        firstName: firstName,
        lastName: lastName,
        address: address,
        city: city,
        state: state,
        postalCode: postalCode,
        phoneNumber: personalInfoState.phoneNumber,
      };
    }

    return shippingAddress;
  };

  const getBundlePhoneNumber = (item, phoneNoBundleState) => {
    if (personalInfoState.flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE) {
      return existingLinesState.getExistingLines.existingLines.find(
        (line) => line.id == item.associatedSaID
      )?.serviceNumber;
    }
    if (!phoneNoBundleState || phoneNoBundleState.flow === FLOW.NONE) {
      return "";
    }
    if (phoneNoBundleState.flow === FLOW.PICK) {
      return phoneNoBundleState.phoneNumber;
    }
    if (phoneNoBundleState.portinType === PORTIN_TYPE.MOBILE) {
      return phoneNoBundleState.portinMobileDetails.phoneNumber;
    }
    if (phoneNoBundleState.portinType === PORTIN_TYPE.LANDLINE) {
      return phoneNoBundleState.portinLandlineDetails.phoneNumber;
    }
  };

  const identityTypeDesc = identityTypesState.identityTypes
    .filter(
      (idType) =>
        idType.code === personalInfoState.identityType ||
        idType.description === personalInfoState.identityType
    )
    .map((idType) => idType.description);

  const proceedToPaymentInformation = () => {
    history.push(getNextStep(planFlags, CHECKOUT_STEPS.SUMMARY, personalInfoState.flow).route);
  };

  const aproveTermsAndConditions = (value) => {
    setApprovedTermsAndConditions(value);
  };

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.SUMMARY.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.SUMMARY.label })}
      showCart={false}
      nextStepButton={() => (
        <Button
          id="submitSummary"
          disabled={!aprovedTermsAndConditions}
          variant="default-next"
          onClick={proceedToPaymentInformation}>
          {intl.formatMessage({
            id: getNextStep(planFlags, CHECKOUT_STEPS.SUMMARY, personalInfoState.flow)
              .srcButtonLabel,
          })}
        </Button>
      )}>
      <Spinner isOpen={personalInfoState.updateEmail.loading} />
      <StyledModalMessage
        isOpen={shippingDetailsState.taxIncreased}
        onRequestClose={() => dispatch(setTaxIncreased(false))}
        message={intl.formatMessage({ id: "lbl.easyTab-down-payment-changed" })}>
        <Button variant="simple-action" onClick={() => dispatch(setTaxIncreased(false))}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>
      <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
        {itemsState.getItems.items
          .filter((item) => item.category === ITEM_CATEGORY.BUNDLE)
          .map((item, index) => (
            <React.Fragment key={index}>
              <DeviceAndPlanSummary
                summaryData={{
                  bundleDescription:
                    `${item.contactFirstName} ${item.contactLastName}`.trim() ||
                    `${intl.formatMessage({ id: "lbl.line" })} ${index + 1}`,
                  phoneNumber: getBundlePhoneNumber(item, phoneNumberState[index]),
                  temporaryNumber: phoneNumberState[index]?.temporaryNumber,
                  deviceShortDesc: item.shortDescription,
                  deviceAttributes: item.attributes,
                  planShortDesc: item.planShortDescription,
                  prices: item.prices,
                  product: JSON.parse(item.jsonBundleRequest),
                  flow: personalInfoState.flow,
                  pricingOption:
                    item?.extraInfo?.length > 0
                      ? getExtraInfoValues(item.extraInfo)[EXTRA_INFO.SELECTED_PRICING_OPTION]
                      : "",
                }}
              />
            </React.Fragment>
          ))}
        {itemsState.getItems.items.filter((item) => item.category === ITEM_CATEGORY.ACCESSORY)
          .length > 0 && (
          <AccessoriesSummary
            summaryData={{
              itemList: itemsState.getItems.items.filter(
                (item) => item.category === ITEM_CATEGORY.ACCESSORY
              ),
            }}
          />
        )}
        <PersonalInformationSummary
          summaryData={{
            firstName: personalInfoState.firstName,
            lastName: personalInfoState.lastName,
            dobDate: personalInfoState.dobDate,
            identityTypeDesc: identityTypeDesc,
            identityNumber: personalInfoState.identityNumber,
            address: personalInfoState.address,
            city: personalInfoState.city,
            state: personalInfoState.province,
            postalCode: personalInfoState.postalCode,
            phoneNumber: personalInfoState.phoneNumber,
            alternatePhoneNumber: personalInfoState.alternatePhoneNumber,
            emailAddress: personalInfoState.emailAddress,
            accountId: personalInfoState.accountId,
          }}
        />

        {itemsState.getItems.items
          .filter((item) => item.category === ITEM_CATEGORY.SHIPPING)
          .map((item, index) => buildShippingSummaryComponent(item, index))}

        <DocumentsSummary onApproveTermsAndConditions={aproveTermsAndConditions} />

        <TotalPrices
          dueNowPrices={dueNowCharges}
          monthlyPrices={monthlyCharges}
          oneTimePrices={oneTimeCharges}
          showTopBorder={false}
          showTax
          showShipping={shippingDetailsState.shippingFlow !== SHIPPING_FLOW.PICK_IN_STORE}
          specialDiscounts={specialDiscounts}
          flow={personalInfoState.flow}
        />
        <Text
          sx={{
            cursor: "default",
            color: "primary",
            fontSize: "xl",
            fontWeight: "semibold",
            whiteSpace: "normal",
            marginLeft: "5",
          }}>
          {intl.formatMessage({ id: "lbl.partial-charges" })}
        </Text>
      </OSContainer>
    </Layout>
  );
};

export default Summary;
