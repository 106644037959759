import React from "react";
import { Box } from "@theme-ui/components";

const DeleteEllipse = ({ color = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    bg="windowBg"
    sx={{
      alignItems: "center",
      borderRadius: "full",
      justifyContent: "center",
      display: "flex",
      height: "2rem",
      width: "2rem",
    }}
    {...props}>
    <Box
      as="svg"
      xmlns="http://www.w3.org/2000/svg"
      width="24.153"
      height="24.148"
      viewBox="0 0 24 24"
      sx={{ width: size, height: size, stroke: color }}>
      <g transform="translate(-240 -70)">
        <g>
          <g>
            <path
              d="M240.5 76.507h23"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M260.5 76.5v15a2 2 0 0 1-2 2h-13a2 2 0 0 1-2-2v-15"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M242.5 76.5v-1a2 2 0 0 1 2-2h15a2 2 0 0 1 2 2v1"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M249 73.5a3 3 0 0 1 6 0"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M252 80v9.5"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M256.5 80v9.5"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M247.5 80v9.5"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </Box>
  </Box>
);

export default DeleteEllipse;
