import React, { useState, useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Text } from "@theme-ui/components";
import { OSContainer, OSSwitch } from "../../../components/base";
import { StyledModalMessage } from "../../../components/modals";

import { useDispatch, useSelector } from "react-redux";
import { Heading4 } from "../../../components/heading";
import Tooltip from "../../../components/tooltip";
import { ITEM_CATEGORY } from "../../../common/Constants";
import Group from "./Group";
import {
  loadGroupManagement,
  setGroupsViewState,
  joinGroup,
  cancelJoinGroup,
} from "../../../config/redux/slices";
import _ from "lodash";

const GroupManagement = ({ currentBundle, setHasGroupManagement, groupManagementError }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const groupManagementState = useSelector((state) => state.groupManagement);
  const itemsState = useSelector((state) => state.items);
  const [sharing, setSharing] = useState(false);
  const [currentLineGroups, setCurrentLineGroups] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const sectionRef = useRef();

  //1.Load group management data
  useEffect(() => {
    let lineDescriptions = {};
    const createLineViewFromBundle = (groupManagementData, bundleLine) => {
      let savedOperations = groupManagementData.groupOps ? groupManagementData.groupOps : [];
      let savedItem = savedOperations.find((op) => op.sessionItemId === bundleLine.id);
      return {
        accountId: bundleLine.associatedSaID > 0 ? bundleLine.associatedSaID : "",
        itemId: bundleLine.id,
        packageDescription: bundleLine.planShortDescription,
        serviceIdentifier: lineDescriptions[bundleLine.id],
        eligible: true,
        didJoin: savedItem ? true : false,
        groupType: savedItem ? savedItem.groupTypeCode : "",
      };
    };
    const createLineViewFromAccount = (groupManagementData, accountId, sharing) => {
      let existingLine = groupManagementData.existingLines.find((line) => line.id === accountId);
      let groupId = "";
      if (sharing) {
        let accountGroup = groupManagementData.accountGroups.find(
          (acGroup) => acGroup.groupMembers.find((gm) => gm.accountId === accountId) !== undefined
        );
        groupId = accountGroup ? accountGroup.groupId : "";
      }
      let savedOperations = groupManagementData.groupOps ? groupManagementData.groupOps : [];
      let savedItem = savedOperations.find((op) => op.accountId === accountId);
      return {
        accountId: accountId,
        itemId: "",
        packageDescription: existingLine.productDescription,
        serviceIdentifier: existingLine.serviceNumber,
        eligible: !sharing,
        didJoin: savedItem ? true : sharing,
        //groupType will be set only for lines that are joining now
        groupType: savedItem ? savedItem.groupTypeCode : "",
        groupId: savedItem ? savedItem.groupId : groupId,
        status: existingLine.status,
      };
    };

    function addLineFromAccount(groupManagementData, accountId, groupLines, sharing) {
      let groupLine = createLineViewFromAccount(groupManagementData, accountId, sharing);
      let bundleExistingLine = groupLines.find((eLine) => eLine.accountId === accountId);
      if (!bundleExistingLine) {
        groupLines.push(groupLine);
      } else {
        const customizer = (objValue, srcValue) => {
          return objValue ? objValue : srcValue;
        };
        _.assignWith(bundleExistingLine, groupLine, customizer);
      }
    }

    const initGroupManagementData = async () => {
      if (
        !groupManagementState.loadGroupManagement.success &&
        Object.keys(groupManagementState.groupsView).length === 0
      ) {
        const { payload } = await dispatch(loadGroupManagement());

        if (payload) {
          let groupsObj = {};
          const bundles = itemsState.getItems.items.filter(
            (item) => item.category === ITEM_CATEGORY.BUNDLE
          );

          let lineNo = 0;

          //add new bundle lines
          _.each(bundles, (bundle) => {
            lineNo++;
            let lineDesc = intl.formatMessage({ id: "lbl.line" }) + ` ${lineNo}`;
            lineDescriptions[bundle.id] = lineDesc;

            let lineAvailableGroups = getLineAvailableGroups(payload, bundle);
            let line = {};
            if (!bundle.associatedSaID) line = createLineViewFromBundle(payload, bundle);
            else {
              line.accountId = bundle.associatedSaID;
              line.itemId = bundle.id;
            }

            _.each(lineAvailableGroups, (gt) => {
              if (groupsObj[gt]) {
                groupsObj[gt].lines.push(line);
              } else {
                groupsObj[gt] = {};
                groupsObj[gt].lines = [line];
              }
            });
          });

          // display warning message
          if (payload.removedOpLines.length > 0) {
            if (payload.sessionItemGroupOpUpdated) {
              if (payload.removedOpLines.length == 1 && !payload.groupAvailableForUpdatedItem) {
                setWarningMessage(
                  intl.formatMessage({ id: "lbl.warning-line-not-eligible-can-not-join" })
                );
              } else if (
                payload.removedOpLines.length == 1 &&
                payload.groupAvailableForUpdatedItem
              ) {
                setWarningMessage(
                  intl.formatMessage({ id: "lbl.warning-line-not-eligible-can-join" })
                );
              } else if (payload.removedOpLines.length > 1) {
                let warningMessageLinesList = [];
                let warningMessageLines = "";
                _.each(payload.removedOpLines, (removedOpLine) => {
                  warningMessageLinesList.push(
                    lineDescriptions[removedOpLine]
                      ? lineDescriptions[removedOpLine]
                      : removedOpLine
                  );
                });
                warningMessageLinesList.sort();
                _.each(warningMessageLinesList, (lineDesc) => {
                  warningMessageLines = warningMessageLines + lineDesc + ", ";
                });
                warningMessageLines = warningMessageLines.substring(
                  0,
                  warningMessageLines.length - 2
                );
                if (payload.groupAvailableForUpdatedItem) {
                  setWarningMessage(
                    intl.formatMessage(
                      { id: "lbl.warning-lines-not-eligible-can-join" },
                      { n: <br />, lines: warningMessageLines }
                    )
                  );
                } else {
                  setWarningMessage(
                    intl.formatMessage(
                      { id: "lbl.warning-lines-not-eligible-can-not-join" },
                      { n: <br />, lines: warningMessageLines }
                    )
                  );
                }
              }
            } else {
              let warningMessageLines = "";
              _.each(payload.removedOpLines, (removedOpLine) => {
                warningMessageLines =
                  warningMessageLines +
                  (lineDescriptions[removedOpLine]
                    ? lineDescriptions[removedOpLine]
                    : removedOpLine) +
                  ", ";
              });
              warningMessageLines = warningMessageLines.substring(
                0,
                warningMessageLines.length - 2
              );
              setWarningMessage(
                intl.formatMessage(
                  { id: "lbl.warning-lines-not-eligible" },
                  { n: <br />, lines: warningMessageLines }
                )
              );
            }
          }

          if (payload.existingLines && payload.accountGroups) {
            let sharingAccountsByType = {};
            _.each(payload.accountGroups, (ag) => {
              let gtMembers = ag.groupMembers.map((gm) => gm.accountId);
              sharingAccountsByType[ag.groupType.code] = gtMembers;
            });

            //add existing eligible lines
            let allSharingAccounts = Object.values(sharingAccountsByType).flat();
            let notSharingAccounts = payload.existingLines.filter(
              (line) => !allSharingAccounts.includes(line.id)
            );
            _.each(notSharingAccounts, (existingLine) => {
              _.each(Object.keys(groupsObj), (groupTypeCode) => {
                let groupType = payload.groupTypes.find((gt) => gt.code === groupTypeCode);
                let futureProductType = existingLine.futureProductDefinition?.productType;

                if (
                  groupType.packageTypes.includes(existingLine.productType) &&
                  groupType.statuses.includes(existingLine.status) &&
                  (!futureProductType || groupType.packageTypes.includes(futureProductType))
                ) {
                  addLineFromAccount(
                    payload,
                    existingLine.id,
                    groupsObj[groupTypeCode].lines,
                    false
                  );
                }
              });
            });

            //add existing sharing lines
            _.each(Object.keys(groupsObj), (groupTypeCode) => {
              if (sharingAccountsByType[groupTypeCode]) {
                _.each(sharingAccountsByType[groupTypeCode], (accountId) => {
                  addLineFromAccount(payload, accountId, groupsObj[groupTypeCode].lines, true);
                });
              }
            });
          }

          dispatch(setGroupsViewState(groupsObj));
          setDataForCurrentLine(payload, groupsObj);
        }
      } else {
        setDataForCurrentLine(groupManagementState.data, groupManagementState.groupsView);
      }
    };
    initGroupManagementData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupManagementState.loadGroupManagement.success]);

  useEffect(() => {
    if (groupManagementState.loadGroupManagement.success && groupManagementError)
      sectionRef.current?.scrollIntoView({ block: "center" });
  }, [groupManagementState.loadGroupManagement.success, groupManagementError]);

  const getLineAvailableGroups = (groupManagementData, bundleLine) => {
    let groupTypes = groupManagementData.groupTypes;

    let linePackageType = bundleLine.jsonBundleRequest
      ? JSON.parse(bundleLine.jsonBundleRequest).productType
      : "";

    let accountGroupTypeCode = getAccountGroupTypeCode(groupManagementData, bundleLine);
    if (accountGroupTypeCode) {
      let accountGroupType = groupManagementData.groupTypes.find(
        (gt) => gt.code === accountGroupTypeCode
      );
      if (!accountGroupType) {
        return [accountGroupTypeCode];
      }
    }
    return accountGroupTypeCode
      ? groupTypes.filter((gt) => gt.code === accountGroupTypeCode).map((gt) => gt.code)
      : groupTypes.filter((gt) => gt.packageTypes.includes(linePackageType)).map((gt) => gt.code);
  };
  const getAccountGroupTypeCode = (groupManagementData, bundleLine) => {
    let accountGroup = groupManagementData.accountGroups.find(
      (acGroup) =>
        acGroup.groupMembers.find((gm) => gm.accountId === bundleLine.associatedSaID) !== undefined
    );
    return accountGroup ? accountGroup.groupType.code : null;
  };

  const setDataForCurrentLine = (groupManagementData, groupsView) => {
    let lineAvailableGroups = getLineAvailableGroups(groupManagementData, currentBundle);

    let currentLineGroupTypes = _.entries(groupsView)
      .filter(
        (keyValue) =>
          lineAvailableGroups.includes(keyValue[0]) &&
          keyValue[1].lines.filter((line) => line.eligible || line.didJoin).length > 1
      )
      .map((entry) => entry[0]);
    setCurrentLineGroups(currentLineGroupTypes);
    setHasGroupManagement(currentLineGroupTypes.length > 0);

    let currentLineDidJoin = _.map(Object.values(groupsView), "lines")
      .flat()
      .find((line) => line.itemId === currentBundle.id && line.didJoin);
    setSharing(!!currentLineDidJoin);
  };

  const onJoinGroup = (groupType, lineToJoin) => {
    dispatch(joinGroup({ groupType: groupType, lineToJoin: lineToJoin }));
  };
  const onCancelJoin = (lineToCancel) => {
    dispatch(cancelJoinGroup({ lineToCancel: lineToCancel }));
  };
  const showGroupManagement = () => {
    return currentLineGroups.length > 0;
  };
  const clearWarningMessage = () => {
    setWarningMessage("");
  };

  const currentLineJoined = () => {
    return _.entries(groupManagementState.groupsView).some(
      (keyValue) =>
        keyValue[1].lines.filter((line) => line.itemId === currentBundle.id && line.didJoin)
          .length > 0
    );
  };
  const disableToggle = currentLineJoined();
  const disableGroup = (groupViewEntry) => {
    let newGroup = _.every(groupViewEntry[1].lines, "eligible");
    let oneRemaining =
      groupViewEntry[1].lines.filter(
        (line) => line.groupType === groupViewEntry[0] || !line.groupType
      ).length === 1;
    return newGroup && oneRemaining;
  };
  return (
    <>
      {showGroupManagement() && (
        <OSContainer mt={2} pb={5} sx={{ flexDirection: "column" }} variant="page-content">
          <Flex sx={{ width: "100%" }} ref={sectionRef}>
            <Flex ml={8.5} sx={{ flex: ["", "", 1], flexDirection: "column" }}>
              <Heading4 my={0}>
                {intl.formatMessage({ id: "lbl.group-management-header" })}
              </Heading4>
            </Flex>
            <Flex sx={{ justifyContent: "flex-end" }}>
              <Tooltip
                display="flex"
                position="top"
                value={
                  disableToggle && intl.formatMessage({ id: "lbl.group-management-header-tooltip" })
                }
                sx={{
                  width: "15rem",
                }}>
                <OSSwitch
                  id="groupManagementToggle"
                  name="groupManagementToggle"
                  disabled={disableToggle}
                  checked={sharing}
                  onChange={() => !disableToggle && setSharing(!sharing)}
                  ml="auto"
                  mr={6}
                />
              </Tooltip>
            </Flex>
          </Flex>
          {sharing && currentLineGroups.length > 1 && (
            <Text mx={8.5} mt={2} variant="headerStep">
              {intl.formatMessage({ id: "lbl.more-group-types" })}
            </Text>
          )}
          {sharing && (
            <Flex px={[8, 8, 9]} mt={9} sx={{ flexDirection: "column", width: "100%" }}>
              {_.entries(groupManagementState.groupsView)
                .filter((keyValue) => currentLineGroups.includes(keyValue[0]))
                .map((keyValue, index) => (
                  <Group
                    key={index}
                    lines={keyValue[1].lines}
                    groupType={keyValue[0]}
                    onJoinGroup={onJoinGroup}
                    onCancel={onCancelJoin}
                    disable={disableGroup(keyValue)}
                  />
                ))}
            </Flex>
          )}
        </OSContainer>
      )}
      <StyledModalMessage
        isOpen={warningMessage.length > 0}
        onRequestClose={clearWarningMessage}
        message={warningMessage}>
        <Button variant="simple-action" onClick={clearWarningMessage}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>
    </>
  );
};

export default GroupManagement;
