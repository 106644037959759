import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

const DISCOUNTS_INITIAL_STATE = {
  getBundleDiscounts: {
    loading: false,
    success: false,
    error: null,
    prices: {},
  },
};

export const getBundleDiscounts = createAsyncThunk(
  "discounts/getBundleDiscounts",
  async ({ itemId }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      const value = await FacadeAPI.GET(`/session/${sessionId}/item/${itemId}/discounts`, null, {
        noTimeout: true,
      });
      return { value: value, itemId: itemId };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const discountsSlice = createSlice({
  name: "discounts",
  initialState: DISCOUNTS_INITIAL_STATE,
  reducers: {
    clearGetBundleDiscounts(state) {
      return {
        ...state,
        getBundleDiscounts: DISCOUNTS_INITIAL_STATE.getBundleDiscounts,
      };
    },
    removeBundleDiscounts: {
      reducer: (state, action) => {
        const { itemId } = action.payload;

        const updatedPrices = { ...state.getBundleDiscounts.prices };
        delete updatedPrices[itemId];

        return {
          ...state,
          success: false,
          getBundleDiscounts: { ...state.getBundleDiscounts, prices: updatedPrices },
        };
      },
      prepare: (itemId) => {
        return {
          payload: {
            itemId,
          },
        };
      },
    },
  },
  extraReducers: {
    [getBundleDiscounts.pending]: (state) => {
      state.getBundleDiscounts.loading = true;
      state.getBundleDiscounts.success = false;
      state.getBundleDiscounts.error = null;
    },
    [getBundleDiscounts.fulfilled]: (state, action) => {
      state.getBundleDiscounts.loading = false;
      state.getBundleDiscounts.success = true;
      state.getBundleDiscounts.error = false;
      state.getBundleDiscounts.prices = {
        ...state.getBundleDiscounts.prices,
        [action.payload.itemId]: action.payload.value,
      };
    },
    [getBundleDiscounts.rejected]: (state, action) => {
      state.getBundleDiscounts.loading = false;
      state.getBundleDiscounts.success = false;
      state.getBundleDiscounts.error = action.error;
    },
  },
});

export const { clearGetBundleDiscounts, removeBundleDiscounts } = discountsSlice.actions;

export default discountsSlice.reducer;
