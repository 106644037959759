import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { canLogin } from "../../common/Constants";

/**
 * Private route.
 *
 * @param {*} param0.component The component to display for this route.
 * @param {*} param0.options The rest of props to send for Route.
 *
 * @returns The specified component or a redirect to Login if the user is not authenticated.
 */
const PrivateRoute = ({ component: Component, ...options }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const { flow } = useSelector((state) => state.personalInformation);
  const { sessionId, status } = useSelector((state) => state.session);

  return (
    <Route
      {...options}
      render={(props) => {
        if (!loggedIn && canLogin(flow)) {
          return (
            <Redirect to={{ pathname: "/personal-information", state: { from: props.location } }} />
          );
        } else if (sessionId && status === "FINISHED" && options.path !== "/order-confirmation") {
          return (
            <Redirect to={{ pathname: "/order-confirmation", state: { from: props.location } }} />
          );
        } else if (sessionId === null) {
          return <Redirect to={{ pathname: "/error", state: { from: props.location } }} />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
