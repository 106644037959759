import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Box, Flex, Text } from "@theme-ui/components";
import { SELFCARE_PROFILE, INVALID_FIELDS } from "../../../common/Constants";
import { ErrorMessage, OSContainer, OSSwitch } from "../../../components/base";
import { Heading3 } from "../../../components/heading";
import Tooltip from "../../../components/tooltip";

const SelfcareAccess = ({ onAccessLevelModified, selfcareProfile, untouchedMandatoryFields }) => {
  const intl = useIntl();

  return (
    <Box mt={8}>
      <Flex>
        <Heading3 color="primary">
          {intl.formatMessage({ id: "lbl.selfcare-access-question" })}
        </Heading3>
        <Text
          ml="0.1rem"
          color="error"
          sx={{
            fontSize: "sm",
          }}>
          *
        </Text>
      </Flex>

      <OSContainer
        variant="page-content"
        mt={2}
        sx={{
          borderColor:
            untouchedMandatoryFields.includes(INVALID_FIELDS.SELFCARE_ACCESS) &&
            selfcareProfile === ""
              ? "error"
              : "cardBorderColor",
        }}>
        <Flex ml={8.5} mr={6} sx={{ flexDirection: "column", width: "100%" }}>
          <Flex sx={{ width: "100%" }}>
            <Flex sx={{ flex: 1 }}>
              <Text variant={"lineDescription"}>
                {intl.formatMessage({ id: "lbl.full-access" })}
              </Text>
            </Flex>
            <Flex sx={{ justifyContent: "flex-end" }}>
              <Tooltip
                display="flex"
                position="custom"
                customProps={{
                  desktop: { mt: "-9rem", ml: "-8rem" },
                  tablet: { mt: "-9rem", ml: "-8rem" },
                  mobile: { mt: "-9rem", ml: "-8rem" },
                }}
                value={intl.formatMessage({ id: "lbl.selfcare-full-access-tooltip-message" })}
                sx={{
                  width: "7.5rem",
                }}>
                <OSSwitch
                  id="selfcareAccessToggle"
                  name="selfcareAccessToggle"
                  checked={selfcareProfile === SELFCARE_PROFILE.SELFCAREFULL}
                  onChange={() => {
                    if (selfcareProfile === "" || selfcareProfile === SELFCARE_PROFILE.SELFCARESA) {
                      onAccessLevelModified(SELFCARE_PROFILE.SELFCAREFULL);
                    } else {
                      onAccessLevelModified(SELFCARE_PROFILE.SELFCARESA);
                    }
                  }}
                  ml="auto"
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Box mb={6} />
          <Flex sx={{ width: "100%" }}>
            <Flex sx={{ flex: 1 }}>
              <Text variant={"lineDescription"}>
                {intl.formatMessage({ id: "lbl.partial-access" })}
              </Text>
            </Flex>
            <Flex sx={{ justifyContent: "flex-end" }}>
              <Tooltip
                display="flex"
                position="custom"
                customProps={{
                  desktop: { mt: "-7rem", ml: "-8rem" },
                  tablet: { mt: "-7rem", ml: "-8rem" },
                  mobile: { mt: "-7rem", ml: "-8rem" },
                }}
                value={intl.formatMessage({ id: "lbl.selfcare-limited-access-tooltip-message" })}
                sx={{
                  width: "7.5rem",
                }}>
                <OSSwitch
                  id="selfcareAccessToggle"
                  name="selfcareAccessToggle"
                  checked={selfcareProfile === SELFCARE_PROFILE.SELFCARESA}
                  onChange={() => {
                    if (
                      selfcareProfile === "" ||
                      selfcareProfile === SELFCARE_PROFILE.SELFCAREFULL
                    ) {
                      onAccessLevelModified(SELFCARE_PROFILE.SELFCARESA);
                    } else {
                      onAccessLevelModified(SELFCARE_PROFILE.SELFCAREFULL);
                    }
                  }}
                  ml="auto"
                />
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
      </OSContainer>
      {untouchedMandatoryFields.includes(INVALID_FIELDS.SELFCARE_ACCESS) && selfcareProfile === "" && (
        <ErrorMessage>
          <FormattedMessage id="lbl.selfcare-access-section" />
        </ErrorMessage>
      )}
    </Box>
  );
};

export default SelfcareAccess;
