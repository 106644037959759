import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import _ from "lodash";
const INITIAL_STATE = {
  saveGroupOperations: {
    loading: false,
    success: false,
    error: null,
  },
  loadGroupManagement: {
    loading: false,
    success: false,
    error: null,
  },
  deleteGroupOperations: {
    loading: false,
    success: false,
    error: null,
  },
  data: {},
  groupsView: {},
};

const getSaveGroupsRequest = (state) => {
  let linesThatDidJoin = _.chain(Object.values(state.groupsView))
    .map((group) => group.lines)
    .flatMap()
    .filter((line) => !!line.groupType)
    .uniqBy("serviceIdentifier")
    .value();
  return linesThatDidJoin.map((line) => {
    return {
      sessionItemId: line.itemId,
      accountId: line.accountId,
      groupTypeCode: line.groupType,
      groupId: line.groupId,
    };
  });
};

export const saveGroupOperations = createAsyncThunk(
  "group/saveGroupOperations",
  async (arg, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    let request = getSaveGroupsRequest(getState().groupManagement);
    let result;
    try {
      result = await FacadeAPI.PUT(`/session/${sessionId}/groups`, request, {
        expectedResponse: "",
      });
    } catch (err) {
      return rejectWithValue(err);
    }
    return result;
  }
);

export const loadGroupManagement = createAsyncThunk(
  "group/groupManagementData",
  async (arg, { getState }) => {
    const sessionId = getState().session.sessionId;
    return await FacadeAPI.GET(`/session/${sessionId}/groupManagementData`);
  }
);

export const deleteGroupOperations = createAsyncThunk(
  "group/deleteGroups",
  async (arg, { getState, dispatch }) => {
    const sessionId = getState().session.sessionId;
    await FacadeAPI.PUT(
      `/session/${sessionId}/deleteGroups`,
      {},
      {
        expectedResponse: "",
      }
    );
    dispatch(reloadGroupManagement());
  }
);
const groupManagementSlice = createSlice({
  name: "groupManagement",
  initialState: INITIAL_STATE,
  reducers: {
    setGroupsViewState(state, action) {
      state.groupsView = action.payload;
    },
    joinGroup(state, action) {
      let { groupType, lineToJoin } = action.payload;
      let groupIdLine = state.groupsView[groupType].lines.find(
        (line) => !line.eligible && line.accountId > 0
      );
      _.each(Object.keys(state.groupsView), (groupTypeCode) => {
        _.each(state.groupsView[groupTypeCode].lines, (line) => {
          if (line.serviceIdentifier === lineToJoin.serviceIdentifier) {
            line.didJoin = true;
            line.groupType = groupType;
            line.groupId = groupIdLine ? groupIdLine.groupId : "";
          }
        });
      });
    },
    cancelJoinGroup(state, action) {
      let { lineToCancel } = action.payload;
      _.each(Object.keys(state.groupsView), (groupTypeCode) => {
        _.each(state.groupsView[groupTypeCode].lines, (line) => {
          if (line.serviceIdentifier === lineToCancel.serviceIdentifier) {
            line.didJoin = false;
            line.groupType = "";
            line.groupId = "";
          }
        });
      });
    },
    reloadGroupManagement(state) {
      return {
        ...state,
        loadGroupManagement: INITIAL_STATE.loadGroupManagement,
        groupsView: {},
      };
    },
  },
  extraReducers: {
    [saveGroupOperations.pending]: (state) => {
      state.saveGroupOperations.loading = true;
      state.saveGroupOperations.success = false;
    },
    [saveGroupOperations.fulfilled]: (state) => {
      state.saveGroupOperations.loading = false;
      state.saveGroupOperations.success = true;
    },
    [saveGroupOperations.rejected]: (state, action) => {
      state.saveGroupOperations.loading = false;
      state.saveGroupOperations.error = action.error;
    },
    [loadGroupManagement.pending]: (state) => {
      state.loadGroupManagement.loading = true;
      state.loadGroupManagement.success = false;
    },
    [loadGroupManagement.fulfilled]: (state, action) => {
      state.loadGroupManagement.loading = false;
      state.loadGroupManagement.success = true;
      state.data = action.payload;
    },
    [loadGroupManagement.rejected]: (state, action) => {
      state.loadGroupManagement.loading = false;
      state.loadGroupManagement.error = action.error;
    },
    [deleteGroupOperations.pending]: (state) => {
      state.deleteGroupOperations.loading = true;
      state.deleteGroupOperations.success = false;
    },
    [deleteGroupOperations.fulfilled]: (state) => {
      state.deleteGroupOperations.loading = false;
      state.deleteGroupOperations.success = true;
    },
    [deleteGroupOperations.rejected]: (state, action) => {
      state.deleteGroupOperations.loading = false;
      state.deleteGroupOperations.error = action.error;
    },
  },
});
export const { setGroupsViewState, joinGroup, cancelJoinGroup, reloadGroupManagement } =
  groupManagementSlice.actions;
export default groupManagementSlice.reducer;
