import { baseTheme } from "./base-theme";

export const elTheme = {
  ...baseTheme,
  shadows: {
    ...baseTheme.shadows,
    sm: "none",
  },
  colors: {
    ...baseTheme.colors,
    windowBg: "#f2f2f2",
    headerBg: "#FFFFFF",
    invertedTextColor: "#242424",
    secondaryBg: "#FFFFFF",
    ternaryBg: "#FFFFFF",
    secondary: "#332a86",
    ternary: "#332a86",
    quaternary: "#242424",
    cardBg: "#fff",
    cardBorderColor: "#f2f2f2",
    cardBorderHoverColor: "#e81849",
    selectedCardBg: "#fff",
    selectedCardTextColor: "#242424",
    svgStrokePrimary: "#332a86", // "#bc544b",
    svgStrokeSecondary: "#bababa", // "#bc544b",
    bulletBgPrimary: "#332a86",
    hrTriangle: "#d3d3d3",
    estimatedTaxesColor: "#e81849",
    noResultsTextColor: "#e81849",
    button: {
      ...baseTheme.colors.button,
      background: "#e81849",
      disabledBackground: "#f2f2f2",
      hoverBackground: "#fe003c",
      color: "#ffffff",
      hoverColor: "#ffffff",
      disabledColor: "#8c8c8c",
    },
  },
};
