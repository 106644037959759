import React from "react";

import Amount from "../../../components/amount";

const ServiceFee = ({ rcFee, setupFee, plusTax = true }) => {
  if (rcFee === 0 && setupFee === 0) return null;
  if (rcFee && rcFee > 0) {
    return <Amount mt={1} compact amount={rcFee} isMonthly color="ternary" plusTax={plusTax} />;
  } else {
    return <Amount mt={1} compact amount={setupFee} color="ternary" plusTax={plusTax} />;
  }
};

export default ServiceFee;
