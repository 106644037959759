import React from "react";

import ArrowLeft from "./ArrowLeft";
import Chevron from "./Chevron";
import ChevronRight from "./ChevronRight";
import ChooseDifferentAddress from "./ChooseDifferentAddress";
import NoShipping from "./NoShipping";
import CloseEllipse from "./CloseEllipse";
import DeleteEllipse from "./DeleteEllipse";
import Document from "./Document";
import EditEllipse from "./EditEllipse";
import HomeDelivery from "./HomeDelivery";
import LocationPin from "./LocationPin";
import LocationPinDistance from "./LocationPinDistance";
import ShowMoreIcon from "./ShowMoreIcon";
import Mastercard from "./Mastercard";
import NoDevice from "./NoDevice";
import Plus from "./Plus";
import PhoneNumber from "./PhoneNumber";
import TooltipInfo from "./TooltipInfo";
import PickInStore from "./PickInStore";
import Receipt from "./Receipt";
import Search from "./Search";
import SecurePayment from "./SecurePayment";
import TickOrderConfirmation from "./TickOrderConfirmation";
import Trash from "./Trash";
import Visa from "./Visa";
import Contract from "./Contract";
import PaymentInformation from "./PaymentInformation";
import Logout from "./Logout";
import Error from "./Error";
import RoundCheckbox from "./RoundCheckbox";
import InsuranceCard from "./InsuranceCard";
import DialogBubble from "./DialogBubble";
import EditIcon from "./EditIcon";
import PdfFile from "./PdfFile";
import SuccessIcon from "./SuccessIcon";
import OutOfStock from "./OutOfStock";
import NoPickUp from "./NoPickUp";

const OSIcon = ({ name, ...props }) => {
  switch (name) {
    case "arrow-left":
      return <ArrowLeft color="primary" size="iconTiny" {...props} />;

    case "chevron":
      return <Chevron color="primary" size="iconSmall" {...props} />;

    case "chevron-right":
      return <ChevronRight color="svgStrokePrimary" size="iconTiny" {...props} />;

    case "choose-different-address":
      return (
        <ChooseDifferentAddress
          size="iconLarge"
          color1="disabledCardTextColor"
          color2="svgStrokePrimary"
          {...props}
        />
      );

    case "close-ellipse":
      return <CloseEllipse color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "contract":
      return <Contract color="ternary" size="iconmedium" {...props} />;

    case "delete-ellipse":
      return <DeleteEllipse color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "dialog-bubble":
      return <DialogBubble color="svgStrokePrimary" size="iconMedium" {...props} />;

    case "document":
      return <Document color="svgStrokePrimary" size="iconMedium" {...props} />;

    case "error":
      return <Error {...props} />;

    case "edit-ellipse":
      return <EditEllipse color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "edit-icon":
      return <EditIcon strokeColor="svgStrokePrimary" size="iconSmall" {...props} />;

    case "home-delivery":
      return <HomeDelivery color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "home-delivery-disabled":
      return <HomeDelivery color="svgStrokeSecondary" size="iconLarge" {...props} />;

    case "insurance-card":
      return <InsuranceCard color="svgStrokePrimary" size="iconMedium" {...props} />;

    case "location-pin":
      return <LocationPin color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "location-pin-disabled":
      return <LocationPin color="svgStrokeSecondary" size="iconSmall" {...props} />;

    case "location-pin-distance":
      return <LocationPinDistance color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "location-pin-distance-disabled":
      return <LocationPinDistance color="svgStrokeSecondary" size="iconSmall" {...props} />;

    case "logout":
      return <Logout fill="svgStrokePrimary" size="iconTiny" {...props} />;

    case "show-more":
      return <ShowMoreIcon color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "mastercard-disabled":
      return (
        <Mastercard fill_1="#ababab" fill_2="#888" fill_3="#cecdcd" size="iconLarge" {...props} />
      );

    case "mastercard-enabled":
      return <Mastercard {...props} size="iconLarge" />;

    case "no-device":
      return <NoDevice color="svgStrokePrimary" size="iconVeryLarge" {...props} />;

    case "no-pickup":
      return <NoPickUp color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "no-shipping":
      return <NoShipping color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "out-of-stock":
      return <OutOfStock color="svgStrokeSecondary" size="iconSmall" {...props} />;

    case "payment-information":
      return <PaymentInformation color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "pdf-file":
      return <PdfFile size="iconMedium" {...props} />;

    case "phone-number":
      return <PhoneNumber color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "phone-number-disabled":
      return <PhoneNumber color="svgStrokeSecondary" size="iconSmall" {...props} />;

    case "plus":
      return <Plus color="ternary" size="iconTiny" {...props} />;

    case "round-checkbox":
      return <RoundCheckbox color="svgStrokePrimary" size="iconMedium" {...props} />;

    case "pick-in-store":
      return <PickInStore color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "pick-in-store-disabled":
      return <PickInStore color="svgStrokeSecondary" size="iconLarge" {...props} />;

    case "receipt":
      return <Receipt size="iconMedium" color="svgStrokePrimary" {...props} />;

    case "search":
      return <Search color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "securePayment":
      return <SecurePayment color="svgStrokePrimary" size="iconTiny" {...props} />;

    case "success-icon":
      return <SuccessIcon size="iconSmall" {...props} />;

    case "tickOrderConfirmation":
      return <TickOrderConfirmation color="svgStrokePrimary" size="iconLarge" {...props} />;

    case "tooltip-info":
      return <TooltipInfo color="svgStrokePrimary" size="iconMedium" {...props} />;

    case "trash":
      return <Trash color="svgStrokePrimary" size="iconSmall" {...props} />;

    case "visa-disabled":
      return <Visa {...props} size="iconLarge" />;

    case "visa-enabled":
      return <Visa fill="#172b85" size="iconLarge" {...props} />;

    default:
      return null;
  }
};

export { OSIcon };
