import React from "react";
import { Box } from "@theme-ui/components";

const Mastercard = ({
  fill_1 = "#f26122",
  fill_2 = "#ea1d25",
  fill_3 = "#f69e1e",
  size = "iconLarge",
  ...props
}) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: size,
      height: size,
      FlexShrink: 0,
    }}
    viewBox="0 0 48 32"
    {...props}>
    <path
      d="M4,0H44q.2,0,.392.019t.388.058q.193.038.381.1T45.531.3q.181.075.355.168t.337.2q.163.109.315.234t.291.264q.139.139.264.291t.234.315q.109.163.2.337t.168.355q.075.182.132.369t.1.381q.038.193.058.388T48,4V28q0,.2-.019.392t-.058.388q-.038.193-.1.381t-.132.369q-.075.182-.168.355t-.2.337q-.109.163-.234.315t-.264.291q-.139.139-.291.264t-.315.234q-.163.109-.337.2t-.355.168q-.181.075-.369.132t-.381.1q-.193.038-.388.057T44,32H4q-.2,0-.392-.019t-.388-.057q-.193-.038-.381-.1T2.469,31.7q-.182-.075-.355-.168t-.337-.2q-.163-.109-.315-.234t-.291-.264q-.139-.139-.264-.291t-.234-.315q-.109-.163-.2-.337T.3,29.531q-.075-.182-.132-.369t-.1-.381q-.038-.193-.058-.388T0,28V4q0-.2.019-.392t.058-.388q.038-.193.1-.381T.3,2.469q.075-.182.168-.355t.2-.337q.109-.163.234-.315t.264-.291q.139-.139.291-.264T1.777.674q.163-.109.337-.2T2.469.3Q2.65.229,2.838.172t.381-.1q.193-.038.388-.058T4,0Z"
      fill="#fff"
    />
    <g transform="translate(4 3)">
      <g opacity="0">
        <path d="M.65.2H39.36V25.8H.65Z" fill="#fff" />
      </g>
      <path d="M14.9,4.66H25.11V21.34H14.9Z" fill={fill_1} />
      <path
        d="M16,13a10.58,10.58,0,0,1,4-8.34,10.61,10.61,0,1,0,0,16.68A10.58,10.58,0,0,1,16,13Z"
        fill={fill_2}
      />
      <path
        d="M36.14,19.57v-.34h.15v-.07h-.35v.07h.14v.34Zm.68,0v-.41h-.1l-.12.29-.13-.29h-.1v.41h.07v-.31l.12.27h.08l.11-.27v.31Z"
        fill={fill_3}
      />
      <path
        d="M37.16,13A10.61,10.61,0,0,1,20,21.34,10.61,10.61,0,0,0,21.78,6.45,10.4,10.4,0,0,0,20,4.66,10.61,10.61,0,0,1,37.16,13Z"
        fill={fill_3}
      />
    </g>
  </Box>
);

export default Mastercard;
