import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  validateAccountPIN: {
    loading: false,
    success: false,
    error: null,
  },
  registerAccountPIN: {
    loading: false,
    success: false,
    error: null,
  },
};

export const validateAccountPIN = createAsyncThunk(
  "accountPin/validateAccountPIN",
  async ({ accountId, pin }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.POST(
        `/account/${accountId}/validateAccountPin`,
        { sessionId, pin },
        { expectedResponse: "" }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerAccountPIN = createAsyncThunk(
  "accountPin/registerAccountPIN",
  async ({ accountId, pin }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.POST(
        `/account/${accountId}/registerAccountPIN`,
        { sessionId, pin },
        { expectedResponse: "" }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const pinSlice = createSlice({
  name: "accountPin",
  initialState: INITIAL_STATE,
  reducers: {
    clearValidateAccountPIN(state) {
      return {
        ...state,
        validateAccountPIN: INITIAL_STATE.validateAccountPIN,
      };
    },
    clearRegisterAccountPIN(state) {
      return {
        ...state,
        registerAccountPIN: INITIAL_STATE.registerAccountPIN,
      };
    },
  },
  extraReducers: {
    [validateAccountPIN.pending]: (state) => {
      state.validateAccountPIN.loading = true;
    },
    [validateAccountPIN.fulfilled]: (state) => {
      state.validateAccountPIN.loading = false;
      state.validateAccountPIN.success = true;
      state.validateAccountPIN.error = null;
    },
    [validateAccountPIN.rejected]: (state, action) => {
      state.validateAccountPIN.loading = false;
      state.validateAccountPIN.error = action.payload;
    },
    [registerAccountPIN.pending]: (state) => {
      state.registerAccountPIN.loading = true;
    },
    [registerAccountPIN.fulfilled]: (state) => {
      state.registerAccountPIN.loading = false;
      state.registerAccountPIN.success = true;
      state.registerAccountPIN.error = null;
    },
    [registerAccountPIN.rejected]: (state, action) => {
      state.registerAccountPIN.loading = false;
      state.registerAccountPIN.error = action.payload;
    },
  },
});

export const { clearValidateAccountPIN, clearRegisterAccountPIN } = pinSlice.actions;
export default pinSlice.reducer;
