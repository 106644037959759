import React from "react";
import { Flex } from "@theme-ui/components";

import { Heading2 } from "../../heading";
import Device from "./Device";
import Plan from "./Plan";
import OldDeviceInstallment from "./OldDeviceInstallment";
import Tooltip from "../../tooltip/Tooltip";
import useOverFlow from "../../../hooks/useOverFlow";

/**
 * Renders a bundle item.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 * @param {showTaxes} props.showTaxes Show item taxes.
 * @param {lineDescription} props.lineDescription Cart line description.
 */
const Bundle = ({ item, plusTax, showPrices, lineDescription }) => {
  const [isOverflow, overflow] = useOverFlow();
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Tooltip
        mt={0}
        position="top"
        isOverflow={isOverflow}
        value={lineDescription}
        sx={{
          width: "100%",
        }}>
        <Heading2
          ref={overflow}
          mb={3}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {lineDescription}
        </Heading2>
      </Tooltip>
      <Device item={item} showPrices={showPrices} plusTax={plusTax} />
      <OldDeviceInstallment item={item} showPrices={showPrices} plusTax={plusTax} />
      <Plan item={item} showPrices={showPrices} plusTax={plusTax} />
    </Flex>
  );
};

export default Bundle;
