import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import dayjs from "dayjs";

import IntlConstants from "../config/intl/Constants";

const OSIntlContext = React.createContext();

function OSIntlProvider({ children }) {
  const switchLanguage = (language) => {
    setIntlParams({
      ...intlParams,
      locale: language,
      messages: IntlConstants.MESSAGES[language],
    });
    dayjs.locale(language);
  };

  const [intlParams, setIntlParams] = useState({
    locale: IntlConstants.INITIAL_LOCALE,
    messages: IntlConstants.MESSAGES[IntlConstants.INITIAL_LOCALE],
    switchLanguage: switchLanguage,
  });

  return (
    <OSIntlContext.Provider value={intlParams}>
      <IntlProvider
        key={intlParams.locale}
        locale={intlParams.locale}
        messages={IntlConstants.MESSAGES[intlParams.locale]}
        formats={IntlConstants.FORMATS}
        defaultLocale={IntlConstants.INITIAL_LOCALE}>
        {children}
      </IntlProvider>
    </OSIntlContext.Provider>
  );
}

export { OSIntlContext, OSIntlProvider };
