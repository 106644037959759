import _ from "lodash";
import { CHECKOUT_STEPS } from "../components/header/Constants";
import { PERSONAL_INFORMATION_FLOW } from "../common/Constants";

function getCrtStepDynamicIndex(steps, crtStep) {
  return _.findIndex(steps, (step) => _.isEqual(step, crtStep));
}

function getActiveStep(crtStepStaticIndex) {
  return _.find(CHECKOUT_STEPS, (step) => step.index === crtStepStaticIndex);
}

export function getPreviousStep(planFlags, crtStepStaticIndex, flow) {
  let steps = getSteps(planFlags, flow);
  let crtStep = getActiveStep(crtStepStaticIndex);
  let crtStepDynamicIdx = getCrtStepDynamicIndex(steps, crtStep);

  if (crtStepDynamicIdx <= 1) {
    return CHECKOUT_STEPS.PERSONAL_INFORMATION;
  }

  return steps[crtStepDynamicIdx - 1];
}

export function getNextStep(planFlags, crtStep, flow) {
  let steps = getSteps(planFlags, flow);
  let crtStepDynamicIdx = getCrtStepDynamicIndex(steps, crtStep);

  if (crtStepDynamicIdx >= steps.length) {
    return CHECKOUT_STEPS.PAYMENT_INFORMATION;
  }

  return steps[crtStepDynamicIdx + 1];
}

export function getSteps(planFlags, flow) {
  let flowLoaded = flow !== "";
  return Object.keys(CHECKOUT_STEPS)
    .sort((a, b) => CHECKOUT_STEPS[a].index - CHECKOUT_STEPS[b].index)
    .filter(
      (stepKey) =>
        !flowLoaded ||
        flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE ||
        stepKey !== propName(CHECKOUT_STEPS, CHECKOUT_STEPS.UPDATE_DEVICE)
    ) // ^ hide Device Upgrade step for other flows besides EXISTING_UPGRADE
    .filter(
      (stepKey) =>
        flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE ||
        stepKey !== propName(CHECKOUT_STEPS, CHECKOUT_STEPS.PHONE_NUMBER)
    ) // ^ hide Phone Number step for EXISTING_UPGRADE flow
    .filter(
      (stepKey) =>
        planFlags.planExists || stepKey !== propName(CHECKOUT_STEPS, CHECKOUT_STEPS.PHONE_NUMBER)
    )
    .map((key) => CHECKOUT_STEPS[key]);
}

export function getMobileStepsData(planFlags, crtStepStaticIdx, flow) {
  if (!crtStepStaticIdx) {
    return null;
  }

  let steps = getSteps(planFlags, flow);
  let activeStep = getActiveStep(crtStepStaticIdx);
  let activeStepDynamicIdx = getCrtStepDynamicIndex(steps, activeStep);

  let completedStep = null;
  if (activeStepDynamicIdx > 0) {
    completedStep = {
      hasPrevious: activeStepDynamicIdx > 1,
      label: steps[activeStepDynamicIdx - 1].label,
    };
  }

  let activeOrCompletedStep = {
    isCompleted: activeStepDynamicIdx === steps.length - 1,
    label: steps[activeStepDynamicIdx].label,
  };

  let nextOrActiveStep = null;
  if (activeStepDynamicIdx < steps.length - 1) {
    nextOrActiveStep = {
      hasNext: activeStepDynamicIdx < steps.length - 2,
      label: steps[activeStepDynamicIdx + 1].label,
    };
  }

  return {
    left: completedStep,
    center: activeOrCompletedStep,
    right: nextOrActiveStep,
  };
}

const propName = (obj, type) => Object.keys(obj).find((key) => obj[key] === type);
