import React from "react";
import { Flex, Text } from "@theme-ui/components";

const Attribute = ({ value, ...props }) => {
  return (
    <Flex
      bg="windowBg"
      py={2}
      sx={{
        border: "1",
        borderColor: "greyish",
        borderRadius: "lg",
        boxShadow: "sm",
        cursor: "default",
        justifyContent: "center",
        alignItems: "center",
        minWidth: "6.125rem",
        px: 5,
      }}
      {...props}>
      <Text variant="attribute">{value}</Text>
    </Flex>
  );
};

export default Attribute;
