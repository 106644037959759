import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Storage from "store2";
import { Box, Button, Flex, Heading } from "@theme-ui/components";

import Layout from "../../components/layout";
import { clearGetSessionResponse } from "../../config/redux/slices";
import GetErrorDescription from "../../components/get-error-description";
import { storefrontURLs } from "../../config/security/Deployment";

const Error = () => {
  const dispatch = useDispatch();
  const sessionData = useSelector((state) => state.session);

  return (
    <Layout showCart={false} showSteps={false} loadSession={false}>
      <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
        <Box
          sx={{
            height: "fit-content",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "cardBg",
            border: "1",
            borderColor: "cardBorderColor",
            borderRadius: "xl",
            width: [300, 500, 610],
            boxShadow: "md",
            overflow: "auto",
          }}>
          <Box sx={{ py: [10, 12, 16], px: 6, textAlign: "center" }}>
            <Heading
              variant="heading3normal"
              sx={{
                textAlign: "center",
                whitespace: "nowrap",
              }}>
              <GetErrorDescription error={sessionData.getSession.error} />
            </Heading>

            <Box sx={{ pt: 6, textAlign: "center" }}>
              <Button
                variant="simple-action"
                onClick={() => {
                  dispatch(clearGetSessionResponse());
                  Storage.clear();
                  window.location.replace(storefrontURLs.base);
                }}>
                <FormattedMessage id="lbl.btn_back" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default Error;
