import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import { ComponentType, NONE_ME_SRV_CODE } from "../../../common/Constants";

const ADDITIONAL_SERVICES_INITIAL_STATE = {
  updatedPlan: null,
  dependentEasyUpSrvSelected: false,
  loadSelectedPlan: {
    loading: false,
    success: false,
    error: null,
    plan: null,
  },
  getEasyUpConfigServices: {
    loading: false,
    success: false,
    error: null,
    easyUpConfigServices: {},
  },
};

export const loadSelectedPlan = createAsyncThunk(
  "additionalServices/selectedPlan",
  async (args) => {
    const { packageCode, skuCodeHandset, skuCodeSim, technology } = { ...args };

    return await FacadeAPI.GET(
      `/plan/${packageCode}?skuCodeHandset=${skuCodeHandset}&skuCodeSim=${skuCodeSim}&technology=${technology}`
    );
  }
);

export const getEasyUpConfigServices = createAsyncThunk(
  "additionalServices/easyUpConfigServices",
  async () => {
    const easyUpServices = await FacadeAPI.GET(`/configuration/easyUpServiceCodes`);
    const easyUpDependentServices = await FacadeAPI.GET(
      `/configuration/deviceProtectionServiceCodes`
    );
    return { easyUpServices: easyUpServices, easyUpDependentServices: easyUpDependentServices };
  }
);

const findProductByCode = (parentProductCode, mainPlan) => {
  if (mainPlan.code === parentProductCode) {
    return mainPlan;
  }

  if (
    mainPlan.subproducts !== null &&
    mainPlan.subproducts !== undefined &&
    mainPlan.subproducts.length
  ) {
    for (let subProd of mainPlan.subproducts) {
      let innerSupProd = findProductByCode(parentProductCode, subProd);
      if (innerSupProd !== null) {
        return innerSupProd;
      }
    }
  }
  return null;
};

const findServiceByCode = (serviceCode, prod) => {
  for (let service of prod.services) {
    if (service.code === serviceCode) {
      return service;
    }
  }
};

const findFeatureGroup = (featureGroupCode, service) => {
  for (let featureGroup of service.instances[0].featuresGroups) {
    if (featureGroup.code === featureGroupCode) {
      return featureGroup;
    }
  }
};

const isAnyDependencyServiceSelected = (prod, dependencyServices) => {
  return prod.services
    .filter((srv) => dependencyServices?.includes(srv.code))
    .flatMap((service) => service.instances)
    .some((instance) => instance.selected === true);
};

const additionalServicesSlice = createSlice({
  name: "additionalServices",
  initialState: {
    ...ADDITIONAL_SERVICES_INITIAL_STATE,
  },
  reducers: {
    updateAdditionalServices(state, action) {
      const easyUpDependentServices =
        state.getEasyUpConfigServices.easyUpConfigServices.easyUpDependentServices;
      const isDependentEasyUpSelected = action.payload.updatedPlan.subproducts
        .flatMap((prod) => prod.services)
        .some(
          (service) =>
            easyUpDependentServices.includes(service.code) && service.instances[0].selected
        );

      return {
        ...state,
        ...action.payload,
        dependentEasyUpSrvSelected: isDependentEasyUpSelected,
      };
    },

    selectSubProduct(state, action) {
      let subProductCode = action.payload;
      let subProduct = findProductByCode(subProductCode, state.updatedPlan);
      subProduct.selected = !subProduct.selected;
    },

    selectService(state, action) {
      const { productCode, serviceCode } = action.payload;
      const product = findProductByCode(productCode, state.updatedPlan);
      let service = findServiceByCode(serviceCode, product);
      service.instances[0].selected = !service.instances[0].selected;
    },

    selectMEServiceOrSubProduct(state, action) {
      let { parentProductCode, entityCode, isService } = action.payload;
      let mainPlan = state.updatedPlan;

      function markServiceOrSubProductAsSelected(entityCode, plan, isService) {
        let isNonePresent =
          plan.services.filter((s) => s.type === ComponentType.RADIO && s.code === NONE_ME_SRV_CODE)
            .length > 0;
        if (isNonePresent) {
          for (let service of plan.services.filter((s) => s.type === ComponentType.RADIO)) {
            service.instances[0].selected =
              isService && service.code === entityCode && !service.instances[0].selected;
          }
          for (let subProd of plan.subproducts.filter((p) => p.type === ComponentType.RADIO)) {
            subProd.selected = !isService && subProd.code === entityCode && !subProd.selected;
          }
        } else {
          for (let service of plan.services.filter((s) => s.type === ComponentType.RADIO)) {
            service.instances[0].selected = isService && service.code === entityCode;
          }
          for (let subProd of plan.subproducts.filter((p) => p.type === ComponentType.RADIO)) {
            subProd.selected = !isService && subProd.code === entityCode;
          }
        }
      }

      let parentPlan = findProductByCode(parentProductCode, mainPlan);
      markServiceOrSubProductAsSelected(entityCode, parentPlan, isService);

      const easyUpConfigServices = state.getEasyUpConfigServices.easyUpConfigServices;
      const easyUpService = parentPlan.services.find((serv) =>
        easyUpConfigServices.easyUpServices.includes(serv.code)
      );
      if (easyUpService) {
        const isEasyUpDependencySelected = isAnyDependencyServiceSelected(
          parentPlan,
          easyUpConfigServices.easyUpDependentServices
        );

        state.dependentEasyUpSrvSelected = isEasyUpDependencySelected;

        if (easyUpService?.instances[0].selected && !isEasyUpDependencySelected) {
          easyUpService.instances[0].selected = false;
        }
      }
    },

    selectFeature(state, action) {
      let { parentProductCode, serviceCode, featureGroupCode, featureCode } = action.payload;

      let product = findProductByCode(parentProductCode, state.updatedPlan);
      let service = findServiceByCode(serviceCode, product);
      let featureGroup = findFeatureGroup(featureGroupCode, service);
      let isME = featureGroup.features[0].type === ComponentType.RADIO;
      let isNonePresent = featureGroup.features.some((feat) => !feat.description);
      for (let feature of featureGroup.features) {
        if (isME) {
          if (isNonePresent) {
            feature.selected = featureCode === feature.code && !feature.selected;
          } else {
            feature.selected = featureCode === feature.code;
          }
        } else if (featureCode === feature.code) {
          feature.selected = !feature.selected;
        }
      }
    },

    changeMultipleInstanceService(state, action) {
      let { parentProductCode, serviceCode, counter } = action.payload;
      let product = findProductByCode(parentProductCode, state.updatedPlan);
      let service = findServiceByCode(serviceCode, product);

      if (counter === service.minNumberOfInstances - 1) {
        for (let instance of service.instances) instance.selected = false;
      } else if (counter === service.minNumberOfInstances && service.instances.length === counter) {
        for (let instance of service.instances) instance.selected = true;
      } else if (service.instances.length < counter) {
        service.instances.push({ ...service.instances[0] });
      } else if (service.instances.length > counter) {
        service.instances.pop();
      }
    },
  },
  extraReducers: {
    [loadSelectedPlan.pending]: (state) => {
      state.loadSelectedPlan = {
        loading: true,
        success: false,
        error: null,
      };
    },
    [loadSelectedPlan.fulfilled]: (state, action) => {
      state.loadSelectedPlan = {
        loading: false,
        success: true,
        plan: action.payload,
      };
    },
    [loadSelectedPlan.rejected]: (state, action) => {
      state.loadSelectedPlan = {
        loading: false,
        success: false,
        error: action.error,
      };
    },
    [getEasyUpConfigServices.pending]: (state) => {
      state.getEasyUpConfigServices.loading = true;
      state.getEasyUpConfigServices.success = false;
    },
    [getEasyUpConfigServices.fulfilled]: (state, action) => {
      state.getEasyUpConfigServices.loading = false;
      state.getEasyUpConfigServices.success = true;
      state.getEasyUpConfigServices.easyUpConfigServices = action.payload;
    },
    [getEasyUpConfigServices.rejected]: (state, action) => {
      state.getEasyUpConfigServices.loading = false;
      state.getEasyUpConfigServices.success = false;
      state.getEasyUpConfigServices.error = action.error;
    },
  },
});

export const {
  updateAdditionalServices,
  selectSubProduct,
  selectService,
  selectMEServiceOrSubProduct,
  selectFeature,
  changeMultipleInstanceService,
} = additionalServicesSlice.actions;

export default additionalServicesSlice.reducer;
