import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Flex, Text, Box, Button } from "@theme-ui/components";
import { OSSection } from "../../../components/base";
import Separator from "../../../components/separator";
import { ACCOUNT_STATUS } from "../../../common/Constants";

const Group = ({ groupType, lines, onJoinGroup, onCancel, disable }) => {
  const intl = useIntl();
  const gridTemplate = "repeat(5, 1fr)";

  function isJoinGroupButtonDisabled(line) {
    return (line.didJoin && line.groupType !== groupType) || disable;
  }

  function isJoinGroupButtonVisible(line) {
    return line.eligible && (!line.didJoin || (line.didJoin && line.groupType !== groupType));
  }

  function isCancelJoinButtonVisible(line) {
    return line.eligible && line.didJoin && line.groupType === groupType;
  }

  function getSharingStatusLabel(line) {
    if (line.eligible && isJoinGroupButtonDisabled(line)) {
      return intl.formatMessage({ id: "lbl.ineligible" });
    }
    return intl.formatMessage({
      id: line.eligible && !line.didJoin ? "lbl.eligible" : "lbl.sharing",
    });
  }

  function getEligibleLineLabel(line) {
    return intl.formatMessage({
      id:
        line.accountId > 0
          ? line.status === ACCOUNT_STATUS.NEW
            ? "lbl.pending"
            : "lbl.existing"
          : "lbl.new",
    });
  }

  const getGroupLine = (index, line) => {
    return (
      <React.Fragment key={index}>
        {index >= 0 && <Separator variant="card-border-no-width" />}
        <OSSection
          name="groupLineRow"
          sx={{
            display: "grid",
            flexDirection: "row",
            gridGap: 2,
            gridTemplateColumns: gridTemplate,
            gridAutoRows: "min-content",
          }}>
          <Text variant="default" mb={1}>
            {getEligibleLineLabel(line)}
          </Text>
          <Text
            variant="default"
            sx={{
              mb: 1,
              overflowWrap: "break-word",
            }}>
            {line.serviceIdentifier}
          </Text>
          <Text
            variant="default"
            sx={{
              mb: 1,
              overflowWrap: "break-word",
            }}>
            {line.packageDescription}
          </Text>
          <Text variant="default" mb={1}>
            {getSharingStatusLabel(line)}
          </Text>
          {isJoinGroupButtonVisible(line) && (
            <Button
              id="joinGroup"
              disabled={isJoinGroupButtonDisabled(line)}
              variant="simple-action-join-group"
              sx={{ maxWidth: "8rem", maxHeight: "2.5rem" }}
              onClick={onJoinGroup.bind(this, groupType, line)}>
              <FormattedMessage id="btn.join" />
            </Button>
          )}
          {isCancelJoinButtonVisible(line) && (
            <Button
              variant="simple-action-join-group"
              sx={{ maxWidth: "8rem", maxHeight: "2.5rem" }}
              onClick={onCancel.bind(this, line)}>
              <FormattedMessage id="btn.cancel-join" />
            </Button>
          )}
        </OSSection>
      </React.Fragment>
    );
  };
  return (
    <>
      <OSSection
        name="groupName"
        sx={{
          display: "grid",
          flexDirection: "row",
          gridGap: 2,
          gridTemplateColumns: gridTemplate,
          gridAutoRows: "min-content",
        }}>
        <Text variant="heading6" mb={1}>
          {intl.formatMessage({ id: "lbl.eligible-lines" })}
        </Text>
        <Text variant="heading6" mb={1}>
          {intl.formatMessage({ id: "lbl.member-phone-number" })}
        </Text>
        <Text variant="heading6" mb={1}>
          {intl.formatMessage({ id: "lbl.member-package" })}
        </Text>
        <Text variant="heading6" mb={1}>
          {intl.formatMessage({ id: "lbl.member-sharing-status" })}
        </Text>
        <Text variant="heading6" mb={1}>
          {intl.formatMessage({ id: "lbl.member-actions" })}
        </Text>
      </OSSection>
      <Flex mt={0} sx={{ py: "2", flexDirection: "column" }}>
        {lines.map((line, index) => getGroupLine(index, line))}
        <Box mb={6} />
      </Flex>
    </>
  );
};

export default Group;
