import React from "react";
import { PRICING_OPTION } from "../../common/Constants";
import { Flex, Text } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";

const AmountDescription = ({ id, className, pricingOption, termsAmount }) => {
  return (
    <Flex
      className={className}
      id={id}
      sx={{
        flexDirection: "column",
        textAlign: "center",
      }}>
      <Text>
        <FormattedMessage
          id="lbl.for-x-months"
          values={{
            noOfMonths: termsAmount,
          }}
        />
      </Text>
      {pricingOption === PRICING_OPTION.EASYTABFLEX && (
        <Text>
          <FormattedMessage id="lbl.with-easyTab-Flex" />
        </Text>
      )}
      {(!pricingOption || pricingOption === PRICING_OPTION.EASYTAB) && (
        <Text>
          <FormattedMessage id="lbl.with-easyTab" />
        </Text>
      )}
    </Flex>
  );
};

export default AmountDescription;
