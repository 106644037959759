import React, { useContext } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";
import LangCode from "../../config/intl/LangCode";
import _ from "lodash";
import { OSIntlContext } from "../../contexts/OSIntlContext";

/**
 * Amount component.
 *
 * @param {Object} props Component props.
 * @param {Number} props.amount The amount.
 * @param {String} props.description The amount description.
 * @param {String} props.currencySymbol The currency symbol.
 * @param {Boolean} props.isMonthly Flag indicating if the payment is monthly based.
 * @param {String} props.color Text color.
 * @param {String} props.descriptionColor Description text color, used only if provided. Otherwise, {props.color} will be used.
 * @param {String} props.type Amount type.
 * @param {*} props.props Rest of the props.
 */
const Amount = ({
  amount,
  description,
  currencySymbol = "$",
  isMonthly = false,
  color = "primary",
  descriptionColor,
  type,
  compact = false,
  includeTax = false,
  plusTax = false,
  className,
  id,
  ...props
}) => {
  const intl = useIntl();
  const { locale } = useContext(OSIntlContext);
  const preciseAmount = _.round(amount, 2).toFixed(2);
  const parsedAmount = preciseAmount.split(".");
  const isEnglishLang = locale === LangCode.English;
  const descColor = descriptionColor ? descriptionColor : color;
  const isEnLocaleAndNegativeAmount = isEnglishLang && preciseAmount < 0;
  let amountProps;
  switch (type) {
    case "bold_superlarge":
      amountProps = {
        fontSize: "3xl",
        fontWeight: "bold",
      };
      break;
    case "bold_extralarge":
      amountProps = {
        fontSize: "2xl",
        fontWeight: "bold",
      };
      break;
    case "bold_large":
      amountProps = {
        fontSize: "xxl",
        fontWeight: "bold",
      };
      break;
    case "semibold_extralarge":
      amountProps = {
        fontSize: "2xl",
        fontWeight: "semibold",
      };
      break;
    case "semibold_large":
      amountProps = {
        fontSize: "xxl",
        fontWeight: "semibold",
      };
      break;
    case "normal_large":
      amountProps = {
        fontSize: "xxl",
        fontWeight: "normal",
      };
      break;
    default:
      amountProps = {
        fontSize: "xxl",
        fontWeight: "medium",
      };
      break;
  }

  return (
    <Flex
      className={className}
      id={id}
      sx={{
        minWidth: !compact ? "6rem" : "initial",
        cursor: "inherit",
        flexDirection: "column",
        justifyContent: "center",
      }}
      {...props}>
      <Flex
        className={className}
        id={id}
        sx={{
          lineHeight: 0.7,
          color: color,
          ...amountProps,
        }}>
        {/*  Currency(English case) and integer part */}
        <Flex
          className={className}
          id={id}
          sx={{
            flex: "1",
            justifyContent: "flex-end",
          }}>
          {isEnLocaleAndNegativeAmount && (
            <Text
              sx={{
                color: color,
              }}>
              -
            </Text>
          )}
          {isEnglishLang && (
            <Text
              sx={{
                alignSelf: "flex-start",
                fontSize: "50%",
                color: color,
              }}>
              {currencySymbol}
            </Text>
          )}
          <Text
            sx={{
              color: color,
            }}>
            {parsedAmount[0] * (isEnLocaleAndNegativeAmount ? -1 : 1)}
          </Text>
        </Flex>
        {/*  Decimal and monthly part + currency(French case) */}
        <Flex
          className={className}
          id={id}
          sx={{
            width: !compact ? "2.5rem" : "initial",
            justifyContent: "flex-start",
          }}>
          <Text
            sx={{
              fontSize: "50%",
              color: color,
            }}>
            {parsedAmount[1]}
          </Text>
          <Flex
            className={className}
            id={id}
            sx={{
              flexDirection: !isEnglishLang && "column",
            }}>
            {!isEnglishLang && (
              <Text
                sx={{
                  alignSelf: "flex-start",
                  marginLeft: "0.1rem",
                  fontSize: "50%",
                  color: color,
                }}>
                {currencySymbol}
              </Text>
            )}
            {isMonthly && (
              <Text
                sx={{
                  alignSelf: "flex-end",
                  marginLeft: "-0.2rem",
                  fontSize: "45%",
                  color: color,
                }}>
                {`/${intl.formatMessage({ id: "lbl.monthly" })}`}
              </Text>
            )}
          </Flex>
        </Flex>
      </Flex>
      {/*  Description */}
      {description && (
        <Text variant="amountDescription" color={descColor}>
          {description}
        </Text>
      )}
      {includeTax && (
        <Text variant="amountDescription" color={descColor}>
          {intl.formatMessage({ id: "lbl.tax-included" })}
        </Text>
      )}
      {plusTax && (
        <Text variant="amountDescription" color={descColor}>
          {intl.formatMessage({ id: "lbl.plus-tax" })}
        </Text>
      )}
    </Flex>
  );
};

export default Amount;
