import { useState, useEffect, useRef } from "react";
import { useThemeUI } from "@theme-ui/core";
import { Box, Flex, Text } from "@theme-ui/components";
import { useMonth } from "@datepicker-react/hooks";

import Day from "./Day";
import { OSIcon } from "../icons";

function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 99;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
}

const Month = ({ date, value, year, month, firstDayOfWeek, setCalendarVisiblity, id }) => {
  const d = date || new Date();
  const { theme } = useThemeUI();
  const [showYears, setShowYears] = useState(false);
  const years = generateArrayOfYears();
  const [selectedYear, setSelectedYear] = useState(new Date(d).getFullYear());
  const currentYearRef = useRef();
  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
  });

  const clendarButtonStyle = {
    backgroundColor: "inherit",
    ":hover": { backgroundColor: "button.disabledBackground" },
    cursor: "pointer",
    borderRadius: "lg",
    py: 1,
    color: "primary",
  };

  useEffect(() => {
    if (showYears && currentYearRef && currentYearRef.current) {
      currentYearRef.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [showYears]);

  return (
    <Box>
      <Flex
        sx={{
          textAlign: "center",
          padding: 2,
          flexDirection: "column",
          justifyContent: "space-around",
          width: "18.125rem",
        }}>
        <Flex
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
            mb: 1,
          }}>
          {!showYears && (
            <OSIcon
              rotate="180"
              name="chevron"
              sx={clendarButtonStyle}
              onClick={value.goToPreviousMonths}
            />
          )}

          <Flex
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Flex
              onClick={() => setShowYears(!showYears)}
              sx={{ alignItems: "center", cursor: "pointer" }}>
              <Text sx={{ fontWeight: "semibold", cursor: "pointer" }}>{monthLabel}</Text>
              <OSIcon name="chevron" ml={2} mt={-0.5} rotate={!showYears ? "90" : "-90"} />
            </Flex>
          </Flex>

          {!showYears && (
            <OSIcon
              rotate="0"
              name="chevron"
              sx={clendarButtonStyle}
              onClick={value.goToNextMonths}
            />
          )}
        </Flex>

        {showYears ? (
          <Flex sx={{ px: 2.5, height: "12.375rem", overflow: "scroll", overflowX: "hidden" }}>
            <Box
              sx={{
                textAlign: "center",
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                justifyContent: "space-around",
                gridGap: 3,
              }}>
              {years.map((year) => {
                const isSelectedYear = selectedYear === year;
                return (
                  <Box
                    ref={isSelectedYear ? currentYearRef : null}
                    bg={isSelectedYear && theme.colors.secondary}
                    onClick={() => {
                      setSelectedYear(year);
                      setShowYears(!showYears);
                      value.onDateSelect(new Date(d).setYear(year));
                      setCalendarVisiblity(true);
                    }}
                    key={year}
                    sx={{
                      fontFamily: "body",
                      fontSize: "base",
                      px: 1,
                      py: 2,
                      color: isSelectedYear && theme.colors.selectedCardBg,
                      ":hover": {
                        backgroundColor: !isSelectedYear && "button.disabledBackground",
                      },
                      cursor: "pointer",
                      borderRadius: "lg",
                    }}>
                    {year}
                  </Box>
                );
              })}
            </Box>
          </Flex>
        ) : (
          <>
            <Flex
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7,1fr)",
                justifyContent: "space-around",
                gridGap: 1,
              }}>
              {weekdayLabels.map((dayLabel) => (
                <Box
                  key={dayLabel}
                  color="disabledCardTextColor"
                  sx={{
                    textAlign: "center",
                    fontSize: "base",
                    padding: 1,
                  }}>
                  {dayLabel}
                </Box>
              ))}
            </Flex>

            <Box
              sx={{
                textAlign: "center",
                display: "grid",
                gridTemplateColumns: "repeat(7, 10fr)",
                justifyContent: "space-around",
              }}>
              {days.map((day, index) => {
                if (typeof day === "object") {
                  return (
                    <Day
                      id={id}
                      date={day.date}
                      key={day.date.toString()}
                      dayLabel={day.dayLabel}
                    />
                  );
                }

                return <Box key={1000 + index} />;
              })}
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Month;
