import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import { PERSONAL_INFORMATION_FLOW } from "../../../common/Constants";
const INITIAL_STATE = {
  validateServiceAgreement: {
    loading: false,
    error: null,
    success: false,
    validateSA: [],
  },
  isValidSAPlan: {
    loading: false,
    success: false,
    error: null,
    value: false,
  },
  checkGoodCompatibilityWithServiceDeviceFeatures: {
    loading: false,
    success: false,
    error: null,
    value: false,
  },
  loadExistingPlan: {
    loading: false,
    success: false,
    error: null,
    plan: null,
  },
  getServicesDescriptionFromProductDefinition: {
    loading: false,
    success: false,
    error: null,
    services: [],
  },
};

export const validateServiceAgreement = createAsyncThunk(
  "account/validateServiceAgreement",
  async (existingLine, { getState, rejectWithValue }) => {
    if (getState().personalInformation.flow === PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER) {
      return [];
    }
    try {
      await FacadeAPI.PUT(`/account/validateServiceAgreement/`, existingLine, {
        expectedResponse: "",
      });
    } catch (err) {
      return rejectWithValue(err);
    }
    return existingLine;
  }
);

export const isValidSAPlan = createAsyncThunk(
  "account/validateSAPlan",
  async (request, { getState, rejectWithValue }) => {
    try {
      const sessionId = getState().session.sessionId;
      return await FacadeAPI.PUT(
        `/account/validateSAPlan/${sessionId}/${request.saId}/${request.productCode}/${request.deviceSku}/${request.pricingOption}`
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkGoodCompatibilityWithServiceDeviceFeatures = createAsyncThunk(
  "/plan/checkGoodCompatibilityWithServiceDeviceFeatures",
  async (request, { getState, rejectWithValue }) => {
    try {
      const sessionId = getState().session.sessionId;
      return await FacadeAPI.GET(
        `/plan/checkGoodCompatibilityWithServiceDeviceFeatures/${sessionId}/${request.masterAccountId}/${request.saId}/${request.deviceSku}`
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadExistingPlan = createAsyncThunk("account/existingPlan", async (request) => {
  return await FacadeAPI.GET(
    `/plan/existingPlan/${request.accountId}/${request.handsetSku}/${request.simSku}`
  );
});

export const getServicesDescriptionFromProductDefinition = createAsyncThunk(
  "/plan/getServicesDescriptionFromProductDefinition",
  async (request, { getState, rejectWithValue }) => {
    try {
      const sessionId = getState().session.sessionId;
      return await FacadeAPI.GET(
        `/plan/getServicesDescriptionFromProductDefinition/${sessionId}/${request.itemId}?services=${request.services}`
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const serviceAgreementSlice = createSlice({
  name: "serviceAgreement",
  initialState: INITIAL_STATE,
  reducers: {
    clearValidateServiceAgreementResponse(state) {
      return {
        ...state,
        validateServiceAgreement: INITIAL_STATE.validateServiceAgreement,
        isValidSAPlan: INITIAL_STATE.isValidSAPlan,
        checkGoodCompatibilityWithServiceDeviceFeatures:
          INITIAL_STATE.checkGoodCompatibilityWithServiceDeviceFeatures,
      };
    },
    clearGetServicesDescriptionFromProductDefinition(state) {
      return {
        ...state,
        getServicesDescriptionFromProductDefinition:
          INITIAL_STATE.getServicesDescriptionFromProductDefinition,
      };
    },
  },
  extraReducers: {
    [validateServiceAgreement.pending]: (state) => {
      state.validateServiceAgreement.loading = true;
      state.validateServiceAgreement.success = false;
    },
    [validateServiceAgreement.fulfilled]: (state, action) => {
      state.validateServiceAgreement.loading = false;
      state.validateServiceAgreement.success = true;
      state.validateServiceAgreement.validateSA = action.payload;
    },
    [validateServiceAgreement.rejected]: (state, action) => {
      state.validateServiceAgreement.loading = false;
      state.validateServiceAgreement.error = action.payload;
    },
    [isValidSAPlan.pending]: (state) => {
      state.isValidSAPlan.loading = true;
      state.isValidSAPlan.success = false;
      state.isValidSAPlan.error = null;
    },
    [isValidSAPlan.fulfilled]: (state, action) => {
      state.isValidSAPlan.loading = false;
      state.isValidSAPlan.success = true;
      state.isValidSAPlan.value = action.payload;
    },
    [isValidSAPlan.rejected]: (state, action) => {
      state.isValidSAPlan.loading = false;
      state.isValidSAPlan.success = false;
      state.isValidSAPlan.error = action.error;
    },
    [checkGoodCompatibilityWithServiceDeviceFeatures.pending]: (state) => {
      state.checkGoodCompatibilityWithServiceDeviceFeatures.loading = true;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.success = false;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.error = null;
    },
    [checkGoodCompatibilityWithServiceDeviceFeatures.fulfilled]: (state, action) => {
      state.checkGoodCompatibilityWithServiceDeviceFeatures.loading = false;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.success = true;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.value = action.payload;
    },
    [checkGoodCompatibilityWithServiceDeviceFeatures.rejected]: (state, action) => {
      state.checkGoodCompatibilityWithServiceDeviceFeatures.loading = false;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.success = false;
      state.checkGoodCompatibilityWithServiceDeviceFeatures.error = action.error;
    },
    [loadExistingPlan.pending]: (state) => {
      state.loadExistingPlan.loading = true;
      state.loadExistingPlan.success = false;
      state.loadExistingPlan.error = null;
    },
    [loadExistingPlan.fulfilled]: (state, action) => {
      state.loadExistingPlan.loading = false;
      state.loadExistingPlan.success = true;
      state.loadExistingPlan.plan = action.payload;
    },
    [loadExistingPlan.rejected]: (state, action) => {
      state.loadExistingPlan.loading = false;
      state.loadExistingPlan.success = false;
      state.loadExistingPlan.error = action.error;
    },
    [getServicesDescriptionFromProductDefinition.pending]: (state) => {
      state.getServicesDescriptionFromProductDefinition.loading = true;
      state.getServicesDescriptionFromProductDefinition.success = false;
      state.getServicesDescriptionFromProductDefinition.error = null;
    },
    [getServicesDescriptionFromProductDefinition.fulfilled]: (state, action) => {
      state.getServicesDescriptionFromProductDefinition.loading = false;
      state.getServicesDescriptionFromProductDefinition.success = true;
      state.getServicesDescriptionFromProductDefinition.services = action.payload;
    },
    [getServicesDescriptionFromProductDefinition.rejected]: (state, action) => {
      state.getServicesDescriptionFromProductDefinition.loading = false;
      state.getServicesDescriptionFromProductDefinition.success = false;
      state.getServicesDescriptionFromProductDefinition.error = action.error;
    },
  },
});
export const {
  clearValidateServiceAgreementResponse,
  clearGetServicesDescriptionFromProductDefinition,
} = serviceAgreementSlice.actions;
export default serviceAgreementSlice.reducer;
