import React from "react";
import { Flex, Image, Text } from "@theme-ui/components";
import { PRICE_TYPE } from "../../../common/Constants";
import Amount from "../../amount";
import { Heading6 } from "../../heading";
import { useIntl } from "react-intl";

/**
 * @typedef {Object} ItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                        - The price.
 * @property {String} type                         - Price type.
 * @property {Number} termsAmount                  - Price terms - amount.
 * @property {String} termsUnit                    - Price terms - unit.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {ItemPrice[]} prices                 - List of item's prices.
 * @property {ItemAttribute[]} attributes         - List of item's attributes.
 */
/**
 * Renders a accessory item.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 */
const Accessory = ({ item, plusTax, showPrices }) => {
  const intl = useIntl();
  const oneTimePrice = item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);

  return (
    item.sku !== null && (
      <Flex sx={{ flexDirection: "column" }}>
        <Flex mb={2}>
          <Flex sx={{ flex: 1 }}>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                height: "2.5625rem",
                width: "2.5625rem",
                minWidth: "2.5625rem",
              }}
              mr={3}>
              <Image
                src={item.thumbnailURL}
                alt="accessory"
                sx={{ objectFit: "contain", width: "2.5625rem", height: "2.5625rem" }}
              />
            </Flex>
            <Flex sx={{ flexDirection: "column" }}>
              {/* Accessory description */}
              <Heading6 color="ternary" mb={1.5}>
                {item.shortDescription}
              </Heading6>
            </Flex>
          </Flex>

          {showPrices && (
            <Flex sx={{ flexDirection: "column" }} ml={2} mr={[8, 8, 0]}>
              {/* Accessory price */}
              <Amount
                amount={oneTimePrice.price + oneTimePrice.tax}
                description={!plusTax && intl.formatMessage({ id: "lbl.upfront" })}
              />
              {(plusTax && (
                <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
              )) ||
                (oneTimePrice.tax > 0 && (
                  <Text variant="taxDescription">
                    {intl.formatMessage({ id: "lbl.tax-included" })}
                  </Text>
                ))}
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  );
};

export default Accessory;
