import React, { useCallback, useEffect, useState } from "react";
import { Box, Flex, Text, Label } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import qs from "qs";

import { OSCheckbox, OSContainer, OSSwitch, OSLink, OSLabel } from "../../../components/base";
import { OSIcon } from "../../../components/icons";
import { Heading3, Heading4 } from "../../../components/heading";
import InfoBox from "../../../components/InfoBox";

import { useDispatch, useSelector } from "react-redux";
import { storefrontURLs } from "../../../config/security/Deployment";
import {
  NONE_CODE,
  SIM_TYPE,
  SYS_CONFIG_PARAMETER,
  CPE_SKU_CODE,
  EXTRA_INFO,
  SIM_CAPABILITY,
  POS_PARAMETER,
} from "../../../common/Constants";
import {
  loadGoodModelUDFs,
  getModelsByGroupCode,
  getSysConfigParameter,
  getPosParameter,
} from "../../../config/redux/slices";
import { udfsNames } from "../../../pages/personal-information/Constants";
import Autocomplete from "../../../components/Autocomplete";

const DevicePlaceholder = ({
  device,
  setChangeItem,
  simType,
  onChangeSimType,
  onChangeBYODInfo,
  untouchedMandatoryFields,
  ...props
}) => {
  const intl = useIntl();
  const { externalCartId } = useSelector((state) => state.session);
  const { ...itemsState } = useSelector((state) => state.items);
  const sysConfigParameters = useSelector(
    (state) => state.configuration.getSysConfigParameter.sysConfigParameters
  );
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);

  const dispatch = useDispatch();
  const [showIFrame, setshowIFrame] = useState(false);
  const [mandatoryFieldMarked, setMandatoryFieldMarked] = useState(false);
  const simTypeToggleChecked = simType === SIM_TYPE.eSIM;

  const onSimTypeUpdate = useCallback(
    (simTypeToggleChecked) => {
      onChangeSimType(simTypeToggleChecked ? SIM_TYPE.eSIM : SIM_TYPE.SIM);
    },
    [onChangeSimType]
  );

  const onChooseDifferentDevice = async () => {
    setChangeItem("DEVICE");
    setshowIFrame(true);
  };

  const byodSkuInitialValue = (extraInfo) => {
    if (device?.extraInfo && device?.extraInfo.length > 0) {
      let deviceExtraInfo = Object.fromEntries(
        device.extraInfo.split(";").map((elem) => elem.split("="))
      );
      if (deviceExtraInfo[extraInfo]) {
        return deviceExtraInfo[extraInfo];
      }
    }
    return NONE_CODE;
  };
  const [ownPhoneSku, setOwnPhoneSku] = useState(byodSkuInitialValue(EXTRA_INFO.BYOD_SKU));
  const [cantFindMyPhone, setCantFindMyPhone] = useState(
    byodSkuInitialValue(EXTRA_INFO.BYOD_SKU, false) === CPE_SKU_CODE ? true : false
  );
  const [simEsimToggleVisible, setSimEsimToggleVisible] = useState(
    byodSkuInitialValue(EXTRA_INFO.BOTH_SIM) === "Y" ? true : false
  );

  const onBringOwnPhoneChange = (skuCode) => {
    if (skuCode != NONE_CODE) {
      setOwnPhoneSku(skuCode);
      dispatch(loadGoodModelUDFs({ skuCode: skuCode }));
    } else {
      setSimEsimToggleVisible(false);
    }
    setCantFindMyPhone(false);
  };

  const onChangeCantFindMyPhone = () => {
    let cantFindMyPhoneValue = !cantFindMyPhone;
    setCantFindMyPhone(cantFindMyPhoneValue);
    if (cantFindMyPhoneValue) {
      setOwnPhoneSku(CPE_SKU_CODE);
      dispatch(loadGoodModelUDFs({ skuCode: CPE_SKU_CODE }));
    } else {
      setSimEsimToggleVisible(false);
      setOwnPhoneSku(NONE_CODE);
    }
  };

  const IFrame = ({ src }) => {
    return (
      <Flex mt={5} bg="whiteSmoke" sx={{ borderRadius: "2lg", width: "100%" }}>
        <iframe src={src} width="100%" height={window.innerHeight} style={{ border: 0 }} />
      </Flex>
    );
  };
  useEffect(() => {
    const handler = (event) => {
      if (storefrontURLs.base.includes(event.origin)) {
        location.reload();
      }
    };
    window.addEventListener("message", handler, false);
    return () => {
      window.removeEventListener("message", handler, false);
    };
  }, []);

  useEffect(() => {
    dispatch(getSysConfigParameter(SYS_CONFIG_PARAMETER.ECOMM_CPE_SIM_TYPE_VISIBILITY));
    dispatch(getSysConfigParameter(SYS_CONFIG_PARAMETER.ECOMM_SIM_TYPE_VISIBILITY));
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
  }, [dispatch]);

  useEffect(() => {
    if (!itemsState.getModelsByGroupCode.success) {
      dispatch(getModelsByGroupCode());
    }
  }, [itemsState.getModelsByGroupCode.success, dispatch]);

  useEffect(() => {
    onChangeBYODInfo({ byodSku: ownPhoneSku });
  }, [onChangeBYODInfo, ownPhoneSku]);

  useEffect(() => {
    if (itemsState.loadGoodModelUDFs.success && itemsState.loadGoodModelUDFs?.udfs) {
      let ownSkuEmpty = ownPhoneSku === NONE_CODE || ownPhoneSku === CPE_SKU_CODE;
      if (!ownSkuEmpty || (cantFindMyPhone && ownSkuEmpty)) {
        let simCapable = itemsState.loadGoodModelUDFs.udfs[udfsNames.SIMCAPABLE];
        let isBothSimEsimCapable = simCapable && simCapable === udfsNames.ESIM_AND_SIM;
        let defaultValue = isBothSimEsimCapable
          ? itemsState.loadGoodModelUDFs.udfs[udfsNames.DEFAULTSIM]
          : simCapable;
        onChangeSimType(defaultValue === SIM_CAPABILITY.ESIM_ONLY ? SIM_TYPE.eSIM : SIM_TYPE.SIM);
        let sysConfigParameter = cantFindMyPhone
          ? SYS_CONFIG_PARAMETER.ECOMM_CPE_SIM_TYPE_VISIBILITY
          : SYS_CONFIG_PARAMETER.ECOMM_SIM_TYPE_VISIBILITY;
        let isSimEsimVisible =
          isBothSimEsimCapable && sysConfigParameters[sysConfigParameter] === "Y";
        setSimEsimToggleVisible(isSimEsimVisible);
        onChangeBYODInfo({ byodSku: ownPhoneSku, bothSim: isSimEsimVisible ? "Y" : "N" });
      }
    }
  }, [
    itemsState.loadGoodModelUDFs,
    ownPhoneSku,
    cantFindMyPhone,
    onChangeBYODInfo,
    onChangeSimType,
    sysConfigParameters,
  ]);

  return (
    <Box sx={{ width: "100%" }} {...props}>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Heading3 color="primary">{intl.formatMessage({ id: "lbl.device" })}</Heading3>
        {!showIFrame && (
          <Flex
            id={"changeDevice-BYOP-" + device?.itemId}
            className="track"
            mr={["none", "none", 10]}
            onClick={onChooseDifferentDevice}
            sx={{ cursor: "pointer", alignItems: "center" }}>
            <Text
              variant="subtitle1"
              mt={0}
              color="ternary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}>
              {intl.formatMessage({ id: "lbl.choose-a-device" })}
            </Text>
            <OSIcon name="chevron-right" ml={2} />
          </Flex>
        )}
      </Flex>
      {showIFrame ? (
        <IFrame
          src={
            storefrontURLs.devices +
            `?` +
            qs.stringify({ cartId: externalCartId, itemId: device.itemId })
          }
        />
      ) : (
        <OSContainer variant="device-and-plan-section">
          <Flex
            mt={[5, "none", "none"]}
            sx={{
              alignItems: ["center", "flex-start", "flex-start"],
              flexDirection: "column",
              width: ["100%", "100%", "100%"],
            }}>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: ["column", "row", "row"],
                width: ["100%", "100%", "90%"],
              }}>
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "7rem",
                  width: "7rem",
                }}>
                <OSIcon name="no-device" />
              </Flex>
              <Flex
                sx={{
                  alignItems: "center",
                  flexDirection: ["column", "row", "row"],
                  width: ["100%", "100%", "90%"],
                  ml: ["none", "4rem", "4rem"],
                }}>
                <Flex
                  sx={{
                    justifyContent: ["center", "flex-start", "flex-start"],
                    minWidth: ["50%", "10rem", "7rem"],
                  }}>
                  <Heading4 sx={{ textAlign: "center" }}>
                    {intl.formatMessage({ id: "lbl.add-device" })}
                  </Heading4>
                </Flex>
                <Flex sx={{ justifyContent: "center" }}>
                  <Text variant="lineDescription">
                    {intl.formatMessage({ id: "lbl.no-device-description" })}
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              sx={{
                mt: [1.5, "none"],
                justifyContent: "center",
                width: ["100%", "100%", "90%"],
              }}>
              <Flex
                sx={{
                  flexDirection: ["column", "row", "row"],
                  width: "100%",
                  alignItems: ["center", "flex-end"],
                  ml: ["none", 32, 32],
                }}>
                <Autocomplete
                  label={intl.formatMessage({ id: "lbl.select-own-phone" })}
                  placeHolder={intl.formatMessage({ id: "lbl.start-typing-phone" })}
                  defaultValue={ownPhoneSku}
                  mandatory={!cantFindMyPhone}
                  onClick={onBringOwnPhoneChange}
                  disabled={cantFindMyPhone}
                  width={["100%", "40%"]}
                  untouchedMandatoryFields={untouchedMandatoryFields}
                  mandatoryFieldMarked={setMandatoryFieldMarked}
                />

                <Flex
                  sx={{
                    flexDirection: "column",
                    ml: ["none", 16, 28],
                    mt: [1.5, "none"],
                    mb: mandatoryFieldMarked ? 9 : 3,
                  }}>
                  <Label sx={{ alignItems: "flex-end" }}>
                    <OSCheckbox
                      mr={[7, 1]}
                      checked={cantFindMyPhone}
                      onChange={() => onChangeCantFindMyPhone()}
                    />
                    <Flex
                      sx={{
                        justifyContent: ["center", "flex-start", "flex-start"],
                      }}>
                      <Heading4 sx={{ fontWeight: "medium", color: "primary" }}>
                        {intl.formatMessage({ id: "lbl.cant-find-my-phone" })}
                      </Heading4>
                    </Flex>
                  </Label>
                </Flex>
              </Flex>
            </Flex>

            {simEsimToggleVisible && (
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: ["100%", "100%", "90%"],
                }}>
                <Flex
                  sx={{
                    width: "100%",
                    flexDirection: ["column", "row", "row"],
                    ml: ["none", 44, 44],
                    mt: [2, 8, 8],
                    alignItems: "center",
                  }}>
                  <Flex
                    sx={{
                      flexDirection: "column",
                      width: ["80%", "40%", "25%"],
                    }}>
                    <Flex
                      sx={{
                        justifyContent: ["center", "flex-start", "flex-start"],
                      }}>
                      <OSLabel isMandatory>
                        {intl.formatMessage({ id: "lbl.select-sim-type" })}
                      </OSLabel>
                    </Flex>
                    <Flex
                      sx={{
                        justifyContent: ["center", "flex-start", "flex-start"],
                        mt: ["0.5rem", "1rem", "1rem"],
                      }}>
                      <Heading4 sx={{ fontWeight: "normal", pr: "5" }}>SIM</Heading4>
                      <OSSwitch
                        id="simTypeToggle"
                        name="simTypeToggle"
                        variant="toggleSwitch"
                        ml="0.5rem"
                        checked={simTypeToggleChecked}
                        onChange={() => onSimTypeUpdate(!simTypeToggleChecked)}
                      />
                      <Heading4 sx={{ fontWeight: "normal", pl: "5" }}>eSIM</Heading4>
                    </Flex>
                  </Flex>
                  <InfoBox
                    sx={{
                      ml: ["none", "3rem", "6rem"],
                      mt: ["1rem", "none", "none"],
                      maxWidth: ["100%", "50%", "30%"],
                      alignItems: "center",
                    }}>
                    <Flex sx={{ justifyContent: "center", lineHeight: "2", textAlign: "center" }}>
                      <Text variant="subtitle1" mt={0} color="primary" sx={{ cursor: "pointer" }}>
                        <FormattedMessage id="lbl.incompatible-phone_1" />
                      </Text>
                    </Flex>
                    <Flex sx={{ justifyContent: "center", lineHeight: "2", textAlign: "center" }}>
                      <Text variant="subtitle1" mt={0} color="primary" sx={{ cursor: "pointer" }}>
                        <FormattedMessage id="lbl.incompatible-phone_2" />
                        <OSLink
                          url={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
                          labelId="lbl.contactUs"
                          variant="chat-link"
                        />
                        <FormattedMessage id="lbl.incompatible-phone_3" />
                      </Text>
                    </Flex>
                  </InfoBox>
                </Flex>
              </Flex>
            )}
          </Flex>
        </OSContainer>
      )}
    </Box>
  );
};

export default DevicePlaceholder;
