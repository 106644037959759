import React from "react";
import { useIntl } from "react-intl";
import { Link } from "@theme-ui/components";

const OSLink = ({ url, labelId, ...props }) => {
  const intl = useIntl();

  function openTab() {
    if (!url) {
      return;
    }
    window.open(url);
  }
  return (
    <Link to={"#"} onClick={openTab} {...props}>
      {intl.formatMessage({ id: labelId })}
    </Link>
  );
};

export default OSLink;
