import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Heading, Text } from "@theme-ui/components";

import Layout from "../../components/layout";
import { OSContainer } from "../../components/base";
import { Heading1 } from "../../components/heading";
import { OSIcon } from "../../components/icons";

const OrderFailed = () => {
  const history = useHistory();
  return (
    <Layout showSteps={false} showCart={false}>
      <OSContainer variant="center" sx={{ width: ["100%", "100%", "64rem"] }}>
        <OSIcon name="error" />

        <Heading1 mt={7} mb={3.5} color="error">
          <FormattedMessage id="header.payment_failed" />
        </Heading1>

        <Heading variant="heading3normal" sx={{ lineHeight: "tight", textAlign: "center" }}>
          <FormattedMessage id="lbl.could-not-process-payment-for-order" />
        </Heading>

        <Heading variant="heading3normal" sx={{ lineHeight: "tight", textAlign: "center" }}>
          <FormattedMessage
            id="lbl.payment_failed"
            values={{
              messageError: history?.location?.state?.errorMessage,
              b: (chunks) => (
                <Text display="inline" fontWeight="bold">
                  {chunks}
                </Text>
              ),
            }}
          />
        </Heading>

        <Heading variant="heading3normal" sx={{ lineHeight: "tight", textAlign: "center" }}>
          <FormattedMessage id="lbl.please-reload-process-payment-with-new-card" />
        </Heading>

        <Button variant="default-next" my={6} onClick={() => history.push("/payment-information")}>
          <FormattedMessage id="btn.return_to_payment" />
        </Button>
      </OSContainer>
    </Layout>
  );
};

export default OrderFailed;
