import React, { useEffect } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { SHIPPING_ADDRESS } from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeText } from "../../../../common/StringUtilities";
import { getDefaultStates } from "../../../../config/redux/slices";

const DefaultAddressCard = ({ defaultAddress, selected, onSelect, disabled, ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const statesData = useSelector((state) => state.states);

  useEffect(() => {
    const loadStates = async () => {
      if (!statesData.getDefaultStates.states || statesData.getDefaultStates.states.length === 0) {
        dispatch(getDefaultStates());
      }
    };
    loadStates();
  }, [dispatch, statesData.getDefaultStates.states]);

  function getAddress() {
    return `${capitalizeText(defaultAddress.address)}, ${intl.formatMessage({
      id: "lbl.city",
    })}: ${capitalizeText(defaultAddress.city)}, ${intl.formatMessage({
      id: "lbl.postal-code",
    })}: ${defaultAddress.postalCode}, ${defaultAddress.state} `;
  }

  return (
    <Flex onClick={() => !selected && onSelect(SHIPPING_ADDRESS.DEFAULT)} {...props}>
      <Flex
        bg={disabled ? "button.disabledBackground" : selected ? "selectedCardBg" : "cardBg"}
        px={7}
        py={5}
        sx={{
          flexDirection: "column",
          alignItems: "center",
          border: 1,
          borderColor: disabled ? "greyish" : selected ? "secondary" : "cardBorderColor",
          borderRadius: "2lg",
          boxShadow: "sm",
          width: "100%",
          cursor: disabled ? "default" : "pointer",
          ":hover": !selected &&
            !disabled && {
              borderColor: "cardBorderHoverColor",
            },
        }}>
        <Flex
          sx={{
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}>
          <Flex mb={2.5}>
            <Text
              variant="heading2"
              sx={{
                overflowWrap: "break-word",
              }}
              color={
                disabled ? "button.disabledColor" : selected ? "selectedCardTextColor" : "ternary"
              }
              mr={4}>
              {`${defaultAddress.firstName} ${defaultAddress.lastName}`}
            </Text>
            <Text
              variant="summaryLabel"
              ml="auto"
              color={
                disabled
                  ? "button.disabledColor"
                  : selected
                  ? "selectedCardTextColor"
                  : "quaternary"
              }>
              {intl.formatMessage({ id: "lbl.your-default-address" })}
            </Text>
          </Flex>
          <Text
            variant="description"
            color={
              disabled ? "button.disabledColor" : selected ? "selectedCardTextColor" : "ternary"
            }
            sx={{ lineHeight: 7 }}>
            {getAddress()}.
          </Text>
          <Text
            variant="description"
            color={
              disabled ? "button.disabledColor" : selected ? "selectedCardTextColor" : "ternary"
            }
            sx={{ lineHeight: 7 }}>
            {`${intl.formatMessage({
              id: "lbl.phNo",
            })}: ${defaultAddress.phoneNumber}`}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DefaultAddressCard;
