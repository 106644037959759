import React from "react";
import { Box } from "@theme-ui/components";
import { keyframes } from "@emotion/react";

import TransparentModal from "./TransparentModal";

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = ({ isOpen }) => {
  return (
    <TransparentModal isOpen={isOpen} opacity={1}>
      <Box
        as="svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 50"
        sx={{
          width: "iconLarge",
          height: "iconLarge",
          margin: 10,
          animation: `${rotate} 1s linear infinite`,
        }}>
        <Box
          as="circle"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="2"
          sx={{
            stroke: "svgStrokePrimary",
            strokeLinecap: "round",
            animation: `${dash} 1.5s ease-in-out infinite`,
          }}
        />
      </Box>
    </TransparentModal>
  );
};

export default Spinner;
