import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Flex } from "@theme-ui/components";
import { OSContainer, OSSection } from "../../../components/base";

import { useDispatch, useSelector } from "react-redux";
import { Heading5 } from "../../../components/heading";
import { OSIcon } from "../../../components/icons";
import { loadSelectedPlan, updateAdditionalServices } from "../../../config/redux/slices";
import OSProduct from "./OSProduct";
import OSProductSkeleton from "./OSProductSkeleton";
import { getEasyUpConfigServices } from "../../../config/redux/slices/AdditionalServicesSlice";

const AdditionalServices = ({
  bundle,
  onSelectService,
  untouchedMandatoryFields,
  plusTax = true,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [productCards, setProductCards] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const product = useSelector((state) => state.additionalServices?.updatedPlan);
  const easyUpConfigServicesState = useSelector(
    (state) => state.additionalServices.getEasyUpConfigServices
  );

  useEffect(() => {
    !easyUpConfigServicesState.success && dispatch(getEasyUpConfigServices());
  }, [dispatch, easyUpConfigServicesState.success]);

  useEffect(() => {
    if (bundle !== undefined && bundle.packageCode !== null && easyUpConfigServicesState.success) {
      const deviceSku = bundle.sku;
      const packageCode = bundle.packageCode;
      if (bundle.jsonBundleRequest && JSON.parse(bundle.jsonBundleRequest).code === packageCode) {
        dispatch(updateAdditionalServices({ updatedPlan: JSON.parse(bundle.jsonBundleRequest) }));
      } else
        dispatch(
          loadSelectedPlan({
            packageCode: packageCode,
            skuCodeHandset: deviceSku,
            skuCodeSim: "TRIPLESIMVOLTE",
            technology: "",
          })
        ).then(({ payload }) => {
          dispatch(updateAdditionalServices({ updatedPlan: payload }));
        });
    }
  }, [dispatch, bundle, easyUpConfigServicesState.success]);

  useEffect(() => {
    const isProductSet = !!product;
    if (collapsed && untouchedMandatoryFields.length > 0) setCollapsed(false);
    if (isProductSet) {
      setProductCards(
        <OSProduct
          product={product}
          onSelectService={onSelectService}
          isMainPlan
          untouchedMandatoryFields={untouchedMandatoryFields}
          plusTax={plusTax}
        />
      );
    }
  }, [product, onSelectService, untouchedMandatoryFields, plusTax, collapsed]);

  return (
    <OSContainer
      mt={2}
      pb={collapsed ? 5 : 10}
      sx={{ flexDirection: "column" }}
      variant="page-content">
      <Flex>
        <Flex sx={{ flex: 1 }}>
          <Heading5 ml={8.5} my={0}>
            {intl.formatMessage({ id: "lbl.choose-additional-services" })}
          </Heading5>
        </Flex>
        <Flex sx={{ width: "4rem" }} onClick={() => setCollapsed(!collapsed)}>
          <OSIcon
            name="chevron"
            rotate={collapsed ? "90" : "-90"}
            display="block"
            sx={{
              ml: "1rem",
              mt: 0,
              alignSelf: "right",
              pointerEvents: "none",
            }}
          />
        </Flex>
      </Flex>

      {!collapsed && (
        <Flex px={[8, 8, 9]} sx={{ flexDirection: "column", width: "100%" }}>
          <OSSection mt={6}>
            {!product && <OSProductSkeleton />}
            {product && <>{productCards}</>}
          </OSSection>
        </Flex>
      )}
    </OSContainer>
  );
};

export default AdditionalServices;
