import { Button } from "@theme-ui/components";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import Storage from "store2";

import { ErrorCode } from "../../common/Constants";
import { getSession } from "../../config/redux/slices";
import { storefrontURLs } from "../../config/security/Deployment";
import GetErrorDescription from "../get-error-description";
import { StyledModalMessage } from "../modals";

export default ({ error, buttonMessageId = "btn.ok", clickHandler, closeHandler }) => {
  const dispatch = useDispatch();
  const sessionError = error && error.errorCode == ErrorCode.INVALID_SESSION_ID;
  const tokenError = error && error.errorCode == ErrorCode.AUTHORIZATION_ERROR;

  const actionHandler = () => {
    if (sessionError) {
      Storage.clear();
      window.location.replace(storefrontURLs.base);
      return;
    }
    if (tokenError) {
      dispatch(getSession());
    }
    clickHandler();
  };

  return (
    <StyledModalMessage
      isOpen={error}
      onRequestClose={closeHandler}
      message={<GetErrorDescription error={error} />}>
      <Button variant="simple-action" onClick={actionHandler}>
        <FormattedMessage id={sessionError || tokenError ? "btn.ok" : buttonMessageId} />
      </Button>
    </StyledModalMessage>
  );
};
