import React from "react";
import { Box } from "@theme-ui/components";

const EditIcon = ({ strokeColor = "svgStrokePrimary", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.143 14.768"
    sx={{ width: size, height: size, stroke: strokeColor, flexShrink: 0 }}
    {...props}>
    <g transform="translate(0.5 0.588)">
      <g transform="translate(340 16217.501)">
        <path
          d="M12.5,9v2.868a1.311,1.311,0,0,1-1.311,1.311H1.811A1.311,1.311,0,0,1,.5,11.868V2.49A1.311,1.311,0,0,1,1.811,1.179H7.5"
          transform="translate(-340.5 -16217)"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
          opacity="0.4"
        />
        <path
          d="M13.933,5.344,11.18,2.591,13,.774a.945.945,0,0,1,1.332,0L15.75,2.2a.944.944,0,0,1,0,1.331Z"
          transform="translate(-340.881 -16218)"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          d="M13.933,5.344,11.18,2.591,5.915,7.855a1.264,1.264,0,0,0-.321.549l-.713,2.539a.7.7,0,0,0,.7.7L8.12,10.93a1.266,1.266,0,0,0,.549-.322Z"
          transform="translate(-340.881 -16218)"
          fill="none"
          stroke={strokeColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </Box>
);

export default EditIcon;
