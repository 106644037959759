import styled from "@emotion/styled";

export const BaseModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  overflow-y: scroll !important;
  @supports ((-webkit-backdrop-filter: blur(6px)) or (backdrop-filter: blur(6px))) {
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }
`;
