import React from "react";
import { Box, Flex, Text } from "@theme-ui/components";

const AddButton = ({ className, id, ...props }) => {
  return (
    <Flex
      {...props}
      sx={{
        width: "3.5rem",
        height: "1.75rem",
        flexDirection: "row",
        backgroundColor: "secondary",
        borderRadius: "full",
        ":hover": {
          cursor: "pointer",
        },
      }}>
      <Box
        className={className}
        id={id}
        sx={{ flex: 1, alignItems: "flex-end", justifyContent: "center", textAlign: "center" }}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.125rem",
            color: "windowBg",
            fontSize: "1.75rem",
            lineHeight: "solid",
            userSelect: "none",
            fontWeight: "bold",
          }}>
          +
        </Text>
      </Box>
    </Flex>
  );
};
export default AddButton;
