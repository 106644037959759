import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Flex, Text } from "@theme-ui/components";
import StyledModalMessage from "./StyledModalMessage";

const PopupModal = ({
  type,
  modal,
  onClearModal,
  onRequestClose,
  button1Id,
  button2Id,
  isCloseDisabled,
  ...props
}) => {
  return (
    <StyledModalMessage
      isOpen={modal.isOpen}
      message={modal.message}
      onRequestClose={onRequestClose}
      isCloseDisabled={isCloseDisabled}
      type={type}>
      <Flex sx={{ width: "100%", justifyContent: "space-evenly", ...props }}>
        <Button
          variant="simple-action"
          onClick={() => {
            modal.onClickBtn1();
            onClearModal();
          }}>
          <FormattedMessage
            id={button1Id}
            values={{
              sup: (chunks) => (
                <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                  {chunks}
                </Text>
              ),
              n: <br />,
            }}
          />
        </Button>
        <Button
          variant="simple-action"
          onClick={() => {
            modal.onClickBtn2();
            onClearModal();
          }}>
          <FormattedMessage
            id={button2Id}
            values={{
              sup: (chunks) => (
                <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                  {chunks}
                </Text>
              ),
              n: <br />,
            }}
          />
        </Button>
      </Flex>
    </StyledModalMessage>
  );
};

export default PopupModal;
