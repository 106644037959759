import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FacadeAPI } from "../../../rest/RestClient";
import { getDefaultStates } from "./StatesSlice";
import { getItems } from "./ItemsSlice";
import { ITEM_CATEGORY, ITEM_ATTRIBUTE_CODE } from "../../../common/Constants";

import {
  SHIPPING_ADDRESS,
  SHIPPING_FLOW,
  SHIPPMING_METHODS,
} from "../../../pages/shipping-details/Constants";

const INITIAL_STATE = {
  getStores: {
    loading: false,
    success: false,
    error: null,
    stores: [],
    allStores: [],
  },

  saveShippingDetails: {
    loading: false,
    success: false,
    error: null,
  },

  getShippingMethods: {
    loading: false,
    success: false,
    error: null,
    methods: [],
  },

  getShippingContact: {
    loading: false,
    success: false,
    error: null,
    data: null,
  },

  shippingFlow: SHIPPING_FLOW.NONE,
  shippingAddress: SHIPPING_ADDRESS.DEFAULT,
  shippingMethod: SHIPPMING_METHODS.NONE,
  shippingDescription: "",
  buzzerCode: "",
  shippingNote: "",

  pickUpStore: "",
  defaultAddress: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    emailAddress: "",
  },
  newAddress: {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    emailAddress: "",
  },
  taxIncreased: false,
};

export const getStores = createAsyncThunk(
  "shippingDetails/getStores",
  async (args, { getState }) => {
    const sessionId = getState().session.sessionId;
    return await FacadeAPI.GET(`/store/${sessionId}`);
  }
);

export const saveShippingDetails = createAsyncThunk(
  "session/shippingDetails",
  async (request, { getState, dispatch, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      const result = await FacadeAPI.PUT(`/session/${sessionId}/shippingDetails`, request, {
        noTimeout: true,
      });
      // Load updated session items in order to update the shopping-cart.
      dispatch(getItems());
      dispatch(setTaxIncreased(result.taxIncreased));
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getShippingMethods = createAsyncThunk(
  "shippingDetails/methods",
  async (args, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/shipping/methods/${sessionId}`);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getShippingContact = createAsyncThunk(
  "session/shippingContact",
  async (args, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/session/${sessionId}/shippingContact`);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const restoreShipingInfoOnState = createAsyncThunk(
  "session/shippingMethod",
  async (arg, { getState, dispatch }) => {
    var flow = getState().personalInformation.flow;
    var items = arg;

    if (flow === "") {
      return;
    }

    dispatch(getDefaultStates());

    const shippingObject = items.find((item) => item.category === ITEM_CATEGORY.SHIPPING);
    if (shippingObject === undefined) {
      return;
    }
    const shippingFlow = shippingObject?.attributes.find(
      (attrItem) => attrItem.code === ITEM_ATTRIBUTE_CODE.SHIPMENT_OPTION
    );

    if (shippingFlow.value === SHIPPING_FLOW.PICK_IN_STORE) {
      dispatch(
        setStorePickUpState({
          shippingFlow: SHIPPING_FLOW.PICK_IN_STORE,
          pickUpStore: shippingObject.sku,
        })
      );
    } else if (shippingFlow.value === SHIPPING_FLOW.NOT_NEEDED) {
      dispatch(
        setShippingNotNeededState({
          shippingFlow: SHIPPING_FLOW.NOT_NEEDED,
        })
      );
    } else {
      var isNewAddress,
        address,
        firstName,
        lastName,
        state,
        postalCode,
        phoneNumber,
        emailAddress,
        city;

      if (shippingObject.attributes && shippingObject.attributes.length > 0) {
        var attributes = shippingObject.attributes;
        var shippingDescription = attributes.find(
          (attr) => attr.code === ITEM_ATTRIBUTE_CODE.SHIPPING_DESCRIPTION
        );
        var shippingNote = attributes.find(
          (attr) => attr.code === ITEM_ATTRIBUTE_CODE.SHIPPING_NOTE
        );
        var buzzerCode = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.BUZZER_CODE);
        isNewAddress = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.IS_NEW_ADDRESS);
        if (isNewAddress.value === "Y") {
          address = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.ADDRESS);
          firstName = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.FIRST_NAME);
          lastName = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.LAST_NAME);
          state = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.STATE);
          postalCode = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.POSTAL_CODE);
          phoneNumber = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.PHONE_NUMBER);
          emailAddress = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.EMAIL_ADDRESS);
          city = attributes.find((attr) => attr.code === ITEM_ATTRIBUTE_CODE.CITY);

          dispatch(
            setHomeDeliveryState({
              shippingFlow: SHIPPING_FLOW.HOME_DELIVERY,
              shippingMethod: shippingObject.sku,
              shippingAddress: SHIPPING_ADDRESS.NEW,
              shippingDescription: shippingDescription.value,
              shippingNote: shippingNote.value,
              buzzerCode: buzzerCode.value,
              newAddress: {
                firstName: firstName.value,
                lastName: lastName.value,
                address: address.value,
                city: city.value,
                state: state.value,
                postalCode: postalCode.value,
                phoneNumber: phoneNumber.value,
                emailAddress: emailAddress.value,
              },
            })
          );
        } else {
          dispatch(
            setHomeDeliveryState({
              shippingFlow: SHIPPING_FLOW.HOME_DELIVERY,
              shippingMethod: shippingObject.sku,
              shippingAddress: SHIPPING_ADDRESS.DEFAULT,
              shippingDescription: shippingDescription.value,
              shippingNote: shippingNote.value,
              buzzerCode: buzzerCode.value,
            })
          );
        }
      }
    }
  }
);

const shippingDetailsSlice = createSlice({
  name: "shippingDetails",
  initialState: INITIAL_STATE,
  reducers: {
    setHomeDeliveryState(state, action) {
      return {
        ...state,
        ...action.payload,
        pickUpStore: INITIAL_STATE.pickUpStore,
      };
    },
    setStorePickUpState(state, action) {
      return {
        ...state,
        ...action.payload,
        shippingAddress: INITIAL_STATE.shippingAddress,
        shippingMethod: INITIAL_STATE.shippingMethod,
        defaultAddress: INITIAL_STATE.defaultAddress,
        phoneNumber: INITIAL_STATE.phoneNumber,
        newAddress: INITIAL_STATE.newAddress,
      };
    },
    setShippingNotNeededState(state, action) {
      return {
        ...state,
        ...action.payload,
        pickUpStore: INITIAL_STATE.pickUpStore,
        shippingAddress: INITIAL_STATE.shippingAddress,
        shippingMethod: INITIAL_STATE.shippingMethod,
        defaultAddress: INITIAL_STATE.defaultAddress,
        phoneNumber: INITIAL_STATE.phoneNumber,
        newAddress: INITIAL_STATE.newAddress,
      };
    },
    clearSaveShippingDetailsResponse(state) {
      return {
        ...state,
        saveShippingDetails: INITIAL_STATE.saveShippingDetails,
      };
    },
    setTaxIncreased(state, action) {
      return {
        ...state,
        taxIncreased: action.payload,
      };
    },
  },
  extraReducers: {
    [getStores.pending]: (state) => {
      state.getStores.loading = true;
      state.getStores.success = false;
      state.getStores.error = null;
      state.getStores.stores = [];
      state.getStores.allStores = [];
    },
    [getStores.fulfilled]: (state, action) => {
      state.getStores.loading = false;
      state.getStores.success = true;
      state.getStores.allStores = action.payload;
      state.getStores.stores = action.payload?.filter((store) => store.availableInOnlineStore);
    },
    [getStores.rejected]: (state, action) => {
      state.getStores.loading = false;
      state.getStores.success = false;
      state.getStores.error = action.error;
    },

    [saveShippingDetails.pending]: (state) => {
      state.saveShippingDetails.loading = true;
      state.saveShippingDetails.success = false;
      state.saveShippingDetails.error = null;
    },
    [saveShippingDetails.fulfilled]: (state) => {
      state.saveShippingDetails.loading = false;
      state.saveShippingDetails.success = true;
    },
    [saveShippingDetails.rejected]: (state, action) => {
      state.saveShippingDetails.loading = false;
      state.saveShippingDetails.success = false;
      state.saveShippingDetails.error = action.payload;
    },

    [getShippingMethods.pending]: (state) => {
      state.getShippingMethods.loading = true;
      state.getShippingMethods.success = false;
      state.getShippingMethods.error = null;
    },
    [getShippingMethods.fulfilled]: (state, action) => {
      state.getShippingMethods.loading = false;
      state.getShippingMethods.success = true;
      state.getShippingMethods.methods = action.payload;
    },
    [getShippingMethods.rejected]: (state, action) => {
      state.getShippingMethods.loading = false;
      state.getShippingMethods.success = false;
      state.getShippingMethods.error = action.payload;
    },

    [getShippingContact.pending]: (state) => {
      state.getShippingContact.loading = true;
      state.getShippingContact.success = false;
      state.getShippingContact.error = null;
    },
    [getShippingContact.fulfilled]: (state, action) => {
      state.getShippingContact.loading = false;
      state.getShippingContact.success = true;
      state.getShippingContact.data = action.payload;
    },
    [getShippingContact.rejected]: (state, action) => {
      state.getShippingContact.loading = false;
      state.getShippingContact.success = false;
      state.getShippingContact.error = action.payload;
    },
  },
});

export const {
  setHomeDeliveryState,
  setStorePickUpState,
  setShippingNotNeededState,
  clearSaveShippingDetailsResponse,
  setTaxIncreased,
} = shippingDetailsSlice.actions;
export default shippingDetailsSlice.reducer;
