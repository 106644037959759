import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import { clearGetItemsApiResponse } from "./ItemsSlice";

const PERSONAL_INFO_INITIAL_STATE = {
  // loaded will be false each time the redux store is cleared (e.g. link injection from browser bar)
  loaded: false,
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  dobDate: "",
  identityType: "",
  identityNumber: "",
  issuingCountry: "",
  issuingProvince: "",
  secondIDType: "",
  secondIDNumber: "",
  sinNumber: "",
  phoneNumber: "",
  alternatePhoneNumber: "",
  emailAddress: "",
  address: "",
  city: "",
  province: "",
  postalCode: "",
  oldAddress: "",
  oldCity: "",
  oldProvince: "",
  oldPostalCode: "",
  udfs: [],
  agreeCreditScoreCheck: false,
  flow: "",
  accountId: "",
  neuralResponse: "",
  tuSessionId: "",
  tuDataRequest: null,
  tuResponseCode: "",
  pin: "",
  pinPresent: false,
  savePersonalInformation: {
    loading: false,
    success: false,
    error: null,
  },
  loadPersonalInformation: {
    loading: false,
    success: false,
    error: null,
  },
  validateAddress: {
    address: "",
    city: "",
    postalCode: "",
    province: "",
    response: "",
    loading: false,
    success: false,
    error: null,
  },
  getCreditLevelDetails: {
    loading: false,
    success: false,
    error: null,
    creditLevelDetails: {},
  },
  updateEmail: {
    loading: false,
    success: false,
    error: null,
  },
  requestTUIdentityCheck: {
    loading: false,
    success: false,
    error: null,
  },
  requestTUIdentityCheckTimeout: {
    loading: false,
    success: false,
    error: null,
  },
  isSkippedFraudCheckAndInvoiceIsNotPaid: {
    loading: false,
    success: false,
    error: null,
    value: false,
  },
};

export const savePersonalInformation = createAsyncThunk(
  "session/savePersonalInformation",
  async (request, { getState, rejectWithValue, dispatch }) => {
    const sessionId = getState().session.sessionId;
    try {
      const result = await FacadeAPI.PUT(`/session/${sessionId}/personalInformation`, request);
      // Clear session items in order to update the price taxes according to new address
      dispatch(clearGetItemsApiResponse());
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const loadPersonalInformation = createAsyncThunk(
  "session/loadPersonalInformation",
  async (args, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/session/${sessionId}/personalInformation`);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const validateAddress = createAsyncThunk(
  "session/validateAddress",
  async ({ request }, { rejectWithValue }) => {
    try {
      return await FacadeAPI.PUT(`/address/validateAddress`, request);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCreditLevelDetails = createAsyncThunk(
  "account/creditLevelDetails",
  async (maAccountId) => {
    return await FacadeAPI.GET(`/account/creditLevelDetails/${maAccountId}`);
  }
);

export const updateEmail = createAsyncThunk(
  "session/updateEmail",
  async ({ newEmail }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.PUT(
        `/session/${sessionId}/updateEmail?newEmail=${newEmail}`,
        {},
        {
          expectedResponse: "",
        }
      );
      return newEmail;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const requestTUIdentityCheck = createAsyncThunk(
  "session/requestIdentityCheck",
  async ({ tuDataRequest }, { getState, rejectWithValue, dispatch }) => {
    const sessionId = getState().session.sessionId;
    try {
      const result = await FacadeAPI.PUT(
        `/session/${sessionId}/requestIdentityCheck`,
        tuDataRequest
      );
      dispatch(clearGetItemsApiResponse());
      return result;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const requestTUIdentityCheckTimeout = createAsyncThunk(
  "session/requestIdentityCheckTimeout",
  async ({ accountId }, { rejectWithValue }) => {
    try {
      await FacadeAPI.PUT(
        `/account/${accountId}/requestIdentityCheckTimeout`,
        {},
        {
          expectedResponse: "",
        }
      );
      return accountId;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const isSkippedFraudCheckAndInvoiceIsNotPaid = createAsyncThunk(
  "account/skippedFraudCheckAndInvoiceIsNotPaid",
  async (accountId) => {
    return await FacadeAPI.GET(`/account/skippedFraudCheckAndInvoiceIsNotPaid/${accountId}`);
  }
);

function setValidateAddress(state, action) {
  state.validateAddress.address = action.payload.address.street1;
  if (action.payload.address.street2 !== "") {
    state.validateAddress.address += " " + action.payload.address.street2;
  }
  state.validateAddress.city = action.payload.address.city;
  state.validateAddress.postalCode = action.payload.address.postalCode;
  state.validateAddress.province = action.payload.address.state;
}

const personalInformationSlice = createSlice({
  name: "personalInformation",
  initialState: {
    ...PERSONAL_INFO_INITIAL_STATE,
  },
  reducers: {
    updatePersonaInformation(state, action) {
      return { ...PERSONAL_INFO_INITIAL_STATE, ...action.payload, loaded: true };
    },
    clearLoadPersonalInfoApiResponse(state) {
      return {
        ...state,
        loadPersonalInformation: PERSONAL_INFO_INITIAL_STATE.loadPersonalInformation,
      };
    },
    clearSavePersonalInfoApiResponse(state) {
      return {
        ...state,
        savePersonalInformation: PERSONAL_INFO_INITIAL_STATE.savePersonalInformation,
      };
    },
    clearValidateAddressApiResponse(state) {
      return {
        ...state,
        validateAddress: PERSONAL_INFO_INITIAL_STATE.validateAddress,
      };
    },
    clearGetCreditLevelDetails(state) {
      return {
        ...state,
        getCreditLevelDetails: PERSONAL_INFO_INITIAL_STATE.getCreditLevelDetails,
      };
    },
    clearUpdateEmail(state) {
      return {
        ...state,
        updateEmail: PERSONAL_INFO_INITIAL_STATE.updateEmail,
      };
    },
  },
  extraReducers: {
    [savePersonalInformation.pending]: (state) => {
      state.savePersonalInformation.loading = true;
      state.savePersonalInformation.success = false;
      state.savePersonalInformation.error = null;
    },
    [savePersonalInformation.fulfilled]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        savePersonalInformation: {
          ...state.savePersonalInformation,
          loading: false,
          success: true,
        },
      };
    },
    [savePersonalInformation.rejected]: (state, action) => {
      state.savePersonalInformation.loading = false;
      state.savePersonalInformation.success = false;
      state.savePersonalInformation.error = action.payload;
    },

    [loadPersonalInformation.pending]: (state) => {
      state.loadPersonalInformation.loading = true;
      state.loadPersonalInformation.success = false;
      state.loadPersonalInformation.error = null;
    },
    [loadPersonalInformation.fulfilled]: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loaded: true,
        loadPersonalInformation: {
          ...state.loadPersonalInformation,
          loading: false,
          success: true,
        },
      };
    },
    [loadPersonalInformation.rejected]: (state, action) => {
      state.loadPersonalInformation.loading = false;
      state.loadPersonalInformation.success = false;
      state.loadPersonalInformation.error = action.payload;
    },

    [validateAddress.pending]: (state) => {
      state.validateAddress.loading = true;
      state.validateAddress.success = false;
      state.validateAddress.error = null;
    },
    [validateAddress.fulfilled]: (state, action) => {
      state.validateAddress.loading = false;
      state.validateAddress.success = true;
      state.validateAddress.error = null;
      state.validateAddress.response = action.payload.response;
      setValidateAddress(state, action);
    },
    [validateAddress.rejected]: (state, action) => {
      state.validateAddress.loading = false;
      state.validateAddress.success = false;
      state.validateAddress.error = action.payload;
    },
    [getCreditLevelDetails.pending]: (state) => {
      state.getCreditLevelDetails.loading = true;
      state.getCreditLevelDetails.success = false;
      state.getCreditLevelDetails.error = null;
    },
    [getCreditLevelDetails.fulfilled]: (state, action) => {
      state.getCreditLevelDetails.loading = false;
      state.getCreditLevelDetails.success = true;
      state.getCreditLevelDetails.creditLevelDetails = action.payload;
    },
    [getCreditLevelDetails.rejected]: (state, action) => {
      state.getCreditLevelDetails.loading = false;
      state.getCreditLevelDetails.success = false;
      state.getCreditLevelDetails.error = action.error;
    },
    [updateEmail.pending]: (state) => {
      state.updateEmail.loading = true;
      state.updateEmail.success = false;
      state.updateEmail.error = null;
    },
    [updateEmail.fulfilled]: (state, action) => {
      state.updateEmail.loading = false;
      state.updateEmail.success = true;
      state.emailAddress = action.payload;
    },
    [updateEmail.rejected]: (state, action) => {
      state.updateEmail.loading = false;
      state.updateEmail.success = false;
      state.updateEmail.error = action.error;
    },
    [requestTUIdentityCheck.pending]: (state) => {
      state.requestTUIdentityCheck.loading = true;
      state.requestTUIdentityCheck.success = false;
      state.requestTUIdentityCheck.error = null;
    },
    [requestTUIdentityCheck.fulfilled]: (state, action) => {
      state.requestTUIdentityCheck.loading = false;
      state.requestTUIdentityCheck.success = true;
      state.tuResponseCode = action.payload;
    },
    [requestTUIdentityCheck.rejected]: (state, action) => {
      state.requestTUIdentityCheck.loading = false;
      state.requestTUIdentityCheck.success = false;
      state.requestTUIdentityCheck.error = action.error;
    },
    [requestTUIdentityCheckTimeout.pending]: (state) => {
      state.requestTUIdentityCheckTimeout.loading = true;
      state.requestTUIdentityCheckTimeout.success = false;
      state.requestTUIdentityCheckTimeout.error = null;
    },
    [requestTUIdentityCheckTimeout.fulfilled]: (state) => {
      state.requestTUIdentityCheckTimeout.loading = false;
      state.requestTUIdentityCheckTimeout.success = true;
    },
    [requestTUIdentityCheck.rejected]: (state, action) => {
      state.requestTUIdentityCheckTimeout.loading = false;
      state.requestTUIdentityCheckTimeout.success = false;
      state.requestTUIdentityCheckTimeout.error = action.error;
    },
    [isSkippedFraudCheckAndInvoiceIsNotPaid.pending]: (state) => {
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.loading = true;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.success = false;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.error = null;
    },
    [isSkippedFraudCheckAndInvoiceIsNotPaid.fulfilled]: (state, action) => {
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.loading = false;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.success = true;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.value = action.payload;
    },
    [isSkippedFraudCheckAndInvoiceIsNotPaid.rejected]: (state, action) => {
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.loading = false;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.success = false;
      state.isSkippedFraudCheckAndInvoiceIsNotPaid.error = action.error;
    },
  },
});

export const {
  updatePersonaInformation,
  clearLoadPersonalInfoApiResponse,
  clearSavePersonalInfoApiResponse,
  clearValidateAddressApiResponse,
  clearGetCreditLevelDetails,
  clearUpdateEmail,
} = personalInformationSlice.actions;

export default personalInformationSlice.reducer;
