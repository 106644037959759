import React from "react";
import { Box } from "@theme-ui/components";

import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

const Header = (props) => {
  return (
    <>
      <Box sx={{ display: ["inline", "inline", "none"] }}>
        <HeaderMobile {...props} />
      </Box>
      <Box sx={{ display: ["none", "none", "inline"] }}>
        <HeaderDesktop {...props} />
      </Box>
    </>
  );
};

export default Header;
