import { Box, Flex, Image, Text, Button } from "@theme-ui/components";

import Attribute from "../../../components/attribute";
import { Heading3, Heading4 } from "../../../components/heading";
import { StyledModalMessage } from "../../../components/modals";
import { OSIcon } from "../../../components/icons";
import { useDispatch } from "react-redux";

import { OSContainer, OSSelect, OSLabel } from "../../../components/base";
import { NONE_CODE } from "../../../common/Constants";
import { useIntl, FormattedMessage } from "react-intl";
import React, { useState } from "react";
import { deleteSession } from "../../../config/redux/slices";
const SADevice = ({
  device,
  existingLines,
  selectedSA,
  onSelectServiceNumber,
  onRemoveDevice,
  numberOfBundles,
  pageHeader,
  ...props
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [modal, setModal] = useState({
    isChange: false,
    isRemove: false,
    message: "",
    onClick: null,
  });

  const onRemove = async () => {
    onRemoveDevice && onRemoveDevice(device.id);
  };
  const onDelete = async () => {
    await dispatch(deleteSession());
  };
  const clearModal = () => setModal({ isRemove: false, message: "", onClick: null });

  const ModalButtons = () => {
    return (
      <>
        <Button
          mr={[3, 5, 10]}
          variant="simple-action"
          onClick={() => {
            modal.onClick();
            clearModal();
          }}>
          <FormattedMessage id={numberOfBundles > 1 ? "btn.ok" : "btn.end-sale"} />
        </Button>

        <Button
          variant="simple-action"
          onClick={() => {
            clearModal();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </>
    );
  };
  return (
    <Box pr={10} pl={10} sx={{ width: "100%" }} {...props}>
      <StyledModalMessage
        isOpen={modal.isRemove}
        message={modal.message}
        onRequestClose={clearModal}
        type="error">
        <ModalButtons />
      </StyledModalMessage>
      <Flex
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          justifyContent: "space-between",
          flexDirection: ["column", "column", "row"],
        }}>
        <Heading3 pt={10}>{pageHeader}</Heading3>

        <Flex
          mt={[2, 2, "none"]}
          mr={["none", "none", 10]}
          pt={10}
          sx={{
            alignItems: "center",
            justifyContent: ["space-between", "space-between", "flex-start"],
            width: ["100%", "100%", "auto"],
          }}>
          <Flex
            onClick={() =>
              setModal({
                isRemove: true,
                message: intl.formatMessage({
                  id:
                    numberOfBundles > 1
                      ? "lbl.remove-device-warning"
                      : "lbl.remove-last-device-warning",
                }),
                onClick: numberOfBundles > 1 ? onRemove : onDelete,
              })
            }
            sx={{ cursor: "pointer", alignItems: "center" }}>
            <Text
              variant="subtitle1"
              mt={0}
              ml={8}
              color="ternary"
              sx={{ cursor: "pointer", textDecoration: "underline" }}>
              {intl.formatMessage({ id: "lbl.remove-item-small" })}
            </Text>
            <OSIcon name="trash" ml={2} />
          </Flex>
        </Flex>
      </Flex>
      <OSContainer variant="device-and-plan-section">
        <Flex
          sx={{
            minWidth: ["50%", "25%", "20%"],
            flexDirection: ["column", "column", "row"],
            alignItems: ["flex-start", "flex-start", "center"],
            justifyContent: "space-between",
            flex: 1,
          }}>
          <Flex
            sx={{
              width: ["100%", "100%", "30%"],
              justifyContent: "center",
              height: "7.5em",
            }}>
            <Flex
              sx={{
                justifyContent: "flex-start",
                minWidth: "7rem",
                width: "7rem",
              }}>
              <Image src={device.thumbnailURL} alt="device" sx={{ objectFit: "contain" }} />
            </Flex>

            <Flex sx={{ justifyContent: "center", flexWrap: "wrap" }}>
              <Heading4 sx={{ minWidth: "5rem", textAlign: "center" }}>
                {device.shortDescription}
              </Heading4>
              {device.attributes.length > 0 && (
                <Flex
                  mt={1.5}
                  sx={{
                    gap: 1.5,
                    flexDirection: ["column"],
                    alignItems: "center",
                    flexWrap: "wrap",
                    width: "100%",
                  }}>
                  {device.attributes.map((attribute, index) => (
                    <Attribute key={index} value={attribute.value} />
                  ))}
                </Flex>
              )}
            </Flex>
          </Flex>

          <Flex
            sx={{
              flexDirection: "column",
              minWidth: ["15rem", "70%", "30%"],
            }}
            mt={[5, 5, "none"]}
            ml={[0, 0, "auto"]}>
            <OSLabel variant="labelLarge">
              {intl.formatMessage({ id: "label.upgrade-line" })}
            </OSLabel>
            <OSSelect
              mt={2}
              name="serviceNumber"
              onChange={(e) =>
                onSelectServiceNumber(e.target.value, device.id, device.sku, device.pricingOption)
              }
              value={selectedSA}
              color={"ternary"}
              sx={{ fontWeight: "bold" }}
              variant="select">
              {existingLines &&
                [
                  {
                    id: NONE_CODE,
                    serviceNumber: intl.formatMessage({ id: "lbl.choose-from-active-lines" }),
                  },
                ]
                  .concat(existingLines)
                  .map((existingLine) => (
                    <option key={existingLine.id} value={existingLine.id}>
                      {existingLine.serviceNumber}
                    </option>
                  ))}
            </OSSelect>
          </Flex>
        </Flex>
      </OSContainer>
    </Box>
  );
};

export default SADevice;
