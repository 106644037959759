import { Flex } from "@theme-ui/components";

const OSContainer = ({ children, variant, ...props }) => {
  return (
    <Flex variant={"layout." + variant} {...props}>
      {children}
    </Flex>
  );
};

export default OSContainer;
