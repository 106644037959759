import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { Box, Flex, Text, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

import DefaultAddressCard from "./DefaultAddressCard";
import DefaultAddressForm from "./DefaultAddressForm";
import ShippingMethod from "./ShippingMethod";
import Layout from "../../../../components/layout";
import PageHeader from "../../PageHeader";
import { HomeDeliverySkeleton } from "../../../../components/skeletons";

import { OSContainer } from "../../../../components/base";
import {
  getShippingMethods,
  saveShippingDetails,
  setHomeDeliveryState,
  getShippingContact,
} from "../../../../config/redux/slices";

import { CHECKOUT_STEPS } from "../../../../components/header/Constants";
import { SHIPPING_ADDRESS, SHIPPING_FLOW, SHIPPMING_METHODS } from "../../Constants";
import {
  canLogin,
  ITEM_ATTRIBUTE_CODE,
  ITEM_CATEGORY,
  POS_PARAMETER,
  PRICE_TYPE,
} from "../../../../common/Constants";
import { getNextStep } from "../../../../common/StepsUtilities";
import { StyledModalMessage } from "../../../../components/modals";
import Spinner from "../../../../components/spinner/Spinner";
import { OSIcon } from "../../../../components/icons";
import { useWindowSize } from "../../../../hooks";
import HRTriangle from "../../../../components/HRTriangle";

const HomeDelivery = ({ onChangeShippingFlow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const personalInfoState = useSelector((state) => state.personalInformation);
  const shippingDetailsState = useSelector((state) => state.shippingDetails);
  const { planFlags, ...itemsState } = useSelector((state) => state.items);
  const personalInformationState = useSelector((state) => state.personalInformation);
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  const configurationState = useSelector((state) => state.configuration);

  const [shippingAddress, setShippingAddress] = useState(shippingDetailsState.shippingAddress);
  const [shippingMethod, setShippingMethod] = useState(shippingDetailsState.shippingMethod);
  const [shippingDescription, setShippingDescription] = useState(
    shippingDetailsState.shippingDescription
  );
  const [stockAvailable, setStockAvailable] = useState(false);
  const [onlineStore, setOnlineStore] = useState();
  const [accessoriesToBeRemoved, setAccessoriesToBeRemoved] = useState(null);
  const [showRemoveAccessoriesMsg, setShowRemoveAccessoriesMsg] = useState(false);

  const [defaultAddress, setDefaultAddress] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    phoneNumber: "",
    emailAddress: "",
  });

  const windowSize = useWindowSize();
  const headerContainer = useRef(null);
  const standardOption = useRef(null);
  const ExpressOption = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(454);
  const [hrWidth, setHrWidth] = useState(0);

  useLayoutEffect(() => {
    if (
      standardOption &&
      standardOption.current &&
      shippingDetailsState &&
      shippingDetailsState.getShippingMethods.methods &&
      shippingDetailsState.getShippingMethods.methods[0] &&
      shippingMethod === shippingDetailsState.getShippingMethods.methods[0].code
    ) {
      setHrTrianglePosition(
        standardOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          standardOption.current.offsetWidth / 2
      );
    }

    if (
      ExpressOption &&
      ExpressOption.current &&
      shippingDetailsState &&
      shippingDetailsState.getShippingMethods.methods &&
      shippingDetailsState.getShippingMethods.methods[1] &&
      shippingMethod === shippingDetailsState.getShippingMethods.methods[1].code
    ) {
      setHrTrianglePosition(
        ExpressOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          ExpressOption.current.offsetWidth / 2
      );
    }
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
  }, [shippingMethod, windowSize.width, shippingDetailsState]);

  const computeListOfAccessoriesToBeRemoved = (osStore, existsStock) => {
    if (!existsStock) return;
    let accsList;
    if (osStore.accessoriesOutOfStock && Object.keys(osStore.accessoriesOutOfStock).length > 0) {
      accsList = Object.keys(osStore.accessoriesOutOfStock).map(
        (key) => itemsState.getItems.items.find((item) => item.sku === key)?.shortDescription
      );
    }
    setAccessoriesToBeRemoved(accsList);
  };

  useEffect(() => {
    if (
      shippingDetailsState.getStores.success &&
      configurationState.getPosParameter.success &&
      itemsState.getItems.success
    ) {
      if (shippingDetailsState.getStores.allStores?.length > 0) {
        let osStore = shippingDetailsState.getStores.allStores.find(
          (store) => store.code === posParameters[POS_PARAMETER.ONLINE_STORE_PROVIDER]
        );
        setOnlineStore(osStore);
        const existsBundle = itemsState.getItems.items.find(
          (item) => item.category === ITEM_CATEGORY.BUNDLE
        );
        let existsStock =
          osStore &&
          ((existsBundle && osStore.resourcesAvailable) ||
            (!existsBundle && !osStore.allAccessoriesOutOfStock));
        setStockAvailable(existsStock);
        computeListOfAccessoriesToBeRemoved(osStore, existsStock);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shippingDetailsState.getStores.success,
    configurationState.getPosParameter.success,
    itemsState.getItems.success,
  ]);

  useEffect(() => {
    if (personalInformationState.loaded && canLogin(personalInformationState.flow)) {
      dispatch(getShippingContact());
    }
  }, [dispatch, personalInformationState.flow, personalInformationState.loaded]);

  useEffect(() => {
    if (shippingDetailsState.getShippingMethods.success) {
      return;
    }

    dispatch(getShippingMethods());
  }, [dispatch, shippingDetailsState.getShippingMethods.success]);

  useEffect(() => {
    if (shippingDetailsState.getShippingMethods.success && itemsState.getItems.success) {
      const shippingItem = itemsState.getItems.items.find(
        (item) => item.category === ITEM_CATEGORY.SHIPPING
      );
      let shippingMethodCode = shippingDetailsState.getShippingMethods.methods[0].code;
      let shippingDescription = shippingDetailsState.getShippingMethods.methods[0].description;

      if (shippingItem && shippingItem.attributes.length > 0) {
        const shippingMethod = shippingItem.attributes.find(
          (attrItem) => attrItem.code === ITEM_ATTRIBUTE_CODE.SHIPPING_METHOD
        );
        const shippingDescriptionAttr = shippingItem.attributes.find(
          (attrItem) => attrItem.code === ITEM_ATTRIBUTE_CODE.SHIPPING_DESCRIPTION
        );
        shippingMethodCode = shippingMethod ? shippingMethod.value : shippingMethodCode;
        shippingDescription = shippingDescriptionAttr
          ? shippingDescriptionAttr.value
          : shippingDescription;
      }
      setShippingMethod(shippingMethodCode);
      setShippingDescription(shippingDescription);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingDetailsState.getShippingMethods.success, itemsState.getItems.success]);

  useEffect(() => {
    if (personalInfoState.loaded) {
      if (canLogin(personalInfoState.flow)) {
        if (shippingDetailsState.getShippingContact.data !== null) {
          setDefaultAddress({
            ...shippingDetailsState.getShippingContact.data,
            state: shippingDetailsState.getShippingContact.data.province,
            emailAddress: shippingDetailsState.getShippingContact.data.email,
          });
        }
      } else {
        setDefaultAddress({
          firstName: personalInfoState.firstName,
          lastName: personalInfoState.lastName,
          address: personalInfoState.address,
          city: personalInfoState.city,
          state: personalInfoState.province,
          postalCode: personalInfoState.postalCode,
          phoneNumber: personalInfoState.phoneNumber,
          emailAddress: personalInfoState.emailAddress,
        });
      }
    }
  }, [
    personalInfoState.address,
    personalInfoState.city,
    personalInfoState.emailAddress,
    personalInfoState.firstName,
    personalInfoState.flow,
    personalInfoState.lastName,
    personalInfoState.loaded,
    personalInfoState.phoneNumber,
    personalInfoState.postalCode,
    personalInfoState.province,
    shippingDetailsState.getShippingContact.data,
    shippingDetailsState.getShippingContact.data?.province,
  ]);
  const onChangeShippingAddress = (shippingAddress) => {
    setShippingAddress(shippingAddress);
  };

  const onSelectShippingMethod = (shippingMethod, shippingDescription) => {
    setShippingMethod(shippingMethod);
    setShippingDescription(shippingDescription);
  };

  const onNext = (values) => {
    // Save redux state
    dispatch(
      setHomeDeliveryState({
        shippingFlow: SHIPPING_FLOW.HOME_DELIVERY,
        shippingAddress: shippingAddress,
        shippingMethod: shippingMethod,
        shippingDescription: shippingDescription,
        shippingNote: values.shippingNote,
        buzzerCode: values.buzzerCode,
        defaultAddress: {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          postalCode: "",
          phoneNumber: "",
          emailAddress: "",
        },
      })
    );

    const shippingMethodObject = shippingDetailsState.getShippingMethods.methods.find(
      (method) => method.code === shippingMethod
    );
    let shippingAddressAttrs = [];
    dispatch(
      saveShippingDetails({
        flow: SHIPPING_FLOW.HOME_DELIVERY,
        shippingMethod: shippingMethodObject.code,
        shippingAddress: defaultAddress,
        sessionItem: {
          sku: shippingMethodObject.code,
          category: ITEM_CATEGORY.SHIPPING,
          shortDescription: shippingMethodObject.description,
          thumbnailURL: "home-delivery",
          prices: [
            {
              code: shippingMethodObject.code,
              price: shippingMethodObject.price,
              tax: 0,
              type: PRICE_TYPE.ONE_TIME,
              termsAmount: -1,
              termsUnit: "NONE",
            },
          ],
          attributes: [
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPPING_NOTE,
              value: values.shippingNote,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.BUZZER_CODE,
              value: values.buzzerCode,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.GOOD_TYPE,
              value: shippingMethodObject.goodsTypeID,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.GOOD_MODEL,
              value: shippingMethodObject.goodsModelID,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.TAX_INCLUDED,
              value: shippingMethodObject.taxIncluded,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPPING_DESCRIPTION,
              value: shippingDescription,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPPING_METHOD,
              value: shippingMethodObject.code,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.IS_NEW_ADDRESS,
              value: shippingAddress === SHIPPING_ADDRESS.NEW ? "Y" : "N",
            },
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPMENT_OPTION,
              value: SHIPPING_FLOW.HOME_DELIVERY,
            },
            ...shippingAddressAttrs,
          ],
        },
        accessoriesOutOfStock: onlineStore.accessoriesOutOfStock,
      })
    );
  };

  const goTo = () => {
    onChangeShippingFlow(SHIPPING_FLOW.PICK_IN_STORE);
  };
  const isNextDisabled =
    !stockAvailable ||
    shippingAddress === SHIPPING_ADDRESS.NONE ||
    shippingMethod === SHIPPMING_METHODS.NONE ||
    shippingDetailsState.saveShippingDetails.loading;

  const isContentLoading =
    shippingDetailsState.getStores.loading ||
    configurationState.getPosParameter.loading ||
    itemsState.getItems.loading;

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.SHIPPING_DETAILS.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.SHIPPING_DETAILS.pageTitle })}
      nextStepButton={() => (
        <Button
          id="submitShipping"
          variant="default-next"
          disabled={isNextDisabled}
          form={accessoriesToBeRemoved ? "" : "defaultAddressForm"}
          type={accessoriesToBeRemoved ? "button" : "submit"}
          onClick={accessoriesToBeRemoved ? () => setShowRemoveAccessoriesMsg(true) : undefined}>
          {intl.formatMessage({
            id: shippingDetailsState.saveShippingDetails.loading
              ? "lbl.processing"
              : getNextStep(planFlags, CHECKOUT_STEPS.SHIPPING_DETAILS, personalInfoState.flow)
                  .srcButtonLabel,
          })}
        </Button>
      )}>
      <StyledModalMessage
        isOpen={showRemoveAccessoriesMsg && (accessoriesToBeRemoved ?? false)}
        onRequestClose={() => setShowRemoveAccessoriesMsg(false)}
        message={intl.formatMessage({ id: "lbl.remove-out-of-stock-accessories" })}
        listItems={accessoriesToBeRemoved}>
        <Button mr={20} variant="simple-action" onClick={() => setShowRemoveAccessoriesMsg(false)}>
          <FormattedMessage id="btn.cancel" />
        </Button>
        <Button variant="simple-action" form="defaultAddressForm" type="submit">
          <FormattedMessage id="btn.proceed" />
        </Button>
      </StyledModalMessage>
      <Spinner isOpen={shippingDetailsState.saveShippingDetails.loading} />
      <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
        <PageHeader
          selectedShippingFlow={SHIPPING_FLOW.HOME_DELIVERY}
          onChangeShippingFlow={onChangeShippingFlow}
        />
        <Box
          mt={7.5}
          px={[8, 8, 9]}
          sx={{
            display: "grid",
            gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)"],
            columnGap: [0, 6],
            rowGap: [6, 0],
            gridAutoRows: ["1fr", "min-content"],
          }}>
          <DefaultAddressCard
            defaultAddress={defaultAddress}
            selected={shippingAddress === SHIPPING_ADDRESS.DEFAULT}
            onSelect={onChangeShippingAddress}
            disabled={!stockAvailable}
          />
          {!stockAvailable && !isContentLoading && (
            <Flex
              bg="secondaryBg"
              px={5.5}
              py={7.5}
              sx={{
                alignItems: "center",
                border: 1,
                borderColor: "border",
                borderRadius: "2lg",
                boxShadow: "sm",
                width: "100%",
              }}>
              <Flex sx={{ position: "absolute" }}>
                <OSIcon name="no-shipping" />
              </Flex>
              <Flex
                mr="auto"
                ml="auto"
                sx={{
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                }}>
                <Text variant="description" color="noResultsTextColor">
                  {intl.formatMessage({ id: "lbl.unable-perform-home-deliveries" })}
                </Text>
                <Flex sx={{ alignItems: "center" }}>
                  <Text
                    variant="storePickup"
                    onClick={goTo}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}>
                    {intl.formatMessage({ id: "lbl.choose-pick-up-store" })}
                  </Text>
                  <OSIcon name="chevron-right" ml={1} />
                </Flex>
              </Flex>
            </Flex>
          )}

          {stockAvailable && !isContentLoading && (
            <DefaultAddressForm defaults={shippingDetailsState} width="100%" onSubmit={onNext} />
          )}

          {isContentLoading && <HomeDeliverySkeleton />}
        </Box>

        {stockAvailable && !isContentLoading && (
          <Flex
            ref={headerContainer}
            mt={10}
            mb={3}
            sx={{ width: "100%", flexDirection: "column" }}>
            <Flex sx={{ display: ["none", "inline"] }}>
              <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
            </Flex>

            <Flex mt={5} sx={{ justifyContent: "center" }} px={[8, 8, 9]}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, 1fr)"],
                  columnGap: [0, 6],
                  rowGap: 6,
                  gridAutoRows: "min-content",
                }}>
                {shippingDetailsState.getShippingMethods.methods.map((method, index) => (
                  <ShippingMethod
                    key={method.code}
                    data={{
                      title: method.description,
                      description:
                        index === 0
                          ? "Delivery within 14 business days"
                          : "Delivery within 2-5 business days",
                      amount: method.price,
                      tax: method.tax,
                      ref: index === 0 ? standardOption : ExpressOption,
                    }}
                    selected={shippingMethod === method.code}
                    onSelect={() =>
                      onSelectShippingMethod(
                        method.code,
                        index === 0
                          ? "Delivery within 14 business days"
                          : "Delivery within 2-5 business days"
                      )
                    }
                  />
                ))}
              </Box>
            </Flex>
          </Flex>
        )}
      </OSContainer>
    </Layout>
  );
};

export default HomeDelivery;
