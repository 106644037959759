import React from "react";
import { Box } from "@theme-ui/components";

const ChevronRight = ({ color = "#0c3865", size = "iconTiny", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 6.062 10.709"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(0.707 0.707)">
      <g transform="translate(-12.381 -9.353)">
        <path
          d="M12011,5841.38l2.324-2.323,2.322-2.324"
          transform="translate(-11998.618 -5822.732)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          d="M0,4.647,2.324,2.324,4.647,0"
          transform="translate(17.029 9.353) rotate(90)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </Box>
);

export default ChevronRight;
