import React, { useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Flex, Text } from "@theme-ui/components";
import Amount from "../../components/amount";
import CollapsibleHeader from "../../components/collapsible-header/CollapsibleHeader";
import { Heading5 } from "../../components/heading";
import { useWindowSize } from "../../hooks";

const ShippingDetailsSummary = ({ ...props }) => {
  const { summaryData } = props;
  const intl = useIntl();
  const windowSize = useWindowSize();

  const [collapsed, setCollapsed] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);

  const summaryContainer = useRef(null);

  useLayoutEffect(() => {
    setHrTrianglePosition(summaryContainer.current.offsetWidth / 4);
    setHrWidth(
      summaryContainer && summaryContainer.current ? summaryContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  return (
    <Box ref={summaryContainer} mb={!collapsed && 6}>
      <CollapsibleHeader
        primaryTitle={intl.formatMessage({ id: "header.shipping-details-summary" })}
        hrWidth={hrWidth}
        hrTrianglePosition={hrTrianglePosition}
        collapsed={collapsed}
        onCollapsed={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (
        <Flex
          sx={{
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: ["column", "row", "row"],
            px: [8, 8, 9],
          }}>
          <Box sx={{ width: ["100%", "50%", "50%"] }}>
            {summaryData.needsFulfillment && (
              <Flex sx={{ flexDirection: "column", width: ["100%", "100%", "80%"] }}>
                <Heading5 mt="none">
                  {summaryData.store
                    ? intl.formatMessage({ id: "lbl.pick-in-store-delivery" })
                    : intl.formatMessage({ id: "lbl.shipping-address" })}
                </Heading5>

                <Flex
                  sx={{
                    width: "100%",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}>
                  {(!summaryData.store && (
                    <Flex sx={{ width: "100%" }}>
                      <Text
                        variant="summaryValue"
                        sx={{
                          overflowWrap: "break-word",
                        }}>
                        {`${summaryData.shippingAddress.firstName} ${summaryData.shippingAddress.lastName}`}
                      </Text>
                    </Flex>
                  )) || <Text variant="summaryValue">{summaryData.store.name}</Text>}
                  {(!summaryData.store && (
                    <Text variant="summaryValue">
                      {`${summaryData.shippingAddress.address} ${summaryData.shippingAddress.city} ${summaryData.shippingAddress.state} ${summaryData.shippingAddress.postalCode}`}
                    </Text>
                  )) || <Text variant="summaryValue">{summaryData.store.address}</Text>}
                  {(!summaryData.store && (
                    <Flex sx={{ alignItems: ["center", "flex-start", "center"] }}>
                      <Text variant="summaryLabel">
                        {intl.formatMessage({
                          id: "lbl.phone-number",
                        })}
                        :
                      </Text>
                      <Text variant="summaryValue">{summaryData.shippingAddress.phoneNumber}</Text>
                    </Flex>
                  )) ||
                    (summaryData.store.phoneNumber && (
                      <Flex sx={{ alignItems: ["center", "flex-start", "center"] }}>
                        <Text variant="summaryLabel">
                          {intl.formatMessage({
                            id: "lbl.phone-number",
                          })}
                          :
                        </Text>
                        <Text variant="summaryValue">{summaryData.store.phoneNumber}</Text>
                      </Flex>
                    ))}
                  {summaryData.store && (
                    <Text variant="summaryLabel" pt={5} sx={{ fontWeight: "bold" }}>
                      {intl.formatMessage({
                        id: "lbl.two-pieces-id",
                      })}
                    </Text>
                  )}
                </Flex>
              </Flex>
            )}

            {!summaryData.needsFulfillment && (
              <Flex sx={{ flexDirection: "column", width: ["100%", "100%", "100%"] }}>
                <Text variant="largeLabel">
                  {intl.formatMessage({ id: "lbl.shipping-not-needed" })}
                </Text>
              </Flex>
            )}
          </Box>

          <Box sx={{ width: ["100%", "50%", "50%"] }}>
            {!summaryData.store && summaryData.needsFulfillment && (
              <Flex
                sx={{
                  flexDirection: "column",
                  width: ["100%", "100%", "80%"],
                  mt: [4, 0, 0],
                }}>
                <Heading5 mt="none">
                  {summaryData.shortDescription}
                  {summaryData.shippingTax > 0 && (
                    <Text variant="subtitle1" pl={2}>
                      {`(${intl.formatMessage({ id: "lbl.plus-tax" })})`}
                    </Text>
                  )}
                </Heading5>

                <Flex
                  sx={{
                    pr: [0, 4, 4],
                    width: ["100%", "100%", "70%"],
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}>
                  <Text variant="summaryValue">{summaryData.shippingDescription}</Text>
                  {summaryData.shippingPrice > 0 && (
                    <Amount amount={summaryData.shippingPrice} color="ternary" />
                  )}
                </Flex>
              </Flex>
            )}
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ShippingDetailsSummary;
