import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/en-ca";
import "dayjs/locale/fr-ca";

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale("en-ca", {
  formats: { LL: "D/MMM/YYYY" },
});
