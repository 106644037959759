import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Flex, Text, Box, Button, Input } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { OSIcon } from "../icons";
import { useWindowSize } from "../../hooks";
import Tooltip from "../tooltip/Tooltip";
import HRTriangle from "../HRTriangle";

import useOverFlow from "../../hooks/useOverFlow";

const MultipleTabHeader = ({
  tabsSize,
  activeTabIndex,
  editModeAvailable,
  currentItem,
  linesTitles,
  setLinesTitles,
  isTopMessageVisible,
}) => {
  const intl = useIntl();
  const windowSize = useWindowSize();

  const headerContainer = useRef(null);
  const option = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const [linesNames, setLinesNames] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [isDirty, setDirty] = useState(false);

  useLayoutEffect(() => {
    if (option.current) {
      setHrTrianglePosition(
        option.current.offsetLeft -
          headerContainer.current.offsetLeft +
          option.current.offsetWidth / 2
      );
    }
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width, activeTabIndex]);

  const getLineTitle = (lineName, tabPosition) => {
    if (lineName?.firstName && lineName?.lastName) {
      return lineName.firstName.concat(" ", lineName.lastName);
    } else if (lineName?.accesoriesTitle) {
      return lineName.accesoriesTitle;
    } else {
      return `${intl.formatMessage({ id: "lbl.line" })} ${tabPosition + 1}`;
    }
  };
  // Initialize
  useEffect(() => {
    if (!currentItem) {
      return;
    }
    const { firstName, lastName } = initFirstLastName();
    setFirstName(firstName);
    setLastName(lastName);

    setEditMode(false);
    setLinesNames(linesTitles);
    // eslint-disable-next-line
  }, [currentItem]);

  const Tab = ({ position, isActive = false, label, editModeAvailable = false, isFirst }) => {
    const title = getLineTitle(label, position);
    const [isOverflowName, overflowName] = useOverFlow();
    const [isOverflowNumber, overflowNumber] = useOverFlow();
    return (
      <Flex
        ref={isActive ? option : null}
        backgroundColor={isActive ? "activeTabColor" : "secondaryBg"}
        sx={{
          borderTop: 1,
          borderRight: 1,
          borderColor: "cardBorderColor",
          borderTopLeftRadius: isFirst && !isTopMessageVisible ? "xl" : 0,
          pt: isTopMessageVisible ? 2 : 0,
          width: "16rem",
          flexDirection: "column",
        }}>
        {editModeAvailable && isActive && (
          <Box
            pt={2}
            pr={2}
            sx={{ cursor: "pointer", alignSelf: "flex-end" }}
            onClick={() => setEditMode(!editMode)}>
            <OSIcon name="edit-icon" />
          </Box>
        )}

        <Flex pb={8} px={4} sx={{ flexDirection: "column" }}>
          <Tooltip
            mt={isActive && editModeAvailable ? 0 : 8}
            position="top"
            value={label?.firstName}
            isOverflow={isOverflowName}
            sx={{
              width: "100%",
            }}>
            <Text
              ref={overflowName}
              variant={isActive ? "shoppingCartTitle" : "multipleTabNormal"}
              color="ternary"
              my={0}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "center",
              }}>
              {title}
            </Text>
          </Tooltip>
          {label?.serviceNumber && (
            <Tooltip
              position="top"
              value={label?.serviceNumber}
              isOverflow={isOverflowNumber}
              sx={{
                width: "100%",
              }}>
              <Text
                ref={overflowNumber}
                variant={isActive ? "shoppingCartTitle" : "multipleTabNormal"}
                color="ternary"
                my={0}
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                }}>
                {label?.serviceNumber}
              </Text>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );
  };

  const renderTabs = (size) => {
    let tabs = [];
    for (let i = 0; i < size; i++) {
      tabs.push(
        <Tab
          key={i}
          position={i}
          isActive={i === activeTabIndex}
          isFirst={i === 0}
          label={linesNames[i]}
          editModeAvailable={editModeAvailable && i === activeTabIndex}
        />
      );
    }

    return tabs;
  };

  const reset = () => {
    const { firstName, lastName } = initFirstLastName();
    setFirstName(firstName);
    setLastName(lastName);
    setDirty(false);
  };

  const initFirstLastName = () => {
    const tabFirstName = linesTitles[activeTabIndex].firstName;
    const tabLastName = linesTitles[activeTabIndex].lastName;
    if (tabFirstName && tabLastName) {
      return { firstName: tabFirstName, lastName: tabLastName };
    }
    return { firstName: currentItem.contactFirstName, lastName: currentItem.contactLastName };
  };

  const saveDetails = () => {
    setDirty(false);
    setEditMode(false);

    var newLineNames = linesNames;
    newLineNames[activeTabIndex] = { firstName: firstName, lastName: lastName };
    const linesTitlesCopy = [...linesTitles];
    linesTitlesCopy[activeTabIndex] = newLineNames[activeTabIndex];
    setLinesNames(newLineNames);
    setLinesTitles(linesTitlesCopy);
  };
  return (
    <>
      <Flex ref={headerContainer}>{renderTabs(tabsSize)}</Flex>

      {editMode && (
        <Flex
          px={9}
          py={4}
          sx={{
            flexDirection: ["column", "column", "row"],
            borderTop: 1,
            borderColor: "cardBorderColor",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "activeTabColor",
            width: "100%",
          }}>
          <Text variant="description">
            {intl.formatMessage({ id: "lbl.fill-in-line-contact-details" })}
          </Text>

          <Flex sx={{ gap: 2, width: ["100%", "75%"], flexDirection: ["column", "row"] }}>
            <Flex sx={{ width: ["100%", "auto"], justifyContent: "center", gap: 2 }}>
              <Input
                name="lineContactDetailsFirstName"
                placeholder={intl.formatMessage({ id: "lbl.first-name" })}
                type="input"
                variant="search"
                bg="secondaryBg"
                sx={{
                  width: "auto",
                }}
                value={firstName}
                maxLength={30}
                onChange={(e) => {
                  setFirstName(e.target.value.trimStart());
                  setDirty(
                    e.target.value.length !== 0 &&
                      e.target.value !== firstName &&
                      lastName.length !== 0
                  );
                }}
              />

              <Input
                name="lineContactDetailsLastName"
                placeholder={intl.formatMessage({ id: "lbl.last-name" })}
                type="input"
                variant="search"
                bg="secondaryBg"
                sx={{
                  width: "auto",
                }}
                value={lastName}
                maxLength={30}
                onChange={(e) => {
                  setLastName(e.target.value.trimStart());
                  setDirty(
                    e.target.value.length !== 0 &&
                      e.target.value !== lastName &&
                      firstName.length !== 0
                  );
                }}
              />
            </Flex>
            <Flex sx={{ justifyContent: "center", gap: 2 }}>
              <Button
                variant="base-button"
                disabled={!isDirty}
                bg="ternary"
                px={8}
                onClick={() => {
                  saveDetails();
                }}>
                {intl.formatMessage({
                  id: "btn.save-details",
                })}
              </Button>

              <Button
                variant="base-button"
                bg="secondaryBg"
                px={5.5}
                color="ternary"
                sx={{ border: "solid", borderWidth: 1 }}
                onClick={() => {
                  reset();
                  setEditMode(false);
                }}>
                {intl.formatMessage({ id: "btn.cancel" })}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      )}

      <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} fillColor="#eae5f1" />
    </>
  );
};

export default MultipleTabHeader;
