import _ from "lodash";
import { PRICE_TYPE, EXTRA_INFO } from "../../../common/Constants";
import { getExtraInfoValues } from "../../../common/StringUtilities";

// Get the full price of a device
export const getDevicePrice = (bundlePriceOffers, sku) => {
  const price = bundlePriceOffers.find((elem) => elem.code === sku)?.price[0]?.price;
  const tax = bundlePriceOffers.find((elem) => elem.code === sku)?.price[0]?.tax;
  return Math.round((price + tax + Number.EPSILON) * 100) / 100;
};

// Check if device price is installment with down payment
export const isInstallmentWithDownPayment = (device) => {
  const rcPrice = device.prices.find((price) => price.type === PRICE_TYPE.RECURRING);
  const otPrice = device.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
  return rcPrice && rcPrice.price > 0 && otPrice && otPrice.price >= 0;
};

// Convert a device item to an object with id as key and sku as value
export const getIdSkuObject = (device) => {
  return {
    [device.id]: device.sku,
  };
};

// Calculate the total price of remaining devices
export const calculateTotalPriceOfRemainingDevices = (remainingDevices, allBundlesPriceOffers) => {
  let sum = 0;
  for (const val of remainingDevices) {
    for (const [key, value] of Object.entries(val)) {
      if (value) {
        sum += allBundlesPriceOffers[key].find((o) => o.code === value).price[0].price;
        sum += allBundlesPriceOffers[key].find((o) => o.code === value).price[0].tax;
      }
    }
  }

  return Math.round((sum + Number.EPSILON) * 100) / 100;
};

// Calculate the used installment available amount
export const calculateUsedAvailableAmount = (prevDevices, bundlePriceOffers) => {
  let result = 0;

  for (let i = 0; i < prevDevices.length; i++) {
    if (isInstallmentWithDownPayment(prevDevices[i])) {
      const devicePrice = getDevicePrice(bundlePriceOffers[prevDevices[i].id], prevDevices[i].sku);
      const downPayment = prevDevices[i].prices.find(
        (price) => price.type === PRICE_TYPE.ONE_TIME
      ).price;
      const deviceUsedAvailableAmount =
        Math.round((devicePrice - downPayment + Number.EPSILON) * 100) / 100;
      result += Math.round((deviceUsedAvailableAmount + Number.EPSILON) * 100) / 100;
    }
  }

  return result;
};

export const updateCurrentBundlePriceOffers = (currentBundlePriceOffers, bundleSku, newPrice) => {
  let installmentOffer = currentBundlePriceOffers.find(
    (offer) => offer.code !== "PLAN" && offer.code !== bundleSku
  );
  const indexInstallmentOffer = currentBundlePriceOffers.indexOf(installmentOffer);
  installmentOffer = { ...installmentOffer, price: newPrice };

  const updatedPriceOffers = [...currentBundlePriceOffers];
  updatedPriceOffers.splice(indexInstallmentOffer, 1, installmentOffer);

  return updatedPriceOffers;
};

export const updateCurrentBundleBYOD = (currentBundlePriceOffers, newPrice) =>
  currentBundlePriceOffers.map((po) => {
    return {
      ...po,
      price: po.code !== "PLAN" ? newPrice : po.price,
    };
  });

export const groupOffers = (offers, itemId) => {
  const groupedOffer = _.groupBy(offers, (offer) => offer.code);
  return Object.keys(groupedOffer).map((key) => {
    return { itemId: itemId, code: key, price: groupedOffer[key] };
  });
};

export const calculateItemsUsedInstallmentAmount = (items) => {
  return items
    .filter((item) =>
      item.prices.find((price) => price.type === PRICE_TYPE.RECURRING && price.code?.length > 0)
    )
    .map((item) => {
      const fullPrice = item.prices.find((price) => price.type === PRICE_TYPE.RECURRING).fullPrice;
      const downPayment =
        item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME)?.price ?? 0;
      return Math.round((fullPrice - downPayment + Number.EPSILON) * 100) / 100;
    })
    .reduce((previous, current) => {
      return Math.round((previous + current + Number.EPSILON) * 100) / 100;
    }, 0);
};

export const calculateTotalOldInstallmentPayments = (items) => {
  return items
    .filter((item) => item.prices.find((price) => price.type === PRICE_TYPE.OLD_INSTALLMENT))
    .map((item) => {
      const oldInstallmentPrice = item.prices.find(
        (price) => price.type === PRICE_TYPE.OLD_INSTALLMENT
      );
      let flexDeferredAmount = 0;
      if (item.extraInfo?.length > 0) {
        let extraInfoValue = getExtraInfoValues(item.extraInfo)[EXTRA_INFO.FLEX_DEFERRED_AMOUNT];
        flexDeferredAmount = extraInfoValue ? parseFloat(extraInfoValue) : 0;
      }

      return (
        Math.round(
          (oldInstallmentPrice.price +
            oldInstallmentPrice.tax +
            flexDeferredAmount +
            Number.EPSILON) *
            100
        ) / 100
      );
    })
    .reduce((previous, current) => {
      return Math.round((previous + current + Number.EPSILON) * 100) / 100;
    }, 0);
};
