import React from "react";
import { Flex, Text } from "@theme-ui/components";

const IFrameMockup = () => {
  return (
    <Flex
      px="10rem"
      bg="WhiteSmoke"
      sx={{
        width: "100%",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: "10px",
        fontSize: "xl",
      }}>
      <Text mb={10}>You have been redirected to the eastlink storefront!</Text>
      <Text>happy shopping 😃</Text>
    </Flex>
  );
};
export default IFrameMockup;
