import React, { useEffect, useState } from "react";
import { Box, Flex, Text, Button, Label } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Storage from "store2";
import dayjs from "dayjs";

import { Heading3 } from "../../../components/heading";
import {
  FormInput,
  FormInputSkeleton,
  OSCheckbox,
  OSSection,
  OSLink,
} from "../../../components/base";
import UserDefinedFields from "../../../components/user-defined-fields";
import {
  NONE_CODE,
  PERSONAL_INFORMATION_FLOW,
  NEURAL_RESPONSE,
  POS_PARAMETER,
} from "../../../common/Constants";
import {
  clearSavePersonalInfoApiResponse,
  clearValidateAddressApiResponse,
  getAccountContactMinAge,
  getAccountUDFs,
  getCountries,
  getDefaultStates,
  getIdentityTypes,
  getMaClass,
  getStatesByCountry,
  getUseCreditScore,
  savePersonalInformation,
  validateAddress,
  getPosParameter,
  deleteSession,
  getAccountPinLengths,
} from "../../../config/redux/slices";
import { Branding } from "../../../config/branding/Branding";
import { getNextStep } from "../../../common/StepsUtilities";
import { CHECKOUT_STEPS } from "../../../components/header/Constants";
import { StyledModalMessage } from "../../../components/modals";
import Spinner from "../../../components/spinner/Spinner";
import { udfsNames } from "../Constants";
import { OSIcon } from "../../../components/icons";
import Tooltip from "../../../components/tooltip";
import ErrorHandler from "../../../components/error-handler";
import { getResponseMessage, TU_RESPONSE } from "../../../common/TransunionUtilities";

const NewCustomerForm = (props) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const { parentCallback } = props;

  const personalInformationData = useSelector((state) => state.personalInformation);
  const validateAddressInfo = personalInformationData.validateAddress;
  const [addressValidationMessage, setAddressValidationMessage] = useState();
  const [neuralCheckErrorMessage, setNeuralCheckErrorMessage] = useState();
  const [TUResponseCode, setTUResponseCode] = useState();
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  const accountPinLengths = useSelector(
    (state) => state.configuration.getAccountPinLengths.pinLengths
  );

  const [identityType, setIdentityType] = useState(
    personalInformationData.identityType ? personalInformationData.identityType : NONE_CODE
  );
  const [gender, setGender] = useState(
    personalInformationData.gender ? personalInformationData.gender : NONE_CODE
  );
  const [issuingCountry, setIssuingCountry] = useState(
    personalInformationData.issuingCountry
      ? personalInformationData.issuingCountry
      : Branding.DEFAULT_ISSUING_COUNTRY
  );
  const [issuingProvince, seIssuingProvince] = useState(
    personalInformationData.issuingProvince ? personalInformationData.issuingProvince : NONE_CODE
  );
  const [province, setProvince] = useState(
    personalInformationData.province ? personalInformationData.province : NONE_CODE
  );
  const [udfs, setUdfs] = useState(
    personalInformationData.udfs.length ? personalInformationData.udfs : []
  );
  const [agreeCreditScoreCheck, setAgreeCreditScoreCheck] = useState(
    personalInformationData.agreeCreditScoreCheck
  );

  const [postalCode, setPostalCode] = useState(personalInformationData.postalCode);
  const [address, setAddress] = useState(personalInformationData.address);
  const [city, setCity] = useState(personalInformationData.city);
  const [oldPostalCode, setOldPostalCode] = useState(personalInformationData.oldPostalCode);
  const [oldAddress, setOldAddress] = useState(personalInformationData.oldAddress);
  const [oldCity, setOldCity] = useState(personalInformationData.oldCity);
  const [oldProvince, setOldProvince] = useState(
    personalInformationData.oldProvince ? personalInformationData.oldProvince : NONE_CODE
  );
  const [oldAddressFlag, setOldAddressFlag] = useState(false);
  const [pin, setPin] = useState(personalInformationData.pin);

  const [formValidated, setFormValidated] = useState(false);
  const [errorAddressValidation, setErrorAddressValidation] = useState(true);

  const { register, handleSubmit, errors, control } = useForm({ mode: "onTouched" });
  const reactHookFormHandle = { register, errors };

  const useCreditScoreData = useSelector((state) => state.configuration.getUseCreditScore);
  const minAgeData = useSelector((state) => state.configuration.getAccountContactMinAge);
  const countriesData = useSelector((state) => state.countries);
  const statesData = useSelector((state) => state.states);
  const identityTypesData = useSelector((state) => state.identityTypes);
  const maClassData = useSelector((state) => state.maClass);
  const accountUDFsData = useSelector((state) => state.accountUDFs);
  const { planFlags } = useSelector((state) => state.items);
  const [personalInfoToBeSaved, setPersonalInfoToBeSaved] = useState();

  const noneItem = {
    description: intl.formatMessage({ id: "lbl.none" }),
    code: NONE_CODE,
  };
  const genderArray = [
    noneItem,
    {
      description: intl.formatMessage({ id: "lbl.male" }),
      code: "1",
    },
    {
      description: intl.formatMessage({ id: "lbl.female" }),
      code: "2",
    },
  ];

  const getFormattedSuggestedAddressValue = (isValueValid, value) => {
    return isValueValid ? value : <b>{value}</b>;
  };

  useEffect(() => {
    if (Branding?.creditScore.visible) {
      dispatch(getUseCreditScore());
    }

    dispatch(getAccountContactMinAge());
    dispatch(getMaClass());
    dispatch(getCountries());
    dispatch(getStatesByCountry(Branding.DEFAULT_ISSUING_COUNTRY));
    dispatch(getDefaultStates());
    dispatch(getIdentityTypes());
    dispatch(getAccountUDFs());
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
    dispatch(getAccountPinLengths());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStatesByCountry(issuingCountry));
  }, [dispatch, issuingCountry]);

  useEffect(() => {
    if (statesData.getDefaultStates.states?.length > 0 && province !== undefined) {
      //should pass for empty province
      let provinceFromList = statesData.getDefaultStates.states.find(
        (prov) => prov.iso === province
      );
      if (!provinceFromList) {
        provinceFromList = statesData.getDefaultStates.states.find(
          (prov) => prov.description.toUpperCase() === province.toUpperCase()
        );
        setProvince(provinceFromList ? provinceFromList.iso : "");
      }
    }
  }, [statesData, province]);

  useEffect(() => {
    if (!udfs.length) setUdfs(accountUDFsData.accountUDFs);
  }, [accountUDFsData, udfs.length]);

  useEffect(() => {
    const goToNextStep = async () => {
      if (personalInformationData.savePersonalInformation.success) {
        const tuResponse = personalInformationData.tuResponseCode;
        const neuralResponse = personalInformationData.neuralResponse;

        if (neuralResponse !== NEURAL_RESPONSE.SUCCESS) {
          setNeuralCheckErrorMessage(neuralResponse);
          return;
        }
        if (tuResponse === TU_RESPONSE.IDCHECK_REQUIRED) {
          history.replace("/validate-identity");
          return;
        }
        if (tuResponse !== TU_RESPONSE.SUCCESS) {
          setTUResponseCode(tuResponse);
          return;
        }
        dispatch(clearSavePersonalInfoApiResponse());
        history.replace(
          getNextStep(planFlags, CHECKOUT_STEPS.PERSONAL_INFORMATION, personalInformationData.flow)
            .route
        );
      }
    };
    goToNextStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, personalInformationData.savePersonalInformation.success, planFlags]);

  useEffect(() => {
    if (personalInformationData.validateAddress.success) {
      //exception received from address validation
      if (validateAddressInfo.response !== "") {
        setErrorAddressValidation(true);
        setAddressValidationMessage(
          <Text variant="subtitle1" mt={[2, 8]} ml={0} sx={{ whiteSpace: "normal" }}>
            {intl.formatMessage({ id: "lbl.address-validation-error-message" })}
            <OSLink
              url={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
              labelId="lbl.contact-us"
              variant="chat-link"
            />
            {intl.formatMessage({ id: "lbl.for-help" })}
          </Text>
        );
        dispatch(clearValidateAddressApiResponse());
        return;
      }

      const isValidAddress = validateAddressInfo.address.toUpperCase() === address.toUpperCase();
      const isValidCity = validateAddressInfo.city.toUpperCase() === city.toUpperCase();
      const isValidPostalCode =
        validateAddressInfo.postalCode.toUpperCase() === postalCode.toUpperCase();
      const isValidProvince = validateAddressInfo.province.toUpperCase() === province.toUpperCase();

      const suggestedAddressMessage = intl.formatMessage(
        {
          id: "lbl.suggested-address",
        },
        {
          address: getFormattedSuggestedAddressValue(isValidAddress, validateAddressInfo.address),
          city: getFormattedSuggestedAddressValue(isValidCity, validateAddressInfo.city),
          province: getFormattedSuggestedAddressValue(
            isValidProvince,
            validateAddressInfo.province
          ),
          pcode: getFormattedSuggestedAddressValue(
            isValidPostalCode,
            validateAddressInfo.postalCode
          ),
        }
      );

      if (!isValidAddress || !isValidCity || !isValidProvince || !isValidPostalCode) {
        setAddressValidationMessage(suggestedAddressMessage);
      } else {
        setAddressValidationMessage("");
        setFormValidated(true);
        dispatch(savePersonalInformation(personalInfoToBeSaved));
        dispatch(clearValidateAddressApiResponse());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, personalInformationData.validateAddress.success]);

  const onValidateAddress = (values) => {
    setAddress(values.address);
    setCity(values.city);
    setProvince(values.province);
    setPostalCode(values.postalCode);
    setAddressValidationMessage("");
    dispatch(clearValidateAddressApiResponse());
    let request = {
      address: values.address,
      city: values.city,
      province: values.province,
      postalCode: values.postalCode,
    };
    dispatch(validateAddress({ request }));
  };

  const onNext = (values) => {
    let udfsUpdated = udfs.map((elem) =>
      elem.name === udfsNames.ACCT_LANGUAGE
        ? { ...elem, value: personalInformationData.language }
        : elem
    );

    let requestForSave = {
      title: values.title,
      firstName: values.firstName.trim(),
      middleName: values.middleName.trim(),
      lastName: values.lastName.trim(),
      gender: values.gender,
      dobDate: dayjs(values.dobDate).format("YYYY-MM-DD"),
      identityType: identityType,
      identityNumber: values.identityNumber,
      issuingCountry: issuingCountry,
      issuingProvince: issuingProvince,
      secondIDType: values.secondIDType,
      secondIDNumber: values.secondIDNumber,
      sinNumber: values.sinNumber,
      phoneNumber: values.phoneNumber,
      alternatePhoneNumber: values.alternatePhoneNumber,
      emailAddress: values.emailAddress,
      address: values.address,
      city: values.city,
      province: province,
      postalCode: values.postalCode,
      regStreet: personalInformationData.regStreet,
      regCity: personalInformationData.regCity,
      regProvince: personalInformationData.regProvince,
      regPostalCode: personalInformationData.regPostalCode,
      language: personalInformationData.language,
      oldStreet: values.oldAddress,
      oldCity: values.oldCity,
      oldProvince: values.oldProvince,
      oldPostalCode: values.oldPostalCode,
      udfs: udfsUpdated,
      agreeCreditScoreCheck: true, // TODO: Check why this flag on true throws error
      flow: PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER,
      tuSessionId: window._TUSessionID_,
      tuDataRequest: personalInformationData.tuDataRequest,
      pin: pin,
    };

    if (!formValidated) {
      setPersonalInfoToBeSaved(requestForSave);
      onValidateAddress(values);
    } else {
      dispatch(savePersonalInformation(requestForSave));
      dispatch(clearValidateAddressApiResponse());
    }
  };

  const resetApiResponse = () => {
    resetNeuralCheckErrorMessage();
    dispatch(clearSavePersonalInfoApiResponse());
    dispatch(clearValidateAddressApiResponse());
  };

  const resetNeuralCheckErrorMessage = () => {
    setNeuralCheckErrorMessage("");
  };

  const resetOldAddressValues = (oldAddressFlag) => {
    setOldAddressFlag(oldAddressFlag);
    setOldAddress("");
    setOldCity("");
    setOldProvince("");
    setOldPostalCode("");
  };

  const acceptSuggestedAddress = () => {
    if (address.toUpperCase() !== validateAddressInfo.address.toUpperCase()) {
      setAddress(validateAddressInfo.address);
    }

    if (city.toUpperCase() !== validateAddressInfo.city.toUpperCase()) {
      setCity(validateAddressInfo.city);
    }

    if (province.toUpperCase() !== validateAddressInfo.province.toUpperCase()) {
      setProvince(validateAddressInfo.province);
    }

    if (postalCode.toUpperCase() !== validateAddressInfo.postalCode.toUpperCase()) {
      setPostalCode(validateAddressInfo.postalCode);
    }
  };

  const onInputChange = () => {
    setErrorAddressValidation(false);
    setAddressValidationMessage("");
    setAgreeCreditScoreCheck(false);
    setFormValidated(false);
    parentCallback(false);
  };

  const handleTUResponseFailure = () => {
    Storage.remove("session_id");
    dispatch(deleteSession());
  };

  const TUResponseMessage = TUResponseCode && getResponseMessage(TUResponseCode);

  return (
    <React.Fragment>
      <ErrorHandler
        error={personalInformationData.savePersonalInformation.error}
        clickHandler={resetApiResponse}
        closeHandler={resetApiResponse}
      />
      {TUResponseCode && (
        <StyledModalMessage
          isOpen={TUResponseCode !== TU_RESPONSE.SUCCESS}
          onRequestClose={handleTUResponseFailure}
          message={TUResponseMessage}>
          <Button variant="simple-action" onClick={handleTUResponseFailure}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </StyledModalMessage>
      )}
      {neuralCheckErrorMessage !== "" && neuralCheckErrorMessage === NEURAL_RESPONSE.ALERT && (
        <StyledModalMessage
          isOpen={neuralCheckErrorMessage !== ""}
          onRequestClose={resetNeuralCheckErrorMessage}
          message={<FormattedMessage id="lbl.alert-neural-check" />}>
          <Button variant="simple-action" onClick={resetNeuralCheckErrorMessage}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </StyledModalMessage>
      )}
      {neuralCheckErrorMessage !== "" &&
        (neuralCheckErrorMessage === NEURAL_RESPONSE.TIMEOUT ||
          neuralCheckErrorMessage === NEURAL_RESPONSE.VALIDATIONS) && (
          <StyledModalMessage
            isOpen={neuralCheckErrorMessage !== ""}
            onRequestClose={resetApiResponse}
            message={<FormattedMessage id="lbl.help-neural-check-chat" />}>
            <Button variant="simple-action" onClick={resetApiResponse}>
              <FormattedMessage id="btn.ok" />
            </Button>
          </StyledModalMessage>
        )}

      <Spinner
        isOpen={
          personalInformationData.savePersonalInformation.loading ||
          personalInformationData.validateAddress.loading
        }
      />
      <form id="newCustomerForm" onSubmit={handleSubmit(onNext)}>
        <OSSection name="personalDetails">
          <Heading3 color="primary" my={5}>
            <FormattedMessage id="header.personal-information" />
          </Heading3>
          <Box
            sx={{
              display: "grid",
              gridGap: [2, 2, 2],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              section="personalDetails"
              label={intl.formatMessage({ id: "lbl.title" })}
              defaultValue={personalInformationData.title}
              register={register}
              errors={errors}
              maxLength="100"
              name="title"
              type="text"
            />
            <FormInput
              section="personalDetails"
              isMandatory
              label={intl.formatMessage({ id: "lbl.first-name" })}
              defaultValue={personalInformationData.firstName}
              {...reactHookFormHandle}
              maxLength="100"
              name="firstName"
              type="text"
              validations={{
                validate: {
                  required: (value) => value.trim().length !== 0,
                },
              }}
              onChange={onInputChange}
            />
            <FormInput
              section="personalDetails"
              label={intl.formatMessage({ id: "lbl.middle-name" })}
              defaultValue={personalInformationData.middleName}
              {...reactHookFormHandle}
              maxLength="60"
              name="middleName"
              type="text"
            />
            <FormInput
              section="personalDetails"
              isMandatory
              label={intl.formatMessage({ id: "lbl.last-name" })}
              defaultValue={personalInformationData.lastName}
              {...reactHookFormHandle}
              maxLength="100"
              name="lastName"
              type="text"
              validations={{
                validate: {
                  required: (value) => value.trim().length !== 0,
                },
              }}
              onChange={onInputChange}
            />
            <FormInput
              defaultValue={gender}
              control={control}
              section="personalDetails"
              type="select"
              label={intl.formatMessage({ id: "lbl.gender" })}
              name="gender"
              {...reactHookFormHandle}
              onChange={(event) => {
                setGender(event.target.value);
              }}
              errors={errors}>
              {genderArray.map((gender) => (
                <option key={gender.code} value={gender.code}>
                  {gender.description}
                </option>
              ))}
            </FormInput>

            <FormInput
              control={control}
              section="personalDetails"
              isMandatory
              onInputChange={onInputChange}
              label={intl.formatMessage({ id: "lbl.date-of-birth" })}
              defaultValue={
                personalInformationData.dobDate ? new Date(personalInformationData.dobDate) : null
              }
              {...reactHookFormHandle}
              validations={{
                validate: {
                  validateDob: (value) => {
                    if (!value) return true;
                    let minAge = minAgeData.minAge ? minAgeData.minAge : 18;
                    let dob = dayjs(value);
                    if (dayjs().diff(dob, "year") < minAge) {
                      return intl.formatMessage(
                        { id: "err.birthday_validation" },
                        { minYears: minAge }
                      );
                    }
                    return true;
                  },
                },
              }}
              id="dobDate"
              name="dobDate"
              type="date"
            />

            <FormInput
              disabled
              section="personalDetails"
              label={intl.formatMessage({ id: "lbl.language" })}
              defaultValue={intl.formatMessage({ id: "lbl.input-language" })}
              {...reactHookFormHandle}
              name="language"
              type="text"
            />
          </Box>
        </OSSection>

        <OSSection name="selfcareCredentials">
          <Heading3 color="primary" my={5}>
            <Flex>
              <FormattedMessage id="header.self-care-pin-credentials" />
              <Tooltip
                ml="1rem"
                position="right"
                value={intl.formatMessage({ id: "lbl.self-care_hint" })}
                sx={{ fontWeight: "normal" }}>
                <OSIcon name="tooltip-info" />
              </Tooltip>
            </Flex>
          </Heading3>
          <Box
            sx={{
              display: "grid",
              gridGap: [2, 2, 2],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              section="selfcareCredentials"
              isMandatory
              label={intl.formatMessage({ id: "lbl.create-pin" })}
              defaultValue={personalInformationData.pin}
              {...reactHookFormHandle}
              maxLength={accountPinLengths.maxLength}
              name="pin"
              type="password"
              onChange={(event) => setPin(event.target.value)}
              validations={{
                pattern: {
                  value: /^[0-9]*$/,
                  message: intl.formatMessage(
                    { id: "err.pin-digits" },
                    { pinLength: accountPinLengths.minLength }
                  ),
                },
                validate: {
                  pinLength: (value) => {
                    return (
                      (value.length < accountPinLengths.minLength &&
                        intl.formatMessage(
                          { id: "err.pin-digits" },
                          { pinLength: accountPinLengths.minLength }
                        )) ||
                      true
                    );
                  },
                },
              }}
            />
            <FormInput
              section="selfcareCredentials"
              isMandatory
              label={intl.formatMessage({ id: "lbl.confirmation-pin" })}
              {...reactHookFormHandle}
              maxLength={accountPinLengths.maxLength}
              name="confirmationPin"
              type="password"
              validations={{
                validate: {
                  confirmPin: (value) => {
                    return (
                      (value !== pin && intl.formatMessage({ id: "err.pin-confirm-pin" })) || true
                    );
                  },
                },
              }}
            />
          </Box>
        </OSSection>

        <OSSection name="governmentID">
          <Heading3 color="primary" my={5}>
            <FormattedMessage id="lbl.government-id" />
          </Heading3>
          <Box
            sx={{
              display: "grid",
              gridGap: [2, 2, 2],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              defaultValue={NONE_CODE}
              control={control}
              section="governmentID"
              type="select"
              isMandatory
              label={intl.formatMessage({ id: "lbl.identity-type" })}
              name="identityType"
              {...reactHookFormHandle}
              validations={{
                validate: {
                  required: (value) => value !== NONE_CODE,
                },
              }}
              onChange={(event) => {
                setIdentityType(event.target.value);
                onInputChange();
              }}>
              {[{ code: NONE_CODE, description: intl.formatMessage({ id: "lbl.none" }) }]
                .concat(identityTypesData.identityTypes)
                .map((idType) => (
                  <option key={idType.code} value={idType.code}>
                    {idType.description}
                  </option>
                ))}
            </FormInput>
            <FormInput
              section="governmentID"
              isMandatory
              label={intl.formatMessage({ id: "lbl.identity-number" })}
              defaultValue={personalInformationData.identityNumber}
              {...reactHookFormHandle}
              validations={{
                pattern: {
                  value: /^([a-zA-Z0-9]{5,20})?$/i,
                  message: intl.formatMessage({ id: "err.identity-number-pattern" }),
                },
              }}
              maxLength="20"
              name="identityNumber"
              type="text"
              onChange={onInputChange}
            />
            <FormInput
              defaultValue={NONE_CODE}
              control={control}
              section="governmentID"
              type="select"
              label={intl.formatMessage({ id: "lbl.issuing-country" })}
              name="issuingCountry"
              {...reactHookFormHandle}
              onChange={(event) => {
                setIssuingCountry(event.target.value);
                seIssuingProvince(NONE_CODE);
              }}>
              {[{ name: intl.formatMessage({ id: "lbl.none" }), iso2Code: NONE_CODE }]
                .concat(countriesData.countries)
                .map((country) => (
                  <option key={country.iso2Code} value={country.iso2Code}>
                    {country.name}
                  </option>
                ))}
            </FormInput>

            <FormInput
              defaultValue={NONE_CODE}
              control={control}
              section="governmentID"
              type="select"
              label={intl.formatMessage({ id: "lbl.issuing-province" })}
              name="issuingProvince"
              {...reactHookFormHandle}
              onChange={(event) => {
                seIssuingProvince(event.target.value);
              }}>
              {[
                {
                  countryISO2: NONE_CODE,
                  iso: NONE_CODE,
                  description: intl.formatMessage({ id: "lbl.none" }),
                },
              ]
                .concat(statesData.getStatesByCountry.states)
                .map((state) => (
                  <option key={state.iso} value={state.iso}>
                    {state.iso === NONE_CODE ? state.description : state.iso}
                  </option>
                ))}
            </FormInput>
            <FormInput
              section="governmentID"
              label={intl.formatMessage({ id: "lbl.second-id-type" })}
              defaultValue={personalInformationData.secondIDType}
              {...reactHookFormHandle}
              maxLength="50"
              name="secondIDType"
              type="text"
            />
            <FormInput
              section="governmentID"
              label={intl.formatMessage({ id: "lbl.second-id-number" })}
              defaultValue={personalInformationData.secondIDNumber}
              {...reactHookFormHandle}
              maxLength="30"
              name="secondIDNumber"
              type="text"
            />
            <FormInput
              section="governmentID"
              isMandatory={maClassData.class.identificationNumberMandatory}
              wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 3] } }}
              label={intl.formatMessage({ id: "lbl.sin-number" })}
              defaultValue={personalInformationData.sinNumber}
              {...reactHookFormHandle}
              validations={{
                pattern: {
                  value: /^[0-9]{9}$/i,
                  message: intl.formatMessage({ id: "err.sin-invalid" }),
                },
              }}
              maxLength="9"
              name="sinNumber"
              type="text"
            />
          </Box>
        </OSSection>
        <OSSection name="contactDetails">
          <Heading3 color="primary" my={5}>
            <FormattedMessage id="lbl.contact-details" />
          </Heading3>
          <Box
            sx={{
              display: "grid",
              gridGap: [2, 2, 2],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              section="contactDetails"
              isMandatory
              label={intl.formatMessage({ id: "lbl.primary-number" })}
              defaultValue={personalInformationData.phoneNumber}
              {...reactHookFormHandle}
              maxLength="20"
              name="phoneNumber"
              type="text"
              onChange={onInputChange}
            />

            <FormInput
              section="contactDetails"
              label={intl.formatMessage({ id: "lbl.alternate-phone-number" })}
              defaultValue={personalInformationData.alternatePhoneNumber}
              {...reactHookFormHandle}
              maxLength="20"
              name="alternatePhoneNumber"
              type="text"
            />

            <FormInput
              isMandatory
              label={intl.formatMessage({ id: "lbl.email-address" })}
              defaultValue={personalInformationData.emailAddress}
              {...reactHookFormHandle}
              validations={{
                pattern: {
                  value: /^([A-Za-z0-9._%-]+)@([A-Za-z0-9.-]+\.[a-zA-Z]{2,})$/i,
                  message: intl.formatMessage({ id: "err.email-pattern-invalid" }),
                },
              }}
              maxLength="80"
              name="emailAddress"
              type="text"
              onChange={onInputChange}
            />
          </Box>

          <Box
            mt={2}
            sx={{
              display: "grid",
              gridGap: [2, 2, 2],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            <FormInput
              key={"address" + address}
              section="contactDetails"
              wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
              isMandatory
              label={intl.formatMessage({ id: "lbl.home-address" })}
              defaultValue={address}
              onChange={() => {
                onInputChange();
              }}
              {...reactHookFormHandle}
              maxLength="100"
              name="address"
              type="text"
            />

            <FormInput
              key={"city" + city}
              section="contactDetails"
              isMandatory
              label={intl.formatMessage({ id: "lbl.city" })}
              defaultValue={city}
              onChange={() => {
                onInputChange();
              }}
              {...reactHookFormHandle}
              maxLength="70"
              name="city"
              type="text"
            />

            <FormInput
              key={"province" + province}
              defaultValue={province}
              control={control}
              section="contactDetails"
              isMandatory
              type="select"
              label={intl.formatMessage({ id: "lbl.province" })}
              name="province"
              {...reactHookFormHandle}
              validations={{
                validate: {
                  required: (value) => value !== NONE_CODE,
                },
              }}
              onChange={(event) => {
                setProvince(event.target.value);
                onInputChange();
              }}>
              {[
                {
                  countryISO2: NONE_CODE,
                  iso: NONE_CODE,
                  description: intl.formatMessage({ id: "lbl.none" }),
                },
              ]
                .concat(statesData.getDefaultStates.states)
                .map((state) => (
                  <option key={state.iso} value={state.iso}>
                    {state.iso === NONE_CODE ? state.description : state.iso}
                  </option>
                ))}
            </FormInput>

            <FormInput
              key={"pc" + postalCode}
              section="contactDetails"
              isMandatory
              label={intl.formatMessage({ id: "lbl.postal-code" })}
              defaultValue={postalCode}
              onChange={() => {
                onInputChange();
              }}
              {...reactHookFormHandle}
              maxLength="10"
              name="postalCode"
              type="text"
            />
          </Box>

          {/* Address validation message */}
          {addressValidationMessage && addressValidationMessage !== "" && (
            <Box
              mt={8}
              px={8}
              py={4}
              sx={{
                display: "inline-grid",
                gridGap: [2, 2, 8],
                gridTemplateColumns: ["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(8, 1fr)"],
                backgroundColor: "windowBg",
                borderRadius: "xl",
              }}>
              <Box
                sx={{ gridColumnStart: 1, gridColumnEnd: [1, 1, errorAddressValidation ? 7 : 5] }}>
                <Flex sx={{ alignItems: "center" }}>
                  <OSIcon display={["none", "none", "inline"]} name="dialog-bubble" mr={4} />
                  <Box>
                    {!errorAddressValidation && (
                      <Text variant="attribute" color="secondary" sx={{ lineHeight: "relaxed" }}>
                        {intl.formatMessage({ id: "lbl.suggested-address-message" })}
                      </Text>
                    )}
                    {errorAddressValidation ? (
                      <React.Fragment>{addressValidationMessage}</React.Fragment>
                    ) : (
                      <Box>
                        <Text variant="attribute" color="primary" sx={{ lineHeight: "relaxed" }}>
                          {addressValidationMessage}
                        </Text>
                      </Box>
                    )}
                  </Box>
                </Flex>
              </Box>
              {!errorAddressValidation && (
                <Button
                  variant="address-validation-action"
                  onClick={() => {
                    acceptSuggestedAddress();
                    setAddressValidationMessage("");
                    setFormValidated(true);
                  }}
                  sx={{
                    color: "secondary",
                    alignSelf: "center",
                    gridColumnStart: [1, 1, 5],
                    gridColumnEnd: [1, 1, 7],
                  }}>
                  {intl.formatMessage({
                    id: "btn.select-suggested",
                  })}
                </Button>
              )}
              <Button
                variant="address-validation-action"
                onClick={() => {
                  setErrorAddressValidation(false);
                  setAddressValidationMessage("");
                  setFormValidated(true);
                }}
                sx={{
                  color: "button.disabledColor",
                  alignSelf: "center",
                  gridColumnStart: [1, 1, 7],
                  gridColumnEnd: [1, 1, 9],
                }}>
                {intl.formatMessage({
                  id: "btn.keep-my-own",
                })}
              </Button>
            </Box>
          )}
        </OSSection>

        {accountUDFsData.loading && udfs.length <= 0 && (
          <OSSection name="miscInfo">
            <Heading3 color="primary" my={5}>
              <FormattedMessage id="lbl.miscellaneous-information" />
            </Heading3>
            <Box
              sx={{
                display: "grid",
                gridGap: [2, 2, 2],
                gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(4, 1fr)"],
                gridAutoRows: "min-content",
              }}>
              <FormInputSkeleton />
              <FormInputSkeleton />
            </Box>
          </OSSection>
        )}
        {udfs.length > 0 && (
          <OSSection name="miscInfo">
            <Heading3 my={5}>
              <FormattedMessage id="lbl.miscellaneous-information" />
            </Heading3>
            <UserDefinedFields
              udfs={udfs}
              updateUDFs={setUdfs}
              reactHookFormHandle={reactHookFormHandle}
            />
          </OSSection>
        )}
        <OSSection>
          <Box
            sx={{
              display: "grid",
              gridGap: [2, 2, 1],
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(2, auto)", "repeat(2 auto)"],
              gridAutoRows: "min-content",
              alignItems: "flex-end",
            }}>
            <Text
              variant="heading3"
              mt={5}
              sx={{
                color: "primary",
                lineHeight: "relaxed",
              }}>
              {intl.formatMessage({
                id: "lbl.address-message",
              })}
              <Text color="error">*</Text>
            </Text>
            <FormInput
              defaultValue={NONE_CODE}
              control={control}
              section="contactDetails"
              type="select"
              name="confirmAddress"
              {...reactHookFormHandle}
              validations={{
                validate: {
                  unselectedValue: (value) => value !== NONE_CODE,
                },
              }}
              onChange={(event) => {
                resetOldAddressValues(
                  event.target.value === "true" && event.target.value !== NONE_CODE
                );
              }}>
              {[
                { code: NONE_CODE, description: intl.formatMessage({ id: "lbl.select-answer" }) },
                { code: false, description: intl.formatMessage({ id: "lbl.btn-yes" }) },
                { code: true, description: intl.formatMessage({ id: "lbl.btn-no" }) },
              ].map((idType) => (
                <option key={idType.code} value={idType.code}>
                  {idType.description}
                </option>
              ))}
            </FormInput>
          </Box>

          {oldAddressFlag && (
            <Box
              mt={2}
              sx={{
                display: "grid",
                gridGap: [2, 2, 2],
                gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(6, 1fr)"],
                gridAutoRows: "min-content",
              }}>
              <FormInput
                key={"oldAddress" + oldAddress}
                section="contactDetails"
                wrapperProps={{ sx: { gridColumnStart: 1, gridColumnEnd: [1, 3, 4] } }}
                isMandatory={oldAddressFlag}
                label={intl.formatMessage({ id: "lbl.previous-home-address" })}
                defaultValue={oldAddress}
                {...reactHookFormHandle}
                maxLength="100"
                name="oldAddress"
                type="text"
              />

              <FormInput
                key={"oldCity" + oldCity}
                section="contactDetails"
                isMandatory={oldAddressFlag}
                label={intl.formatMessage({ id: "lbl.city" })}
                defaultValue={oldCity}
                {...reactHookFormHandle}
                maxLength="70"
                name="oldCity"
                type="text"
              />

              <FormInput
                defaultValue={oldProvince}
                control={control}
                section="contactDetails"
                isMandatory={oldAddressFlag}
                type="select"
                label={intl.formatMessage({ id: "lbl.province" })}
                name="oldProvince"
                {...reactHookFormHandle}
                validations={{
                  validate: {
                    required: (value) => value !== NONE_CODE,
                  },
                }}
                onChange={(event) => {
                  setOldProvince(event.target.value);
                  onInputChange();
                }}>
                {[
                  {
                    countryISO2: NONE_CODE,
                    iso: NONE_CODE,
                    description: intl.formatMessage({ id: "lbl.none" }),
                  },
                ]
                  .concat(statesData.getDefaultStates.states)
                  .map((state) => (
                    <option key={state.iso} value={state.iso}>
                      {state.iso === NONE_CODE ? state.description : state.iso}
                    </option>
                  ))}
              </FormInput>

              <FormInput
                key={"oldPostalCode" + oldPostalCode}
                section="contactDetails"
                isMandatory={oldAddressFlag}
                label={intl.formatMessage({ id: "lbl.postal-code" })}
                defaultValue={oldPostalCode}
                {...reactHookFormHandle}
                maxLength="10"
                name="oldPostalCode"
                type="text"
              />
            </Box>
          )}
        </OSSection>
        {!useCreditScoreData.loading && useCreditScoreData.useCreditScore && (
          <OSSection name="creditScore">
            <Flex
              sx={{
                width: ["100%", "100%", "100%"],
                flexDirection: ["column", "column", "column"],
                alignItems: "flex-start",
                pl: 5,
                marginTop: 3,
              }}>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-message1" />
              </Text>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-message2" />
              </Text>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-message3" />
              </Text>
            </Flex>
            <Flex sx={{ flexDirection: "column" }} mt={5} pl={5}>
              <Label>
                <OSCheckbox
                  pr={8}
                  checked={agreeCreditScoreCheck}
                  onChange={() => {
                    parentCallback(!agreeCreditScoreCheck);
                    setAgreeCreditScoreCheck(!agreeCreditScoreCheck);
                  }}
                />

                <Text
                  color="primary"
                  sx={{
                    wordWrap: "break-word",
                    lineHeight: "relaxed",
                    fontWeight: "bold",
                  }}>
                  {/* to do split into 2 strings, indent the parts */}
                  <FormattedMessage id="lbl.personal-information-checkbox-message" />
                </Text>
              </Label>
            </Flex>
            <Flex
              sx={{
                width: ["100%", "100%", "100%"],
                flexDirection: ["column", "column", "column"],
                alignItems: "flex-start",
                pl: 16,
              }}>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-checkbox-message1" />
              </Text>
            </Flex>
            <Flex
              sx={{
                width: ["100%", "100%", "100%"],
                flexDirection: ["column", "column", "column"],
                alignItems: "flex-start",
                pl: 16,
              }}>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-checkbox-message2" />
              </Text>
            </Flex>
            <Flex
              sx={{
                width: ["100%", "100%", "100%"],
                flexDirection: ["column", "column", "column"],
                alignItems: "flex-start",
                pl: 5,
                marginTop: 3,
              }}>
              <Text fontSize="xs" color="primary">
                <FormattedMessage id="lbl.personal-information-message-imp" />
              </Text>
            </Flex>
          </OSSection>
        )}
      </form>
    </React.Fragment>
  );
};

export default NewCustomerForm;
