import React from "react";
import { Box } from "@theme-ui/components";

const ShowMoreIcon = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(-1464.813 -761.119)">
      <path
        d="M48.6,41.77,43.3,22.1A3.319,3.319,0,0,0,40.1,19.645H8.754A3.319,3.319,0,0,0,5.549,22.1L.257,41.77a3.319,3.319,0,0,0,3.206,4.182H45.39A3.319,3.319,0,0,0,48.6,41.77Z"
        transform="translate(1468.034 765.266)"
        fill="none"
        stroke="#332a86"
        strokeWidth="2"
      />
      <line
        x2="20"
        transform="translate(1469.313 803.618)"
        fill="#fff"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        x2="16"
        transform="translate(1495.313 790.618)"
        fill="#fff"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        y2="26.306"
        transform="translate(1492.413 784.911)"
        fill="none"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        x1="0.162"
        y2="4.599"
        transform="translate(1479.151 806.619)"
        fill="none"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <line
        x2="2.861"
        y2="17.6"
        transform="translate(1502.313 793.619)"
        fill="none"
        stroke="#332a86"
        strokeLinecap="round"
        strokeWidth="2"
        opacity="0.4"
      />
      <path
        d="M0,11.97A11.976,11.976,0,0,1,12.234,0a12.221,12.221,0,0,1,11.7,11.967,11.515,11.515,0,0,1-1.6,5.983L14.361,33.375a2.749,2.749,0,0,1-4.786,0L1.6,17.952A11.182,11.182,0,0,1,0,11.97Z"
        transform="translate(1467.231 766.036)"
        fill="#fff"
        stroke="#332a86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M0,7.41A7.414,7.414,0,0,1,7.573,0,7.566,7.566,0,0,1,14.816,7.41a7.129,7.129,0,0,1-.988,3.7L8.89,20.661a1.7,1.7,0,0,1-2.963,0L.989,11.114A6.922,6.922,0,0,1,0,7.41Z"
        transform="translate(1494.904 766.036)"
        fill="#fff"
        stroke="#332a86"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g transform="translate(1475.165 773.17)" fill="none" opacity="0.4">
        <path
          d="M4.149,8.3A4.149,4.149,0,1,1,8.3,4.149,4.149,4.149,0,0,1,4.149,8.3Z"
          stroke="none"
        />
        <path
          d="M 4.147208690643311 6.297674179077148 C 5.331691265106201 6.295961856842041 6.296315670013428 5.331099033355713 6.297675609588623 4.1488356590271 C 6.297675609588623 2.963965654373169 5.333705425262451 1.999995708465576 4.1488356590271 1.999995708465576 C 2.963965654373169 1.999995708465576 1.999995708465576 2.963965654373169 1.999995708465576 4.1488356590271 C 1.999995708465576 5.333127021789551 2.963133096694946 6.296733379364014 4.147208690643311 6.297674179077148 M 4.1488356590271 8.297676086425781 C 1.857505679130554 8.297676086425781 -4.340972736827098e-06 6.440165519714355 -4.340972736827098e-06 4.1488356590271 C -4.340972736827098e-06 1.857505679130554 1.857505679130554 -4.340972736827098e-06 4.1488356590271 -4.340972736827098e-06 C 6.440165519714355 -4.340972736827098e-06 8.297676086425781 1.857505679130554 8.297676086425781 4.1488356590271 C 8.295045852661133 6.439125537872314 6.439125537872314 8.295045852661133 4.1488356590271 8.297676086425781 Z"
          stroke="none"
          fill="#332a86"
        />
      </g>
      <g transform="translate(1500.428 771.728)" fill="none" opacity="0.4">
        <path
          d="M1.884,3.769A1.884,1.884,0,1,1,3.769,1.884,1.884,1.884,0,0,1,1.884,3.769Z"
          stroke="none"
        />
        <path
          d="M 1.88433837890625 3.768678426742554 C 0.843648374080658 3.768678426742554 -1.6210937019423e-06 2.925028324127197 -1.6210937019423e-06 1.88433837890625 C -1.6210937019423e-06 0.843648374080658 0.843648374080658 -1.6210937019423e-06 1.88433837890625 -1.6210937019423e-06 C 2.925028324127197 -1.6210937019423e-06 3.768678426742554 0.843648374080658 3.768678426742554 1.88433837890625 C 3.767478466033936 2.924548387527466 2.924548387527466 3.767478466033936 1.88433837890625 3.768678426742554 Z"
          stroke="none"
          fill="#332a86"
        />
      </g>
    </g>
  </Box>
);

export default ShowMoreIcon;
