import React from "react";
import { Box } from "@theme-ui/components";

const Plus = ({ color = "#0c3865", size = "iconTiny", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <path
      fill={color}
      d="M-5424-2011v-6h-6a2 2 0 0 1-2-2 2 2 0 0 1 2-2h6v-6a2 2 0 0 1 2-2 2 2 0 0 1 2 2v6h6a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-6v6a2 2 0 0 1-2 2 2 2 0 0 1-2-2z"
      transform="translate(5432 2029)"
    />
  </Box>
);

export default Plus;
