import React from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { OSIcon } from "../../../../components/icons";

const Store = ({ data, selected, existsBundleInCart, onSelect, id, className, ...props }) => {
  const intl = useIntl();

  const disableSelection =
    (existsBundleInCart && !data.resourcesAvailable) ||
    (!existsBundleInCart && data.allAccessoriesOutOfStock);
  const outOfStockMessage = !data.resourcesAvailable
    ? "lbl.phone-out-of-stock"
    : data.allAccessoriesOutOfStock
    ? "lbl.all-accessories-out-of-stock"
    : data.accessoriesOutOfStock && Object.keys(data.accessoriesOutOfStock).length > 0
    ? "lbl.some-accessories-out-of-stock"
    : "";
  const textColor = disableSelection
    ? "disabledCardTextColor"
    : selected
    ? "selectedCardTextColor"
    : "ternary";

  return (
    <Flex
      id={id}
      className={className}
      bg={disableSelection ? "cardBgDisabled" : selected ? "selectedCardBg" : "cardBg"}
      onClick={!disableSelection ? onSelect : undefined}
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        maxWidth: ["100%", "48%", "48%", "32%"],
        minWidth: ["auto", "48%", "48%", "15rem"],
        width: "100%",
        height: "12.5rem",
        border: 1,
        borderColor: selected && !disableSelection ? "secondary" : "#e2e2e2",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: disableSelection ? "default" : "pointer",
        ".store-secondary": {
          color: selected ? "windowBg" : "quaternary",
        },
        "&:hover": !selected &&
          !disableSelection && {
            borderColor: "cardBorderHoverColor",
          },
      }}
      {...props}>
      <Flex
        id={id}
        className={className}
        p={5}
        pb={disableSelection ? 0 : 6.5}
        sx={{ flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <Text variant="heading5" my={0} color={textColor} sx={{ whiteSpace: "normal" }}>
          {data.name}
        </Text>
        <Flex id={id} className={className} sx={{ alignItems: "center" }}>
          <OSIcon name={disableSelection ? "location-pin-disabled" : "location-pin"} mr={2} />
          <Text variant="summaryLabel" color={textColor} mr={0} py={0}>
            {data.address}
          </Text>
        </Flex>
        <Flex id={id} className={className} sx={{ alignItems: "center" }}>
          <OSIcon
            name={disableSelection ? "location-pin-distance-disabled" : "location-pin-distance"}
            mr={2}
          />
          {data.distance >= 0 && (
            <Text variant="summaryLabel" color={textColor} mr={0} py={0}>
              {intl.formatMessage({ id: "btn.store-distance" }, { distance: data.distance })}
            </Text>
          )}
        </Flex>
        <Flex id={id} className={className} sx={{ alignItems: "center" }}>
          <OSIcon name={disableSelection ? "phone-number-disabled" : "phone-number"} mr={2} />
          <Text variant="summaryLabel" color={textColor} mr={0} py={0}>
            {data.phoneNumber}
          </Text>
        </Flex>
      </Flex>

      {outOfStockMessage && outOfStockMessage !== "" && (
        <Flex
          id={id}
          className={className}
          pb={1.5}
          pr={2}
          sx={{
            position: "relative",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
          }}>
          <Text
            variant="summaryValue"
            py={0}
            mr={2}
            color="disabledCardTextColor"
            sx={{ textAlign: "right" }}>
            {intl.formatMessage({ id: outOfStockMessage })}
          </Text>
          <OSIcon name="out-of-stock" />
        </Flex>
      )}
    </Flex>
  );
};

export default Store;
