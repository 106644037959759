import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import Storage from "store2";

import Layout from "../../components/layout";
import {
  getSession,
  loadPersonalInformation,
  clearGetSessionResponse,
} from "../../config/redux/slices";
import { canLogin, PERSONAL_INFORMATION_FLOW, SESSION_STATUS } from "../../common/Constants";
import Spinner from "../../components/spinner/Spinner";

const Start = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const sessionData = useSelector((state) => state.session);
  const { loggedIn } = useSelector((state) => state.auth);
  const { flow, loaded } = useSelector((state) => state.personalInformation);

  useEffect(() => {
    Storage.session.clear();
    Storage.remove("tabindex");
    let { sessionId } = qs.parse(location.search, { ignoreQueryPrefix: true });
    dispatch(getSession({ sessionId }));
  }, [dispatch, location.search]);

  useEffect(() => {
    // we need it in order to know if we should jump directly to a further step or not
    if (!loaded && sessionData.getSession.success) {
      dispatch(loadPersonalInformation());
      Storage.session.set("kwExtraData", { path: "/", cartId: sessionData.externalCartId });
    }
  }, [dispatch, loaded, sessionData]);

  useEffect(() => {
    if (sessionData.getSession.success && loaded) {
      dispatch(clearGetSessionResponse());
      if (sessionData.status === SESSION_STATUS.FINISHED) {
        history.replace("/error");
        return;
      }

      // no need to jump to a forward step because we'll be redirected back to personal information,
      // hence we should just stay in personal information
      if (!flow || (canLogin(flow) && !loggedIn)) {
        history.replace("/personal-information");
        return;
      }

      switch (sessionData.status) {
        case SESSION_STATUS.CREATED:
          console.log("session started, redirecting to personal info");
          history.replace("/personal-information");
          break;
        case SESSION_STATUS.VALIDATE_IDENTITY:
          console.log("session started, redirecting to validate identity");
          history.replace("/validate-identity");
          break;
        case SESSION_STATUS.VALIDATE_PIN:
          console.log("session started, redirecting to validate pin");
          history.replace("/validate-pin");
          break;
        case SESSION_STATUS.VALIDATED_CUSTOMER:
          if (flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE) {
            console.log("session started, redirecting to device upgrade");
            history.replace("/update-device");
            break;
          } else {
            console.log("session started, redirecting to device and plan");
            history.replace("/device-and-plan");
            break;
          }
        case SESSION_STATUS.FINALIZED_BUNDLE:
          console.log("session started, redirecting to shipping details");
          history.replace("/shipping-details");
          break;

        case SESSION_STATUS.COLLECTED_SHIPPING:
          console.log("session started, redirecting to summary");
          history.replace("/summary");
          break;

        default:
          history.push("/error");
      }
    } else if (sessionData.getSession.error) {
      history.push("/error");
    }
  }, [
    dispatch,
    history,
    sessionData.getSession.success,
    sessionData.getSession.error,
    sessionData.status,
    loggedIn,
    flow,
    loaded,
  ]);

  console.log(JSON.stringify(sessionData));

  return (
    <Layout showSteps={false} showCart={false} loadSession={false}>
      <Spinner isOpen={!loaded} />
    </Layout>
  );
};

export default Start;
