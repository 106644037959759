import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  // API Responses
  initiateHostedCcOperation: {
    loading: false,
    success: false,
    error: null,
    ipPayPaymentRequest: null,
  },
};

export const initiateHostedCcOperation = createAsyncThunk(
  "ippay/initiateHostedCcOperation",
  async (request, { rejectWithValue }) => {
    try {
      return await FacadeAPI.GET(`/ippay/initiateHostedCcOperation`, request, { noTimeout: true });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const ippaySlice = createSlice({
  name: "ippay",
  initialState: INITIAL_STATE,
  reducers: {
    clearIpPayInitiateHostedCCResponse(state) {
      return {
        ...state,
        initiateHostedCcOperation: INITIAL_STATE.initiateHostedCcOperation,
      };
    },
  },
  extraReducers: {
    [initiateHostedCcOperation.pending]: (state) => {
      state.initiateHostedCcOperation.loading = true;
      state.initiateHostedCcOperation.success = false;
      state.initiateHostedCcOperation.error = null;
      state.initiateHostedCcOperation.ipPayPaymentRequest = null;
    },
    [initiateHostedCcOperation.fulfilled]: (state, action) => {
      state.initiateHostedCcOperation.loading = false;
      state.initiateHostedCcOperation.success = true;
      state.initiateHostedCcOperation.ipPayPaymentRequest = action.payload;
    },
    [initiateHostedCcOperation.rejected]: (state, action) => {
      state.initiateHostedCcOperation.loading = false;
      state.initiateHostedCcOperation.success = false;
      state.initiateHostedCcOperation.error = action.payload;
    },
  },
});
export const { clearIpPayInitiateHostedCCResponse } = ippaySlice.actions;
export default ippaySlice.reducer;
