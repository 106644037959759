import React from "react";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "@theme-ui/core";
import { CacheProvider, Global } from "@emotion/react";
import createCache from "@emotion/cache";
import { ModalProvider } from "./components/modals";
import OSRouter from "./config/router";
import { OSIntlProvider } from "./contexts/OSIntlContext";
import store from "./config/redux/store";

const cache = createCache({ key: "checkout", nonce: "randomsecret123456" });

function BrandedContent() {
  const brandingData = useSelector((state) => state.branding.data);
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={brandingData.theme}>
        <ModalProvider>
          <OSRouter />
          <Global
            styles={() => ({
              body: {
                // needed in order to remove background content shifting when modals are opened in a page with scroll
                overflowY: "visible !important",
                margin: 0,
                fontFamily: "'Work Sans', sans-serif",
                fontFeatureSettings: "'lnum'",
                WebkitFontSmoothing: "antialiased",
                MozOsxFontSmoothing: "grayscale",
              },
              "@media screen and (max-width: 320px)": {
                html: {
                  fontSize: `calc(10px * ${window.devicePixelRatio})`,
                },
              },
              "@media screen and (min-width: 320.02px) and (max-width: 1919.98px)": {
                html: {
                  fontSize: `calc(10px + (16 - 10) * ((100vw - 320px) / (1920 - 320)) * ${window.devicePixelRatio})`,
                },
              },
              "@media screen and (min-width: 1920px)": {
                html: {
                  fontSize: `calc(16px * ${window.devicePixelRatio})`,
                },
              },

              /* iOS Safari - fix for issues with zooming form controls when font-size is < 16px */
              "@media screen and (max-width: 40em)": {
                "select:active, input:active, textarea:active": {
                  fontSize: "16px !important;",
                },
              },

              /* Safari - hide autofill icon */
              "input::-webkit-credentials-auto-fill-button": {
                visibility: "hidden",
                position: "absolute",
                right: 0,
              },
            })}
          />
        </ModalProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <OSIntlProvider>
        <BrandedContent />
      </OSIntlProvider>
    </Provider>
  );
}

export default App;
