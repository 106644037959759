import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getIdentityTypes = createAsyncThunk("identityTypes/getIdentityTypes", async () => {
  return await FacadeAPI.GET("/system/identityTypes");
});

const identityTypesSlice = createSlice({
  name: "identityTypes",
  initialState: {
    loading: false,
    success: false,
    error: null,

    identityTypes: [],
  },
  reducers: {},
  extraReducers: {
    [getIdentityTypes.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [getIdentityTypes.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.identityTypes = action.payload;
    },
    [getIdentityTypes.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.error;
    },
  },
});

export default identityTypesSlice.reducer;
