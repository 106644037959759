import { Box, Button, Flex, Text } from "@theme-ui/components";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNextStep } from "../../../common/StepsUtilities";
import { getResponseMessage, TU_RESPONSE } from "../../../common/TransunionUtilities";
import { FormInput, OSContainer } from "../../../components/base";
import { CHECKOUT_STEPS } from "../../../components/header/Constants";
import { Heading4 } from "../../../components/heading";
import Layout from "../../../components/layout";
import { StyledModalMessage } from "../../../components/modals";
import Spinner from "../../../components/spinner/Spinner";
import {
  loadPersonalInformation,
  requestTUIdentityCheck,
  requestTUIdentityCheckTimeout,
} from "../../../config/redux/slices";
import { deleteSession } from "../../../config/redux/slices/SessionSlice";
import Storage from "store2";

const Question = ({ reactHookFormHandle, control, question, index }) => {
  const intl = useIntl();

  return (
    <Flex
      mt={4}
      sx={{
        borderRadius: "xl",
        justifyContent: "space-between",
        flexDirection: ["column", "column", "row"],
        alignItems: ["stretch", "stretch", "center"],
      }}>
      <Box
        mt={[4, 4, 1]}
        mr={4}
        sx={{
          flex: "2",
          alignSelf: ["flex-start", "flex-start", "center"],
        }}>
        <Text mr={3} variant="heading5">
          {index < 10 ? `0${index + 1}` : index}
        </Text>
        <Text variant="subtitle2">{question.text}</Text>
      </Box>
      <Box
        mt={[3, 3, 0]}
        sx={{
          flex: "1",
        }}>
        <FormInput
          type="select"
          name={question.questionId}
          control={control}
          defaultValue={intl.formatMessage({ id: "lbl.none" })}
          {...reactHookFormHandle}
          validations={{
            validate: {
              required: (value) => value !== intl.formatMessage({ id: "lbl.none" }),
            },
          }}>
          {[{ value: intl.formatMessage({ id: "lbl.none" }) }]
            .concat(question.answers)
            .map((answer, index) => (
              <option key={index} value={answer.value}>
                {answer.value}
              </option>
            ))}
        </FormInput>
      </Box>
    </Flex>
  );
};

const ValidateIdentity = () => {
  const [responseCode, setResponseCode] = useState();
  const [startTime, setStartTime] = useState();
  const personalInformationData = useSelector((state) => state.personalInformation);
  const requestIdentityCheckState = useSelector(
    (state) => state.personalInformation.requestTUIdentityCheck
  );
  const tuDataRequest = useSelector((state) => state.personalInformation.tuDataRequest);
  const { planFlags } = useSelector((state) => state.items);
  const { register, errors, handleSubmit, control } = useForm({ mode: "onTouched" });
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const reactHookFormHandle = { register, errors };
  const timerRef = useRef();

  const totalQuestionsTime = useMemo(() => {
    const totalTime = tuDataRequest?.idVision?.examDetails.questions
      .map((question) => question.expirationTime)
      .map((time) => {
        const splittedTime = time.split(":");
        return +splittedTime[0] * 60 + +splittedTime[1];
      })
      .reduce((prev, curr) => prev + curr, 0);

    return totalTime * 1000;
  }, [tuDataRequest]);

  const handleIdentityTimeout = useCallback(() => {
    setResponseCode(TU_RESPONSE.QUESTIONS_TIMEOUT);
    dispatch(requestTUIdentityCheckTimeout({ accountId: personalInformationData.accountId }));
  }, [dispatch, personalInformationData.accountId]);

  useEffect(() => {
    if (personalInformationData.loaded && tuDataRequest.tuSessionId) {
      if (window._TUSessionID_ !== tuDataRequest.tuSessionId) {
        setResponseCode(TU_RESPONSE.FAILED);
        return;
      }
      if (personalInformationData.tuResponseCode === TU_RESPONSE.SUCCESS) {
        history.replace(
          getNextStep(planFlags, CHECKOUT_STEPS.PERSONAL_INFORMATION, personalInformationData.flow)
            .route
        );
        return;
      }

      const now = new Date().getTime();
      let startTimestamp = Storage.session.get("startQuestionsTime");
      if (!startTimestamp) {
        Storage.session("startQuestionsTime", now);
        startTimestamp = now;
      }

      const estimatedTime = startTimestamp + totalQuestionsTime;
      if (estimatedTime > new Date().getTime()) {
        setStartTime(estimatedTime);
      } else {
        handleIdentityTimeout();
      }
    }
  }, [
    tuDataRequest,
    totalQuestionsTime,
    handleIdentityTimeout,
    planFlags,
    history,
    personalInformationData.loaded,
    personalInformationData.flow,
    personalInformationData.tuResponseCode,
  ]);

  useEffect(() => {
    !personalInformationData.loaded && dispatch(loadPersonalInformation());
  }, [dispatch, personalInformationData.loaded]);

  useEffect(() => {
    if (!requestIdentityCheckState.success) return;

    const tuResponse = personalInformationData.tuResponseCode;
    if (tuResponse === TU_RESPONSE.SUCCESS) {
      history.replace(
        getNextStep(planFlags, CHECKOUT_STEPS.PERSONAL_INFORMATION, personalInformationData.flow)
          .route
      );
    } else {
      setResponseCode(tuResponse);
    }
  }, [planFlags, history, personalInformationData, requestIdentityCheckState.success]);

  const performIdentityCheck = (event) => {
    timerRef.current.stop();
    const updatedTUDataRequest = _.cloneDeep(tuDataRequest);

    const questions = tuDataRequest.idVision.examDetails.questions;

    const timeTakenToAnswer = computeQuestionsAnswersAvgTime(questions);
    const answeredQuestions = getQuestionsAnswers(event, questions, timeTakenToAnswer);

    updatedTUDataRequest.idVision.examDetails.questions = answeredQuestions;

    dispatch(requestTUIdentityCheck({ tuDataRequest: updatedTUDataRequest }));
    Storage.session.remove("startQuestionsTime");
  };

  const computeQuestionsAnswersAvgTime = (questions) => {
    const totalTime = new Date().getTime() + totalQuestionsTime;
    const timePerQuestion = new Date((totalTime - startTime) / questions.length);
    return timePerQuestion.getMinutes() + ":" + timePerQuestion.getSeconds();
  };

  const getQuestionsAnswers = (event, questions, timePerQuestion) => {
    let answeredQuestions = [];
    for (const question of questions) {
      let questionAnswers = [...question.answers];
      const selectedAnswerIndex = questionAnswers.findIndex(
        (answer) => answer.value === event[question.questionId]
      );

      let selectedAnswer = { ...questionAnswers[selectedAnswerIndex], selected: true };
      questionAnswers.splice(selectedAnswerIndex, 1, selectedAnswer);

      const answeredQuestion = {
        ...question,
        answers: questionAnswers,
        timeTakenToAnswer: timePerQuestion,
      };
      answeredQuestions.push(answeredQuestion);
    }
    return answeredQuestions;
  };

  const handleResponseFailure = () => {
    Storage.remove("session_id");
    Storage.session.remove("startQuestionsTime");
    dispatch(deleteSession());
  };

  const responseMessage = responseCode && getResponseMessage(responseCode);

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.PERSONAL_INFORMATION.index}
      pageTitle={intl.formatMessage({ id: "lbl.validate-identity" })}
      nextStepButton={() => (
        <Button
          id="submitValidateIdentity"
          variant="default-next"
          type="submit"
          form="questionForm">
          <FormattedMessage id="btn.next" />
        </Button>
      )}>
      {/* generate error */}
      {responseCode && (
        <StyledModalMessage
          isOpen={responseCode !== TU_RESPONSE.SUCCESS}
          onRequestClose={handleResponseFailure}
          message={responseMessage}>
          <Button variant="simple-action" onClick={handleResponseFailure}>
            <FormattedMessage id="btn.ok" />
          </Button>
        </StyledModalMessage>
      )}
      <Spinner
        isOpen={
          personalInformationData.loadPersonalInformation.loading ||
          requestIdentityCheckState.loading
        }
      />

      <OSContainer variant="page-content" flexDirection="column">
        <Box px={[8, 8, 9]}>
          <Heading4 color="primary">
            <FormattedMessage id="lbl.verify-identity-msg" />
          </Heading4>
          <Box as="ul" my={6}>
            <Text as="li" variant="subtitle2" color="ternary">
              <FormattedMessage id="lbl.verify-identity-msg-info1" />
            </Text>
            <Text as="li" variant="subtitle2" color="ternary">
              <FormattedMessage id="lbl.verify-identity-msg-info2" />
            </Text>
            <Text as="li" variant="subtitle2" color="ternary">
              <FormattedMessage id="lbl.verify-identity-msg-info3" />
            </Text>
          </Box>

          <Flex
            py={6}
            px={8}
            sx={{
              border: "solid",
              borderColor: "cardBorderColor",
              borderWidth: 1,
              borderRadius: "xl",
              flexDirection: "column",
            }}>
            <Flex
              mb={2}
              sx={{
                justifyContent: ["flex-start", "flex-start", "space-between"],
                alignItems: ["flex-start", "flex-start", "center"],
                flexDirection: ["column", "column", "row"],
              }}>
              <Heading4 color="ternary">
                <FormattedMessage id="lbl.question-validate-identity" />
              </Heading4>
              <Flex mt={[4, 3, 0]}>
                <Text variant="heading4" color="primary" fontWeight="normal">
                  <FormattedMessage id="lbl.allocated-time-countdown" />
                </Text>
                {startTime && (
                  <Countdown
                    ref={timerRef}
                    date={startTime}
                    onComplete={handleIdentityTimeout}
                    renderer={({ minutes, seconds }) => {
                      return (
                        <Text variant="heading4" ml={5}>
                          {zeroPad(minutes)}:{zeroPad(seconds)}
                        </Text>
                      );
                    }}
                  />
                )}
              </Flex>
            </Flex>
            <form id="questionForm" onSubmit={handleSubmit(performIdentityCheck)}>
              {tuDataRequest &&
                tuDataRequest.idVision.examDetails.questions.map((question, index) => (
                  <React.Fragment key={question.questionId}>
                    <Question
                      reactHookFormHandle={reactHookFormHandle}
                      control={control}
                      question={question}
                      index={index}
                    />
                  </React.Fragment>
                ))}
            </form>
          </Flex>
        </Box>
      </OSContainer>
    </Layout>
  );
};

export default ValidateIdentity;
