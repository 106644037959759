import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import SearchStore from "./SearchStore";
import ShowMore from "./ShowMore";
import Store from "./Store";
import Layout from "../../../../components/layout";
import PageHeader from "../../PageHeader";
import { StoreSkeleton } from "../../../../components/skeletons";
import { OSContainer } from "../../../../components/base";
import { saveShippingDetails, setStorePickUpState } from "../../../../config/redux/slices";

import { CHECKOUT_STEPS } from "../../../../components/header/Constants";
import { SHIPPING_FLOW } from "../../Constants";
import { ITEM_CATEGORY, ITEM_ATTRIBUTE_CODE, PRICE_TYPE } from "../../../../common/Constants";
import { getNextStep } from "../../../../common/StepsUtilities";
import { StyledModalMessage } from "../../../../components/modals";
import Spinner from "../../../../components/spinner/Spinner";
import { OSIcon } from "../../../../components/icons";

const PickInStore = ({ onChangeShippingFlow }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const shippingDetailsState = useSelector((state) => state.shippingDetails);
  const { planFlags, ...itemsState } = useSelector((state) => state.items);
  const personalInformationData = useSelector((state) => state.personalInformation);

  const [pickUpStore, setPickUpStore] = useState("");
  const [stores, setStores] = useState(shippingDetailsState.getStores.stores);
  const [stockAvailable, setStockAvailable] = useState(false);
  const [displayStoresNo, setDisplayStoresNo] = useState(5);
  const [existsBundleInCart, setExistsBundleInCart] = useState(5);
  const [accessoriesToBeRemoved, setAccessoriesToBeRemoved] = useState(null);
  const [showRemoveAccessoriesMsg, setShowRemoveAccessoriesMsg] = useState(false);
  const isHomeDeliveryAllowed =
    !personalInformationData.isSkippedFraudCheckAndInvoiceIsNotPaid.value;

  const computeListOfAccessoriesToBeRemoved = (selectedStore) => {
    const storeObj = shippingDetailsState.getStores.stores.find(
      (store) => store.code === selectedStore
    );
    if (storeObj.accessoriesOutOfStock && Object.keys(storeObj.accessoriesOutOfStock).length > 0) {
      return Object.keys(storeObj.accessoriesOutOfStock).map(
        (key) => itemsState.getItems.items.find((item) => item.sku === key)?.shortDescription
      );
    }
    return null;
  };

  const storeHasAvailableStock = (existsBundle, store) => {
    return (
      (existsBundle && store.resourcesAvailable) ||
      (!existsBundle && !store.allAccessoriesOutOfStock)
    );
  };

  const computeStockAvailability = (existsBundle, stores) => {
    let anyStoreWithStock = stores?.find((store) => storeHasAvailableStock(existsBundle, store));
    setStockAvailable(anyStoreWithStock !== null && anyStoreWithStock !== undefined);
  };

  useEffect(() => {
    if (itemsState.getItems.success && shippingDetailsState.getStores.success) {
      setStores(shippingDetailsState.getStores.stores);
      if (shippingDetailsState.getStores.stores?.length > 0) {
        const existsBundle = itemsState.getItems.items.find(
          (item) => item.category === ITEM_CATEGORY.BUNDLE
        );
        setExistsBundleInCart(existsBundle);
        computeStockAvailability(existsBundle, shippingDetailsState.getStores.stores);

        let storeProvider = shippingDetailsState.pickUpStore;
        const shippingItem = itemsState.getItems.items.find(
          (item) => item.category === ITEM_CATEGORY.SHIPPING
        );
        if (storeProvider === "" && shippingItem) {
          storeProvider = shippingItem.sku;
        }
        if (storeProvider) {
          let index = shippingDetailsState.getStores.stores.findIndex(
            (store) => store.code === storeProvider
          );
          if (index > -1) {
            setPickUpStore(
              storeHasAvailableStock(
                existsBundle,
                shippingDetailsState.getStores.stores.find((store) => store.code === storeProvider)
              )
                ? storeProvider
                : ""
            );
            setDisplayStoresNo(5 + Math.floor(++index / 6) * 6);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsState.getItems.success, shippingDetailsState.getStores.success]);

  const onSearchInputChange = (value, useMyAddr) => {
    setDisplayStoresNo(5);
    if (!value || value === "") {
      let stores = shippingDetailsState.getStores.stores;
      if (useMyAddr) {
        stores = stores.filter((store) => store.distance < 100);
        let index = stores.findIndex((store) => store.code === pickUpStore);
        if (index < 0) {
          setPickUpStore("");
        }
      }
      setStores(stores);
      computeStockAvailability(existsBundleInCart, stores);
    } else {
      let filteredStores = shippingDetailsState.getStores.stores.filter((store) =>
        store.address.toLowerCase().includes(value.toLowerCase())
      );
      let index = filteredStores.findIndex((store) => store.code === pickUpStore);
      if (index < 0) {
        setPickUpStore("");
      }
      setStores(filteredStores);
      computeStockAvailability(existsBundleInCart, filteredStores);
    }
  };

  const onSelectStore = (code) => {
    setPickUpStore(code);
  };

  const onClickNext = () => {
    const accsList = computeListOfAccessoriesToBeRemoved(pickUpStore);
    if (accsList) {
      setAccessoriesToBeRemoved(accsList);
      setShowRemoveAccessoriesMsg(true);
    } else {
      onNext();
    }
  };

  const onNext = () => {
    // Save redux state
    dispatch(
      setStorePickUpState({
        shippingFlow: SHIPPING_FLOW.PICK_IN_STORE,
        pickUpStore: pickUpStore,
      })
    );

    const storeObject = shippingDetailsState.getStores.stores.find(
      (store) => store.code === pickUpStore
    );
    dispatch(
      saveShippingDetails({
        flow: SHIPPING_FLOW.PICK_IN_STORE,
        shippingMethod: "",
        isNewShippingAddress: false,
        shippingAddress: null,
        sessionItem: {
          sku: storeObject.code,
          category: ITEM_CATEGORY.SHIPPING,
          shortDescription: intl.formatMessage({ id: "lbl.pick-in-store-delivery" }),
          thumbnailURL: "pick-in-store",
          prices: [
            {
              code: storeObject.code,
              price: 0.0,
              tax: 0,
              type: PRICE_TYPE.ONE_TIME,
              termsAmount: -1,
              termsUnit: "NONE",
            },
          ],
          attributes: [
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPPING_NOTE,
              value: storeObject.name,
            },
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPMENT_OPTION,
              value: SHIPPING_FLOW.PICK_IN_STORE,
            },
          ],
        },
        accessoriesOutOfStock: storeObject.accessoriesOutOfStock,
      })
    );
  };

  const onShowMore = () => {
    setDisplayStoresNo(displayStoresNo + 6);
  };
  const goTo = () => {
    onChangeShippingFlow(SHIPPING_FLOW.HOME_DELIVERY);
  };
  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.SHIPPING_DETAILS.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.SHIPPING_DETAILS.pageTitle })}
      nextStepButton={() => (
        <Button
          id="submitShipping"
          variant="default-next"
          disabled={
            !pickUpStore || pickUpStore === "" || shippingDetailsState.saveShippingDetails.loading
          }
          onClick={onClickNext}>
          {intl.formatMessage({
            id: shippingDetailsState.saveShippingDetails.loading
              ? "lbl.processing"
              : getNextStep(
                  planFlags,
                  CHECKOUT_STEPS.SHIPPING_DETAILS,
                  personalInformationData.flow
                ).srcButtonLabel,
          })}
        </Button>
      )}>
      <Spinner isOpen={shippingDetailsState.saveShippingDetails.loading} />

      <StyledModalMessage
        isOpen={showRemoveAccessoriesMsg && (accessoriesToBeRemoved ?? false)}
        onRequestClose={() => setShowRemoveAccessoriesMsg(false)}
        message={intl.formatMessage({ id: "lbl.remove-out-of-stock-accessories" })}
        listItems={accessoriesToBeRemoved}>
        <Button mr={20} variant="simple-action" onClick={() => setShowRemoveAccessoriesMsg(false)}>
          <FormattedMessage id="btn.cancel" />
        </Button>
        <Button variant="simple-action" onClick={onNext}>
          <FormattedMessage id="btn.proceed" />
        </Button>
      </StyledModalMessage>
      <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
        <PageHeader
          selectedShippingFlow={SHIPPING_FLOW.PICK_IN_STORE}
          onChangeShippingFlow={onChangeShippingFlow}
        />

        <Box
          mt={8}
          p={2}
          px={9}
          sx={{
            display: !stockAvailable ? ["grid", "flex"] : "grid",
            flexDirection: ["column", "row"],
            justifyContent: !stockAvailable ? "space-between" : "",
            gridTemplateColumns: ["repeat(1, 1fr)", "1fr 2fr", "1fr 3fr"],
            gridAutoRows: "min-content",
          }}>
          <SearchStore onInputChange={onSearchInputChange} disabled={!stockAvailable} />

          <Box
            sx={{
              display: ["grid", "flex"],
              flexWrap: "wrap",
              gridTemplateColumns: "repeat(1, 1fr)",
              gridAutoRows: ["1fr", "min-content"],
              gridGap: 4,
            }}>
            {(shippingDetailsState.getStores.loading || itemsState.getItems.loading) &&
              [...Array(3).keys()].map((index) => <StoreSkeleton key={index} />)}
            {!shippingDetailsState.getStores.loading &&
              !itemsState.getItems.loading &&
              stockAvailable &&
              stores &&
              stores.length > 0 &&
              stores.map(
                (store, index) =>
                  index < displayStoresNo && (
                    <Store
                      id={"store-" + store.code}
                      className="track"
                      key={store.code}
                      data={store}
                      selected={pickUpStore === store.code}
                      existsBundleInCart={existsBundleInCart}
                      onSelect={() => onSelectStore(store.code)}
                    />
                  )
              )}
            {!shippingDetailsState.getStores.loading &&
              stores &&
              stores.length > displayStoresNo &&
              stockAvailable && <ShowMore onShowMore={onShowMore} />}
          </Box>

          {!stockAvailable && !shippingDetailsState.getStores.loading && (
            <Flex
              bg="secondaryBg"
              px={5.5}
              py={7.5}
              sx={{
                maxWidth: "38rem",
                alignItems: "center",
                border: 1,
                borderColor: "border",
                borderRadius: "2lg",
                boxShadow: "sm",
                width: "100%",
                height: "8.3125rem",
              }}>
              <Flex sx={{ position: "absolute" }}>
                <OSIcon name="no-pickup" />
              </Flex>
              <Flex
                mr="auto"
                ml="auto"
                sx={{
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  height: "100%",
                }}>
                <Text variant="description" color="noResultsTextColor">
                  {intl.formatMessage({ id: "lbl.unable-provide-pick-up-store" })}
                </Text>
                {isHomeDeliveryAllowed && (
                  <Flex sx={{ alignItems: "center" }}>
                    <Text
                      variant="storePickup"
                      onClick={goTo}
                      color="ternary"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}>
                      {intl.formatMessage({ id: "lbl.choose-home-delivery" })}
                    </Text>
                    <OSIcon name="chevron-right" ml={1} />
                  </Flex>
                )}
              </Flex>
            </Flex>
          )}
        </Box>
      </OSContainer>
    </Layout>
  );
};

export default PickInStore;
