import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PRICE_TYPE, EXTRA_INFO } from "../../../common/Constants";
import { FacadeAPI } from "../../../rest/RestClient";
import { getItems } from "./ItemsSlice";
import { groupOffers } from "../helpers/ItemPriceSliceHelpers";

const INITIAL_STATE = {
  // API Responses
  updateItemPrices: {
    loading: false,
    success: false,
    error: null,
  },
  getBundlePriceOffers: {
    loading: false,
    success: false,
    error: null,
  },
  getAccessoriesPriceOffers: {
    loading: false,
    success: false,
    error: null,
  },
  checkEasyTabFlexCompatibility: {
    loading: false,
    success: false,
    error: null,
  },
  getTotalDevicesFullPrice: {
    loading: false,
    success: false,
    error: null,
  },

  bundlePriceOffers: [],
  accessoriesPriceOffers: [],
  totalDevicesFullPrice: 0,
};

export const updateItemPrices = createAsyncThunk(
  "itemPrice/updateItemPrices",
  async ({ itemId, request }, { getState, dispatch }) => {
    if (request?.length === 0) {
      return dispatch(getItems());
    }
    const sessionId = getState().session.sessionId;
    await FacadeAPI.PUT(`/session/${sessionId}/item/${itemId}/price`, request, {
      expectedResponse: "",
    });
    // Retrieve again the items with updated prices to display them in the shopping cart.
    dispatch(getItems());
  }
);

export const getBundlePriceOffers = createAsyncThunk(
  "itemPrice/getBundlePriceOffers",
  async ({ itemId }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/session/${sessionId}/item/${itemId}/price/offers`, null, {
        noTimeout: true,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getAccessoriesPriceOffers = createAsyncThunk(
  "itemPrice/accessoriesPriceOffers",
  async (arg, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/session/${sessionId}/price/offers/accessories`, null, {
        noTimeout: true,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const checkEasyTabFlexCompatibility = createAsyncThunk(
  "itemPrice/checkEasyTabFlexCompatibility",
  async (arg, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.PUT(
        `/session/${sessionId}/checkEasyTabFlexCompatibility`,
        {},
        {
          expectedResponse: "",
        }
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getTotalDevicesFullPrice = createAsyncThunk(
  "itemPrice/totalDevicesFullPrice",
  async (arg, { getState }) => {
    const sessionId = getState().session.sessionId;
    return await FacadeAPI.GET(`/session/${sessionId}/totalDevicesFullPrice`);
  }
);

export const isServiceDiscountFromRule = (serviceDiscount) => {
  const ruleCode = Object.fromEntries(
    serviceDiscount.extraInfo.split(";").map((elem) => elem.split("="))
  )[EXTRA_INFO.DISCOUNT_RULE_CODE];
  return ruleCode !== undefined && ruleCode.length > 0;
};

const itemPriceSlice = createSlice({
  name: "itemPrice",
  initialState: INITIAL_STATE,
  reducers: {
    updateBundleOffers(state, newOffer) {
      let rcFee = newOffer.payload.rcFee;
      let setupFee = newOffer.payload.setupFee;
      let noInstances = newOffer.payload.noInstances;
      let discounts = newOffer.payload.discounts;
      let bundleOffer = state.bundlePriceOffers;

      let newPlanOffer = bundleOffer.map((item) => {
        if (item.code === "PLAN") {
          let itemCopy = { ...item };
          let recurringIndex = item.price.findIndex(
            (price) => price.type === "PLAN_ADDITIONAL_RECURRING"
          );
          let oneTimeIndex = item.price.findIndex(
            (price) => price.type === "PLAN_ADDITIONAL_ONE_TIME"
          );
          let planServiceDiscountServiceIndex = item.price.findIndex(
            (price) =>
              (price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
                price.type === PRICE_TYPE.PLAN_SERVICE_IVD) &&
              isServiceDiscountFromRule(price)
          );

          let priceRcurring = { ...item.price[recurringIndex] };
          let priceOneTime = { ...item.price[oneTimeIndex] };
          let priceArr = [...item.price];

          priceRcurring.price = priceRcurring.price + rcFee * noInstances;
          priceOneTime.price = priceOneTime.price + setupFee * noInstances;
          priceArr[recurringIndex] = priceRcurring;
          priceArr[oneTimeIndex] = priceOneTime;

          if (planServiceDiscountServiceIndex == -1) {
            itemCopy.price = [...priceArr, ...discounts];
          } else {
            itemCopy.price = priceArr;
          }

          return itemCopy;
        } else return item;
      });

      let copyBundlePriceOffer = { ...state.bundlePriceOffers };
      copyBundlePriceOffer = newPlanOffer;

      return {
        ...state,
        bundlePriceOffers: copyBundlePriceOffer,
      };
    },
    updateDeviceOffers(state, curentBundleOffer) {
      let copyBundlePriceOffer = { ...state.bundlePriceOffers };
      copyBundlePriceOffer = curentBundleOffer.payload.newOffers;
      return {
        ...state,
        bundlePriceOffers: copyBundlePriceOffer,
      };
    },
    clearUpdateItemPricesApiResponses(state) {
      return {
        ...state,
        updateItemPrices: INITIAL_STATE.updateItemPrices,
      };
    },

    clearGetBundlePriceApiResponse(state) {
      return {
        ...state,
        getBundlePriceOffers: INITIAL_STATE.getBundlePriceOffers,
        bundlePriceOffers: [],
      };
    },

    clearGetAccessoriesPriceOffersApiResponse(state) {
      return {
        ...state,
        getAccessoriesPriceOffers: INITIAL_STATE.getAccessoriesPriceOffers,
        accessoriesPriceOffers: [],
      };
    },

    clearCheckEasyTabFlexCompatibility(state) {
      return {
        ...state,
        checkEasyTabFlexCompatibility: INITIAL_STATE.checkEasyTabFlexCompatibility,
      };
    },

    clearGetTotalDevicesFullPrice(state) {
      return {
        ...state,
        getTotalDevicesFullPrice: INITIAL_STATE.getTotalDevicesFullPrice,
        totalDevicesFullPrice: 0,
      };
    },

    updateCurrentBundle911PriceOffers: {
      reducer: (state, action) => {
        const { bundlePriceOffers, newPrice } = action.payload;

        let priceOffers = [...bundlePriceOffers];

        if (priceOffers) {
          let planOffer = priceOffers.find((offer) => offer.code === "PLAN");
          const indexPlanOffer = priceOffers.indexOf(planOffer);

          let e911PlanPrice = planOffer.price.find((price) => price.type === PRICE_TYPE.PLAN_E911);
          const indexE911PlanPrice = planOffer.price.indexOf(e911PlanPrice);

          const updatedPlanPrices = [...planOffer.price];
          updatedPlanPrices.splice(indexE911PlanPrice, 1, newPrice);
          planOffer = { ...planOffer, price: updatedPlanPrices };

          const updatedPriceOffers = [...priceOffers];
          updatedPriceOffers.splice(indexPlanOffer, 1, planOffer);

          priceOffers = [...updatedPriceOffers];
        }

        // Update whole state
        return { ...state, bundlePriceOffers: priceOffers };
      },
      prepare: (bundlePriceOffers, bundleId, newPrice) => {
        return {
          payload: {
            bundlePriceOffers,
            bundleId,
            newPrice,
          },
        };
      },
    },
  },
  extraReducers: {
    [updateItemPrices.pending]: (state) => {
      state.updateItemPrices.loading = true;
      state.updateItemPrices.success = false;
      state.updateItemPrices.error = null;
    },
    [updateItemPrices.fulfilled]: (state) => {
      state.updateItemPrices.loading = false;
      state.updateItemPrices.success = true;
    },
    [updateItemPrices.rejected]: (state, action) => {
      state.updateItemPrices.loading = false;
      state.updateItemPrices.error = action.error;
    },

    [getBundlePriceOffers.pending]: (state) => {
      state.getBundlePriceOffers.loading = true;
      state.getBundlePriceOffers.success = false;
      state.getBundlePriceOffers.error = null;
      state.bundlePriceOffers = [];
    },
    [getBundlePriceOffers.fulfilled]: (state, action) => {
      state.getBundlePriceOffers.loading = false;
      state.getBundlePriceOffers.success = true;
      state.bundlePriceOffers = groupOffers(action.payload, action.meta.arg.itemId);
    },
    [getBundlePriceOffers.rejected]: (state, action) => {
      state.getBundlePriceOffers.loading = false;
      state.getBundlePriceOffers.error = action.payload;
    },

    [getAccessoriesPriceOffers.pending]: (state) => {
      state.getAccessoriesPriceOffers.loading = true;
      state.getAccessoriesPriceOffers.success = false;
      state.getAccessoriesPriceOffers.error = null;
      state.accessoriesPriceOffers = [];
    },
    [getAccessoriesPriceOffers.fulfilled]: (state, action) => {
      state.getAccessoriesPriceOffers.loading = false;
      state.getAccessoriesPriceOffers.success = true;
      state.accessoriesPriceOffers = groupOffers(action.payload);
    },
    [getAccessoriesPriceOffers.rejected]: (state, action) => {
      state.getAccessoriesPriceOffers.loading = false;
      state.getAccessoriesPriceOffers.error = action.payload;
    },
    [checkEasyTabFlexCompatibility.pending]: (state) => {
      state.checkEasyTabFlexCompatibility.loading = true;
      state.checkEasyTabFlexCompatibility.success = false;
      state.checkEasyTabFlexCompatibility.error = null;
    },
    [checkEasyTabFlexCompatibility.fulfilled]: (state) => {
      state.checkEasyTabFlexCompatibility.loading = false;
      state.checkEasyTabFlexCompatibility.success = true;
      state.checkEasyTabFlexCompatibility.error = null;
    },
    [checkEasyTabFlexCompatibility.rejected]: (state, action) => {
      state.checkEasyTabFlexCompatibility.loading = false;
      state.checkEasyTabFlexCompatibility.error = action.payload;
    },
    [getTotalDevicesFullPrice.pending]: (state) => {
      state.getTotalDevicesFullPrice.loading = true;
      state.getTotalDevicesFullPrice.success = false;
      state.getTotalDevicesFullPrice.error = null;
      state.totalDevicesFullPrice = 0;
    },
    [getTotalDevicesFullPrice.fulfilled]: (state, action) => {
      state.getTotalDevicesFullPrice.loading = false;
      state.getTotalDevicesFullPrice.success = true;
      state.getTotalDevicesFullPrice.error = null;
      state.totalDevicesFullPrice = action.payload;
    },
    [getTotalDevicesFullPrice.rejected]: (state, action) => {
      state.getTotalDevicesFullPrice.loading = false;
      state.getTotalDevicesFullPrice.error = action.payload;
    },
  },
});

export const {
  clearGetBundlePriceApiResponse,
  updateBundleOffers,
  updateDeviceOffers,
  clearUpdateItemPricesApiResponses,
  clearGetAccessoriesPriceOffersApiResponse,
  updateCurrentBundle911PriceOffers,
  clearCheckEasyTabFlexCompatibility,
  clearGetTotalDevicesFullPrice,
} = itemPriceSlice.actions;
export default itemPriceSlice.reducer;
