import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Button, Text, Flex, Box } from "@theme-ui/components";
import { useHistory } from "react-router-dom";

import Layout from "../../components/layout";
import PageHeader from "./PageHeader";
import HomeDelivery from "./shipping-flows/home-delivery";
import PickInStore from "./shipping-flows/pick-in-store";
import { OSContainer } from "../../components/base";

import { CHECKOUT_STEPS } from "../../components/header/Constants";
import { SHIPPING_FLOW } from "./Constants";
import {
  POS_PARAMETER,
  ITEM_CATEGORY,
  ITEM_ATTRIBUTE_CODE,
  ErrorCode,
  PRICE_TYPE,
} from "../../common/Constants";
import { getNextStep } from "../../common/StepsUtilities";

import {
  getStores,
  getPosParameter,
  clearSaveShippingDetailsResponse,
  saveShippingDetails,
} from "../../config/redux/slices";
import ErrorHandler from "../../components/error-handler";
import Spinner from "../../components/spinner/Spinner";

const ShippingDetails = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const shippingDetailsState = useSelector((state) => state.shippingDetails);
  const personalInfoState = useSelector((state) => state.personalInformation);
  const { planFlags } = useSelector((state) => state.items);

  const [shippingFlow, setShippingFlow] = useState(shippingDetailsState.shippingFlow);
  const { flow } = useSelector((state) => state.personalInformation);

  useEffect(() => {
    dispatch(getStores());
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_PROVIDER));
  }, [dispatch]);

  const onSetShippingFlow = (shippingFlow) => {
    setShippingFlow(shippingFlow);
  };
  const onNext = () => {
    dispatch(
      saveShippingDetails({
        flow: SHIPPING_FLOW.NOT_NEEDED,
        shippingMethod: "",
        isNewShippingAddress: false,
        shippingAddress: null,
        sessionItem: {
          category: ITEM_CATEGORY.SHIPPING,
          shortDescription: intl.formatMessage({ id: "lbl.shipping-not-needed" }),
          prices: [
            {
              price: 0.0,
              tax: 0,
              type: PRICE_TYPE.ONE_TIME,
              termsAmount: -1,
              termsUnit: "NONE",
            },
          ],
          attributes: [
            {
              code: ITEM_ATTRIBUTE_CODE.SHIPMENT_OPTION,
              value: SHIPPING_FLOW.NOT_NEEDED,
            },
          ],
        },
      })
    );
  };
  const resetSaveShippingDetailsState = useCallback(
    (errorCode) => {
      dispatch(clearSaveShippingDetailsResponse());
      if (errorCode === ErrorCode.NO_ITEMS_AVAILABLE) {
        dispatch(getStores());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (shippingDetailsState.saveShippingDetails.success) {
      resetSaveShippingDetailsState();
      history.push(
        getNextStep(planFlags, CHECKOUT_STEPS.SHIPPING_DETAILS, personalInfoState.flow).route
      );
    }
  }, [
    history,
    planFlags,
    resetSaveShippingDetailsState,
    shippingDetailsState.saveShippingDetails.success,
    personalInfoState.flow,
  ]);

  return (
    <>
      {(shippingFlow === SHIPPING_FLOW.NONE || shippingFlow === SHIPPING_FLOW.NOT_NEEDED) && (
        <Layout
          checkoutStep={CHECKOUT_STEPS.SHIPPING_DETAILS.index}
          pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.SHIPPING_DETAILS.pageTitle })}
          nextStepButton={() => (
            <Button
              id="submitShipping"
              variant="default-next"
              disabled={shippingFlow === SHIPPING_FLOW.NONE}
              onClick={onNext}>
              {intl.formatMessage({
                id: getNextStep(planFlags, CHECKOUT_STEPS.SHIPPING_DETAILS, flow).srcButtonLabel,
              })}
            </Button>
          )}>
          <Spinner isOpen={shippingDetailsState.saveShippingDetails.loading} />
          <OSContainer variant="page-content" sx={{ flexDirection: "column" }}>
            <PageHeader
              selectedShippingFlow={shippingFlow}
              onChangeShippingFlow={onSetShippingFlow}
            />
          </OSContainer>
          {shippingFlow === SHIPPING_FLOW.NOT_NEEDED && (
            <Box mt={1}>
              <Flex
                bg="secondaryBg"
                px={9}
                py={7.5}
                sx={{
                  alignItems: "left",
                  borderColor: "border",
                  borderRadius: "2lg",
                  boxShadow: "sm",
                  width: "100%",
                }}>
                <Flex sx={{ flexDirection: "column", flex: 1 }}>
                  <Text variant="description">
                    {intl.formatMessage({ id: "lbl.shipping-not-needed" })}
                  </Text>
                  <Text variant="description" mt={7}>
                    {intl.formatMessage({ id: "lbl.shipping-not-needed-proceed" })}
                  </Text>
                </Flex>
              </Flex>
            </Box>
          )}
        </Layout>
      )}

      <ErrorHandler
        error={shippingDetailsState.saveShippingDetails.error}
        closeHandler={() =>
          resetSaveShippingDetailsState(shippingDetailsState.saveShippingDetails.error?.errorCode)
        }
        clickHandler={() =>
          resetSaveShippingDetailsState(shippingDetailsState.saveShippingDetails.error?.errorCode)
        }
      />

      {shippingFlow === SHIPPING_FLOW.HOME_DELIVERY && (
        <HomeDelivery onChangeShippingFlow={onSetShippingFlow} />
      )}

      {shippingFlow === SHIPPING_FLOW.PICK_IN_STORE && (
        <PickInStore onChangeShippingFlow={onSetShippingFlow} />
      )}
    </>
  );
};

export default ShippingDetails;
