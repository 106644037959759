import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { OSIcon } from "../../../../components/icons";

const ShowMore = ({ onShowMore, ...props }) => {
  const intl = useIntl();

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
        bg: "secondaryBg",
        flexDirection: "column",
        maxWidth: ["100%", "48%", "48%", "32%"],
        minWidth: ["auto", "48%", "48%", "15rem"],
        p: 5,
        width: "100%",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: "default",
      }}
      {...props}>
      <Flex
        sx={{ cursor: "pointer", alignItems: "center", flexDirection: "column" }}
        mb={["none", 4]}
        mt={4}
        onClick={onShowMore}>
        <OSIcon name="show-more" ml={1} />
        <Flex>
          <Text
            variant="subtitle1"
            mt={0}
            color="ternary"
            sx={{ cursor: "pointer", textDecoration: "underline" }}>
            {intl.formatMessage({ id: "btn.show-more" })}
          </Text>
          <OSIcon name="chevron-right" ml={1} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ShowMore;
