import React from "react";
import { Box } from "@theme-ui/components";

const NoShipping = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g transform="translate(-12206.875 1136.375)">
      <g transform="translate(0 -4)" opacity="0.4">
        <line
          x2="12.832"
          transform="translate(12234.356 -1090.699)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M29.392,32.3h2.74V27.579a6.722,6.722,0,0,0-.992-3.5,7.444,7.444,0,0,0-2.83-2.661,8.2,8.2,0,0,0-3.9-.981h-3.5V17.471H1V32.3H3.825"
          transform="translate(12223.967 -1122.996)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M11.166,37.371A2.965,2.965,0,1,1,8.2,34.406a2.966,2.966,0,0,1,2.966,2.965Z"
          transform="translate(12222.698 -1128.07)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M37.239,37.371a2.965,2.965,0,1,1-2.966-2.965,2.966,2.966,0,0,1,2.966,2.965Z"
          transform="translate(12215.896 -1128.07)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          y2="5.874"
          transform="translate(12244.884 -1099.538)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x1="4.485"
          transform="translate(12247.228 -1097.625)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
      <g transform="translate(12213 -1127)">
        <path
          d="M0,11.97A11.976,11.976,0,0,1,12.234,0a12.221,12.221,0,0,1,11.7,11.967,11.515,11.515,0,0,1-1.6,5.983L14.361,33.375a2.749,2.749,0,0,1-4.786,0L1.6,17.952A11.182,11.182,0,0,1,0,11.97Z"
          transform="translate(0 0)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M62.782,26.749,53.7,35.833"
          transform="translate(-46.273 -18.703)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="M53.7,26.749l9.084,9.084"
          transform="translate(-46.272 -18.703)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </Box>
);

export default NoShipping;
