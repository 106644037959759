import React from "react";
import { Box, Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { OSContainer } from "../../../components/base";
import { OSIcon } from "../../../components/icons";
import { Heading3, Heading4 } from "../../../components/heading";

import { clearSession } from "../../../config/redux/slices";
import { useDispatch } from "react-redux";

const PlanPlaceholder = ({ ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onChooseDifferentDevice = async () => {
    await dispatch(clearSession());
    window.location.assign("http://devmagento.mindcti.com/plans");
  };

  return (
    <Box sx={{ width: "100%" }} {...props}>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
        <Heading3 color="primary">{intl.formatMessage({ id: "lbl.plan" })}</Heading3>
        <Flex
          mr={["none", "none", 10]}
          onClick={onChooseDifferentDevice}
          sx={{ cursor: "pointer", alignItems: "center" }}>
          <Text variant="subtitle1" mt={0} color="ternary" sx={{ textDecoration: "underline" }}>
            {intl.formatMessage({ id: "lbl.choose-a-plan" })}
          </Text>
          <OSIcon name="chevron-right" ml={2} />
        </Flex>
      </Flex>
      <OSContainer
        variant="device-and-plan-section"
        sx={{
          alignItems: ["flex-start", "center", "center"],
          flexDirection: ["column", "row", "row"],
        }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: ["center", "flex-start", "space-around"],
            width: ["100%", "22rem", "19rem"],
          }}>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              minWidth: "7rem",
              width: "7rem",
            }}></Flex>
          <Flex sx={{ justifyContent: "center", flexWrap: "wrap", width: "100%" }}>
            <Heading4 sx={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "lbl.add-plan" })}
            </Heading4>
          </Flex>
        </Flex>
        <Flex
          mt={[5, "none", "none"]}
          mx={["none", "none", 8.5]}
          sx={{
            alignItems: ["center", "flex-start", "flex-start"],
            flexDirection: "column",
          }}>
          <Text variant="lineDescription">
            {intl.formatMessage({ id: "lbl.no-plan-description" })}
          </Text>
        </Flex>
      </OSContainer>
    </Box>
  );
};

export default PlanPlaceholder;
