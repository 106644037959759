import React from "react";
import { Box, Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import Amount from "../amount";

import { PRICE_TYPE, PRICE_TERMS_UNIT, EXTRA_INFO } from "../../common/Constants";

export const isServiceDiscountEasyTabAssociated = (serviceDiscount) => {
  return (
    Object.fromEntries(serviceDiscount.extraInfo.split(";").map((elem) => elem.split("=")))[
      EXTRA_INFO.EASY_TAB_ASSOCIATED
    ] === "Y"
  );
};

export const isServiceDiscountFromRule = (serviceDiscount) => {
  const ruleCode = Object.fromEntries(
    serviceDiscount.extraInfo.split(";").map((elem) => elem.split("="))
  )[EXTRA_INFO.DISCOUNT_RULE_CODE];
  return ruleCode !== undefined && ruleCode.length > 0;
};

export function getNoOfDeferredMonths(serviceDiscount) {
  if (serviceDiscount.extraInfo === null) {
    return 0;
  }
  const deferredMonths = Object.fromEntries(
    serviceDiscount.extraInfo?.split(";").map((elem) => elem.split("="))
  )[EXTRA_INFO.DEFERRED_MONTHS];
  if (deferredMonths !== undefined) {
    return deferredMonths;
  } else return 0;
}

export const isEasyTabAssocitiedFlag = (serviceDiscount) => {
  const easyTabAssocitied = Object.fromEntries(
    serviceDiscount.extraInfo.split(";").map((elem) => elem.split("="))
  )[EXTRA_INFO.EASY_TAB_ASSOCIATED];
  return easyTabAssocitied !== undefined && easyTabAssocitied.length > 0;
};

const PlanPriceOffer = ({ prices, isEasyTabOfferSelected, ...props }) => {
  const intl = useIntl();
  const rcPlanPrice = prices.find((price) => price.type === PRICE_TYPE.PLAN_RECURRING);
  const otPlanPrice = prices.find((price) => price.type === PRICE_TYPE.PLAN_ONE_TIME);
  const e911SrvPrice = prices.find((price) => price.type === PRICE_TYPE.PLAN_E911);
  const connectionFeeSrvPrice = prices.find(
    (price) => price.type === PRICE_TYPE.PLAN_CONNECTION_FEE
  );

  const serviceDiscountPricesList = prices
    .filter((price) => price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT)
    .filter((price) => price.price !== 0)
    .filter((price) => isServiceDiscountFromRule(price))
    .filter((price) => getNoOfDeferredMonths(price) == 0)
    .filter((price) =>
      isEasyTabOfferSelected
        ? isServiceDiscountEasyTabAssociated(price)
        : !isServiceDiscountEasyTabAssociated(price)
    );

  const serviceDiscountAssignedInPackagePricesList = prices
    .filter((price) => price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT)
    .filter((price) => price.price !== 0)
    .filter((price) => !isServiceDiscountFromRule(price))
    .filter((price) => !isEasyTabAssocitiedFlag(price))
    .filter((price) => getNoOfDeferredMonths(price) == 0);

  const serviceDiscountAssignedInPackagePricesListHUG = prices
    .filter((price) => price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT)
    .filter((price) => price.price !== 0)
    .filter((price) => !isServiceDiscountFromRule(price))
    .filter((price) => isEasyTabAssocitiedFlag(price))
    .filter((price) => getNoOfDeferredMonths(price) == 0)
    .filter((price) =>
      isEasyTabOfferSelected
        ? isServiceDiscountEasyTabAssociated(price)
        : !isServiceDiscountEasyTabAssociated(price)
    );

  const serviceDiscountExistingPricesList = prices
    .filter((price) => price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING)
    .filter((price) => price.price !== 0)
    .filter((price) =>
      isEasyTabOfferSelected
        ? isServiceDiscountEasyTabAssociated(price)
        : !isServiceDiscountEasyTabAssociated(price)
    );
  const isPriceWithTax = prices
    .filter((price) =>
      [
        PRICE_TYPE.PLAN_RECURRING,
        PRICE_TYPE.PLAN_ONE_TIME,
        PRICE_TYPE.PLAN_E911,
        PRICE_TYPE.PLAN_CONNECTION_FEE,
      ].includes(price.type)
    )
    .concat(serviceDiscountPricesList)
    .concat(serviceDiscountAssignedInPackagePricesList)
    .concat(serviceDiscountAssignedInPackagePricesListHUG)
    .concat(serviceDiscountExistingPricesList)
    .find((price) => price.tax !== 0);

  const RenderServiceFeePlanPrice = ({ srvPrice, ...props }) => {
    return (
      <Flex
        sx={{
          width: "100%",
          justifyContent: "space-between",
          gap: "5",
          alignItems: "center",
        }}
        {...props}>
        <Flex
          sx={{
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Amount
            mt={1}
            amount={srvPrice.price}
            isMonthly={srvPrice.termsUnit === PRICE_TERMS_UNIT.MONTHS}
            description={
              srvPrice.termsUnit !== PRICE_TERMS_UNIT.MONTHS &&
              intl.formatMessage({ id: "lbl.one-time" })
            }
            color="ternary"
            descriptionColor="primary"
          />
        </Flex>
        <Flex sx={{ flex: 2, flexDirection: "column", alignItems: "flex-start" }}>
          <Text variant="labelSmall">{srvPrice.description}</Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      <Box
        sx={{
          bg: "secondaryBg",
          width: "100%",
          border: 1,
          borderColor: "cardBorderColor",
          borderRadius: "lg",
          gridTemplateColumns: "1fr .125fr 1fr",
        }}
        {...props}>
        <Flex sx={{ width: "100%", flexDirection: "column", justifyContent: "center" }}>
          <Flex sx={{ justifyContent: "flex-start" }}>
            <Flex
              py={4}
              px={[2, 4, 4]}
              sx={{ flex: 1, flexDirection: "column", justifyContent: "center" }}>
              {rcPlanPrice && (
                <Amount
                  amount={rcPlanPrice.price}
                  isMonthly
                  description={
                    rcPlanPrice.termsAmount > 0 &&
                    intl.formatMessage(
                      { id: "lbl.for-x-months" },
                      { noOfMonths: rcPlanPrice.termsAmount }
                    )
                  }
                  color="ternary"
                  descriptionColor="primary"
                />
              )}
              {otPlanPrice && otPlanPrice.price > 0 && (
                <Amount
                  amount={otPlanPrice.price}
                  description={intl.formatMessage({ id: "lbl.one-time" })}
                  color="ternary"
                  descriptionColor="primary"
                  mt={2}
                />
              )}
            </Flex>
            <Flex
              py={4}
              px={[2, 4, 4]}
              sx={{
                borderTopRightRadius: "lg",
                borderBottomRightRadius: "lg",
                flex: 2,
                bg: "windowBg",
                flexDirection: "column",
              }}>
              {e911SrvPrice && (
                <RenderServiceFeePlanPrice srvPrice={e911SrvPrice}></RenderServiceFeePlanPrice>
              )}
              {connectionFeeSrvPrice && (
                <RenderServiceFeePlanPrice
                  srvPrice={connectionFeeSrvPrice}
                  mt={2}></RenderServiceFeePlanPrice>
              )}
              {serviceDiscountExistingPricesList.map((serviceDiscountPrice, index) => (
                <RenderServiceFeePlanPrice key={index} srvPrice={serviceDiscountPrice} mt={2} />
              ))}
              {serviceDiscountPricesList.map((serviceDiscountPrice, index) => (
                <RenderServiceFeePlanPrice key={index} srvPrice={serviceDiscountPrice} mt={2} />
              ))}
              {serviceDiscountAssignedInPackagePricesList.map((serviceDiscountPrice, index) => (
                <RenderServiceFeePlanPrice key={index} srvPrice={serviceDiscountPrice} mt={2} />
              ))}
              {serviceDiscountAssignedInPackagePricesListHUG.map((serviceDiscountPrice, index) => (
                <RenderServiceFeePlanPrice key={index} srvPrice={serviceDiscountPrice} mt={2} />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Box>
      {isPriceWithTax && (
        <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
      )}
    </>
  );
};

export default PlanPriceOffer;
