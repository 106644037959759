import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, Flex, Link } from "@theme-ui/components";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Device from "./bundle-items/Device";
import OldDevice from "./bundle-items/OldDevice";
import DevicePlaceholder from "./bundle-items/DevicePlaceholder";
import Plan from "./bundle-items/Plan";
import PlanPlaceholder from "./bundle-items/PlanPlaceholder";
import {
  PRICE_TYPE,
  PRICE_TERMS_UNIT,
  PERSONAL_INFORMATION_FLOW,
  PRICING_OPTION,
  POS_PARAMETER,
  EXTRA_INFO,
} from "../../common/Constants";
import { BundleItemSkeleton } from "../../components/skeletons";
import AdditionalServices from "./additional-services";
import GroupManagement from "./group-management";
import TotalPrices from "../../components/total-prices";
import {
  isServiceDiscountEasyTabAssociated,
  isServiceDiscountFromRule,
  isEasyTabAssocitiedFlag,
  getNoOfDeferredMonths,
} from "../../components/plan-price-offer/PlanPriceOffer";
import SelfcareAccess from "./selfcare-access";
import { PopupModal } from "../../components/modals";
import AmountPlain from "../../components/AmountPlain";
import { getExtraInfoValues, updateExtraInfo } from "../../common/StringUtilities";
import {
  clearGetBundleDiscounts,
  clearUpdateItemApiResponse,
  getBundleDiscounts,
  getBundlePriceOffers,
  updateItem,
} from "../../config/redux/slices";

const EasyTabMessage = ({ amount }) => {
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  return (
    <Flex
      sx={{
        flexDirection: "column",
      }}>
      <Text color="ternary" sx={{ textAlign: "left" }}>
        <FormattedMessage
          id="lbl.easyTab-flex-choose"
          values={{
            sup: (chunks) => (
              <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
      <Text color="ternary" sx={{ textAlign: "left", my: 1 }}>
        <FormattedMessage
          id="lbl.easyTab-flex-option1"
          values={{
            bullet: "\u2022",
          }}
        />
      </Text>
      <Text color="ternary" sx={{ textAlign: "left", my: 1 }}>
        <FormattedMessage
          id="lbl.easyTab-flex-option2"
          values={{
            bullet: "\u2022",
            sup: (chunks) => (
              <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%" }}>
                {chunks}
              </Text>
            ),
            amount: <AmountPlain color="ternary" amount={amount} />,
          }}
        />
      </Text>
      <Text color="ternary" sx={{ textAlign: "left" }}>
        <FormattedMessage
          id="lbl.see-full-terms-condition"
          values={{
            a: (chunks) => (
              <Link
                variant="chat-link"
                href={posParameters[POS_PARAMETER.OS_TERMS_AND_CONDITIONS_URL]}
                target="_blank">
                {chunks}
              </Link>
            ),
          }}
        />
      </Text>
    </Flex>
  );
};

const Bundle = ({
  bundle,
  activeTabIndex,
  numberOfBundles,
  updateParentBundlePrice,
  bundlePriceOffers,
  selectedDevicePriceOffer,
  onSelectDevicePriceOffer,
  loadingBundle,
  onBundlePriceOffersChange,
  changeItem,
  setChangeItem,
  existingDeviceDetails,
  isEasyTabOfferSelected,
  onAccessLevelModified,
  selfcareProfile,
  untouchedMandatoryFields,
  updateCurrentItem,
  groupManagementError,
  linesTitles,
  simType,
  onChangeSimType,
  onChangeBYODInfo,
  ...props
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [deviceMonthlyCharges, setDeviceMonthlyCharges] = useState(0);
  const [deviceOneTimeCharges, setDeviceOneTimeCharges] = useState(0);
  const [oldEasyTabCharges, setOldEasyTabCharges] = useState(0);
  const [planMonthlyCharges, setPlanMonthlyCharges] = useState({});
  const [planOneTimeCharges, setPlanOneTimeCharges] = useState({});
  const [hasGroupManagement, setHasGroupManagement] = useState(false);
  const [discountsMonthlyCharges, setDiscountsMonthlyCharges] = useState({});
  const [discountsOneTimeCharges, setDiscountsOneTimeCharges] = useState({});
  const { planFlags } = useSelector((state) => state.items);
  const personalInfoState = useSelector((state) => state.personalInformation);
  const itemPriceState = useSelector((state) => state.itemPrice);
  const [specialDiscounts, setSpecialDiscounts] = useState(null);
  const { flow } = useSelector((state) => state.personalInformation);
  const [isDeviceChargeTaxFree, setIsDeviceChargeTaxFree] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    message: "",
    onClickBtn1: null,
    onClickBtn2: null,
  });

  const devicePriceOffers = bundlePriceOffers.filter((item) => item.code !== "PLAN");
  const planPriceOffers = bundlePriceOffers.filter((item) => item.code === "PLAN");
  const defaultPlanPriceOffer = planPriceOffers[0];
  const lineTitle =
    linesTitles[activeTabIndex].firstName.length === 0 &&
    linesTitles[activeTabIndex].lastName.length === 0
      ? `${intl.formatMessage({ id: "lbl.line" })} ${activeTabIndex + 1}`
      : linesTitles[activeTabIndex].firstName.concat(" " + linesTitles[activeTabIndex].lastName);

  const onSelectPriceOfferDevice = (priceOffer) => {
    if (!priceOffer) return;
    computeDevicePrices(priceOffer.price);
    onSelectDevicePriceOffer(priceOffer);
  };

  const computeDevicePrices = (prices) => {
    const rcCharge = prices.find((price) => price.type === PRICE_TYPE.RECURRING)?.price ?? 0;
    const otCharge = prices.find((price) => price.type === PRICE_TYPE.ONE_TIME)?.price ?? 0;
    const otChargeTax = prices.find((price) => price.type === PRICE_TYPE.ONE_TIME)?.tax ?? 0;

    setDeviceMonthlyCharges(rcCharge);
    setDeviceOneTimeCharges(otCharge + otChargeTax);
  };

  const getDiscountsCharges = useCallback(
    (prices, termsUnit) => {
      return prices
        .filter(
          (price) =>
            price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT && price.termsUnit === termsUnit
        )
        .filter((price) => isServiceDiscountFromRule(price))
        .filter((price) => getNoOfDeferredMonths(price) == 0)
        .filter((price) =>
          isEasyTabOfferSelected
            ? isServiceDiscountEasyTabAssociated(price)
            : !isServiceDiscountEasyTabAssociated(price)
        );
    },
    [isEasyTabOfferSelected]
  );

  const getExistingDiscountsCharges = useCallback(
    (prices, termsUnit) => {
      return prices
        .filter(
          (price) =>
            price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING &&
            price.termsUnit === termsUnit
        )
        .filter((price) =>
          isEasyTabOfferSelected
            ? isServiceDiscountEasyTabAssociated(price)
            : !isServiceDiscountEasyTabAssociated(price)
        );
    },
    [isEasyTabOfferSelected]
  );

  const getAssignedByDefaultDiscountsCharges = (prices, termsUnit) => {
    return prices
      .filter(
        (price) => price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT && price.termsUnit === termsUnit
      )
      .filter((price) => !isServiceDiscountFromRule(price))
      .filter((price) => !isEasyTabAssocitiedFlag(price))
      .filter((price) => getNoOfDeferredMonths(price) == 0);
  };

  const getAssignedByDefaultDiscountsChargesHUG = useCallback(
    (prices, termsUnit) => {
      return prices
        .filter(
          (price) =>
            price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT && price.termsUnit === termsUnit
        )
        .filter((price) => !isServiceDiscountFromRule(price))
        .filter((price) => isEasyTabAssocitiedFlag(price))
        .filter((price) => getNoOfDeferredMonths(price) == 0)
        .filter((price) =>
          isEasyTabOfferSelected
            ? isServiceDiscountEasyTabAssociated(price)
            : !isServiceDiscountEasyTabAssociated(price)
        );
    },
    [isEasyTabOfferSelected]
  );

  const computePlanPrices = useCallback(
    (prices) => {
      let planMonthlyCharges = 0;
      let discountsMonthlyCharges = 0;
      let discountsOneTimeCharges = 0;
      let planOneTimeCharges = {};
      let connectionFeeCharges = {};
      let additionalOneTimeCharges = {};

      let planMonthlyPrices = prices.filter((price) =>
        [
          PRICE_TYPE.PLAN_RECURRING,
          PRICE_TYPE.PLAN_ADDITIONAL_RECURRING,
          PRICE_TYPE.PLAN_E911,
        ].includes(price.type)
      );
      planMonthlyCharges += planMonthlyPrices
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);
      let isMonthlyPlanChargesWithTax = planMonthlyPrices.find((price) => price.tax > 0);

      let planOneTimePrices = prices.filter((price) =>
        [
          PRICE_TYPE.PLAN_ONE_TIME,
          PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME,
          PRICE_TYPE.PLAN_CONNECTION_FEE,
        ].includes(price.type)
      );
      planOneTimePrices.map((priceObj) => {
        if (PRICE_TYPE.PLAN_ONE_TIME === priceObj.type) {
          planOneTimeCharges = {
            amount: priceObj.price,
            isTaxFree: priceObj.tax <= 0,
          };
        }
        if (PRICE_TYPE.PLAN_CONNECTION_FEE === priceObj.type) {
          connectionFeeCharges = {
            description: priceObj.description,
            amount: priceObj.price,
            isTaxFree: priceObj.tax <= 0,
          };
        }
        if (PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME === priceObj.type) {
          additionalOneTimeCharges = {
            amount: priceObj.price,
            isTaxFree: priceObj.tax <= 0,
          };
        }
      });

      let monthlyDiscountCharges = getDiscountsCharges(prices, PRICE_TERMS_UNIT.MONTHS);
      discountsMonthlyCharges += monthlyDiscountCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);
      let monthlyAssignedByDefaultDiscountsCharges = getAssignedByDefaultDiscountsCharges(
        prices,
        PRICE_TERMS_UNIT.MONTHS
      );
      discountsMonthlyCharges += monthlyAssignedByDefaultDiscountsCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);
      let monthlyAssignedByDefaultDiscountsChargesHUG = getAssignedByDefaultDiscountsChargesHUG(
        prices,
        PRICE_TERMS_UNIT.MONTHS
      );
      discountsMonthlyCharges += monthlyAssignedByDefaultDiscountsChargesHUG
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);

      let existingDiscountsCharges = getExistingDiscountsCharges(prices, PRICE_TERMS_UNIT.MONTHS);
      discountsMonthlyCharges += existingDiscountsCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);

      let isMonthlyDiscountChargesWithTax = monthlyDiscountCharges
        .concat(monthlyAssignedByDefaultDiscountsCharges)
        .concat(monthlyAssignedByDefaultDiscountsChargesHUG)
        .concat(existingDiscountsCharges)
        .find((price) => price.tax !== 0);

      let oneTimeDiscountsCharges = getDiscountsCharges(prices, PRICE_TERMS_UNIT.NONE);
      discountsOneTimeCharges += oneTimeDiscountsCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);
      let oneTimeAssignedByDefaultDiscountsCharges = getAssignedByDefaultDiscountsCharges(
        prices,
        PRICE_TERMS_UNIT.NONE
      );
      discountsOneTimeCharges += oneTimeAssignedByDefaultDiscountsCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);

      let oneTimeAssignedByDefaultDiscountsChargesHUG = getAssignedByDefaultDiscountsChargesHUG(
        prices,
        PRICE_TERMS_UNIT.NONE
      );
      discountsOneTimeCharges += oneTimeAssignedByDefaultDiscountsChargesHUG
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);

      let existingDiscountsOneTimeCharges = getExistingDiscountsCharges(
        prices,
        PRICE_TERMS_UNIT.NONE
      );
      discountsOneTimeCharges += existingDiscountsOneTimeCharges
        .map((priceObj) => priceObj.price)
        .reduce((prev, curr) => prev + curr, 0);

      let isOneTimeDiscountChargesWithTax = oneTimeDiscountsCharges
        .concat(oneTimeAssignedByDefaultDiscountsCharges)
        .concat(oneTimeAssignedByDefaultDiscountsChargesHUG)
        .concat(existingDiscountsOneTimeCharges)
        .find((price) => price.tax !== 0);

      setPlanMonthlyCharges({
        amount: planMonthlyCharges,
        isTaxFree: !isMonthlyPlanChargesWithTax,
      });
      setPlanOneTimeCharges({
        planOneTime: planOneTimeCharges,
        connectionFee: connectionFeeCharges,
        additionalOneTime: additionalOneTimeCharges,
      });
      setDiscountsMonthlyCharges({
        amount: discountsMonthlyCharges,
        isTaxFree: !isMonthlyDiscountChargesWithTax,
      });
      setDiscountsOneTimeCharges({
        amount: discountsOneTimeCharges,
        isTaxFree: !isOneTimeDiscountChargesWithTax,
      });
    },
    [getAssignedByDefaultDiscountsChargesHUG, getDiscountsCharges, getExistingDiscountsCharges]
  );

  const computeSpecialDiscounts = useCallback(
    (prices) => {
      if (prices) {
        const result = prices
          .filter(
            (price) =>
              price.type === PRICE_TYPE.PLAN_SERVICE_IVD ||
              PRICE_TYPE.PLAN_SERVICE_IVD_EXISTING === price.type
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .filter((price) =>
            isEasyTabOfferSelected
              ? isServiceDiscountEasyTabAssociated(price)
              : !isServiceDiscountEasyTabAssociated(price)
          )
          .map((priceObj) => priceObj.description);
        setSpecialDiscounts(result);
      }
    },
    [isEasyTabOfferSelected]
  );

  const computeOldEasyTabPrice = (prices) => {
    if (prices) {
      const oldEasyTabCharge =
        prices.find((price) => price.type === PRICE_TYPE.OLD_INSTALLMENT)?.price ?? 0;
      setOldEasyTabCharges(oldEasyTabCharge);
    }
  };

  const computeDevicePriceWithTax = (bundle, priceOffers) => {
    let isDeviceChargeTaxFree =
      priceOffers
        .filter((item) => item.code !== "PLAN")
        .find((offer) => offer.code === bundle.sku)
        ?.price.find((price) => price.type === PRICE_TYPE.ONE_TIME)?.tax === 0;
    isDeviceChargeTaxFree && setIsDeviceChargeTaxFree(true);
  };

  useEffect(() => {
    selectedDevicePriceOffer && computeDevicePrices(selectedDevicePriceOffer.price);
    bundle && computeOldEasyTabPrice(bundle.prices);
    defaultPlanPriceOffer && computePlanPrices(defaultPlanPriceOffer.price);
    defaultPlanPriceOffer && computeSpecialDiscounts(defaultPlanPriceOffer.price);
    bundle &&
      bundlePriceOffers &&
      bundlePriceOffers.length > 0 &&
      computeDevicePriceWithTax(bundle, bundlePriceOffers);
  }, [
    bundle,
    computePlanPrices,
    defaultPlanPriceOffer,
    selectedDevicePriceOffer,
    computeSpecialDiscounts,
    bundlePriceOffers,
  ]);

  useEffect(() => {
    const extraInfo = bundle && getExtraInfoValues(bundle.extraInfo);
    if (
      itemPriceState.checkEasyTabFlexCompatibility.success &&
      personalInfoState.getCreditLevelDetails.success &&
      personalInfoState.getCreditLevelDetails.creditLevelDetails &&
      personalInfoState.getCreditLevelDetails.creditLevelDetails.supportFlex &&
      bundle?.pricingOption === PRICING_OPTION.EASYTABFLEX &&
      !extraInfo[EXTRA_INFO.SELECTED_PRICING_OPTION] &&
      bundlePriceOffers.some((offer) => offer.itemId === bundle.id)
    ) {
      const deferredAmount = bundlePriceOffers
        .filter((priceOffer) => priceOffer.code !== bundle.sku)
        .map((priceOffer) => priceOffer.price)
        .flat()
        .find((price) => price.type === PRICE_TYPE.FLEX_DEFERRED_AMOUNT)?.price;
      deferredAmount &&
        setModal({
          isOpen: true,
          message: <EasyTabMessage amount={deferredAmount} />,
          onClickBtn1: () => choosePricingOption(PRICING_OPTION.EASYTABFLEX),
          onClickBtn2: () => choosePricingOption(PRICING_OPTION.EASYTAB),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bundle, itemPriceState.checkEasyTabFlexCompatibility.success, bundlePriceOffers]);

  const choosePricingOption = async (option) => {
    const extraInfo = updateExtraInfo(bundle.extraInfo, EXTRA_INFO.SELECTED_PRICING_OPTION, option);
    let itemUpdateRequest = {
      ...bundle,
      extraInfo: extraInfo,
      jsonBundleRequest: null,
    };
    await dispatch(updateItem(itemUpdateRequest));
    await dispatch(clearUpdateItemApiResponse());
    await dispatch(clearGetBundleDiscounts());
    await dispatch(getBundlePriceOffers());
    await dispatch(getBundleDiscounts({ itemId: bundle.id }));
    onSelectDevicePriceOffer(null);
  };

  const computeAdditionalServicesPrices = useCallback(
    (rcFee, setupFee, noInstances) => {
      let monthlyCharges = {
        ...planMonthlyCharges,
        amount: planMonthlyCharges.amount ? planMonthlyCharges.amount : 0 + rcFee * noInstances,
      };
      setPlanMonthlyCharges(monthlyCharges);

      let additionalOneTimeCharges = {
        ...planOneTimeCharges.additionalOneTime,
        amount: planOneTimeCharges.additionalOneTime?.amount
          ? planOneTimeCharges.additionalOneTime?.amount
          : 0 + setupFee * noInstances,
      };
      let oneTimeCharges = {
        ...planOneTimeCharges,
        additionalOneTime: additionalOneTimeCharges,
      };
      setPlanOneTimeCharges(oneTimeCharges);

      if (rcFee > 0 || setupFee > 0) {
        updateParentBundlePrice(rcFee, setupFee, noInstances);
      }
    },
    [updateParentBundlePrice, planMonthlyCharges, planOneTimeCharges]
  );

  const getOldDeviceLine = (loadingBundle, bundle) => {
    const oldInstallmentPrice = bundle.prices.find(
      (price) => price.type === PRICE_TYPE.OLD_INSTALLMENT
    );
    if (oldInstallmentPrice) {
      if (loadingBundle) {
        return <BundleItemSkeleton title={intl.formatMessage({ id: "lbl.old-device" })} />;
      }
      return (
        <OldDevice device={existingDeviceDetails} taxIncluded={!isDeviceChargeTaxFree} mb={10} />
      );
    }
  };

  const getDeviceLine = (
    loadingBundle,
    bundle,
    devicePriceOffers,
    selectedDevicePriceOffer,
    setChangeItem,
    numberOfBundles
  ) => {
    if (loadingBundle || itemPriceState.checkEasyTabFlexCompatibility.error) {
      return <BundleItemSkeleton title={intl.formatMessage({ id: "lbl.device" })} />;
    }

    if (!bundle || (bundle && bundle.sku === null)) {
      return (
        <DevicePlaceholder
          device={bundle}
          setChangeItem={setChangeItem}
          simType={simType}
          onChangeSimType={onChangeSimType}
          onChangeBYODInfo={onChangeBYODInfo}
          untouchedMandatoryFields={untouchedMandatoryFields}
        />
      );
    }

    return (
      <Device
        device={bundle}
        activeTabIndex={activeTabIndex}
        priceOffers={devicePriceOffers}
        selectedPriceOffer={selectedDevicePriceOffer}
        onSelectPriceOffer={onSelectPriceOfferDevice}
        onBundlePriceOffersChange={onBundlePriceOffersChange}
        setChangeItem={setChangeItem}
        numberOfBundles={numberOfBundles}
        updateCurrentItem={updateCurrentItem}
        linesTitles={linesTitles}
        simType={simType}
        onChangeSimType={onChangeSimType}
      />
    );
  };

  const getPlanLine = (
    loadingBundle,
    bundle,
    planPriceOffers,
    setChangeItem,
    numberOfBundles,
    hasGroupManagement,
    changeItem
  ) => {
    if (loadingBundle) {
      return <BundleItemSkeleton title={intl.formatMessage({ id: "lbl.plan" })} mt={10} />;
    }

    if (!bundle || (bundle && bundle.packageCode === null)) {
      return <PlanPlaceholder mt={10} />;
    }

    return (
      <Plan
        plan={bundle}
        priceOffers={planPriceOffers}
        setChangeItem={setChangeItem}
        numberOfBundles={numberOfBundles}
        mt={10}
        hasGroupManagement={hasGroupManagement}
        isEasyTabOfferSelected={isEasyTabOfferSelected}
        changeItem={changeItem}
      />
    );
  };

  return (
    <Box mt={6} px={[8, 8, 9]} {...props}>
      <PopupModal
        modal={modal}
        isCloseDisabled
        onClearModal={() =>
          setModal({
            isOpen: false,
            message: "",
            onClickBtn1: null,
            onClickBtn2: null,
          })
        }
        button1Id="btn.continue-easyTab-flex"
        button2Id="btn.continue-easyTab"
        type="error"
      />
      {changeItem !== "PLAN" &&
        getDeviceLine(
          loadingBundle,
          bundle,
          devicePriceOffers,
          selectedDevicePriceOffer,
          setChangeItem,
          numberOfBundles
        )}
      {bundle &&
        bundle.associatedSaID > 0 &&
        !changeItem &&
        getOldDeviceLine(loadingBundle, bundle)}
      {changeItem !== "DEVICE" &&
        getPlanLine(
          loadingBundle,
          bundle,
          planPriceOffers,
          setChangeItem,
          numberOfBundles,
          hasGroupManagement,
          changeItem
        )}
      {bundle && bundle.packageCode && !changeItem && (
        <GroupManagement
          setHasGroupManagement={setHasGroupManagement}
          currentBundle={bundle}
          groupManagementError={groupManagementError}
        />
      )}
      {planFlags.additionalServicesExists && !loadingBundle && !changeItem && (
        <AdditionalServices
          bundle={bundle}
          onSelectService={computeAdditionalServicesPrices}
          untouchedMandatoryFields={untouchedMandatoryFields}
          plusTax={!planMonthlyCharges?.isTaxFree || !planOneTimeCharges?.isTaxFree}
        />
      )}

      {flow !== PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE && !changeItem && (
        <SelfcareAccess
          onAccessLevelModified={onAccessLevelModified}
          selfcareProfile={selfcareProfile}
          untouchedMandatoryFields={untouchedMandatoryFields}
        />
      )}

      {!changeItem && !loadingBundle && (
        <TotalPrices
          lineTitle={lineTitle}
          dueNowPrices={{
            devicePrice: { amount: deviceOneTimeCharges, isTaxFree: isDeviceChargeTaxFree },
            oldEasyTabPrice: { amount: oldEasyTabCharges, isTaxFree: isDeviceChargeTaxFree },
          }}
          monthlyPrices={{
            ratePlanFeaturesPrice: planMonthlyCharges,
            easyTabPrice: { amount: deviceMonthlyCharges, isTaxFree: isDeviceChargeTaxFree },
            discountsPrice: discountsMonthlyCharges,
          }}
          oneTimePrices={{
            ...planOneTimeCharges,
            discountsPrice: discountsOneTimeCharges,
          }}
          specialDiscounts={specialDiscounts}
          flow={personalInfoState.flow}
        />
      )}
    </Box>
  );
};

export default Bundle;
