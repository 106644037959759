import React, { useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";

import CartTotal from "./CartTotal";
import CartContent from "./CartContent";
import { ShoppingCartSkeleton } from "../skeletons";
import { ITEM_CATEGORY, PRICE_TERMS_UNIT, PRICE_TYPE } from "../../common/Constants";
import { getItems } from "../../config/redux/slices";

import { OSIcon } from "../icons";
import { useIntl } from "react-intl";
import { SHIPPING_FLOW } from "../../pages/shipping-details/Constants";
import ShoppingCartTotal from "./ShoppingCartTotal";
import { getNoOfDeferredMonths } from "../../components/plan-price-offer/PlanPriceOffer";

const ShoppingCart = ({
  showShipping,
  plusTax,
  showPrices = true,
  showAllItems,
  flow,
  ...props
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [collapsed, setCollapsed] = useState(true);
  const itemsState = useSelector((state) => state.items);
  const { sessionId } = useSelector((state) => state.session);
  const { shippingFlow } = useSelector((state) => state.shippingDetails);

  useEffect(() => {
    if (sessionId && !itemsState.getItems.success) {
      dispatch(getItems());
    }
  }, [sessionId, dispatch, itemsState.getItems.success]);

  const items = showAllItems ? itemsState.getItems.allItems : itemsState.getItems.items;

  const { dueNowCharges, monthlyCharges, oneTimeCharges, specialDiscounts } = useMemo(() => {
    let accessoriesPrice = 0;
    let devicePrice = 0;
    let shippingPrice = 0;
    let ratePlanFeaturesPrice = 0;
    let easyTabPrice = 0;
    let oldEasyTabPrice = 0;
    let oneTimePrice = 0;
    let dueNowTax = 0;
    let monthlyTax = 0;
    let oneTimeTax = 0;
    let discountsMonthlyCharges = 0;
    let discountsOneTimeCharges = 0;
    let specialDiscounts = [];
    let isAccessoryTaxFree = false;
    let isDeviceWithTax = false;
    let taxComputed = false;
    let connectionFeeCharges = {};
    let additionalOneTimePrice = 0;

    let lineNo = 0;
    items.map((item) => {
      if (item.category === ITEM_CATEGORY.BUNDLE) {
        lineNo++;
        const lineTitle =
          `${item?.contactFirstName} ${item?.contactLastName}`.trim() ||
          `${intl.formatMessage({ id: "lbl.line" })} ${lineNo}`;
        devicePrice += item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        easyTabPrice += item.prices
          .filter(
            (price) =>
              PRICE_TYPE.RECURRING === price.type && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        let devicePrices = item.prices.filter(
          (price) => PRICE_TYPE.ONE_TIME === price.type || PRICE_TYPE.RECURRING === price.type
        );
        let deviceTaxInfo =
          devicePrices.length > 0 &&
          devicePrices[0].extraInfo &&
          devicePrices[0].extraInfo.length > 0 &&
          Object.fromEntries(devicePrices[0].extraInfo.split(";").map((elem) => elem.split("=")))[
            "IS_TAX_FREE"
          ];
        taxComputed = !item.sku || (deviceTaxInfo !== null && deviceTaxInfo !== undefined);
        if (item.sku && (!deviceTaxInfo || deviceTaxInfo === "N")) {
          isDeviceWithTax = true;
        }

        oldEasyTabPrice += item.prices
          .filter((price) => PRICE_TYPE.OLD_INSTALLMENT === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);

        discountsMonthlyCharges += item.prices
          .filter(
            (price) =>
              (PRICE_TYPE.PLAN_SERVICE_DISCOUNT === price.type ||
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING === price.type) &&
              price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => priceObj.price)
          .reduce((prev, curr) => prev + curr, 0);

        discountsOneTimeCharges += item.prices
          .filter(
            (price) =>
              (PRICE_TYPE.PLAN_SERVICE_DISCOUNT === price.type ||
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING === price.type) &&
              price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => priceObj.price)
          .reduce((prev, curr) => prev + curr, 0);

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_RECURRING,
                PRICE_TYPE.PLAN_ADDITIONAL_RECURRING,
                PRICE_TYPE.PLAN_E911,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            if (
              priceObj.type !== PRICE_TYPE.PLAN_SERVICE_DISCOUNT &&
              priceObj.type !== PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING
            ) {
              ratePlanFeaturesPrice += priceObj.price;
            }
            monthlyTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_ONE_TIME,
                PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME,
                PRICE_TYPE.PLAN_CONNECTION_FEE,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            if (priceObj.type === PRICE_TYPE.PLAN_ONE_TIME) {
              oneTimePrice += priceObj.price;
            }
            oneTimeTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              [PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME, PRICE_TYPE.PLAN_CONNECTION_FEE].includes(
                price.type
              ) && price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .map((priceObj) => {
            if (priceObj.type === PRICE_TYPE.PLAN_CONNECTION_FEE) {
              connectionFeeCharges = {
                ...connectionFeeCharges,
                description: priceObj.description,
                amount: (connectionFeeCharges.amount ?? 0) + priceObj.price,
              };
            }
            if (priceObj.type === PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME) {
              additionalOneTimePrice += priceObj.price;
            }
          });

        item.prices
          .filter(
            (price) =>
              PRICE_TYPE.PLAN_SERVICE_IVD === price.type ||
              PRICE_TYPE.PLAN_SERVICE_IVD_EXISTING === price.type
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            specialDiscounts = [...specialDiscounts, `${lineTitle}: ${priceObj.description}`];
          });
      }

      if (item.category === ITEM_CATEGORY.ACCESSORY) {
        accessoriesPrice += item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => priceObj.price + priceObj.tax)
          .reduce((prev, curr) => prev + curr, 0);
        isAccessoryTaxFree = item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .find((priceObj) => priceObj.tax === 0);
      }

      if (item.category === ITEM_CATEGORY.SHIPPING && showShipping) {
        item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => {
            shippingPrice += priceObj.price;
            dueNowTax += priceObj.tax;
          });
      }
    });

    return {
      dueNowCharges: {
        accessoriesPrice: { amount: accessoriesPrice, isTaxFree: isAccessoryTaxFree },
        devicePrice: { amount: devicePrice, isTaxFree: !isDeviceWithTax, taxComputed: taxComputed },
        oldEasyTabPrice: {
          amount: oldEasyTabPrice,
          isTaxFree: !isDeviceWithTax,
          taxComputed: taxComputed,
        },
        shippingPrice: { amount: shippingPrice },
        tax: dueNowTax,
        total: accessoriesPrice + devicePrice + shippingPrice + dueNowTax + oldEasyTabPrice,
      },
      monthlyCharges: {
        ratePlanFeaturesPrice: { amount: ratePlanFeaturesPrice },
        discountsPrice: { amount: discountsMonthlyCharges },
        easyTabPrice: {
          amount: easyTabPrice,
          isTaxFree: !isDeviceWithTax,
          taxComputed: taxComputed,
        },
        tax: monthlyTax,
        total: ratePlanFeaturesPrice + discountsMonthlyCharges + easyTabPrice + monthlyTax,
      },
      oneTimeCharges: {
        oneTimePrice: { amount: oneTimePrice },
        connectionFeePrice: connectionFeeCharges,
        additionalOneTimePrice: { amount: additionalOneTimePrice },
        discountsPrice: { amount: discountsOneTimeCharges },
        tax: oneTimeTax,
        total:
          oneTimePrice +
          additionalOneTimePrice +
          (connectionFeeCharges.amount ?? 0) +
          discountsOneTimeCharges +
          oneTimeTax,
      },
      specialDiscounts: specialDiscounts,
    };
  }, [intl, items, showShipping]);

  if (itemsState.getItems.loading) {
    return (
      <Flex {...props}>
        <ShoppingCartSkeleton />
      </Flex>
    );
  }

  return (
    <Flex {...props} sx={{ flexDirection: ["column", "column", "column"], ...props.sx }}>
      <ShoppingCartTotal
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        amount={dueNowCharges.total + monthlyCharges.total + oneTimeCharges.total}
      />
      {!collapsed && (
        <Box sx={{ display: ["block", "block", "none"] }}>
          <CartContent
            showShipping={showShipping}
            plusTax={plusTax}
            showPrices={showPrices}
            items={items}
          />
        </Box>
      )}
      <Box sx={{ display: ["none", "none", "block"] }}>
        <CartContent
          showShipping={showShipping}
          plusTax={plusTax}
          showPrices={showPrices}
          items={items}
        />
      </Box>

      <CartTotal
        showTotals={showPrices}
        showShipping={showShipping && shippingFlow !== SHIPPING_FLOW.PICK_IN_STORE}
        dueNowPrices={dueNowCharges}
        monthlyPrices={monthlyCharges}
        oneTimePrices={oneTimeCharges}
        collapsed={collapsed}
        plusTax={plusTax}
        specialDiscounts={specialDiscounts}
        flow={flow}
      />

      <Box
        sx={{ display: ["none", "none", "flex"], alignItems: "center", justifyContent: "center" }}
        mt={4}>
        <OSIcon name="securePayment" />
        <Text variant="summaryLabel" ml={2.5} color="primary">
          {intl.formatMessage({ id: "lbl.secure-payment" })}
        </Text>
      </Box>
    </Flex>
  );
};
export default ShoppingCart;
