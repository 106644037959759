import React from "react";
import Separator from "../../../components/separator";
import ContentLoader from "react-content-loader";

const OSProductSkeleton = () => (
  <>
    <ContentLoader
      speed={2}
      height="28"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="28" />
    </ContentLoader>
    <Separator my={6} />
    <ContentLoader
      speed={2}
      height="28"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="28" />
    </ContentLoader>
    <Separator my={6} />
    <ContentLoader
      speed={2}
      height="28"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="28" />
    </ContentLoader>
    <Separator my={6} />
    <ContentLoader
      speed={2}
      height="28"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="28" />
    </ContentLoader>
    <Separator my={6} />
    <ContentLoader
      speed={2}
      height="28"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="28" />
    </ContentLoader>
  </>
);

export default OSProductSkeleton;
