import React, { useEffect, useRef } from "react";

const OutsideClickHandler = ({ children, onClickOutside }) => {
  const refEvent = useRef();

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!refEvent.current.contains(e.target)) {
        e.stopPropagation();
        onClickOutside();
      }
    };

    document.addEventListener("mousedown", handleMouseDown, true);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown, true);
    };
  }, [onClickOutside]);

  return <div ref={refEvent}>{children}</div>;
};

export { OutsideClickHandler };
