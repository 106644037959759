import React, { useState } from "react";
import { Box, Flex, Text } from "@theme-ui/components";

const Tooltip = ({
  value,
  position = "default",
  isOverflow = true,
  customProps,
  children,
  childrenPY,
  ...props
}) => {
  const [mustDisplay, setMustDisplay] = useState(false);

  let defaultMargins = { ml: "0rem", mt: "0rem" };
  let customPropsMobile = customProps && customProps.mobile ? customProps.mobile : defaultMargins;
  let customPropsTablet = customProps && customProps.tablet ? customProps.tablet : defaultMargins;
  let customPropsDesktop =
    customProps && customProps.desktop ? customProps.desktop : defaultMargins;
  let properties;
  switch (position) {
    case "top":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "-3rem",
      };
      break;
    case "top-noflex":
      properties = {
        alignItems: "center",
        mt: "-5rem",
      };
      break;
    case "bottom":
      properties = {
        flexDirection: "column",
        alignItems: "center",
        mt: "3rem",
      };
      break;
    case "left":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "-5rem",
      };
      break;
    case "right":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: "3rem",
      };
      break;
    case "custom":
      properties = {
        flexDirection: "row",
        alignItems: "center",
        ml: [customPropsMobile.ml, customPropsTablet.ml, customPropsDesktop.ml],
        mt: [customPropsMobile.mt, customPropsTablet.mt, customPropsDesktop.mt],
      };
      break;
    default:
      properties = {
        flexDirection: ["column", "column", "row"],
        alignItems: ["flex-end", "flex-end", "center"],
        ml: ["0", "0", "2rem"],
        mt: ["2rem", "2rem", "0"],
      };
  }

  return (
    <Flex
      {...props}
      sx={{
        flexDirection: properties.flexDirection,
        alignItems: properties.alignItems,
        ...props.sx,
      }}>
      <Box
        sx={{ width: "100%", display: props.display ? props.display : "" }}
        py={childrenPY}
        onTouchStart={(e) => {
          e.stopPropagation();
          setMustDisplay(!mustDisplay);
        }}
        onMouseEnter={() => {
          setMustDisplay(true);
        }}
        onMouseLeave={() => {
          setMustDisplay(false);
        }}>
        {children}
      </Box>
      {isOverflow && value && value !== "" && (
        <Box
          onMouseLeave={() => {
            setMustDisplay(false);
          }}
          sx={{
            mb: properties.mb ? properties.mb : "0",
            mt: properties.mt ? properties.mt : "0",
            ml: properties.ml ? properties.ml : "0",
            mr: properties.mr ? properties.mr : "0",
            width: "auto",
            maxWidth: "20rem",
            overflowWrap: "break-word",
            display: mustDisplay ? "inline-block" : "none",
            position: "absolute",
            zIndex: 111,
            fontSize: "0.875rem",
            paddingY: "1em",
            textAlign: "left",
            bg: "ternaryBg",
            border: "1",
            borderColor: "cardBorderColor",
            borderRadius: "lg",
            boxShadow: "sm",
            px: "0.7rem",
          }}>
          {value && <Text sx={{ color: "#444" }}>{value}</Text>}
        </Box>
      )}
    </Flex>
  );
};

export default Tooltip;
