import React from "react";
import { Heading6 } from "../../../components/heading";
import { OSSwitch } from "../../../components/base";
import { selectFeature } from "../../../config/redux/slices";
import { Box, Flex, Text } from "@theme-ui/components";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import * as PlanUtils from "./PlanUtils";

const FeatureGroups = ({ service, parentProductCode, isMainPlan }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onFeatureSelect = (parentProductCode, serviceCode, featureGroupCode, featureCode) => {
    dispatch(
      selectFeature({
        parentProductCode,
        serviceCode,
        featureGroupCode,
        featureCode,
      })
    );
  };

  if (!service.instances[0].selected || service.instances[0].featuresGroups.length === 0) {
    return null;
  }

  let visibleFeatGroups = service.instances[0].featuresGroups.filter((fg) => fg.visible);
  if (visibleFeatGroups.length === 0) {
    return null;
  }

  return (
    <Box>
      {visibleFeatGroups.map((featureGroup) => (
        <Box
          pl={PlanUtils.canDisplayService(service) ? 6 : 0}
          key={service.code + featureGroup.groupId}>
          {PlanUtils.canDisplayService(service) ? (
            <Flex sx={{ width: "100%" }}>
              <Heading6>{featureGroup.desc}</Heading6>
            </Flex>
          ) : (
            <Text variant={"lineDescription"} sx={{ flex: 1 }} pb={6}>
              {featureGroup.desc}
            </Text>
          )}
          <Box
            px={isMainPlan ? 6 : 0}
            sx={{
              display: "grid",
              gridColumnGap: [3.5, 10, 10],
              gridRowGap: 3.5,
              gridTemplateColumns: ["repeat(1, 1fr)", "repeat(3, 1fr)", "repeat(3, 1fr)"],
              gridAutoRows: "min-content",
            }}>
            {featureGroup.features
              .filter((feat) => feat.visible && feat.description)
              .map((feature, index) => (
                <Flex sx={{ alignItems: "center" }} key={featureGroup.desc + feature.code + index}>
                  <Text
                    mt={1}
                    mb={3}
                    sx={{
                      color: "primary",
                      fontSize: "base",
                      fontWeight: "light",
                      flex: 1,
                    }}>
                    {feature.description
                      ? feature.description
                      : intl.formatMessage({ id: "lbl.none" })}
                  </Text>
                  <OSSwitch
                    checked={feature.selected}
                    smaller
                    id={"id-" + feature.code + "-" + feature.selected}
                    name="serviceValue"
                    onChange={() =>
                      onFeatureSelect(
                        parentProductCode,
                        service.code,
                        featureGroup.code,
                        feature.code
                      )
                    }
                  />
                </Flex>
              ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
export default FeatureGroups;
