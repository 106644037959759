import React from "react";
import ReactDOM from "react-dom";
import TagManager from "gtm-for-react";

import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { GTMContainerID } from "./app/config/security/Deployment";
import "@fontsource/work-sans/latin.css";
import "./app/resources/fonts/museo-sans/index.css";
import "./setupDayjs";

// disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
}

TagManager.initialize({ gtmId: GTMContainerID, nonce: "randomsecret123456" });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
