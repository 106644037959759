import React from "react";
import { Box } from "@theme-ui/components";

const PickInStore = ({ color = "svgStrokePrimary", size = "iconLarge", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54 54"
    sx={{ width: size, height: size, stroke: color }}
    {...props}>
    <g>
      <g transform="translate(773.99 16152.104)">
        <path
          d="M8.461,43.78V27.734a.7.7,0,0,1,.7-.7h9.411a.7.7,0,0,1,.7.7V43.78"
          transform="translate(-772.792 -16147.604)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          width="19.05"
          height="10.811"
          rx="0.7"
          transform="translate(-748.037 -16120.57)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          x2="44"
          transform="translate(-768.99 -16130.869)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          opacity="0.4"
        />
        <line
          x2="34"
          transform="translate(-763.99 -16142.208)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2"
          opacity="0.4"
        />
        <path
          d="M46.074,5.4V1.2a.7.7,0,0,0-.7-.7H6.648a.7.7,0,0,0-.7.7V5.4L.5,16.735V19A2.632,2.632,0,0,0,3.132,21.63H48.889A2.631,2.631,0,0,0,51.521,19V16.735Z"
          transform="translate(-773 -16147.604)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <rect
          width="46.437"
          height="22.149"
          transform="translate(-770 -16125.973)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <g transform="translate(-758.926 -16114.08)" fill="none" opacity="0.4">
          <path
            d="M1.884,3.769A1.884,1.884,0,1,1,3.769,1.884,1.884,1.884,0,0,1,1.884,3.769Z"
            stroke="none"
          />
          <path
            d="M 1.88433837890625 3.768678426742554 C 0.843648374080658 3.768678426742554 -1.6210937019423e-06 2.925028324127197 -1.6210937019423e-06 1.88433837890625 C -1.6210937019423e-06 0.843648374080658 0.843648374080658 -1.6210937019423e-06 1.88433837890625 -1.6210937019423e-06 C 2.925028324127197 -1.6210937019423e-06 3.768678426742554 0.843648374080658 3.768678426742554 1.88433837890625 C 3.767478466033936 2.924548387527466 2.924548387527466 3.767478466033936 1.88433837890625 3.768678426742554 Z"
            stroke="none"
            fill={color}
          />
        </g>
      </g>
    </g>
  </Box>
);

export default PickInStore;
