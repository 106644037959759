import React from "react";
import { Box, Input } from "@theme-ui/components";
import { ErrorMessage, OSLabel, OSSelect } from "../../base";

import { Branding } from "../../../config/branding/Branding";
import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";
import Datepicker from "../../DatePicker/Datepicker";
import { Controller } from "react-hook-form";

/**
 * Generates a label and an form-input with branding enhancements such as visibility, validations, length limitations<br/>
 *
 * @param type - text, password, date, select<br/>
 * @param name - old form-input name<br/>
 * @param section - section from branding if any<br/>
 * @param label - a label id<br/>
 * @param isMandatory<br/>
 * @param errors - from react hook form<br/>
 * @param register - from react hook form<br/>
 * @param validations - an object containing form-input validations in the same format sent to react hook form<br/>
 * @param maxLength<br/>
 * @param minLength<br/>
 * @param children - children of the form-input<br/>
 * @param wrapperProps - props of the wrapper element<br/>
 * @param labelVariant - variant for label<br/>
 * @param inputProps - props for form-input<br/>
 * @returns {JSX.Element|null}
 * @constructor
 */
const FormInput = ({
  control,
  type,
  name,
  section,
  label,
  isMandatory = false,
  errors,
  register,
  validations,
  maxLength,
  minLength,
  mask,
  children = null,
  wrapperProps = {},
  labelVariant,
  errorProps = {},
  id,
  ...inputProps
}) => {
  /**
   * An element will be hidden when it's section or the element itself will be hidden
   * @param name
   * @param section
   * @returns {boolean}
   */
  const isHidden = (name, section) => {
    let sectionBranding = Branding[section];
    if (!sectionBranding || Object.keys(sectionBranding).length === 0) return false;

    if (sectionBranding.visible === false) return true;

    let elementBranding = sectionBranding[name];
    if (!elementBranding) return false;

    return elementBranding.visible === false;
  };

  if (isHidden(name, section)) {
    return null;
  }

  /**
   * Overwrites with property with value from branding if any
   * @param propertyName
   * @param defaultValue
   * @returns {*}
   */
  const getPropertyByName = (propertyName, defaultValue) => {
    let sectionBranding = Branding[section];
    if (!sectionBranding || Object.keys(sectionBranding).length === 0) return defaultValue;

    let elementBranding = sectionBranding[name];
    if (!elementBranding || Object.keys(elementBranding).length === 0) return defaultValue;

    if (elementBranding[propertyName] !== null && elementBranding[propertyName] !== undefined)
      return elementBranding[propertyName];

    return defaultValue;
  };

  /**
   * Loads additional validations (just required fields for now)
   * @returns {{updatedValidations: (*&{required: *}), updatedMandatory: *}}
   */
  const loadUpdatedValidations = () => {
    let updatedValidations = { ...validations };
    let updatedMandatory = getPropertyByName("mandatory", isMandatory);
    if (updatedMandatory) {
      updatedValidations = { ...validations, required: updatedMandatory };
    }
    let updatedMaxLength = getPropertyByName("maxLength", maxLength);
    if (updatedMaxLength) {
      updatedValidations = { ...updatedValidations, maxLength: updatedMaxLength };
    }
    let updatedMinLength = getPropertyByName("minLength", minLength);
    if (updatedMinLength) {
      updatedValidations = { ...updatedValidations, minLength: updatedMinLength };
    }
    return { updatedValidations, updatedMandatory };
  };

  /**
   * Computes the form-input based on received type
   * @param updatedValidations
   * @returns {JSX.Element|null}
   */
  const getInput = (updatedValidations) => {
    let { minLength, maxLength, ...restOfValidations } = updatedValidations;
    let minMaxLength = { minLength, maxLength };
    switch (type) {
      case "date":
        return (
          <Controller
            defaultValue={inputProps.defaultValue}
            key={"date" + inputProps.defaultValue}
            control={control}
            name={name}
            rules={updatedValidations}
            render={({ onChange, onBlur, value, ref }) => (
              <Datepicker
                id={id}
                ref={ref}
                date={value}
                setDate={(date) => {
                  onChange(date);
                  onBlur();
                }}
                error={errors[name]}
                onBlur={onBlur}
              />
            )}
          />
        );
      case "text":
      case "password":
        return (
          <Input
            {...inputProps}
            autoComplete="off"
            variant={errors[name] ? "inputError" : "input"}
            name={name}
            type={type}
            {...minMaxLength}
            ref={register(restOfValidations)}
          />
        );
      case "mask":
        return (
          <Controller
            defaultValue={inputProps.defaultValue}
            key={"mask" + inputProps.defaultValue}
            control={control}
            name={name}
            rules={updatedValidations}
            render={({ onChange, onBlur }) => (
              <InputMask
                id={id}
                {...inputProps}
                name="styledMaskInput"
                onChange={(value) => {
                  onChange(value);
                  inputProps.onChange(value);
                }}
                onBlur={onBlur}
                mask={mask}>
                {(inputMaskedProps) => (
                  <Input
                    {...inputMaskedProps}
                    type="text"
                    autoComplete="off"
                    variant={errors[name] ? "inputError" : "input"}
                  />
                )}
              </InputMask>
            )}
          />
        );
      case "select":
        return (
          <Controller
            defaultValue={inputProps.defaultValue}
            control={control}
            name={name}
            rules={updatedValidations}
            render={({ onChange, onBlur, ref, value }) => (
              <OSSelect
                value={value}
                name={name}
                ref={ref}
                variant={errors[name] ? "selectError" : "select"}
                onChange={(event) => {
                  onChange(event);
                  inputProps.onChange && inputProps.onChange(event);
                  onBlur();
                }}
                sx={{
                  width: "100%",
                }}>
                {children}
              </OSSelect>
            )}
          />
        );

      default:
        return null;
    }
  };

  let { updatedValidations, updatedMandatory } = loadUpdatedValidations();
  let input = getInput(updatedValidations);
  let updatedLabel = getPropertyByName("label", label);

  return (
    <Box {...wrapperProps}>
      <OSLabel isMandatory={updatedMandatory} variant={labelVariant}>
        {updatedLabel}
      </OSLabel>

      {input}

      {errors[name] && errors[name].type === "required" && (
        <ErrorMessage>
          <FormattedMessage id="err.mandatory-field" values={{ field: updatedLabel }} />
        </ErrorMessage>
      )}
      {errors[name] && errors[name].type === "minLength" && (
        <ErrorMessage {...errorProps}>
          <FormattedMessage id="err.value-too-short" />
        </ErrorMessage>
      )}
      {errors[name] && errors[name].type === "maxLength" && (
        <ErrorMessage {...errorProps}>
          <FormattedMessage id="err.value-too-long" />
        </ErrorMessage>
      )}
      {errors[name] && errors[name].message && (
        <ErrorMessage {...errorProps}>{errors[name].message}</ErrorMessage>
      )}
    </Box>
  );
};
export default FormInput;
