import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Flex, Text } from "@theme-ui/components";
import { DOCUMENT_TYPE } from "../../common/Constants";
import { OSCheckbox } from "../../components/base";
import CollapsibleHeader from "../../components/collapsible-header/CollapsibleHeader";
import GetErrorDescription from "../../components/get-error-description";
import { OSIcon } from "../../components/icons";
import { StyledModalMessage } from "../../components/modals";
import {
  clearGetDraftContractResponse,
  clearGetStaticDocumentContentResponse,
  getAdditionalDocuments,
  getDraftContract,
  getStaticDocumentContent,
} from "../../config/redux/slices";
import { useWindowSize } from "../../hooks";
import Separator from "../../components/separator";

const Document = ({ loading, document, onTouched, onDownload }) => {
  return (
    (loading && (
      <Flex
        sx={{
          mt: ["1rem", 0, 0],
          width: ["100%", "100%", "30%"],
          alignItems: "center",
          justifyContent: ["space-between", "flex-start", "flex-start"],
          flexDirection: "row",
        }}>
        <Box
          sx={{
            height: "2rem",
            width: "11rem",
          }}>
          <ContentLoader
            speed={2}
            style={{ width: "100%", height: "100%" }}
            backgroundColor="#fff"
            foregroundColor="#ecebeb">
            <rect width="100%" height="100%" x="0" y="0" rx="15" ry="15" />
          </ContentLoader>
        </Box>
      </Flex>
    )) ||
    (document && (
      <Flex
        sx={{
          mt: ["1rem", 0, 0],
          width: ["100%", "100%", "30%"],
          alignItems: "center",
          justifyContent: ["space-between", "flex-start", "flex-start"],
          flexDirection: "row",
        }}>
        <Text variant="summaryLabel">{document.repositoryDocName}</Text>
        <Button
          variant="download-action"
          sx={{
            width: onTouched(document.documentType) ? "11rem" : "9rem",
          }}
          onClick={() => onDownload(document.documentType)}>
          <Flex sx={{ alignItems: "center", justifyContent: "space-between", pl: 2 }}>
            {onTouched(document.documentType) && <OSIcon name="success-icon" />}
            <FormattedMessage id="btn.download" />
            <OSIcon name="pdf-file" />
          </Flex>
        </Button>
      </Flex>
    )) ||
    null
  );
};

const DocumentsSummary = ({ ...props }) => {
  const { onApproveTermsAndConditions } = props;
  const intl = useIntl();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const summaryContainer = useRef(null);
  const documentsState = useSelector((state) => state.documents);
  const [collapsed, setCollapsed] = useState(false);
  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const [approvedConditions, setApprovedConditions] = useState(false);
  const [errorDoc, setErrorDoc] = useState();
  const [documentTouched, setDocumentTouched] = useState([]);

  const devProtectionEasyUpDoc = documentsState.getAdditionalDocuments.response?.find(
    (doc) => doc.documentType === DOCUMENT_TYPE.DEVICE_PROTECTION_EASY_UP
  );
  const easyTabFlexDoc = documentsState.getAdditionalDocuments.response?.find(
    (doc) => doc.documentType === DOCUMENT_TYPE.EASYTAB_FLEX
  );

  useLayoutEffect(() => {
    setHrTrianglePosition(summaryContainer.current.offsetWidth / 4);
    setHrWidth(
      summaryContainer && summaryContainer.current ? summaryContainer.current.offsetWidth : 0
    );
  }, [windowSize.width]);

  useEffect(() => {
    dispatch(getAdditionalDocuments());
  }, [dispatch]);

  const generateDocument = useCallback(
    (docType, docName) => {
      setErrorDoc(null);
      if (docType === DOCUMENT_TYPE.CONTRACT) {
        dispatch(clearGetDraftContractResponse());
        dispatch(getDraftContract());
      } else if (docType) {
        dispatch(clearGetStaticDocumentContentResponse());
        dispatch(getStaticDocumentContent({ docType, docName }));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    generateDocument(DOCUMENT_TYPE.CONTRACT);
  }, [generateDocument]);

  useEffect(() => {
    documentsState.getAdditionalDocuments.response?.forEach((doc) =>
      generateDocument(doc.documentType, doc.name)
    );
  }, [generateDocument, documentsState.getAdditionalDocuments.response]);

  const downloadContract = () => {
    documentsState.getDraftContract.error &&
      setErrorDoc({
        docType: DOCUMENT_TYPE.CONTRACT,
        docName: null,
        docError: documentsState.getDraftContract.error,
      });
    if (documentsState.getDraftContract.response) {
      window.open(documentsState.getDraftContract.response);
      !isDocumentTouched(DOCUMENT_TYPE.CONTRACT) &&
        setDocumentTouched([...documentTouched, DOCUMENT_TYPE.CONTRACT]);
    }
  };

  const downloadDocument = (docType) => {
    const document = documentsState.documents.find((doc) => doc.documentType === docType);
    document?.error &&
      setErrorDoc({
        docType: document.documentType,
        docName: document.documentName,
        docError: document.error,
      });
    if (document?.content) {
      window.open(document.content);
      !isDocumentTouched(document.documentType) &&
        setDocumentTouched([...documentTouched, document.documentType]);
    }
  };

  const approveConditions = () => {
    if (isApproveTermsAndConditionsEnabled) {
      setApprovedConditions(!approvedConditions);
      onApproveTermsAndConditions(!approvedConditions);
    }
  };

  const isDocumentTouched = (docType) => {
    return documentTouched.indexOf(docType) !== -1;
  };

  const isApproveTermsAndConditionsEnabled =
    isDocumentTouched(DOCUMENT_TYPE.CONTRACT) &&
    (!devProtectionEasyUpDoc || isDocumentTouched(DOCUMENT_TYPE.DEVICE_PROTECTION_EASY_UP)) &&
    (!easyTabFlexDoc || isDocumentTouched(DOCUMENT_TYPE.EASYTAB_FLEX));

  return (
    <Box ref={summaryContainer} mb={4}>
      <CollapsibleHeader
        primaryTitle={intl.formatMessage({ id: "header.review-documents" })}
        hrWidth={hrWidth}
        hrTrianglePosition={hrTrianglePosition}
        collapsed={collapsed}
        onCollapsed={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (
        <>
          {/* generate error */}
          <StyledModalMessage
            isOpen={errorDoc?.docType}
            onRequestClose={() => generateDocument(errorDoc?.docType, errorDoc?.docName)}
            message={<GetErrorDescription error={errorDoc?.docError} />}>
            <Button
              variant="simple-action"
              onClick={() => generateDocument(errorDoc?.docType, errorDoc?.docName)}>
              <FormattedMessage id="btn.retry" />
            </Button>
          </StyledModalMessage>

          <Flex
            sx={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexDirection: "column",
              px: [8, 8, 9],
            }}>
            <Text variant="largeLabel">
              {intl.formatMessage({ id: "lbl.download-mandatory-docs" })}
            </Text>
            <Flex
              sx={{
                width: "100%",
                justifyContent: ["space-between", "flex-start", "flex-start"],
                my: 6,
                flexDirection: ["column", "row", "row"],
              }}>
              <Flex
                sx={{
                  width: ["100%", "100%", "27%"],
                  alignItems: "center",
                  justifyContent: ["space-between", "flex-start", "flex-start"],
                  flexDirection: "row",
                }}>
                {((documentsState.getAdditionalDocuments.loading ||
                  documentsState.getDraftContract.loading) && (
                  <Box sx={{ height: "2rem", width: "11rem" }}>
                    <ContentLoader
                      speed={2}
                      style={{ width: "100%", height: "100%" }}
                      backgroundColor="#fff"
                      foregroundColor="#ecebeb">
                      <rect width="100%" height="100%" x="0" y="0" rx="15" ry="15" />
                    </ContentLoader>
                  </Box>
                )) || (
                  <>
                    <Text variant="summaryLabel">
                      {intl.formatMessage({
                        id: "lbl.service-agreements",
                      })}
                    </Text>
                    <Button
                      variant="download-action"
                      sx={{ width: isDocumentTouched(DOCUMENT_TYPE.CONTRACT) ? "11rem" : "9rem" }}
                      onClick={downloadContract}>
                      <Flex sx={{ alignItems: "center", justifyContent: "space-between", pl: 2 }}>
                        {isDocumentTouched(DOCUMENT_TYPE.CONTRACT) && (
                          <OSIcon name="success-icon" />
                        )}
                        <FormattedMessage id="btn.download" />
                        <OSIcon name="pdf-file" />
                      </Flex>
                    </Button>
                  </>
                )}
              </Flex>
              <Document
                document={devProtectionEasyUpDoc}
                loading={documentsState.getAdditionalDocuments.loading}
                onTouched={isDocumentTouched}
                onDownload={downloadDocument}
              />
              <Document
                document={easyTabFlexDoc}
                loading={documentsState.getAdditionalDocuments.loading}
                onTouched={isDocumentTouched}
                onDownload={downloadDocument}
              />
            </Flex>
            <Flex
              sx={{
                width: "100%",
                flexDirection: "column",
                alignItems: "flex-start",
              }}>
              <Text sx={{ fontSize: "xs", color: "primary" }}>
                <FormattedMessage id="lbl.summary-message1" />
              </Text>
              <Flex
                sx={{
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  lineHeight: "1.1rem",
                  mt: 2,
                }}>
                <Text sx={{ fontSize: "xs", color: "primary" }}>
                  <FormattedMessage id="lbl.summary-message2" />
                </Text>
              </Flex>
            </Flex>

            <Flex sx={{ width: "100%", alignItems: "center", mt: 4 }}>
              <OSCheckbox
                mr={[7, 2]}
                disabled={!isApproveTermsAndConditionsEnabled}
                onChange={approveConditions}
                checked={approvedConditions}
                sx={{
                  height: "1.5rem !important",
                  width: "1.5rem !important",
                }}
              />
              <Text
                sx={{
                  fontSize: "xs",
                  color: "primary",
                  fontWeight: "bold",
                }}>
                <FormattedMessage id="lbl.summary-checkbox-message" />
              </Text>
            </Flex>
          </Flex>
          <Separator variant="gray-border" mb={4} mt={8} />
        </>
      )}
    </Box>
  );
};

export default DocumentsSummary;
