export function capitalizeText(text) {
  return text.slice(0, 1).toUpperCase() + text.toLowerCase().slice(1, text.length);
}

export const formatPhoneNo = (phoneNumber, seperator) => {
  if (!phoneNumber) return phoneNumber;
  return (
    phoneNumber.substring(0, 1) +
    seperator +
    phoneNumber.substring(1, 4) +
    seperator +
    phoneNumber.substring(4, 7) +
    seperator +
    phoneNumber.substring(7)
  );
};

export const getJsonIgnoreMultiple = (object, propertiesToIgnore) => {
  return JSON.stringify(object, function (key, value) {
    if (key === "$$hashKey") {
      return undefined;
    }
    if (propertiesToIgnore !== null && propertiesToIgnore.indexOf(key) !== -1) {
      return undefined;
    }
    return value;
  });
};

export const getExtraInfoValues = (string) => {
  return string ? Object.fromEntries(string.split(";").map((elem) => elem.split("="))) : "";
};

export const updateExtraInfo = (extraInfoArray, code, value) => {
  if (extraInfoArray && extraInfoArray.length > 0) {
    let itemExtraInfo = getExtraInfoValues(extraInfoArray);
    if (itemExtraInfo[code] && itemExtraInfo[code].length > 0) {
      let newExtraInfo = Object.entries(itemExtraInfo)
        .filter((keyValue) => keyValue[0] !== code)
        .map((keyValue) => keyValue[0] + "=" + keyValue[1])
        .join(";");
      return newExtraInfo + (newExtraInfo ? ";" : "") + code + "=" + value;
    } else {
      return extraInfoArray + ";" + code + "=" + value;
    }
  } else {
    return code + "=" + value;
  }
};
