import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwt from "jwt-decode";
import Storage from "store2";

import { AuthAPI } from "../../../rest/RestClient";
import { OAuthCredentials } from "../../security/Deployment";

const generateCodeVerifier = () => {
  if (!window.crypto) {
    return null;
  }
  let salt = new Uint8Array(32);
  window.crypto.getRandomValues(salt);

  const codec = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~";

  return salt.reduce((code, i) => code + codec[i % 66]);
};

if (!Storage.get("logged_in") && !Storage.get("code_verifier")) {
  Storage.set("code_verifier", generateCodeVerifier(), true);
}

const INITIAL_STATE = {
  loggedIn: Storage.get("logged_in") ?? false,
  error: null,
  accountId: Storage.get("account_id"),
  codeVerifier: Storage.get("code_verifier"),
  getToken: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },
};

const decodeToken = (token) => {
  let accountId, error;
  try {
    const claims = jwt(token);
    accountId = claims.nba;
  } catch (err) {
    error = err;
  }
  return { accountId, error };
};

export const getToken = createAsyncThunk("getToken", async (args, { rejectWithValue }) => {
  const { route, body, ...params } = args;
  try {
    return await AuthAPI.POST(route, body, params);
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const checkAndRefreshToken = createAsyncThunk(
  "checkAndRefreshToken",
  async (_args, { dispatch, getState, rejectWithValue }) => {
    console.log("refresh login token");
    const { tokenURL, clientID, clientSecret } = OAuthCredentials;

    console.log("perform refresh");
    if (getState().auth.refreshLock) {
      console.log("token is refreshing");
      return;
    }

    console.log("start refreshing");
    dispatch({ type: "refresh/start" });

    const refreshToken = Storage.get("refresh_token");

    // get a new one using the refresh token
    try {
      let response = await AuthAPI.POST(
        tokenURL,
        {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: clientID,
        },
        {
          isForm: true,
          authorization: { type: "Basic", token: btoa(clientID + ":" + clientSecret) },
        }
      );

      //store it
      Storage.set("refresh_token", response.refresh_token, true);

      console.log("end refreshing");
      dispatch({ type: "refresh/done" });
    } catch (error) {
      console.log("Refresh failed: " + JSON.stringify(error));
      dispatch({ type: "refresh/fail" });
      dispatch(doLogout());
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    doLogin(state, action) {
      let { accessToken, refreshToken } = action.payload;

      Storage.set("refresh_token", refreshToken, true);

      const { accountId, error } = decodeToken(accessToken);
      let loggedIn = !error && !!accountId;
      Storage.set("account_id", accountId, true);
      Storage.set("logged_in", loggedIn, true);
      Storage.remove("code_verifier");
      return {
        ...state,
        loggedIn: loggedIn,
        error,
        accountId,
        getToken: INITIAL_STATE.getToken,
      };
    },

    doLogout(state) {
      Storage.remove("refresh_token");
      Storage.remove("logged_in");
      Storage.remove("account_id");
      let codeVerifier = generateCodeVerifier();
      Storage.set("code_verifier", codeVerifier);
      return {
        ...state,
        loggedIn: false,
        accountId: "",
        codeVerifier,
      };
    },
  },
  extraReducers: {
    [getToken.pending]: (state) => {
      state.getToken.loading = true;
      state.getToken.success = false;
      state.getToken.error = null;
      state.getToken.response = null;
    },
    [getToken.fulfilled]: (state, action) => {
      state.getToken.loading = false;
      state.getToken.success = true;
      state.getToken.response = action.payload;
    },
    [getToken.rejected]: (state, action) => {
      state.getToken.loading = false;
      state.getToken.success = false;
      state.getToken.error = action.payload;
    },
    "refresh/start": (state) => {
      state.refreshLock = true;
    },
    "refresh/done": (state) => {
      state.refreshLock = false;
    },
    "refresh/fail": (state) => {
      state.refreshLock = false;
    },
  },
});

export const { doLogin, doLogout } = authSlice.actions;

export default authSlice.reducer;
