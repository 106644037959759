import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";
import { Branding } from "../../branding/Branding";

export const getStatesByCountry = createAsyncThunk("states/getStatesByCountry", async (country) => {
  return await FacadeAPI.GET("/address/states", { country: country });
});

export const getDefaultStates = createAsyncThunk("states/getDefaultStates", async () => {
  return await FacadeAPI.GET("/address/states", { country: Branding.DEFAULT_ISSUING_COUNTRY });
});

const statesSlice = createSlice({
  name: "states",
  initialState: {
    getStatesByCountry: {
      loading: false,
      error: null,
      success: false,
      states: [],
    },
    getDefaultStates: {
      loading: false,
      error: null,
      success: false,
      states: [],
    },
  },
  reducers: {},
  extraReducers: {
    [getStatesByCountry.pending]: (state) => {
      state.getStatesByCountry.loading = true;
      state.getStatesByCountry.success = false;
    },
    [getStatesByCountry.fulfilled]: (state, action) => {
      state.getStatesByCountry.loading = false;
      state.getStatesByCountry.states = action.payload;
      state.getStatesByCountry.success = true;
    },
    [getStatesByCountry.rejected]: (state, action) => {
      state.getStatesByCountry.loading = false;
      state.getStatesByCountry.success = true;
      state.getStatesByCountry.error = action.error;
    },

    [getDefaultStates.pending]: (state) => {
      state.getDefaultStates.loading = true;
      state.getDefaultStates.success = false;
    },
    [getDefaultStates.fulfilled]: (state, action) => {
      state.getDefaultStates.loading = false;
      state.getDefaultStates.states = action.payload;
      state.getDefaultStates.success = true;
    },
    [getDefaultStates.rejected]: (state, action) => {
      state.getDefaultStates.loading = false;
      state.getDefaultStates.success = true;
      state.getDefaultStates.error = action.error;
    },
  },
});

export default statesSlice.reducer;
