import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FacadeAPI } from "../../../rest/RestClient";
import { EXTRA_INFO, ITEM_CATEGORY, SIM_TYPE } from "../../../common/Constants";
import * as PlanUtils from "../../../pages/device-and-plan/additional-services/PlanUtils";
import { restoreShipingInfoOnState } from "./ShippingDetailsSlice";
import { reloadGroupManagement } from "./GroupManagementSlice";
import { clearGetBundlePriceApiResponse } from "./ItemPriceSlice";
import { getExtraInfoValues } from "../../../common/StringUtilities";
const INITIAL_STATE = {
  planFlags: {
    planExists: true,
    additionalServicesExists: true,
    needsFulfillment: true,
  },

  saDeviceLinkMap: {},

  // API Responses
  getItems: {
    loading: false,
    success: false,
    error: null,
    items: [],
    allItems: [],
  },
  updateItem: {
    loading: false,
    success: false,
    error: null,
  },
  deleteItem: {
    loading: false,
    success: false,
    error: null,
  },
  saveHardwareUpgradeMapping: {
    loading: false,
    success: false,
    error: null,
  },
  updateItemExtraInfo: {
    loading: false,
    success: false,
    error: null,
  },
  getModelsByGroupCode: {
    loading: false,
    success: false,
    error: null,
    goodModels: [],
  },
  loadGoodModelUDFs: {
    loading: false,
    success: false,
    error: null,
    udfs: [],
  },
};

export const getItems = createAsyncThunk("session/items", async (arg, { getState, dispatch }) => {
  const sessionId = getState().session.sessionId;
  let result = await FacadeAPI.GET(`/session/${sessionId}/items`);
  dispatch(restoreShipingInfoOnState(result.items));
  return result;
});

export const updateItem = createAsyncThunk(
  "session/updateItem",
  async (arg, { getState, dispatch, rejectWithValue }) => {
    const { id, category } = arg;
    if (category === ITEM_CATEGORY.ACCESSORY) {
      return dispatch(getItems());
    }
    const sessionId = getState().session.sessionId;
    try {
      let result = await FacadeAPI.PUT(`/session/${sessionId}/item/${id}`, arg);
      dispatch(getItems());
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "session/deleteItem",
  async ({ itemId, skipItemsReload }, { getState, dispatch, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.DELETE(`/session/${sessionId}/item/${itemId}`, {}, { expectedResponse: "" });
    } catch (err) {
      return rejectWithValue(err);
    }
    if (!skipItemsReload) {
      dispatch(getItems());
      dispatch(reloadGroupManagement());
    }
  }
);

export const updateItemExtraInfo = createAsyncThunk(
  "session/updateItemExtraInfo",
  async (arg, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    const { id } = arg;
    try {
      await FacadeAPI.PUT(`/session/${sessionId}/item/${id}`, arg, {
        expectedResponse: "",
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getModelsByGroupCode = createAsyncThunk(
  "inventory/modelsByGroupCode",
  async (arg, { rejectWithValue }) => {
    try {
      return await FacadeAPI.GET(`/inventory/modelsByGroupCode`);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadGoodModelUDFs = createAsyncThunk(
  "inventory/loadGoodModelUDFs",
  async ({ skuCode }, { rejectWithValue }) => {
    try {
      return await FacadeAPI.GET(`/inventory/loadGoodModelUDFs/${skuCode}`);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export function checkIfAdditionalServicesExists(items) {
  if (items.length > 0 && items.some((item) => item.category === ITEM_CATEGORY.BUNDLE)) {
    let bundleItem = items.find(
      (item) => item.category === ITEM_CATEGORY.BUNDLE && item.packageCode
    );

    if (!bundleItem) {
      return false;
    }
    let packageCode = bundleItem.packageCode;

    if (
      bundleItem.jsonBundleRequest &&
      JSON.parse(bundleItem.jsonBundleRequest).code === packageCode
    ) {
      let product = JSON.parse(bundleItem.jsonBundleRequest);
      if (product && product.visible && PlanUtils.subProductHasVisibleServicesOrFeatures(product)) {
        return true;
      }
    }
  }
  return false;
}

export function checkIfNeedsFulfillment(items) {
  if (
    items.length > 0 &&
    items.some(
      (item) => item.category === ITEM_CATEGORY.BUNDLE || item.category === ITEM_CATEGORY.ACCESSORY
    )
  ) {
    let bundleItems = items.filter(
      (item) =>
        item.category === ITEM_CATEGORY.ACCESSORY ||
        (item.category === ITEM_CATEGORY.BUNDLE &&
          (item.sku !== null || item.simType !== SIM_TYPE.eSIM))
    );

    if (bundleItems && bundleItems.length > 0) {
      return true;
    }
  }
  return false;
}

export function isEasyTabFlexReturn(items) {
  if (items.length > 0 && items.some((item) => item.category === ITEM_CATEGORY.BUNDLE)) {
    let bundleItems = items.filter(
      (item) =>
        item.category === ITEM_CATEGORY.BUNDLE &&
        item.extraInfo &&
        getExtraInfoValues(item.extraInfo)[EXTRA_INFO.FLEX_RETURN]
    );

    if (bundleItems && bundleItems.length > 0) {
      return true;
    }
  }
  return false;
}

export const saveHardwareUpgradeMapping = createAsyncThunk(
  "session/saveHardwareUpgradeMapping",
  async (request, { getState, rejectWithValue, dispatch }) => {
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.PUT(`/session/${sessionId}/saveHardwareUpgradeMapping`, request, {
        expectedResponse: "",
      });
      // Clear session items in order to load new configuration after save hardware upgrade
      dispatch(clearGetItemsApiResponse());
      dispatch(clearGetBundlePriceApiResponse());
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const itemsSlice = createSlice({
  name: "items",
  initialState: INITIAL_STATE,
  reducers: {
    clearGetItemsApiResponse(state) {
      return {
        ...state,
        getItems: INITIAL_STATE.getItems,
      };
    },
    clearUpdateItemApiResponse(state) {
      return {
        ...state,
        updateItem: INITIAL_STATE.updateItem,
      };
    },
    clearDeleteItemApiResponse(state) {
      return {
        ...state,
        deleteItem: INITIAL_STATE.deleteItem,
      };
    },
    clearSaveHardwareUpgradeMapping(state) {
      return {
        ...state,
        saveHardwareUpgradeMapping: INITIAL_STATE.saveHardwareUpgradeMapping,
      };
    },
    clearLoadGoodModelUDFs(state) {
      return {
        ...state,
        loadGoodModelUDFs: INITIAL_STATE.loadGoodModelUDFs,
      };
    },
    updateSAandDeviceLink: {
      reducer: (state, action) => {
        const { saDeviceLinkMap, deviceId, selectedSAOption } = action.payload;
        let saDeviceLink = { ...saDeviceLinkMap };
        if (deviceId && selectedSAOption === null) {
          delete saDeviceLink[deviceId];
        } else if (deviceId) {
          saDeviceLink[deviceId] = selectedSAOption;
        }
        return { ...state, saDeviceLinkMap: saDeviceLink };
      },
      prepare: (saDeviceLinkMap, deviceId, selectedSAOption) => {
        return {
          payload: {
            saDeviceLinkMap,
            deviceId,
            selectedSAOption,
          },
        };
      },
    },
  },
  extraReducers: {
    [getItems.pending]: (state) => {
      state.getItems.loading = true;
      state.getItems.success = false;
      state.getItems.error = null;
      state.getItems.items = [];
      state.getItems.allItems = [];
    },
    [getItems.fulfilled]: (state, action) => {
      state.getItems.loading = false;
      state.getItems.success = true;
      state.getItems.items = action.payload.items;
      state.getItems.allItems = action.payload.allItems;
      state.planFlags.planExists =
        action.payload &&
        action.payload.items &&
        action.payload.items.some(
          (item) => item.category === ITEM_CATEGORY.BUNDLE && item.packageCode !== null
        );
      state.planFlags.additionalServicesExists = checkIfAdditionalServicesExists(
        state.getItems.items
      );
      state.planFlags.needsFulfillment = checkIfNeedsFulfillment(state.getItems.items);
      state.planFlags.easyTabFlexReturn = isEasyTabFlexReturn(state.getItems.items);
    },
    [getItems.rejected]: (state, action) => {
      state.getItems.loading = false;
      state.getItems.success = false;
      state.getItems.error = action.error;
    },

    [updateItem.pending]: (state) => {
      state.updateItem.loading = true;
      state.updateItem.success = false;
      state.updateItem.error = null;
    },
    [updateItem.fulfilled]: (state) => {
      state.updateItem.loading = false;
      state.updateItem.success = true;
    },
    [updateItem.rejected]: (state, action) => {
      state.updateItem.loading = false;
      state.updateItem.success = false;
      state.updateItem.error = action.payload;
    },

    [deleteItem.pending]: (state) => {
      state.deleteItem.loading = true;
      state.deleteItem.success = false;
      state.deleteItem.error = null;
    },
    [deleteItem.fulfilled]: (state) => {
      state.deleteItem.loading = false;
      state.deleteItem.success = true;
    },
    [deleteItem.rejected]: (state, action) => {
      state.deleteItem.loading = false;
      state.deleteItem.success = false;
      state.deleteItem.error = action.payload;
    },

    [saveHardwareUpgradeMapping.pending]: (state) => {
      state.saveHardwareUpgradeMapping.loading = true;
      state.saveHardwareUpgradeMapping.success = false;
      state.saveHardwareUpgradeMapping.error = null;
    },
    [saveHardwareUpgradeMapping.fulfilled]: (state) => {
      state.saveHardwareUpgradeMapping.loading = false;
      state.saveHardwareUpgradeMapping.success = true;
    },
    [saveHardwareUpgradeMapping.rejected]: (state, action) => {
      state.saveHardwareUpgradeMapping.loading = false;
      state.saveHardwareUpgradeMapping.success = false;
      state.saveHardwareUpgradeMapping.error = action.payload;
    },
    [updateItemExtraInfo.pending]: (state) => {
      state.updateItemExtraInfo.loading = true;
      state.updateItemExtraInfo.success = false;
      state.updateItemExtraInfo.error = null;
    },
    [updateItemExtraInfo.fulfilled]: (state) => {
      state.updateItemExtraInfo.loading = false;
      state.updateItemExtraInfo.success = true;
    },
    [updateItemExtraInfo.rejected]: (state, action) => {
      state.updateItemExtraInfo.loading = false;
      state.updateItemExtraInfo.success = false;
      state.updateItemExtraInfo.error = action.payload;
    },
    [getModelsByGroupCode.pending]: (state) => {
      state.getModelsByGroupCode.loading = true;
      state.getModelsByGroupCode.success = false;
      state.getModelsByGroupCode.error = null;
      state.getModelsByGroupCode.goodModels = [];
    },
    [getModelsByGroupCode.fulfilled]: (state, action) => {
      state.getModelsByGroupCode.loading = false;
      state.getModelsByGroupCode.success = true;
      state.getModelsByGroupCode.goodModels = action.payload;
    },
    [getModelsByGroupCode.rejected]: (state, action) => {
      state.getModelsByGroupCode.loading = false;
      state.getModelsByGroupCode.success = false;
      state.getModelsByGroupCode.error = action.payload;
    },
    [loadGoodModelUDFs.pending]: (state) => {
      state.loadGoodModelUDFs.loading = true;
      state.loadGoodModelUDFs.success = false;
      state.loadGoodModelUDFs.error = null;
      state.loadGoodModelUDFs.udfs = [];
    },
    [loadGoodModelUDFs.fulfilled]: (state, action) => {
      state.loadGoodModelUDFs.loading = false;
      state.loadGoodModelUDFs.success = true;
      state.loadGoodModelUDFs.udfs = action.payload;
    },
    [loadGoodModelUDFs.rejected]: (state, action) => {
      state.loadGoodModelUDFs.loading = false;
      state.loadGoodModelUDFs.success = false;
      state.loadGoodModelUDFs.error = action.payload;
    },
  },
});
export const {
  clearGetItemsApiResponse,
  clearUpdateItemApiResponse,
  clearDeleteItemApiResponse,
  clearSaveHardwareUpgradeMapping,
  updateSAandDeviceLink,
  clearLoadGoodModelUDFs,
} = itemsSlice.actions;
export default itemsSlice.reducer;
