import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Text } from "@theme-ui/components";
import { StyledModalMessage } from "../../components/modals";

import SADevice from "./service-agreement-items/SADevice";
import Layout from "../../components/layout";
import { OSContainer } from "../../components/base";
import Spinner from "../../components/spinner/Spinner";
import AmountPlain from "../../components/AmountPlain";

import {
  getExistingLines,
  validateServiceAgreement,
  updateSAandDeviceLink,
  deleteItem,
  saveHardwareUpgradeMapping,
  isValidSAPlan,
  checkGoodCompatibilityWithServiceDeviceFeatures,
  clearSaveHardwareUpgradeMapping,
  clearValidateServiceAgreementResponse,
  clearDeleteItemApiResponse,
} from "../../config/redux/slices";
import { getNextStep } from "../../common/StepsUtilities";
import { getExtraInfoValues } from "../../common/StringUtilities";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import {
  ITEM_CATEGORY,
  ACCOUNT_STATUS,
  NONE_CODE,
  PRICE_TYPE,
  PERSONAL_INFORMATION_FLOW,
  PRICING_OPTION,
  EXTRA_INFO,
} from "../../common/Constants";
import { UpdateDeviceSkeleton } from "../../components/skeletons";
import ErrorHandler from "../../components/error-handler";
import _ from "lodash";
import dayjs from "dayjs";

const UpdateDevice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { planFlags, ...itemsState } = useSelector((state) => state.items);
  const existingLinesState = useSelector((state) => state.existingLines);
  const personalInformationData = useSelector((state) => state.personalInformation);
  const serviceAgreementState = useSelector((state) => state.serviceAgreement);

  const [bundles, setBundles] = useState([]);
  const [infoMessage, setInfoMessage] = useState(null);
  const [contentLoading, setContentLoading] = useState(true);
  const [showEasyTabMsg, setShowEasyTabMsg] = useState(false);
  const [easyTabInfo, setEasyTabInfo] = useState({});
  const [nextPageWarning, setNextPageWarning] = useState(null);
  const [easyTabCheck, setEasyTabCheck] = useState(false);
  const [goodCompatibilityCheck, setGoodCompatibilityCheck] = useState(false);
  const [incompatibleServiceWarning, setIncompatibleServiceWarning] = useState(null);
  const [invalidPlanWarning, setInvalidPlanWarning] = useState(null);
  const [selectedMapping, setSelectedMapping] = useState({});

  const ACTIVE_EASYTAB_OPTION = useMemo(() => {
    return {
      NONE: "NONE",
      PAY_FULL: "KEEP",
      PAY_OLD_INSTALLMENT: "PAY",
      PAY_FLEX: "PAY_FLEX",
    };
  }, []);

  // 1. Load existing lines.
  useEffect(() => {
    let getExistingLinesRequest = {
      masterAccountId: personalInformationData.accountId,
      accountStatuses: [ACCOUNT_STATUS.ACTIVE],
      loadFutureProductDefinition: true,
    };

    if (personalInformationData.loaded && !existingLinesState.getExistingLines.success)
      dispatch(getExistingLines({ getExistingLinesRequest }));
  }, [
    personalInformationData.loaded,
    personalInformationData.accountId,
    existingLinesState.getExistingLines.success,
    dispatch,
  ]);

  const getEasyTab = useCallback(
    (saId) => {
      let existingLine = existingLinesState.getExistingLines.existingLines.find(
        (line) => line.id == saId
      );
      if (existingLine && existingLine.installmentCompleteDetailsDTO) {
        return existingLine.installmentCompleteDetailsDTO;
      }
      return null;
    },
    [existingLinesState.getExistingLines.existingLines]
  );

  const callValidateSAPlan = useCallback(() => {
    let existingLine = existingLinesState.getExistingLines.existingLines.find(
      (line) => line.id == selectedMapping.saId
    );

    if (existingLine) {
      let validSAPlanRequest = {
        saId: existingLine.id,
        productCode: existingLine.productCode,
        deviceSku: selectedMapping.deviceSku,
        pricingOption: selectedMapping.pricingOption,
      };
      dispatch(isValidSAPlan(validSAPlanRequest));
    }
  }, [selectedMapping, existingLinesState, dispatch]);

  const computeEasyTabInfo = useCallback(
    (easyTab) => {
      let easyTabInfo = {
        amount: easyTab.remainingAmount,
        noMonths: easyTab.noMonths,
        flexFeeAppliedDate: easyTab.flexFeeAppliedDate,
        flexReturnStartDate: easyTab.flexReturnStartDate,
        flexReturnEndDate: easyTab.flexReturnEndDate,
        payFullPriceOptionAvailable: false,
        payOldEasyTabOptionAvailable: easyTab.noMonths > 0,
        flexDeferredAmount: getEasyTabFlexDeferredAmount(easyTab),
        easyTabFlexReturn: easyTabFlexStarted(easyTab),
      };

      switch (selectedMapping.pricingOption) {
        case PRICING_OPTION.RETAIL:
          computeEasyTabMessageForRetailPricing(easyTab, easyTabInfo);
          break;
        case PRICING_OPTION.EASYTAB:
          computeEasyTabMessageForEasyTabPricing(
            easyTab,
            easyTabInfo,
            PRICING_OPTION.EASYTAB.toLowerCase()
          );
          break;
        case PRICING_OPTION.EASYTABFLEX:
          computeEasyTabMessageForEasyTabPricing(
            easyTab,
            easyTabInfo,
            PRICING_OPTION.EASYTABFLEX.toLowerCase()
          );
          break;
      }

      function computeEasyTabMessageForRetailPricing(easyTab, easyTabInfo) {
        if (easyTabInfo.flexDeferredAmount > 0 && easyTab.noMonths <= 0) {
          if (!easyTabInfo.easyTabFlexReturn) {
            easyTabInfo.flexReturnDatePeriod = getFlexReturnDatePeriod(easyTab);
            easyTabInfo.flexReturnDatePeriodLabel = getFlexReturnDatePeriodLabel(easyTab);
            easyTabInfo.messageId = "lbl.flex-amount-next-bill";
          } else {
            easyTabInfo.messageId = "lbl.flex-in-period-amount-next-bill";
          }
        }
      }

      function computeEasyTabMessageForEasyTabPricing(easyTab, easyTabInfo, pricingOption) {
        if (easyTab.noMonths > 0) {
          if (easyTabInfo.flexDeferredAmount > 0) {
            if (!easyTabInfo.easyTabFlexReturn) {
              easyTabInfo.flexReturnDatePeriod = getFlexReturnDatePeriod(easyTab);
              easyTabInfo.flexReturnDatePeriodLabel = getFlexReturnDatePeriodLabel(easyTab);
              easyTabInfo.messageId = "lbl.active-easytab-flex-pricing-" + pricingOption;
            } else {
              easyTabInfo.messageId = "lbl.active-easytab-flex-in-period-pricing-" + pricingOption;
            }
          } else {
            easyTabInfo.messageId = "lbl.active-easytab-pricing-" + pricingOption;
          }
        } else if (easyTabInfo.flexDeferredAmount > 0)
          if (!easyTabInfo.easyTabFlexReturn) {
            easyTabInfo.flexReturnDatePeriod = getFlexReturnDatePeriod(easyTab);
            easyTabInfo.flexReturnDatePeriodLabel = getFlexReturnDatePeriodLabel(easyTab);
            easyTabInfo.messageId = "lbl.flex-amount-next-bill";
          } else {
            easyTabInfo.messageId = "lbl.flex-in-period-amount-next-bill";
          }
      }

      function getFlexRemainingDays(easyTab) {
        return Math.ceil(dayjs(easyTab.flexReturnStartDate).diff(dayjs(), "day", true));
      }

      function getFlexReturnDatePeriod(easyTab) {
        let flexReturnDatePeriod = getFlexRemainingDays(easyTab);
        if (flexReturnDatePeriod > 31) {
          flexReturnDatePeriod = Math.ceil(flexReturnDatePeriod / 31);
        }
        return flexReturnDatePeriod;
      }

      function getFlexReturnDatePeriodLabel(easyTab) {
        return (
          <FormattedMessage id={getFlexRemainingDays(easyTab) > 31 ? "lbl.months" : "lbl.days"} />
        );
      }

      function getEasyTabFlexDeferredAmount(easyTab) {
        if (
          easyTab &&
          easyTab.flexDiscount > 0 &&
          easyTab.flexFeeAppliedDate &&
          easyTab.flexFeeAppliedDate.slice(0, 4) === "1970"
        ) {
          return easyTab.flexDiscount + easyTab.flexTopup;
        }
        return -1;
      }

      function easyTabFlexStarted(easyTab) {
        return (
          getEasyTabFlexDeferredAmount(easyTab) > 0 &&
          easyTab.flexReturnStartDate &&
          dayjs(easyTab.flexReturnStartDate).isBefore(dayjs())
        );
      }

      if (easyTabInfo.messageId !== undefined) {
        setShowEasyTabMsg(true);
      } else {
        setGoodCompatibilityCheck(true);
      }

      return easyTabInfo;
    },
    [selectedMapping, setGoodCompatibilityCheck]
  );

  useEffect(() => {
    if (itemsState.getItems.success && existingLinesState.getExistingLines.success) {
      setBundles(
        itemsState.getItems.allItems.filter((item) => item.category === ITEM_CATEGORY.BUNDLE)
      );
      if (bundles.length > existingLinesState.getExistingLines.existingLines.length) {
        setInfoMessage("lbl.more-devices-than-lines");
      } else {
        setInfoMessage(null);
      }
      if (!itemsState.saDeviceLinkMap || Object.keys(itemsState.saDeviceLinkMap).length === 0) {
        let itemsDeviceMap = {};
        itemsState.getItems.allItems.map((item) => {
          if (item.associatedSaID > 0) {
            let oldInstallmentAmountValue = -1;
            let flexDeferredAmount = -1;
            let easyTabFlexReturn = false;
            if (item.oldInstallmentOption === ACTIVE_EASYTAB_OPTION.PAY_OLD_INSTALLMENT) {
              let installmentPrice = item.prices.find(
                (price) => PRICE_TYPE.OLD_INSTALLMENT === price.type
              );
              oldInstallmentAmountValue = installmentPrice?.price;
            }
            if (item.extraInfo?.length > 0) {
              let extraInfoValue = getExtraInfoValues(item.extraInfo)[
                EXTRA_INFO.FLEX_DEFERRED_AMOUNT
              ];
              flexDeferredAmount = extraInfoValue ? parseFloat(extraInfoValue) : -1;
              extraInfoValue = getExtraInfoValues(item.extraInfo)[EXTRA_INFO.FLEX_RETURN];
              easyTabFlexReturn = extraInfoValue ? extraInfoValue === "Y" : false;
            }
            itemsDeviceMap[item.id] = {
              saId: item.associatedSaID,
              easyTabOption: item.oldInstallmentOption,
              oldInstallmentAmount: oldInstallmentAmountValue,
              flexDeferredAmount: flexDeferredAmount,
              easyTabFlexReturn: easyTabFlexReturn,
            };
          }
        });
        if (Object.keys(itemsDeviceMap).length > 0) {
          dispatch(updateSAandDeviceLink(itemsDeviceMap));
        }
      }

      setContentLoading(false);
    }
  }, [
    dispatch,
    itemsState.getItems,
    itemsState.saDeviceLinkMap,
    bundles.length,
    existingLinesState.getExistingLines,
    ACTIVE_EASYTAB_OPTION,
  ]);

  const resetSaveHardwareUpgradeMappingState = useCallback(() => {
    dispatch(clearSaveHardwareUpgradeMapping());
  }, [dispatch]);

  useEffect(() => {
    if (itemsState.saveHardwareUpgradeMapping.success) {
      resetSaveHardwareUpgradeMappingState();
      history.push(
        getNextStep(
          planFlags,
          CHECKOUT_STEPS.UPDATE_DEVICE,
          PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
        ).route
      );
    }
  }, [
    history,
    planFlags,
    itemsState.saveHardwareUpgradeMapping.success,
    resetSaveHardwareUpgradeMappingState,
  ]);

  const resetValidateSaState = useCallback(() => {
    dispatch(clearValidateServiceAgreementResponse());
  }, [dispatch]);

  const onSelectServiceNumber = (selectedSAId, deviceId, deviceSku, pricingOption) => {
    let mapping = {
      deviceId: deviceId,
      saId: selectedSAId,
      deviceSku: deviceSku,
      pricingOption: pricingOption,
      activeEasyTabOption: ACTIVE_EASYTAB_OPTION.NONE,
    };
    setSelectedMapping(mapping);
    setEasyTabInfo({});
    if (selectedSAId > NONE_CODE) {
      let existingLine = existingLinesState.getExistingLines.existingLines.find(
        (line) => line.id == selectedSAId
      );
      dispatch(validateServiceAgreement(existingLine));
    } else {
      updateSelectedServiceDetails(mapping, {});
    }
  };

  const onRemoveDevice = (deviceId) => {
    try {
      dispatch(updateSAandDeviceLink(itemsState.saDeviceLinkMap, deviceId, null));
      dispatch(deleteItem({ itemId: deviceId }));
    } catch (err) {
      console(err);
    }
  };

  const updateSelectedServiceDetails = useCallback(
    (selectedMapping, easyTabInfo) => {
      let flexDeferredAmount = easyTabInfo?.flexDeferredAmount;
      let oldInstallmentAmount = -1;
      if (selectedMapping.activeEasyTabOption === ACTIVE_EASYTAB_OPTION.PAY_OLD_INSTALLMENT) {
        let existingLine = existingLinesState.getExistingLines.existingLines.find(
          (line) => line.id == selectedMapping.saId
        );
        oldInstallmentAmount = existingLine.installmentCompleteDetailsDTO.remainingAmount;
      }
      dispatch(
        updateSAandDeviceLink(itemsState.saDeviceLinkMap, selectedMapping.deviceId, {
          saId: selectedMapping.saId,
          easyTabOption: selectedMapping.activeEasyTabOption,
          oldInstallmentAmount: oldInstallmentAmount,
          flexDeferredAmount: flexDeferredAmount,
          easyTabFlexReturn: easyTabInfo?.easyTabFlexReturn,
        })
      );
    },
    [
      ACTIVE_EASYTAB_OPTION.PAY_OLD_INSTALLMENT,
      existingLinesState.getExistingLines.existingLines,
      dispatch,
      itemsState.saDeviceLinkMap,
    ]
  );

  const setActiveEasyTabOption = (option) => {
    setShowEasyTabMsg(false);
    if (option !== ACTIVE_EASYTAB_OPTION.NONE) {
      let updatedMapping = { ...selectedMapping, activeEasyTabOption: option };
      setSelectedMapping(updatedMapping);
      setGoodCompatibilityCheck(true);
    }
  };

  useEffect(() => {
    if (easyTabCheck) {
      resetValidateSaState();
      setEasyTabCheck(false);
      if (selectedMapping && selectedMapping.saId > 0) {
        let easyTab = getEasyTab(selectedMapping.saId);
        if (easyTab) {
          setEasyTabInfo(computeEasyTabInfo(easyTab));
        }
      } else {
        updateSelectedServiceDetails(selectedMapping, easyTabInfo);
      }
    }
  }, [
    easyTabCheck,
    resetValidateSaState,
    selectedMapping,
    easyTabInfo,
    getEasyTab,
    computeEasyTabInfo,
    updateSelectedServiceDetails,
    dispatch,
    existingLinesState.getExistingLines.existingLines,
  ]);

  useEffect(() => {
    if (goodCompatibilityCheck) {
      setGoodCompatibilityCheck(false);
      if (selectedMapping && selectedMapping.saId > 0 && personalInformationData) {
        let compatibilityRequest = {
          masterAccountId: personalInformationData.accountId,
          saId: selectedMapping.saId,
          deviceSku: selectedMapping.deviceSku,
        };
        dispatch(checkGoodCompatibilityWithServiceDeviceFeatures(compatibilityRequest));
      }
    }
  }, [goodCompatibilityCheck, personalInformationData, selectedMapping, dispatch]);

  useEffect(() => {
    if (serviceAgreementState.validateServiceAgreement.success) {
      resetValidateSaState();
      callValidateSAPlan();
    }
  }, [serviceAgreementState, callValidateSAPlan, resetValidateSaState]);

  useEffect(() => {
    if (serviceAgreementState.checkGoodCompatibilityWithServiceDeviceFeatures.success) {
      resetValidateSaState();
      let message = "";
      if (serviceAgreementState.checkGoodCompatibilityWithServiceDeviceFeatures.value.length > 0) {
        serviceAgreementState.checkGoodCompatibilityWithServiceDeviceFeatures.value.map((item) => {
          message = message + item.invoiceDescription + ", ";
        });
        message = message.substring(0, message.length - 2);
        setIncompatibleServiceWarning(
          intl.formatMessage({ id: "err.incompatible-services" }, { services: message })
        );
      } else {
        updateSelectedServiceDetails(selectedMapping, easyTabInfo);
      }
    }
  }, [
    serviceAgreementState,
    resetValidateSaState,
    selectedMapping,
    easyTabInfo,
    updateSelectedServiceDetails,
    intl,
  ]);

  useEffect(() => {
    if (serviceAgreementState.isValidSAPlan.success) {
      if (serviceAgreementState.isValidSAPlan.value) {
        resetValidateSaState();
        setEasyTabCheck(true);
      } else {
        setInvalidPlanWarning(true);
      }
    }
  }, [
    serviceAgreementState.isValidSAPlan.success,
    serviceAgreementState.isValidSAPlan.value,
    resetValidateSaState,
  ]);

  const onNext = () => {
    dispatch(clearDeleteItemApiResponse());
    let selectedOptions = Object.values(itemsState.saDeviceLinkMap).filter((saOption) => {
      return saOption.saId !== NONE_CODE;
    });
    if (bundles.length > selectedOptions.length) {
      setNextPageWarning(true);
    } else {
      onNextConfirmed();
    }
  };

  const onNextConfirmed = () => {
    let itemUpdateRequest = bundles.map((bundle) => {
      let mapping = itemsState.saDeviceLinkMap[bundle.id];
      if (mapping) {
        let existingLine = existingLinesState.getExistingLines.existingLines.find(
          (line) => line.id == mapping.saId
        );
        return {
          itemId: bundle.id,
          accountId: mapping.saId,
          phoneNumber: existingLine?.serviceNumber,
          firstName: existingLine?.firstName,
          lastName: existingLine?.lastName,
          simSku: existingLine?.sim.sku,
          paymentOption: mapping.easyTabOption,
          oldInstallmentAmount: mapping.oldInstallmentAmount,
          flexDeferredAmount: mapping.flexDeferredAmount,
          easyTabFlexReturn: mapping.easyTabFlexReturn,
        };
      } else {
        return {
          itemId: bundle.id,
          accountId: NONE_CODE,
          paymentOption: ACTIVE_EASYTAB_OPTION.NONE,
          firstName: "",
          lastName: "",
        };
      }
    });

    dispatch(saveHardwareUpgradeMapping(itemUpdateRequest));
  };

  return (
    <Layout
      showCart={true}
      checkoutStep={CHECKOUT_STEPS.UPDATE_DEVICE.index}
      pageTitle={intl.formatMessage({ id: "label.upgrade-device-and-plan" })}
      isMultipleTabHeaderPage={false}
      nextStepButton={() => (
        <Button
          variant="default-next"
          onClick={onNext}
          disabled={
            contentLoading ||
            Object.values(itemsState.saDeviceLinkMap).filter((saOption) => {
              return saOption.saId !== NONE_CODE;
            }).length < 1
          }>
          {intl.formatMessage({ id: "btn.next" })}
        </Button>
      )}>
      <Spinner
        isOpen={
          itemsState.saveHardwareUpgradeMapping.loading ||
          serviceAgreementState.checkGoodCompatibilityWithServiceDeviceFeatures.loading ||
          serviceAgreementState.validateServiceAgreement.loading ||
          serviceAgreementState.isValidSAPlan.loading
        }
      />
      {infoMessage && (
        <OSContainer variant="top-message" sx={{ flexDirection: "column" }}>
          <Text variant="deviceHeader">
            <FormattedMessage textComponent="span" id={infoMessage} />
          </Text>
        </OSContainer>
      )}
      <ErrorHandler
        error={itemsState.saveHardwareUpgradeMapping.error}
        closeHandler={resetSaveHardwareUpgradeMappingState}
        clickHandler={resetSaveHardwareUpgradeMappingState}
      />
      <ErrorHandler
        error={serviceAgreementState.validateServiceAgreement.error}
        closeHandler={resetValidateSaState}
        clickHandler={resetValidateSaState}
      />
      <StyledModalMessage
        isOpen={incompatibleServiceWarning != null}
        onRequestClose={() => {
          setIncompatibleServiceWarning(null);
          resetValidateSaState();
        }}
        message={incompatibleServiceWarning}>
        <Button
          mr={20}
          variant="simple-action"
          onClick={() => {
            setIncompatibleServiceWarning(null);
            resetValidateSaState();
            updateSelectedServiceDetails(selectedMapping, easyTabInfo);
          }}>
          <FormattedMessage id="btn.ok" />
        </Button>
        <Button
          variant="simple-action"
          onClick={() => {
            setIncompatibleServiceWarning(null);
            resetValidateSaState();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </StyledModalMessage>
      <StyledModalMessage
        isOpen={invalidPlanWarning}
        onRequestClose={() => {
          setInvalidPlanWarning(null);
          resetValidateSaState();
        }}
        message={
          <FormattedMessage
            textComponent="span"
            id={
              selectedMapping && selectedMapping.pricingOption === PRICING_OPTION.EASYTABFLEX
                ? "err.misconfigured-installment-plan-flex"
                : "err.misconfigured-installment-plan"
            }
            values={{
              sup: (chunks) => (
                <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                  {chunks}
                </Text>
              ),
            }}
          />
        }>
        <Button
          variant="simple-action"
          mr={20}
          onClick={() => {
            setInvalidPlanWarning(null);
            resetValidateSaState();
            setEasyTabCheck(true);
          }}>
          <FormattedMessage id="btn.ok" />
        </Button>
        <Button
          variant="simple-action"
          onClick={() => {
            setInvalidPlanWarning(null);
            resetValidateSaState();
          }}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </StyledModalMessage>
      <StyledModalMessage
        isOpen={showEasyTabMsg && easyTabInfo}
        onRequestClose={() => setActiveEasyTabOption(ACTIVE_EASYTAB_OPTION.NONE)}
        message={
          <FormattedMessage
            id={
              easyTabInfo && easyTabInfo.messageId
                ? easyTabInfo.messageId
                : "lbl.active-easytab-pricing-easytab"
            }
            values={{
              amount: <AmountPlain color="ternary" amount={easyTabInfo.amount} />,
              noMonths: easyTabInfo.noMonths,
              flexAmount: <AmountPlain color="ternary" amount={easyTabInfo.flexDeferredAmount} />,
              flexReturnDatePeriod: easyTabInfo.flexReturnDatePeriod,
              flexReturnDatePeriodLabel: easyTabInfo.flexReturnDatePeriodLabel,
              sup: (chunks) => (
                <Text as="sup" mr={0} sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                  {chunks}
                </Text>
              ),
            }}
          />
        }
        verticalDirection={true}>
        {easyTabInfo && easyTabInfo.payFullPriceOptionAvailable && (
          <Button
            mb={3}
            variant="simple-action"
            onClick={() => {
              setActiveEasyTabOption(ACTIVE_EASYTAB_OPTION.PAY_FULL);
            }}>
            <FormattedMessage
              id="btn.pay-full-price"
              values={{
                sup: (chunks) => (
                  <Text
                    as="sup"
                    mr={0}
                    sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Button>
        )}
        {easyTabInfo && easyTabInfo.payOldEasyTabOptionAvailable && (
          <Button
            mb={3}
            variant="simple-action"
            onClick={() => {
              setActiveEasyTabOption(ACTIVE_EASYTAB_OPTION.PAY_OLD_INSTALLMENT);
            }}>
            <FormattedMessage
              id="btn.pay-old-easyTab"
              values={{
                sup: (chunks) => (
                  <Text
                    as="sup"
                    mr={0}
                    sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Button>
        )}
        {easyTabInfo &&
          !easyTabInfo.payFullPriceOptionAvailable &&
          !easyTabInfo.payOldEasyTabOptionAvailable && (
            <Button
              mb={3}
              variant="simple-action"
              onClick={() => {
                setActiveEasyTabOption(ACTIVE_EASYTAB_OPTION.PAY_FLEX);
              }}>
              <FormattedMessage
                id="btn.ok"
                values={{
                  sup: (chunks) => (
                    <Text
                      as="sup"
                      mr={0}
                      sx={{ display: "inline", fontSize: "45%", color: "ternary" }}>
                      {chunks}
                    </Text>
                  ),
                }}
              />
            </Button>
          )}
        <Button
          variant="simple-action"
          onClick={() => setActiveEasyTabOption(ACTIVE_EASYTAB_OPTION.NONE)}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </StyledModalMessage>
      <StyledModalMessage
        isOpen={nextPageWarning !== null}
        onRequestClose={() => setNextPageWarning(null)}
        message={intl.formatMessage({ id: "lbl.not-all-devices-assigned" })}>
        <Button mr={20} variant="simple-action" onClick={() => onNextConfirmed()}>
          <FormattedMessage id="btn.ok" />
        </Button>
        <Button variant="simple-action" onClick={() => setNextPageWarning(null)}>
          <FormattedMessage id="btn.cancel" />
        </Button>
      </StyledModalMessage>
      <OSContainer
        variant={infoMessage ? "page-content-no-round-top" : "page-content"}
        sx={{ flexDirection: "column" }}
        pt="0 !important">
        <Flex sx={{ flexDirection: "column" }}>
          {contentLoading && <UpdateDeviceSkeleton />}
          {bundles &&
            bundles.map((bundle) => (
              <SADevice
                key={bundle.id}
                device={bundle}
                existingLines={existingLinesState.getExistingLines.existingLines.filter(
                  (existingLine) => {
                    return !_.entries(itemsState.saDeviceLinkMap).find(
                      (entry) => entry[0] != bundle.id && entry[1].saId == existingLine.id
                    );
                  }
                )}
                selectedSA={
                  itemsState.saDeviceLinkMap[bundle.id]
                    ? itemsState.saDeviceLinkMap[bundle.id].saId
                    : NONE_CODE
                }
                onSelectServiceNumber={onSelectServiceNumber}
                numberOfBundles={bundles.length}
                onRemoveDevice={onRemoveDevice}
                pageHeader={
                  bundle.id === bundles[0].id
                    ? intl.formatMessage({ id: "label.your-devices" })
                    : ""
                }
              />
            ))}
        </Flex>
      </OSContainer>
    </Layout>
  );
};

export default UpdateDevice;
