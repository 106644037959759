export const baseTheme = {
  breakpoints: ["40em", "64.1em", "85.5em"],

  borders: {
    none: 0,
    0.5: "0.5px solid",
    1: "1px solid",
    2: "2px solid",
    4: "4px solid",
    8: "8px solid",
    16: "16px solid",
    32: "32px solid",
  },

  fontWeights: {
    thin: "100",
    extralight: "200",
    light: "300",
    normal: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
    extrabold: "800",
    black: "900",
  },

  sizes: {
    iconTiny: "1rem", // 16px
    iconSmall: "1.25rem", // 20px
    iconMedium: "1.5rem", //24px
    iconLarge: "3.375rem", // 54px
    iconVeryLarge: "5.5rem", // 82px
  },

  space: {
    px: "1px",
    none: "0px",
    0.5: "0.125rem", // 2px
    1: "0.25rem", // 4px
    1.5: "0.375rem", // 6px
    2: "0.5rem", // 8px
    2.5: "0.625rem", // 10px
    3: "0.75rem", // 12px
    3.5: "0.875rem", // 14px
    4: "1rem", // 16px
    4.5: "1.125rem", // 18px
    5: "1.25rem", // 20px
    5.5: "1.375rem", // 22px
    6: "1.5rem", // 24px
    6.5: "1.625rem", // 26px
    7: "1.75rem", // 28px
    7.5: "1.875rem", // 30px
    8: "2rem", // 32px
    8.5: "2.125rem", // 34px
    9: "2.25rem", // 36px
    9.5: "2.375rem", // 38px
    10: "2.5rem", // 40px
    10.5: "2.625rem", // 42px
    11: "2.75rem", // 44px
    11.5: "2.875rem", //46px
    12: "3rem", // 48px
    13: "3rem", // 52px
    13.5: "3.25rem", // 54px
    14: "3.5rem", // 56px
    15: "3.75rem", // 60px
    16: "4rem", // 64px
    17: "4.5rem", // 72px
    20: "5rem", // 80px
    24: "6rem", // 96px
    27.5: "6.875rem", //110px
    28: "7rem", // 112px
    30: "7.5rem", // 120px
    32: "8rem", // 128px
    36: "9rem", // 144px
    40: "10rem", // 160px
    44: "11rem", // 176px
    48: "12rem", // 192px
    52: "13rem", // 208px
    56: "14rem", // 224px
    60: "15rem", // 240px
    64: "16rem", // 256px
    72: "18rem", // 288px
    80: "20rem", // 320px
    96: "24rem", // 384px
  },

  fonts: {
    body: "'Work Sans', sans-serif",
    heading: "'Museo Sans', sans-serif",
  },

  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    base: "1rem", // 16px
    lg: "1.125rem", //18px
    xl: "1.25rem", // 20px
    xxl: "1.375rem", // 22px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
    "6xl": "3.75rem", // 60px
    "7xl": "4.5rem", // 72px
    "8xl": "6rem", // 96px
    "9xl": "8rem", // 128px
  },

  lineHeights: {
    none: "1",
    tight: "1.25",
    snug: "1.375",
    normal: "1.5",
    relaxed: "1.625",
    loose: "2",
    3: ".75rem", // 12px
    4: "1rem", // 16px
    5: "1.25rem", // 20px
    6: "1.5rem", // 24px
    7: "1.75rem", // 28px
    8: "2rem", // 32px
    9: "2.25rem", // 36px
    10: "2.5rem", // 40px
  },

  radii: {
    none: "0px",
    sm: "0.125rem", // 2px
    default: "0.25rem", // 4px
    md: "0.375rem", // 6px
    lg: "0.5rem", // 8px
    "2lg": "0.625rem", // 10px
    xl: "0.75rem", // 12px
    "2xl": "1rem", // 16px
    "3xl": "1.5rem", // 24px
    full: "9999px",
  },

  shadows: {
    sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    default: "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
    inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    none: "none",
  },

  colors: {
    windowBg: "#FFFFFF",
    secondaryBg: "#f3f3f3",
    headerBg: "#FFFFFF",
    ternaryBg: "#FFFFFF",
    invertedTextColor: "#FFFFFF",
    border: "#e5e5e7",
    error: "#be1e2d",
    primary: "#242222",
    secondary: "#48c0b6",
    cardBg: "#f3f3f3",
    cardBgDisabled: "#fcfcfc",
    selectedCardBg: "#48c0b6",
    cardBorderColor: "#f3f3f3",
    cardBorderHoverColor: "#f3f3f3",
    selectedCardTextColor: "#FFFFFF",
    disabledCardTextColor: "#807878",
    bulletBgPrimary: "#48c0b6",
    ternary: "#0c3865",
    quaternary: "#807878",
    greyish: "#e2e2e2",
    wizardNotCompleted: "#babec2",
    svgStrokePrimary: "#48c0b6",
    svgStrokeSecondary: "#FFFFFF",
    hrTriangle: "#48c0b6",
    estimatedTaxesColor: "#48c0b6",
    noResultsTextColor: "#48c0b6",
    activeTabColor: "#eae5f1",
    topMessage: "#707070",
    button: {
      background: "#48c0b6",
      disabledBackground: "#f3f3f3",
      hoverBackground: "#40aca3",
      color: "#ffffff",
      hoverColor: "#ffffff",
      disabledColor: "#8c8c8c",
    },
    link: {
      color: "#8c8c8c",
    },
    checkbox: "#c4c4c4",
  },
  text: {
    default: {
      color: "primary",
    },
    summaryLabel: {
      fontSize: "sm",
      color: "primary",
      mr: 2,
      py: 0.5,
    },
    summaryValue: {
      fontSize: "sm",
      fontWeight: "medium",
      color: "primary",
      py: 0.5,
    },
    heading1: {
      mt: 12,
      mb: 8,
      fontSize: "4xl",
      fontWeight: "bold",
      cursor: "default",
      color: "ternary",
    },
    CollapsibleHeader: {
      fontSize: "4xl",
      fontWeight: "semibold",
      cursor: "default",
      color: "primary",
      fontFamily: "body",
    },
    CollapsibleHeaderLight: {
      fontSize: "4xl",
      fontWeight: "light",
      cursor: "default",
      color: "primary",
      whiteSpace: "normal",
      fontFamily: "body",
    },
    shoppingCartTitle: {
      fontSize: "3xl",
      fontWeight: "bold",
      color: "ternary",
      display: "block",
      whiteSpace: "normal",
    },
    multipleTabNormal: {
      fontSize: "3xl",
      fontWeight: "normal",
      color: "ternary",
      display: "block",
      whiteSpace: "normal",
    },
    heading2: {
      fontSize: "2xl",
      fontWeight: "bold",
      cursor: "default",
      color: "ternary",
    },
    heading3: {
      color: "ternary",
      fontSize: "2xl",
      fontWeight: "semibold",
      whiteSpace: "normal",
      overflowWrap: "break-word",
      cursor: "default",
      fontFamily: "body",
    },
    heading3normal: {
      my: 3,
      cursor: "default",
      color: "ternary",
      whiteSpace: "normal",
      fontSize: "2xl",
      fontWeight: "normal",
      overflowWrap: "normal",
      fontStretch: "normal",
      fontFamily: "body",
    },
    heading4: {
      fontSize: "xxl",
      fontWeight: "bold",
      cursor: "default",
      color: "ternary",
      fontFamily: "body",
    },
    heading5: {
      my: 3,
      cursor: "default",
      color: "ternary",
      fontSize: "xl",
      fontWeight: "bold",
      whiteSpace: "nowrap",
      fontFamily: "body",
    },
    deliveryType: {
      color: "ternary",
      fontSize: "xl",
      fontWeight: "medium",
    },
    description: {
      color: "ternary",
      fontSize: "xl",
      fontWeight: "normal",
    },
    attribute: {
      color: "ternary",
      fontSize: "lg",
      fontWeight: "normal",
      textAlign: "center",
    },
    heading6: {
      mb: 3,
      cursor: "default",
      color: "primary",
      fontSize: "base",
      fontWeight: "bold",
      whiteSpace: "normal",
      fontFamily: "body",
    },
    storePickup: {
      fontSize: "base",
      fontWeight: "semibold",
      color: "ternary",
    },
    headerStep: {
      fontSize: "base",
      fontWeight: "medium",
    },
    subtitle1: {
      mt: 3,
      cursor: "default",
      color: "primary",
      fontSize: "base",
      fontWeight: "normal",
      whiteSpace: "normal",
    },
    subtitle2: {
      mt: 1,
      cursor: "default",
      color: "primary",
      fontSize: "lg",
      fontWeight: "normal",
      whiteSpace: "normal",
    },
    headerStepLight: {
      fontSize: "base",
      fontWeight: "light",
      color: "primary",
    },
    phoneNumberType: {
      fontSize: "2xl",
      cursor: "pointer",
      textAlign: "left",
      fontWeight: "light",
    },
    deviceHeader: {
      fontSize: "xxl",
      fontFamily: "body",
      fontWeight: "normal",
      color: "topMessage",
      textAlign: "justify",
    },
    lineDescription: {
      color: "ternary",
      fontSize: "lg",
      fontWeight: "light",
      wordWrap: "break-word",
      textAlign: "justify",
    },
    outOfStock: {
      fontSize: "sm",
      fontWeight: "bold",
      color: "primary",
      whiteSpace: "pre-line",
    },
    ccTitle: {
      fontSize: "lg",
      fontWeight: "semibold",
      color: "ternary",
    },
    taxDescription: {
      textAlign: "center",
      mt: 2,
    },
    amountDescription: {
      alignSelf: "center",
      fontWeight: "light",
      fontSize: "sm",
    },
    largeLabel: {
      fontStretch: "normal",
      color: "primary",
      fontSize: "xl",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      lineHeight: "1.2",
      textAlign: "left",
    },
    largeBoldLabel: {
      fontStretch: "normal",
      color: "primary",
      fontSize: "xl",
      fontStyle: "normal",
      fontWeight: "bold",
      letterSpacing: "normal",
      lineHeight: "1.2",
      textAlign: "left",
    },
  },
  links: {
    "chat-link": {
      color: "link.color",
      cursor: "pointer",
      textDecoration: "underline",
      fontStyle: "italic",
      "&:focus, &:hover, &:visited,&:link,&:active": {
        color: "link.color",
      },
    },
    "chat-link-no-underline": {
      color: "link.color",
      cursor: "pointer",
      "&:focus, &:hover, &:visited,&:link,&:active": {
        color: "link.color",
      },
    },
  },
  forms: {
    address: {
      fontSize: "lg",
      fontWeight: "normal",
      width: "14rem",
    },
    labelLarge: {
      fontSize: "lg",
      color: "primary",
      cursor: "default",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      overflow: "visible",
      textOverflow: "ellipsis",
      lineHeight: "relaxed",
    },
    labelSmall: {
      fontSize: "sm",
      color: "primary",
      cursor: "default",
      fontWeight: "normal",
      whiteSpace: "nowrap",
      overflow: "visible",
      textOverflow: "ellipsis",
      lineHeight: "relaxed",
    },

    radio: {
      height: "1.625rem",
      width: "1.625rem",
      color: "ternary",
      "input:checked ~ &": {
        color: "ternary",
      },
      ":hover": {
        cursor: "pointer",
      },
    },
    switch: {
      bg: "lightgrey",
      "input:checked ~ &": {
        bg: "ternary",
      },
      "&:disabled": {
        opacity: "0.75",
      },
      "input:disabled ~ &": {
        cursor: "default",
      },
    },
    toggleSwitch: {
      bg: "ternary",
      "input:checked ~ &": {
        bg: "ternary",
      },
      "&:disabled": {
        opacity: "0.75",
      },
      "input:disabled ~ &": {
        cursor: "default",
      },
    },
    checkbox: {
      height: "1.625rem !important",
      width: "1.625rem !important",
      color: "checkbox",
      "input:checked ~ &": {
        color: "secondary",
      },
      "input:disabled ~ &": {
        cursor: "default",
        bg: "greyish",
        color: "button.disabledColor",
      },
      ":hover": {
        cursor: "pointer",
      },
    },
    text: {
      ":disabled": {
        color: "note",
      },
    },
    input: {
      height: "2.75rem",
      borderColor: "border",
      borderRadius: "2lg",
      color: "primary",
      fontFamily: "inherit",
      fontSize: "base",
      ":focus": {
        outline: "none",
        borderColor: "secondary",
        borderWidth: "2px",
      },
      ":disabled": {
        backgroundColor: "button.disabledBackground",
        borderColor: "inactive",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },

    inputError: {
      height: "2.75rem",
      borderColor: "error",
      borderRadius: "2lg",
      color: "primary",
      fontFamily: "inherit",
      fontSize: "base",
      ":focus": {
        outline: "solid",
        borderColor: "error",
        outlineColor: "error",
        outlineWidth: "1px",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },

    select: {
      fontFamily: "body",
      display: "block",
      width: "100%",
      p: 2,
      pr: 8,
      appearance: "none",
      fontSize: "lg",
      fontWeight: "normal",
      lineHeight: "inherit",
      border: 1,
      borderRadius: "2lg",
      color: "inherit",
      bg: "transparent",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      justifyContent: "flex-end",
      height: "2.75rem",
      borderColor: "border",
      ":disabled": {
        backgroundColor: "windowBg",
        borderColor: "windowBg",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
      "&:focus": {
        outline: "none",
        borderColor: "secondary",
        borderWidth: "2px",
      },
    },
    selectError: {
      bg: "transparent",
      height: "2.75rem",
      borderColor: "error",
      borderRadius: "2lg",
      p: 2,
      pr: 8,
      color: "error",
      fontFamily: "body",
      fontSize: "lg",
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
      width: "100%",
      "&:focus": {
        outline: "solid",
        borderColor: "error",
        outlineColor: "error",
        outlineWidth: "1px",
      },
    },

    search: {
      bg: "windowBg",
      height: "2.75rem",
      borderColor: "#c2c2c2",
      borderRadius: "2lg",
      color: "primary",
      fontFamily: "inherit",
      fontSize: "sm",
      ":focus": {
        outline: "solid",
        borderColor: "secondary",
        outlineColor: "secondary",
        outlineWidth: "1px",
      },
      ":disabled": {
        backgroundColor: "inactive",
        borderColor: "inactive",
      },
      "::-ms-clear": {
        display: "none",
      },
      "::-ms-reveal": {
        display: "none",
      },
    },
    slider: {
      "&::-webkit-slider-runnable-track": {
        bg: "#c2c2c2",
        borderRadius: "2xl",
        height: "0.5rem",
      },
      "&::-moz-range-track": {
        bg: "#c2c2c2",
        borderRadius: "2xl",
        height: "0.5rem",
      },
      "&::-webkit-slider-thumb": {
        width: "1.5rem",
        height: "1.5rem",
        background: "secondary",
        cursor: "pointer",
        WebkitAppearance: "none",
        mt: "-0.5rem",
      },
      "&::-moz-range-thumb": {
        width: "1.5rem",
        height: "1.5rem",
        background: "secondary",
        cursor: "pointer",
      },
    },
  },
  buttons: {
    "autocomplete-btn": {
      bg: "secondaryBg",
      color: "primary",
      textAlign: "left",
      "&:focus": {
        borderColor: "#1e90ff",
        outlineColor: "#1e90ff",
        bg: "#1e90ff",
        color: "white",
      },
    },
    "select-phonenumber": {
      fontFamily: "body",
      fontSize: "xl",
      fontWeight: "semibold",
      width: "100%",
      border: 1,
      borderColor: "border",
      borderRadius: "2lg",
      boxShadow: "sm",
      cursor: "pointer",
      ":hover": { borderColor: "secondary" },
      "&:focus": {
        outline: "none",
      },
    },
    "default-next": {
      bg: "button.background",
      borderRadius: "full",
      boxShadow: "default",
      color: "button.color",
      cursor: "pointer",
      fontFamily: "inherit",
      fontSize: "2xl",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      outline: "none !important",
      px: [4, 4, 8],
      py: 4,
      textAlign: "center",
      width: ["auto", "auto", "auto"],
      "&:hover:enabled": {
        bg: "button.hoverBackground",
        color: "button.hoverColor",
      },
      "&:active:enabled": {
        boxShadow: "inset 0 7px 14px rgb(50 50 93 / 10%), inset 0 3px 6px rgb(0 0 0 / 5%)",
      },
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "simple-action-join-group": {
      fontSize: "sm",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      fontWeight: "normal",
      border: 1,
      borderColor: "secondary",
      borderRadius: "full",
      boxShadow: "sm",
      cursor: "pointer",
      bg: "secondaryBg",
      outline: "none !important",
      color: "secondary",
      py: [0, 2, 2],
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
        borderColor: "border",
      },
    },
    "simple-action": {
      bg: "transparent",
      borderRadius: "full",
      border: "1",
      borderColor: "ternary",
      cursor: "pointer",
      color: "ternary",
      fontFamily: "inherit",
      fontSize: "lg",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      outline: "none !important",
      minWidth: "10rem",
      px: [4, 4, 13],
      py: 3,
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "simple-action-order-confirmation": {
      bg: "#FFFFFF",
      borderRadius: "full",
      border: "1",
      borderColor: "ternary",
      cursor: "pointer",
      color: "ternary",
      fontFamily: "inherit",
      fontSize: "lg",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      outline: "none !important",
      minWidth: "6rem",
      maxWidth: "6rem",
      px: [4, 4, 4],
      py: 2,
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "simple-action-port-in": {
      bg: "transparent",
      borderRadius: "full",
      border: "1",
      boxShadow: "md",
      borderColor: "ternary",
      cursor: "pointer",
      color: "ternary",
      fontFamily: "inherit",
      fontSize: "2xl",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "semibold",
      letterSpacing: "0.5px",
      outline: "none !important",
      minWidth: "10rem",
      px: [4, 4, 4],
      py: 2,
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "form-submit": {
      bg: "button.background",
      borderRadius: "full",
      boxShadow: "default",
      color: "button.color",
      cursor: "pointer",
      fontFamily: "inherit",
      fontSize: "lg",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      outline: "none !important",
      minWidth: "10rem",
      px: 2.5,
      py: 3,
      textAlign: "center",
      width: "auto",
      "&:hover:enabled": {
        bg: "button.hoverBackground",
        color: "button.hoverColor",
      },
      "&:active:enabled": {
        boxShadow: "0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 5%)",
      },
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "address-validation-action": {
      bg: "white",
      borderRadius: "full",
      boxShadow: "default",
      cursor: "pointer",
      fontFamily: "inherit",
      fontSize: "default",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      letterSpacing: "normal",
      outline: "none !important",
      textAlign: "center",
      "&:hover:enabled": {
        bg: "windowBg",
      },
      "&:active:enabled": {
        boxShadow: "0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 5%)",
      },
    },
    "base-button": {
      borderRadius: "full",
      boxShadow: "default",
      cursor: "pointer",
      fontFamily: "inherit",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },
    "download-action": {
      bg: "transparent",
      borderRadius: "full",
      border: "1",
      borderColor: "ternary",
      cursor: "pointer",
      color: "ternary",
      fontFamily: "inherit",
      fontSize: "sm",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      outline: "none !important",
      py: 2,
      "&:disabled": {
        bg: "button.disabledBackground",
        color: "button.disabledColor",
        cursor: "default",
      },
    },

    "simple-button": {
      bg: "secondaryBg",
      color: "ternary",
      border: 1,
      borderColor: "ternary",
      borderRadius: "full",
      boxShadow: "sm",
      cursor: "pointer",
      fontFamily: "inherit",
      fontSize: "base",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      letterSpacing: "normal",
      outline: "none !important",
      width: "auto",
    },

    "simple-selected-button": {
      bg: "secondary",
      color: "textLight",
      border: 1,
      borderColor: "ternary",
      borderRadius: "full",
      boxShadow: "sm",
      cursor: "pointer",
      fontFamily: "inherit",
      fontSize: "base",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      outline: "none !important",
      width: "auto",
    },
  },
  layout: {
    "page-content": {
      bg: "ternaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "xl",
      boxShadow: "sm",
      py: 6,
    },
    "page-content-no-round-top": {
      bg: "ternaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderBottomLeftRadius: "xl",
      borderBottomRightRadius: "xl",
      boxShadow: "sm",
      py: 6,
    },
    "page-section": {
      bg: "ternaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "xl",
      boxShadow: "sm",
      px: [4, 8, 9],
      py: 5,
    },
    "device-and-plan-section": {
      bg: "ternaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "xl",
      boxShadow: "sm",
      py: [8, 8, 14],
      px: [9, 9, 9],
      alignItems: ["center", "center", "flex-start"],
      flexDirection: ["column", "column", "row"],
      justifyContent: ["space-around", "space-around", "flex-start"],
      mt: 5,
    },
    center: {
      bg: "ternaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "xl",
      boxShadow: "sm",
      alignItems: "center",
      flexDirection: "column",
      py: 10,
      px: [8, 8, 22],
      margin: "auto",
    },
    "action-section": {
      bg: "secondaryBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "2lg",
      boxShadow: "sm",
      flexDirection: ["column", "row", "row"],
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-evenly",
      py: 5,
      px: 8,
      width: "100%",
      my: 1.5,
    },
    "action-section-order-confirmation": {
      bg: "windowBg",
      border: "1",
      borderColor: "cardBorderColor",
      borderRadius: "2lg",
      boxShadow: "sm",
      flexDirection: ["column", "row", "row"],
      alignItems: "center",
      alignContent: "center",
      justifyContent: "space-between",
      py: 2,
      px: 5,
      width: "100%",
      maxWidth: ["100%", "100%", "49%"],
      my: 0.5,
    },
    "top-message": {
      bg: "windowBg",
      border: "1",
      borderBottom: "0",
      borderColor: "border",
      borderTopLeftRadius: "xl",
      borderTopRightRadius: "xl",
      boxShadow: "sm",
      py: 6,
      px: [4, 8, 9],
      mb: -2,
      zIndex: "1",
    },
  },
  divider: {
    "card-border": {
      bg: "cardBorderColor",
      border: "none",
      borderWidth: "0.8px",
      borderColor: "secondary",
      height: "1px",
      width: "100%",
    },
    "gray-border": {
      bg: "greyish",
      border: "none",
      borderWidth: "0.8px",
      borderColor: "secondary",
      height: "1px",
      width: "100%",
    },
    "card-border-no-width": {
      bg: "cardBorderColor",
      border: "none",
      borderWidth: "0.8px",
      borderColor: "secondary",
      height: "1px",
    },
  },
};
