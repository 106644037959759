import React, { useEffect, useState } from "react";
import { Box } from "@theme-ui/components";
import Accessory from "./Accessory";
import { BundleItemSkeleton } from "../../../components/skeletons";
import _ from "lodash";
import TotalPrices from "../../../components/total-prices";

const Accessories = ({ items, loadingBundle, priceOffers, numberOfBundles, ...props }) => {
  const [accessoriesOneTimeCharges, setAccessoriesOneTimeCharges] = useState(0);
  const [changeAccessory, setChangeAccessory] = useState(null);

  useEffect(() => {
    if (priceOffers?.length === 0) return;

    let totalPrice = 0;
    _.each(items, (item) => {
      const priceOffer = priceOffers.find((price) => price.code === item.sku);
      totalPrice += priceOffer.price[0]?.price + priceOffer.price[0]?.tax;
    });
    let accessoryWithTax = priceOffers.find((price) => price.price[0]?.tax > 0);
    setAccessoriesOneTimeCharges({ amount: totalPrice, isTaxFree: !accessoryWithTax });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceOffers, items]);

  const getAccessoryLine = (index, loadingBundle, item, priceOffers) => {
    if (loadingBundle) {
      return <BundleItemSkeleton key={index} title="" />;
    }

    if (!item || (item && item.sku === null)) {
      return null;
    }

    const priceOffer = priceOffers.find((price) => price.code === item.sku);
    return (
      (changeAccessory === null || (changeAccessory && changeAccessory.id === item.id)) && (
        <Accessory
          key={index}
          item={item}
          priceOffer={priceOffer?.price[0] ?? item.prices[0]}
          setChangeAccessory={setChangeAccessory}
          numberOfBundles={numberOfBundles}
          numberOfAccessories={items.length}
        />
      )
    );
  };

  return (
    <Box mt={6} px={[8, 8, 9]} {...props}>
      {items.map((item, index) => getAccessoryLine(index, loadingBundle, item, priceOffers))}
      <TotalPrices
        dueNowPrices={{
          accessoriesPrice: accessoriesOneTimeCharges,
        }}
      />
    </Box>
  );
};

export default Accessories;
