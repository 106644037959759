import React, { useEffect, useRef, useState } from "react";
import { Input, Flex, Button } from "@theme-ui/components";
import { useSelector } from "react-redux";
import { ErrorMessage, OSLabel } from "../base";
import { OutsideClickHandler } from "./AutocompleteUtilities";
import { CPE_SKU_CODE, NONE_CODE, INVALID_FIELDS } from "../../common/Constants";
import { FormattedMessage } from "react-intl";

const Autocomplete = ({
  isLoading,
  disabled,
  mandatory,
  label,
  placeHolder,
  defaultValue,
  onClick,
  width,
  untouchedMandatoryFields,
  mandatoryFieldMarked,
}) => {
  const [searchValue, setSearchValue] = useState(defaultValue);
  const { ...itemsState } = useSelector((state) => state.items);
  const [filteredListVisible, setFilteredListVisible] = useState(false);
  const onSearchInputChange = (event) => {
    onClick(NONE_CODE);
    setSearchValue(event.target.value);
  };

  const handleKeyDown = (event, index) => {
    let nextbutton = null;
    if (event.keyCode === 38 && index > 0) {
      nextbutton = document.querySelector(`#button${index - 1}`);
    } else if (event.keyCode === 38 && index == 0) {
      nextbutton = document.querySelector("#autoInput");
    } else if (event.keyCode === 40 && index < filtered.length - 1) {
      nextbutton = document.querySelector(`#button${index + 1}`);
    }
    nextbutton?.focus();
  };

  const stopPropagation = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    event.stopPropagation();
  };
  const onInputFocus = () => {
    setFilteredListVisible(true);
  };

  const container = useRef();
  const scrollContainer = useRef();
  const filtered = itemsState.getModelsByGroupCode.goodModels.filter((gm) =>
    gm.description.toUpperCase().includes(searchValue.toUpperCase())
  );

  const markInputFieldMandatory =
    mandatory &&
    ((untouchedMandatoryFields.includes(INVALID_FIELDS.AUTOCOMPLETE) && searchValue === "") ||
      filtered.length == 0);

  const showFilteredList =
    filtered.length > 0 &&
    filteredListVisible &&
    !disabled &&
    !(filtered.length === 1 && filtered[0].description === searchValue);
  useEffect(() => {
    if (disabled) {
      setSearchValue("");
    }
    if (defaultValue !== "" && defaultValue !== CPE_SKU_CODE) {
      let filteredCode = itemsState.getModelsByGroupCode.goodModels.filter((gm) =>
        gm.code.includes(defaultValue)
      );
      setSearchValue(filteredCode.length > 0 ? filteredCode[0].description : "");
    }
  }, [disabled, defaultValue, itemsState.getModelsByGroupCode.goodModels]);

  useEffect(() => {
    if (markInputFieldMandatory && untouchedMandatoryFields.includes(INVALID_FIELDS.AUTOCOMPLETE))
      container.current?.scrollIntoView({ block: "center" });
  }, [untouchedMandatoryFields, markInputFieldMandatory]);

  useEffect(() => {
    mandatoryFieldMarked(markInputFieldMandatory);
  }, [mandatoryFieldMarked, markInputFieldMandatory]);
  return (
    <Flex sx={{ flexDirection: "column", width: width }} ref={container}>
      <OSLabel isMandatory={mandatory}>{label}</OSLabel>

      <OutsideClickHandler
        onClickOutside={() => {
          setFilteredListVisible(false);
        }}>
        <Input
          autoComplete="off"
          id="autoInput"
          disabled={isLoading || disabled}
          mt={2.5}
          name="autocomplete"
          value={searchValue}
          onChange={onSearchInputChange}
          onKeyDown={(event) => {
            if (event.keyCode === 40) {
              const nextbutton = document.querySelector("#button0");
              nextbutton.focus();
            }
            stopPropagation(event);
          }}
          onFocus={onInputFocus}
          placeholder={placeHolder}
          px={5}
          type="input"
          variant="search"
          sx={{
            borderColor: markInputFieldMandatory ? "error" : "",
          }}
          bg={disabled ? "" : "secondaryBg"}
        />

        {showFilteredList && (
          <Flex
            ref={scrollContainer}
            id="filteredList"
            onKeyDown={(event) => {
              stopPropagation(event);
            }}
            sx={{
              position: "absolute",
              flexDirection: "column",
              maxHeight: "10rem",
              border: 1,
              overflow: "scroll",
              overflowX: "hidden",
              bg: "secondaryBg",
              zIndex: "1",
              width: container.current?.scrollWidth,
            }}>
            {filtered.map((value, index) => {
              return (
                <Button
                  variant="autocomplete-btn"
                  id={"button" + index}
                  key={value.code}
                  onKeyDown={(event) => {
                    handleKeyDown(event, index);
                  }}
                  onClick={() => {
                    setFilteredListVisible(false);
                    setSearchValue(value.description);
                    onClick(value.code);
                  }}>
                  {value.description}
                </Button>
              );
            })}
          </Flex>
        )}
        {markInputFieldMandatory && (
          <ErrorMessage>
            <FormattedMessage
              id={
                filtered.length == 0
                  ? "lbl.autocomplete-list-selection"
                  : "lbl.autocomplete-section"
              }
            />
          </ErrorMessage>
        )}
      </OutsideClickHandler>
    </Flex>
  );
};

export default Autocomplete;
