import React from "react";
import { Box } from "@theme-ui/components";

const Logout = ({ fill = "#000000", size = "iconTiny", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    sx={{ width: size, height: size, fill: fill }}
    {...props}>
    <g>
      <path
        stroke="none"
        fillRule="nonzero"
        fill={fill}
        fillOpacity={1}
        d="M 10.027344 11.617188 L 11.273438 11.617188 L 11.273438 14.117188 C 11.273438 15.152344 10.433594 15.992188 9.402344 15.992188 L 1.875 15.992188 C 0.839844 15.992188 0 15.152344 0 14.117188 L 0 1.875 C 0 0.839844 0.839844 0 1.875 0 L 9.402344 0 C 10.433594 0 11.273438 0.839844 11.273438 1.875 L 11.273438 4.371094 L 10.027344 4.371094 L 10.027344 1.875 C 10.027344 1.53125 9.746094 1.25 9.402344 1.25 L 1.875 1.25 C 1.53125 1.25 1.25 1.53125 1.25 1.875 L 1.25 14.117188 C 1.25 14.460938 1.53125 14.742188 1.875 14.742188 L 9.402344 14.742188 C 9.746094 14.742188 10.027344 14.460938 10.027344 14.117188 Z M 13.054688 5.050781 L 12.171875 5.933594 L 13.609375 7.371094 L 5.527344 7.371094 L 5.527344 8.621094 L 13.609375 8.621094 L 12.171875 10.058594 L 13.054688 10.941406 L 16 7.996094 Z M 13.054688 5.050781 "
      />
    </g>
  </Box>
);

export default Logout;
