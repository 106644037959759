import React from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import Amount from "../../amount";
import { Heading2, Heading6 } from "../../heading";
import { OSIcon } from "../../icons";
import { HIDDEN_ATTRIBUTES, ITEM_ATTRIBUTE_CODE, PRICE_TYPE } from "../../../common/Constants";
import { SHIPPING_FLOW } from "../../../pages/shipping-details/Constants";

/**
 * @typedef {Object} ItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                        - The price.
 * @property {String} type                         - Price type.
 * @property {Number} termsAmount                  - Price terms - amount.
 * @property {String} termsUnit                    - Price terms - unit.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {ItemPrice[]} prices                 - List of item's prices.
 * @property {ItemAttribute[]} attributes         - List of item's attributes.
 */
/**
 * Renders a plan item.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 */
const Shipping = ({ item, showPrices }) => {
  const intl = useIntl();

  const oneTimePrice = item.prices.find((price) => price.type === PRICE_TYPE.ONE_TIME);
  const shipmentOption = item.attributes.find(
    (attr) => attr.code === ITEM_ATTRIBUTE_CODE.SHIPMENT_OPTION
  );
  const iconName =
    shipmentOption?.value === SHIPPING_FLOW.HOME_DELIVERY ? "home-delivery" : "pick-in-store";
  const shipmentDesc =
    shipmentOption?.value === SHIPPING_FLOW.HOME_DELIVERY
      ? intl.formatMessage({ id: "lbl.home-delivery" })
      : intl.formatMessage({ id: "lbl.pick-in-store-delivery" });

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading2 mb={3}>{intl.formatMessage({ id: "lbl.shipping" })}</Heading2>

      <Flex>
        <Flex sx={{ flex: 1 }}>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "4.5625rem",
              width: "4.5625rem",
              minWidth: "4.5625rem",
            }}
            mr={3}>
            <OSIcon name={iconName} width="100%" />
          </Flex>
          <Flex sx={{ flexDirection: "column", justifyContent: "center" }}>
            <Heading6 color="ternary" mb={1.5}>
              {item.shortDescription ? item.shortDescription : shipmentDesc}
            </Heading6>

            {/* Shipping attributes */}
            {item.attributes && item.attributes.length > 0 && (
              <Text color="ternary" variant="summaryLabel">
                {item.attributes
                  .filter((attr) => !HIDDEN_ATTRIBUTES.includes(attr.code))
                  .map((attr) => attr.value)
                  .join(", ")}
              </Text>
            )}
          </Flex>
        </Flex>
        {showPrices && (
          <Flex sx={{ flexDirection: "column" }} ml={2} mt={4} mr={[8, 8, 0]}>
            {oneTimePrice && <Amount amount={oneTimePrice.price} />}
            {oneTimePrice && oneTimePrice.tax > 0 && (
              <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default Shipping;
