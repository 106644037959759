import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FacadeAPI } from "../../../rest/RestClient";

export const getUseCreditScore = createAsyncThunk("configuration/useCreditScore", async () => {
  return await FacadeAPI.GET("/configuration/useCreditScore");
});

export const getAccountContactMinAge = createAsyncThunk(
  "configuration/accountContactMinAge",
  async () => {
    return await FacadeAPI.GET("/configuration/accountContactMinAge");
  }
);

export const getProvincesPools = createAsyncThunk(
  "configuration/provincesPoolsConfig",
  async () => {
    return await FacadeAPI.GET("/configuration/provincesPoolsConfig");
  }
);

export const getPosParameter = createAsyncThunk(
  "configuration/posParameter",
  async (paramName, { getState }) => {
    const params = getState().configuration.getPosParameter.posParameters;
    if (params[paramName]) return params[paramName];
    return await FacadeAPI.GET(`/configuration/posParameter/${paramName}`, null, {
      expectedResponse: "text",
    });
  }
);

export const getSysConfigParameter = createAsyncThunk(
  "configuration/sysConfigParameter",
  async (paramName, { getState }) => {
    const params = getState().configuration.getSysConfigParameter.sysConfigParameters;
    if (params[paramName]) return params[paramName];
    return await FacadeAPI.GET(`/configuration/sysConfigParameter/${paramName}`, null, {
      expectedResponse: "text",
    });
  }
);

export const getSecurityRight = createAsyncThunk(
  "configuration/securityRight",
  async (right, { getState }) => {
    const rights = getState().configuration.getSecurityRight.securityRights;
    if (rights[right] !== undefined) {
      return rights[right];
    }
    return await FacadeAPI.GET(`/configuration/securityRight/${right}`);
  }
);

export const getAccountPinLengths = createAsyncThunk(
  "configuration/accountPinLengths",
  async () => {
    return await FacadeAPI.GET(`/configuration/accountPinLengths`);
  }
);

const configurationSlice = createSlice({
  name: "configuration",
  initialState: {
    getUseCreditScore: {
      loading: false,
      error: null,
      useCreditScore: null,
    },
    getAccountContactMinAge: {
      loading: false,
      error: null,
      minAge: 18,
    },
    getProvincesPools: {
      loading: false,
      success: false,
      error: null,
      provincesPools: {},
    },
    getPosParameter: {
      loading: false,
      success: false,
      error: null,
      posParameters: {},
    },
    getSysConfigParameter: {
      loading: false,
      success: false,
      error: null,
      sysConfigParameters: {},
    },
    getSecurityRight: {
      loading: false,
      success: false,
      error: null,
      securityRights: {},
    },
    getAccountPinLengths: {
      loading: false,
      success: false,
      error: null,
      pinLengths: {},
    },
  },
  reducers: {},
  extraReducers: {
    [getUseCreditScore.pending]: (state) => {
      state.getUseCreditScore.loading = true;
    },
    [getUseCreditScore.fulfilled]: (state, action) => {
      state.getUseCreditScore.loading = false;
      state.getUseCreditScore.useCreditScore = action.payload;
    },
    [getUseCreditScore.rejected]: (state, action) => {
      state.getUseCreditScore.loading = false;
      state.getUseCreditScore.error = action.error;
    },

    [getAccountContactMinAge.pending]: (state) => {
      state.getAccountContactMinAge.loading = true;
    },
    [getAccountContactMinAge.fulfilled]: (state, action) => {
      state.getAccountContactMinAge.loading = false;
      state.getAccountContactMinAge.minAge = action.payload;
    },
    [getAccountContactMinAge.rejected]: (state, action) => {
      state.getAccountContactMinAge.loading = false;
      state.getAccountContactMinAge.error = action.error;
    },

    [getProvincesPools.pending]: (state) => {
      state.getProvincesPools.loading = true;
      state.getProvincesPools.success = false;
    },
    [getProvincesPools.fulfilled]: (state, action) => {
      state.getProvincesPools.loading = false;
      state.getProvincesPools.success = true;
      state.getProvincesPools.provincesPools = action.payload;
    },
    [getProvincesPools.rejected]: (state, action) => {
      state.getProvincesPools.loading = false;
      state.getProvincesPools.error = action.error;
    },
    [getPosParameter.pending]: (state) => {
      state.getPosParameter.loading = true;
      state.getPosParameter.success = false;
    },
    [getPosParameter.fulfilled]: (state, action) => {
      state.getPosParameter.loading = false;
      state.getPosParameter.success = true;
      state.getPosParameter.posParameters[action.meta.arg] = action.payload;
    },
    [getPosParameter.rejected]: (state, action) => {
      state.getPosParameter.loading = false;
      state.getPosParameter.success = false;
      state.getPosParameter.error = action.error;
    },
    [getSysConfigParameter.pending]: (state) => {
      state.getSysConfigParameter.loading = true;
      state.getSysConfigParameter.success = false;
    },
    [getSysConfigParameter.fulfilled]: (state, action) => {
      state.getSysConfigParameter.loading = false;
      state.getSysConfigParameter.success = true;
      state.getSysConfigParameter.sysConfigParameters[action.meta.arg] = action.payload;
    },
    [getSysConfigParameter.rejected]: (state, action) => {
      state.getSysConfigParameter.loading = false;
      state.getSysConfigParameter.success = false;
      state.getSysConfigParameter.error = action.error;
    },
    [getSecurityRight.pending]: (state) => {
      state.getSecurityRight.loading = true;
      state.getSecurityRight.success = false;
    },
    [getSecurityRight.fulfilled]: (state, action) => {
      state.getSecurityRight.loading = false;
      state.getSecurityRight.success = true;
      state.getSecurityRight.securityRights[action.meta.arg] = action.payload;
    },
    [getSecurityRight.rejected]: (state, action) => {
      state.getSecurityRight.loading = false;
      state.getSecurityRight.success = false;
      state.getSecurityRight.error = action.error;
    },
    [getAccountPinLengths.pending]: (state) => {
      state.getAccountPinLengths.loading = true;
      state.getAccountPinLengths.success = false;
    },
    [getAccountPinLengths.fulfilled]: (state, action) => {
      state.getAccountPinLengths.loading = false;
      state.getAccountPinLengths.success = true;
      state.getAccountPinLengths.pinLengths = {
        minLength: action.payload.first,
        maxLength: action.payload.second,
      };
    },
    [getAccountPinLengths.rejected]: (state, action) => {
      state.getAccountPinLengths.loading = false;
      state.getAccountPinLengths.success = false;
      state.getAccountPinLengths.error = action.error;
    },
  },
});

export default configurationSlice.reducer;
