import React from "react";
import { Flex } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { FormInput } from "../base";
import { useForm } from "react-hook-form";
import { udfsNames } from "../../pages/personal-information/Constants";
import Tooltip from "../tooltip";
import { OSIcon } from "../icons";

const UDFTextInput = ({ udf, sectionName, onChange, disabled = false, displayTooltip = false }) => {
  const intl = useIntl();
  const { register, errors } = useForm({ mode: "onTouched" });
  const reactHookFormHandle = { register, errors };

  const udfLabel =
    udf.name === udfsNames.SMSCONTACTPN
      ? intl.formatMessage({ id: "lbl.number-for-sms-alerts" })
      : udf.label;
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <FormInput
        section={sectionName}
        disabled={disabled}
        isMandatory={udf.mandatory}
        label={udfLabel}
        defaultValue={udf.value}
        maxLength={udf.maxLen}
        name={udf.name}
        type="text"
        onChange={onChange}
        {...reactHookFormHandle}
      />
      {displayTooltip && (
        <Tooltip
          position="right"
          value={udfLabel}
          mt="-2.15rem"
          mr="0.5rem"
          sx={{ alignSelf: "flex-end" }}>
          <OSIcon name="tooltip-info" />
        </Tooltip>
      )}
    </Flex>
  );
};

export default UDFTextInput;
