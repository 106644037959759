import React, { forwardRef } from "react";
import { Select } from "@theme-ui/components";
import { OSIcon } from "../../icons";

const OSSelect = forwardRef(({ children, ...props }, ref) => (
  <Select
    ref={ref}
    as="select"
    tx="forms"
    variant="select"
    arrow={
      <OSIcon
        rotate="90"
        name="chevron"
        sx={{
          ml: -8,
          alignSelf: "center",
          pointerEvents: "none",
        }}
      />
    }
    {...props}>
    {children}
  </Select>
));

export default OSSelect;
