import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FacadeAPI } from "../../../rest/RestClient";

const INITIAL_STATE = {
  getDraftContract: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },

  getReceipt: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },

  getAdditionalDocuments: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },

  getStaticDocumentContent: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },

  documents: [],
};

export const getDraftContract = createAsyncThunk(
  "docs/getDraftContract",
  async (request, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      const result = await FacadeAPI.GET(`/documents/${sessionId}/draftContract`, request, {
        noTimeout: true,
        expectedResponse: "blob",
      });
      return URL.createObjectURL(result);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getReceipt = createAsyncThunk(
  "docs/getReceipt",
  async (request, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      const result = await FacadeAPI.GET(`/documents/${sessionId}/receipt`, request, {
        noTimeout: true,
        expectedResponse: "blob",
      });
      return URL.createObjectURL(result);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getAdditionalDocuments = createAsyncThunk(
  "docs/getAdditionalDocuments",
  async (request, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/documents/${sessionId}/additionalDocuments`, request, {
        noTimeout: true,
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getStaticDocumentContent = createAsyncThunk(
  "docs/getStaticDocumentContent",
  async (doc, { rejectWithValue }) => {
    const { docName, docType } = doc;
    try {
      const result = await FacadeAPI.GET(`/documents/documentDetails/${docName}/${docType}`, null, {
        noTimeout: true,
        expectedResponse: "blob",
      });
      return URL.createObjectURL(result);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const updateDocumentsList = (documentsList, document) => {
  let docs = [...documentsList];
  const exisitingDocIndex = documentsList.findIndex(
    (doc) => doc.documentType === document.documentType
  );

  if (exisitingDocIndex === -1) {
    docs.push(document);
    return docs;
  }
  docs.splice(exisitingDocIndex, 1, document);
  return docs;
};

const documentsSlice = createSlice({
  name: "draftContract",
  initialState: INITIAL_STATE,
  reducers: {
    clearGetDraftContractResponse(state) {
      return {
        ...state,
        getDraftContract: INITIAL_STATE.getDraftContract,
      };
    },

    clearGetReceiptResponse(state) {
      return {
        ...state,
        getDraftContract: INITIAL_STATE.getReceipt,
      };
    },

    clearGetAdditionalDocumentsResponse(state) {
      return {
        ...state,
        getAdditionalDocuments: INITIAL_STATE.getAdditionalDocuments,
      };
    },

    clearGetStaticDocumentContentResponse(state) {
      return {
        ...state,
        getStaticDocumentContent: INITIAL_STATE.getStaticDocumentContent,
      };
    },
  },
  extraReducers: {
    [getDraftContract.pending]: (state) => {
      state.getDraftContract.loading = true;
      state.getDraftContract.success = false;
      state.getDraftContract.error = null;
      state.getDraftContract.response = null;
    },
    [getDraftContract.fulfilled]: (state, action) => {
      state.getDraftContract.loading = false;
      state.getDraftContract.success = true;
      state.getDraftContract.response = action.payload;
    },
    [getDraftContract.rejected]: (state, action) => {
      state.getDraftContract.loading = false;
      state.getDraftContract.error = action.payload;
    },

    [getReceipt.pending]: (state) => {
      state.getReceipt.loading = true;
      state.getReceipt.success = false;
      state.getReceipt.error = null;
      state.getReceipt.response = null;
    },
    [getReceipt.fulfilled]: (state, action) => {
      state.getReceipt.loading = false;
      state.getReceipt.success = true;
      state.getReceipt.response = action.payload;
    },
    [getReceipt.rejected]: (state, action) => {
      state.getReceipt.loading = false;
      state.getReceipt.error = action.payload;
    },

    [getAdditionalDocuments.pending]: (state) => {
      state.getAdditionalDocuments.loading = true;
      state.getAdditionalDocuments.success = false;
      state.getAdditionalDocuments.error = null;
      state.getAdditionalDocuments.response = null;
    },
    [getAdditionalDocuments.fulfilled]: (state, action) => {
      state.getAdditionalDocuments.loading = false;
      state.getAdditionalDocuments.success = true;
      state.getAdditionalDocuments.response = action.payload;
    },
    [getAdditionalDocuments.rejected]: (state, action) => {
      state.getAdditionalDocuments.loading = false;
      state.getAdditionalDocuments.error = action.payload;
    },

    [getStaticDocumentContent.pending]: (state, action) => {
      state.getStaticDocumentContent.loading = true;
      state.getStaticDocumentContent.success = false;
      state.getStaticDocumentContent.error = null;
      state.getStaticDocumentContent.response = null;

      state.documents = updateDocumentsList(state.documents, {
        documentType: action.meta.arg.docType,
        documentName: action.meta.arg.docName,
        loading: true,
        success: false,
        error: null,
        content: null,
      });
    },
    [getStaticDocumentContent.fulfilled]: (state, action) => {
      state.getStaticDocumentContent.loading = false;
      state.getStaticDocumentContent.success = true;
      state.getStaticDocumentContent.response = action.payload;

      state.documents = updateDocumentsList(state.documents, {
        documentType: action.meta.arg.docType,
        documentName: action.meta.arg.docName,
        loading: false,
        success: true,
        error: null,
        content: action.payload,
      });
    },
    [getStaticDocumentContent.rejected]: (state, action) => {
      state.getStaticDocumentContent.loading = false;
      state.getStaticDocumentContent.error = action.payload;

      state.documents = updateDocumentsList(state.documents, {
        documentType: action.meta.arg.docType,
        documentName: action.meta.arg.docName,
        loading: false,
        success: false,
        error: action.payload,
        content: null,
      });
    },
  },
});

export const {
  clearGetDraftContractResponse,
  clearGetReceiptResponse,
  clearGetAdditionalDocumentsResponse,
  clearGetStaticDocumentContentResponse,
} = documentsSlice.actions;
export default documentsSlice.reducer;
