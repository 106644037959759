import OSProduct from "./OSProduct";
import MutualExclusiveGroup from "./MutualExclusiveGroup";
import ServiceGroup from "./ServiceGroup";
import { ComponentType } from "../../../common/Constants";
import { Heading3 } from "../../../components/heading";
import { Flex } from "@theme-ui/components";
import { useIntl } from "react-intl";
import React from "react";

const OSProductContent = ({
  productCode,
  isMainPlan = false,
  subProductsGroupsMap = null,
  servicesGroupsMap,
  onSelectService,
  onValidateSelected,
  untouchedMandatoryFields,
  plusTax = true,
  allServicesMap,
}) => {
  const intl = useIntl();
  let productContent = [];
  let productServices = [];

  let regularSubProducts = subProductsGroupsMap && subProductsGroupsMap.get(ComponentType.CHECKBOX);
  regularSubProducts &&
    regularSubProducts.forEach((supProd) => {
      productContent.push(
        <OSProduct
          key={supProd.code}
          product={supProd}
          isMainPlan={false}
          outerContainerAlreadyHasElements={productContent.length > 0}
          onSelectService={onSelectService}
          untouchedMandatoryFields={untouchedMandatoryFields}
          plusTax={plusTax}
        />
      );
    });

  for (let key of Object.values(ComponentType)) {
    let services = servicesGroupsMap.get(key);
    if (key === ComponentType.CHECKBOX) {
      if (services && services.length) {
        productServices.push(
          <ServiceGroup
            key={key}
            services={services}
            parentProductCode={productCode}
            isMainPlan={isMainPlan}
            onSelectService={onSelectService}
            plusTax={plusTax}
          />
        );
      }
    } else {
      let subProductsFromSameGroup = subProductsGroupsMap && subProductsGroupsMap.get(key);
      if (services) {
        productServices.push(
          <MutualExclusiveGroup
            key={"meGroup" + productCode}
            parentProductCode={productCode}
            services={services}
            subProducts={subProductsFromSameGroup}
            outerContainerAlreadyHasElements={productServices.length > 0}
            isMainPlan={isMainPlan}
            onSelectService={onSelectService}
            onValidateSelected={onValidateSelected}
            plusTax={plusTax}
            allServicesMap={allServicesMap}
          />
        );
      } else if (subProductsFromSameGroup) {
        productContent.push(
          <MutualExclusiveGroup
            key={"meGroup" + productCode}
            parentProductCode={productCode}
            services={services}
            subProducts={subProductsFromSameGroup}
            outerContainerAlreadyHasElements={productServices.length > 0}
            isMainPlan={isMainPlan}
            onSelectService={onSelectService}
            onValidateSelected={onValidateSelected}
            plusTax={plusTax}
            allServicesMap={allServicesMap}
          />
        );
      }
    }
  }

  productContent.push(
    (isMainPlan && productServices.length > 0 && (
      <React.Fragment key={productCode}>
        <Flex sx={{ flexDirection: "column", width: "100%" }}>
          <Heading3 color="primary" pb={4}>
            {intl.formatMessage({ id: "lbl.addons" })}
          </Heading3>
          <Flex
            sx={{
              border: 1,
              borderColor: "border",
              borderRadius: "2xl",
              px: "1.5rem",
              py: "1.5rem",
              flexDirection: "column",
              width: "100%",
            }}>
            {productServices}
          </Flex>
        </Flex>
      </React.Fragment>
    )) ||
      productServices
  );

  return productContent;
};

export default OSProductContent;
