import React from "react";
import ContentLoader from "react-content-loader";

const FormInputSkeleton = () => {
  return (
    <ContentLoader
      speed={2}
      height="72"
      style={{ width: "100%" }}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="4" rx="10" ry="10" width="100%" height="24" />
      <rect x="0" y="32" rx="10" ry="10" width="100%" height="44" />
    </ContentLoader>
  );
};

export default FormInputSkeleton;
