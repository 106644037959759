import React, { forwardRef } from "react";
import { Heading } from "@theme-ui/components";

const Heading2 = forwardRef(({ children, ...props }, ref) => {
  return (
    <Heading variant="heading2" ref={ref} {...props}>
      {children}
    </Heading>
  );
});

export default Heading2;
