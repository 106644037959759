import React, { useEffect, useState, useRef } from "react";
import { Flex, Text } from "@theme-ui/components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { NONE_ME_SRV_CODE, ComponentType } from "../../../common/Constants";
import { ErrorMessage, OSSwitch } from "../../../components/base";
import { Heading3 } from "../../../components/heading";
import { selectMEServiceOrSubProduct, selectSubProduct } from "../../../config/redux/slices";
import OSProductContent from "./OSProductContent";
import * as PlanUtils from "./PlanUtils";

const ProductHeader = ({ product }) => {
  const intl = useIntl();
  const productDescription = product.osDescription ? product.osDescription : product.description;
  const productDescDetails = productDescription.split("|");
  const productHeader = productDescDetails[0] ?? "";
  const productDesc = productDescDetails[1] ?? "";
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Heading3 color="primary">
        {product.code === NONE_ME_SRV_CODE ? intl.formatMessage({ id: "lbl.none" }) : productHeader}
        {product.mandatoryExclusiveItem && <Text color="error">*</Text>}
      </Heading3>
      <Text variant={"summaryLabel"}>{productDesc}</Text>
    </Flex>
  );
};

const OSProduct = ({
  product,
  isMainPlan,
  onSelectService,
  untouchedMandatoryFields,
  plusTax = true,
}) => {
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const sectionRef = useRef();

  useEffect(() => {
    setShowError(
      untouchedMandatoryFields?.some((prod) => prod == product.code) &&
        !product.services?.some(
          (serv) =>
            serv.instances[0].selected === true && serv.type === ComponentType.RADIO && serv.visible
        )
    );
    if (untouchedMandatoryFields?.length > 0 && untouchedMandatoryFields[0] === product.code)
      sectionRef.current?.scrollIntoView({ block: "center" });
  }, [untouchedMandatoryFields, product]);

  const onSubProductCheck = (productCode) => {
    dispatch(selectSubProduct(productCode));
  };

  if (!PlanUtils.canDisplayProduct(product)) {
    return null;
  }

  let subProductGroupsMap = PlanUtils.groupSubProducts(
    product.subproducts.filter((subProd) => PlanUtils.canDisplayProduct(subProd))
  );
  let serviceGroupsMap = PlanUtils.groupServices(PlanUtils.getServicesForDisplay(product.services));
  const allProductServicesMap = PlanUtils.groupAllServicesByProduct(product);
  let productHasContent = subProductGroupsMap.size > 0 || serviceGroupsMap.size > 0;

  const toggleSubproductSelection = (subProd) => {
    const services = PlanUtils.getServicesForDisplay(subProd.services);
    if (subProd.selected) {
      services
        .filter((service) => service.instances[0].selected)
        .forEach((service) =>
          onSelectService(service.rcFee, service.setupFee, -service.instances.length)
        );
    } else {
      services
        .filter((service) => service.instances[0].selected)
        .forEach((service) =>
          onSelectService(service.rcFee, service.setupFee, service.instances.length)
        );
    }
  };

  const onServiceOrSubProductUpdate = (parentProductCode, entityCode, isService) => {
    dispatch(
      selectMEServiceOrSubProduct({
        parentProductCode,
        entityCode,
        isService,
      })
    );
  };

  const validateSelected = (product, service) => {
    setShowError(product.mandatoryExclusiveItem && !service.instances[0].selected);
  };

  return (
    <Flex
      pb={!isMainPlan && product.selected && productHasContent ? 7.5 : 0}
      sx={{ flexDirection: "column" }}>
      {
        /* Do not display product name for main plan */
        !isMainPlan && (
          <>
            {product.services.some((srv) => srv.visible) && (
              <Flex sx={{ width: "100%" }} pb={4}>
                <ProductHeader product={product} />
                {!product.assignedByDefault && (
                  <OSSwitch
                    id={"product-" + product.code + "-" + product.selected}
                    name="serviceValue"
                    checked={product.selected}
                    onChange={() => {
                      if (product.selected && serviceGroupsMap.get("RADIO")) {
                        serviceGroupsMap.get("RADIO").forEach((service) => {
                          onServiceOrSubProductUpdate(product.code, service.code, false);
                        });
                      }
                      onSubProductCheck(product.code);
                      toggleSubproductSelection(product);
                    }}
                    ml="auto"
                    mr={6}
                  />
                )}
              </Flex>
            )}
          </>
        )
      }
      {product.selected && isMainPlan && (
        <OSProductContent
          productCode={product.code}
          isMainPlan={isMainPlan}
          subProductsGroupsMap={subProductGroupsMap}
          servicesGroupsMap={serviceGroupsMap}
          onSelectService={onSelectService}
          onValidateSelected={validateSelected}
          untouchedMandatoryFields={untouchedMandatoryFields}
          plusTax={plusTax}
          allServicesMap={allProductServicesMap}
        />
      )}
      {product.selected && !isMainPlan && productHasContent && (
        <>
          <Flex
            ref={sectionRef}
            sx={{
              border: 1,
              borderColor: showError ? "error" : "border",
              borderRadius: "2xl",
              px: 6,
              pt: 6,
              flexDirection: "column",
              width: "100%",
            }}>
            <OSProductContent
              productCode={product.code}
              isMainPlan={isMainPlan}
              subProductsGroupsMap={subProductGroupsMap}
              servicesGroupsMap={serviceGroupsMap}
              onSelectService={onSelectService}
              onValidateSelected={validateSelected}
              untouchedMandatoryFields={untouchedMandatoryFields}
              plusTax={plusTax}
              allServicesMap={allProductServicesMap}
            />
          </Flex>
          {showError && (
            <ErrorMessage>
              <FormattedMessage id="err.mandatory-service-selection" />
            </ErrorMessage>
          )}
        </>
      )}
    </Flex>
  );
};

export default OSProduct;
