import { useLayoutEffect, useRef, useState } from "react";

const useOverFlow = () => {
  const overflow = useRef();
  const [isOverflow, setOverflow] = useState(false);
  useLayoutEffect(() => {
    if (overflow.current && overflow.current.scrollWidth > overflow.current.offsetWidth) {
      setOverflow(true);
    }
  }, []);
  return [isOverflow, overflow];
};
export default useOverFlow;
