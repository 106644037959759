import React from "react";
import { Heading } from "@theme-ui/components";

const Heading3 = ({ children, ...props }) => {
  return (
    <Heading variant="heading3" {...props}>
      {children}
    </Heading>
  );
};

export default Heading3;
