import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Text, Link, Label } from "@theme-ui/components";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getNextStep } from "../../../common/StepsUtilities";
import { FormInput, OSCheckbox, OSContainer, OSLink } from "../../../components/base";
import ErrorHandler from "../../../components/error-handler";
import { CHECKOUT_STEPS } from "../../../components/header/Constants";
import Layout from "../../../components/layout";
import Spinner from "../../../components/spinner/Spinner";
import { POS_PARAMETER } from "../../../common/Constants";
import {
  clearValidateAccountPIN,
  clearRegisterAccountPIN,
  getPosParameter,
  logoutSession,
  validateAccountPIN,
  registerAccountPIN,
  getAccountPinLengths,
} from "../../../config/redux/slices";
import { useForm } from "react-hook-form";
import { StyledModalMessage } from "../../../components/modals";

const PINValidation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  const personalInfoState = useSelector((state) => state.personalInformation);
  const accountPinState = useSelector((state) => state.accountPin);
  const { planFlags } = useSelector((state) => state.items);
  const accountPinLengths = useSelector(
    (state) => state.configuration.getAccountPinLengths.pinLengths
  );
  const { register, errors, handleSubmit, clearErrors } = useForm({ mode: "onTouched" });
  const reactHookFormHandle = { register, errors, clearErrors, handleSubmit };
  const [accountPin, setAccountPin] = useState("");
  const [newPin, setNewPin] = useState("");
  const [consentChecked, setConsentChecked] = useState(false);
  const [registerPinChecked, setRegisterPinChecked] = useState(false);
  const [showOverwritePinError, setShowOverwritePinError] = useState(false);

  const onLogout = () => {
    dispatch(logoutSession());
  };

  const performSubmit = () => {
    if (!registerPinChecked) {
      dispatch(validateAccountPIN({ accountId: personalInfoState.accountId, pin: accountPin }));
    } else {
      registerAccountPin();
    }
  };

  useEffect(() => {
    if (accountPinState.validateAccountPIN.success) {
      history.replace(
        getNextStep(planFlags, CHECKOUT_STEPS.PERSONAL_INFORMATION, personalInfoState.flow).route
      );
      dispatch(clearValidateAccountPIN());
    }
  }, [
    accountPinState.validateAccountPIN.success,
    dispatch,
    history,
    personalInfoState.flow,
    planFlags,
  ]);

  useEffect(() => {
    if (accountPinState.registerAccountPIN.success) {
      history.replace(
        getNextStep(planFlags, CHECKOUT_STEPS.PERSONAL_INFORMATION, personalInfoState.flow).route
      );
      dispatch(clearRegisterAccountPIN());
    }
  }, [
    accountPinState.registerAccountPIN.success,
    dispatch,
    history,
    personalInfoState.flow,
    planFlags,
  ]);

  useEffect(() => {
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
    dispatch(getAccountPinLengths());
  }, [dispatch]);

  const registerAccountPin = () => {
    if (personalInfoState.pinPresent) {
      setShowOverwritePinError(true);
    } else {
      dispatch(registerAccountPIN({ accountId: personalInfoState.accountId, pin: newPin }));
    }
  };

  const handleRegisterPinOption = () => {
    clearErrors("accountPin");
    setRegisterPinChecked(!registerPinChecked);
  };

  return (
    <Layout
      checkoutStep={CHECKOUT_STEPS.PERSONAL_INFORMATION.index}
      pageTitle={intl.formatMessage({ id: "lbl.validate-account-pin" })}>
      <ErrorHandler
        error={accountPinState.validateAccountPIN.error}
        closeHandler={() => dispatch(clearValidateAccountPIN())}
        clickHandler={() => dispatch(clearValidateAccountPIN())}
      />
      <ErrorHandler
        error={accountPinState.registerAccountPIN.error}
        closeHandler={() => dispatch(clearRegisterAccountPIN())}
        clickHandler={() => dispatch(clearRegisterAccountPIN())}
      />
      <StyledModalMessage
        isOpen={showOverwritePinError}
        onRequestClose={() => setShowOverwritePinError(false)}
        message={
          <FormattedMessage
            id="err.overwrite-account-pin"
            values={{
              a: (chunks) => (
                <Link
                  variant="chat-link"
                  href={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
                  target="_blank">
                  {chunks}
                </Link>
              ),
            }}
          />
        }>
        <Button variant="simple-action" onClick={() => setShowOverwritePinError(false)}>
          <FormattedMessage id="btn.ok" />
        </Button>
      </StyledModalMessage>

      <Spinner
        isOpen={
          personalInfoState.loadPersonalInformation.loading ||
          accountPinState.validateAccountPIN.loading ||
          accountPinState.registerAccountPIN.loading
        }
      />

      <OSContainer variant="page-content">
        <Flex
          sx={{
            flexDirection: "column",
            alignItems: "center",
            px: 8,
          }}>
          <Box mb={4}>
            <Text sx={{ fontWeight: "bold" }}>
              {intl.formatMessage(
                { id: "lbl.pin-validation-section-label" },
                { pinLength: accountPinLengths.minLength }
              )}
            </Text>
          </Box>
          <Box mb={4}>
            <form id="pinValidationForm" onSubmit={handleSubmit(performSubmit)}>
              <Flex
                sx={{
                  flexDirection: "column",
                }}>
                <FormInput
                  key={"accountPin" + registerPinChecked}
                  {...reactHookFormHandle}
                  label={intl.formatMessage({ id: "lbl.pin" })}
                  isMandatory={!registerPinChecked}
                  disabled={registerPinChecked}
                  name="accountPin"
                  type="password"
                  onChange={(e) => {
                    setAccountPin(e.target.value.trimStart());
                  }}
                />
                <Label mt={2} sx={{ alignItems: "center" }}>
                  <OSCheckbox
                    mr={2}
                    checked={registerPinChecked}
                    onChange={handleRegisterPinOption}
                  />
                  <Text>{intl.formatMessage({ id: "lbl.do-not-have-pin" })}</Text>
                </Label>
              </Flex>
              {registerPinChecked && (
                <Flex
                  mt={4}
                  sx={{
                    flexDirection: "column",
                  }}>
                  <FormInput
                    {...reactHookFormHandle}
                    label={intl.formatMessage({ id: "lbl.create-pin" })}
                    maxLength={accountPinLengths.maxLength}
                    isMandatory
                    name="newPin"
                    type="password"
                    onChange={(event) => setNewPin(event.target.value)}
                    validations={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: intl.formatMessage(
                          { id: "err.pin-digits" },
                          { pinLength: accountPinLengths.minLength }
                        ),
                      },
                      validate: {
                        pinLength: (value) => {
                          return (
                            (value.length < accountPinLengths.minLength &&
                              intl.formatMessage(
                                { id: "err.pin-digits" },
                                { pinLength: accountPinLengths.minLength }
                              )) ||
                            true
                          );
                        },
                      },
                    }}
                  />
                  <FormInput
                    {...reactHookFormHandle}
                    label={intl.formatMessage({ id: "lbl.confirmation-pin" })}
                    maxLength={accountPinLengths.maxLength}
                    isMandatory
                    name="confirmNewPin"
                    type="password"
                    validations={{
                      validate: {
                        confirmPin: (value) => {
                          return (
                            (value !== newPin &&
                              intl.formatMessage({ id: "err.pin-confirm-pin" })) ||
                            true
                          );
                        },
                      },
                    }}
                  />
                </Flex>
              )}
            </form>
          </Box>

          {!registerPinChecked && (
            <Box mb={4}>
              <Text>
                {intl.formatMessage({ id: "lbl.pin-validation-contact-us-message" })}
                <OSLink
                  url={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
                  labelId="lbl.contactUs"
                  variant="chat-link"
                />
                .
              </Text>
            </Box>
          )}

          <Flex mb={8}>
            <Label>
              <OSCheckbox
                mr={[7, 4]}
                checked={consentChecked}
                onChange={() => setConsentChecked(!consentChecked)}
              />
              <Text sx={{ textAlign: "justify" }}>
                {intl.formatMessage({ id: "lbl.pin-validation-consent-message" })}
              </Text>
            </Label>
          </Flex>
          <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
            <Button variant="simple-action" onClick={onLogout}>
              <FormattedMessage id="btn.cancel" />
            </Button>
            <Button
              variant="default-next"
              type="submit"
              form="pinValidationForm"
              disabled={!consentChecked}>
              <FormattedMessage id="btn.pin-validation" />
            </Button>
          </Flex>
        </Flex>
      </OSContainer>
    </Layout>
  );
};

export default PINValidation;
