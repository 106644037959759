import React from "react";
import { Box } from "@theme-ui/components";

const LocationPin = ({ color = "#48c0b6", size = "iconSmall", ...props }) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.527 20.827"
    sx={{ width: size, height: size, stroke: color, flexShrink: 0 }}
    {...props}>
    <g transform="translate(0.514 0.5)">
      <g transform="translate(0 0)">
        <path
          d="M6.707,6.83A6.345,6.345,0,0,0,.37,13.167c0,4.019,5.648,9.713,5.888,9.954a.634.634,0,0,0,.9,0c.241-.241,5.89-5.935,5.89-9.954A6.345,6.345,0,0,0,6.707,6.83"
          transform="translate(2.043 -6.83)"
          fill="none"
          stroke={color}
          strokeWidth="1"
        />
        <path
          d="M6.707,15.385a2.218,2.218,0,1,1,2.218-2.218,2.218,2.218,0,0,1-2.218,2.218"
          transform="translate(2.043 -6.83)"
          fill="none"
          stroke={color}
          strokeWidth="1"
          opacity="0.4"
        />
        <path
          d="M14.951.5h1.011a.959.959,0,0,1,.819.493l2.053,3.465a1.117,1.117,0,0,1-.819,1.72H2.485a1.117,1.117,0,0,1-.819-1.72L3.719.993A.959.959,0,0,1,4.538.5H5.549"
          transform="translate(-1.5 13.648)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="1"
          opacity="0.4"
        />
      </g>
    </g>
  </Box>
);

export default LocationPin;
