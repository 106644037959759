import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Button } from "@theme-ui/components";
import Store from "store2";

import Layout from "../../components/layout";
import KeepPhoneNumber from "./steps/KeepPhoneNumber";
import PickPhoneNumber from "./steps/PickPhoneNumber";
import PageHeader from "./PageHeader";
import { OSContainer } from "../../components/base";
import {
  getItems,
  setPhoneNumberBundleInitialState,
  getPhoneNumberObj,
  clearApiResponses,
  clearValidatePortInCheckApiResponse,
  clearSavePhoneNumberApiResponse,
  updateCurrentBundle911PriceOffers,
  getBundlePriceOffers,
} from "../../config/redux/slices";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import { getNextStep } from "../../common/StepsUtilities";
import { ITEM_CATEGORY, PRICE_TYPE } from "../../common/Constants";
import MultipleTabHeader from "../../components/tab/MultipleTabHeader";
import { FLOW } from "./Constants";
import ErrorHandler from "../../components/error-handler";

const PhoneNumber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const phoneNumberState = useSelector((state) => state.phoneNumber);
  const itemPriceState = useSelector((state) => state.itemPrice);
  const { planFlags, ...itemsState } = useSelector((state) => state.items);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [numberOfBundles, setNumberOfBundles] = useState(0);
  const [linesTitles, setLinesTitles] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [phoneNumberBundle, setPhoneNumberBundle] = useState(null);
  const [flow, setFlow] = useState(FLOW.NONE);
  const typeRef = useRef();
  const personalInformationData = useSelector((state) => state.personalInformation);

  // 0. Load Phone number state if exists in db
  useEffect(() => {
    if (!phoneNumberState[0] && !phoneNumberState.getPhoneNumberObj.success) {
      dispatch(getPhoneNumberObj());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberState, phoneNumberState.getPhoneNumberObj.success]);

  // 1. Load session items.
  useEffect(() => {
    const loadInitialData = async () => {
      if (
        itemsState.getItems.success &&
        itemsState.getItems.items &&
        itemsState.getItems.items.length > 0
      )
        return;
      dispatch(getItems());
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 2. If session items were successfully loaded, set data.
  useEffect(() => {
    if (!phoneNumberState[0] && !phoneNumberState.getPhoneNumberObj.success) {
      return;
    }
    if (itemsState.getItems.success) {
      clearValidatePortInCheckApiResponse();
      const bundles = itemsState.getItems.items.filter(
        (item) => item.category === ITEM_CATEGORY.BUNDLE
      );

      setNumberOfBundles(bundles.length);
      setCurrentItem(bundles[activeTabIndex]);
      setLinesTitles(
        bundles.map((item) => {
          const firstName = `${item.contactFirstName}`.trim();
          const lastName = `${item.contactLastName}`.trim();
          return { firstName: firstName, lastName: lastName };
        })
      );

      const bundle = bundles[activeTabIndex];
      if (bundle) {
        Store.session.transact(
          "kwExtraData",
          (obj) => {
            obj.itemId = bundles[activeTabIndex].itemId;
          },
          {}
        );

        if (!phoneNumberState[activeTabIndex]) {
          dispatch(setPhoneNumberBundleInitialState(activeTabIndex));
          setFlow(FLOW.NONE);
          setPhoneNumberBundle(null);
        } else {
          setFlow(phoneNumberState[activeTabIndex].flow);
          setPhoneNumberBundle(phoneNumberState[activeTabIndex]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberState.getPhoneNumberObj.success, itemsState.getItems.success, activeTabIndex]);

  const onNext = () => {
    typeRef.current.onSavePhoneNumber();
  };

  useEffect(() => {
    if (currentItem) {
      dispatch(getBundlePriceOffers({ itemId: currentItem.id }));
    }
  }, [dispatch, currentItem]);

  useEffect(() => {
    if (currentItem && phoneNumberState.savePhoneNumber.success && itemsState.getItems.success) {
      dispatch(clearApiResponses());
      dispatch(
        updateCurrentBundle911PriceOffers(
          itemPriceState.bundlePriceOffers,
          currentItem.id,
          itemsState.getItems.items
            .find((item) => item.id === currentItem.id)
            ?.prices.find((price) => price.type === PRICE_TYPE.PLAN_E911)
        )
      );

      if (activeTabIndex === numberOfBundles - 1) {
        Store.session.transact(
          "kwExtraData",
          (obj) => {
            delete obj.itemId;
          },
          {}
        );
        history.push(
          getNextStep(planFlags, CHECKOUT_STEPS.PHONE_NUMBER, personalInformationData.flow).route
        );
      } else {
        setActiveTabIndex(activeTabIndex + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem, phoneNumberState.savePhoneNumber.success, itemsState.getItems.success]);

  const onBack = (isBack) => {
    if (isBack) {
      setActiveTabIndex(activeTabIndex - 1);
    }
  };

  const onChangeFlowType = (selectedFlow) => {
    dispatch(setPhoneNumberBundleInitialState(activeTabIndex));
    setPhoneNumberBundle(null);
    setFlow(selectedFlow);
  };

  return (
    <Layout
      showCart={true}
      checkoutStep={CHECKOUT_STEPS.PHONE_NUMBER.index}
      pageTitle={intl.formatMessage({ id: CHECKOUT_STEPS.PHONE_NUMBER.label })}
      isMultipleTabHeaderPage={true}
      isInitialTab={activeTabIndex === 0}
      parentCallback={onBack}
      nextStepButton={() => (
        <Button
          id="submitPhoneNo"
          variant="default-next"
          disabled={(phoneNumberBundle && !phoneNumberBundle.stepCompleted) || !phoneNumberBundle}
          onClick={onNext}>
          {intl.formatMessage({
            id:
              activeTabIndex === numberOfBundles - 1
                ? getNextStep(planFlags, CHECKOUT_STEPS.PHONE_NUMBER, personalInformationData.flow)
                    .srcButtonLabel
                : "btn.next",
          })}
        </Button>
      )}>
      <OSContainer variant="page-content" sx={{ flexDirection: "column" }} pt="0 !important">
        {numberOfBundles > 0 && (
          <MultipleTabHeader
            tabsSize={numberOfBundles}
            activeTabIndex={activeTabIndex}
            editModeAvailable={false}
            currentItem={currentItem}
            linesTitles={linesTitles}
          />
        )}
        {currentItem && phoneNumberState[activeTabIndex] && (
          <>
            <PageHeader
              key={flow + activeTabIndex}
              selectedFlow={flow}
              onChangeFlow={onChangeFlowType}
            />

            {flow === FLOW.PICK && (
              <PickPhoneNumber
                ref={typeRef}
                key={"pick_" + activeTabIndex}
                activeTabIndex={activeTabIndex}
                itemId={currentItem.id}
                setPhoneNumberBundle={setPhoneNumberBundle}
              />
            )}

            {flow === FLOW.KEEP && (
              <KeepPhoneNumber
                ref={typeRef}
                key={"keep_" + activeTabIndex}
                activeTabIndex={activeTabIndex}
                itemId={currentItem.id}
                setPhoneNumberBundle={setPhoneNumberBundle}
              />
            )}
            <ErrorHandler
              error={phoneNumberState.savePhoneNumber.error}
              closeHandler={() => dispatch(clearSavePhoneNumberApiResponse())}
              clickHandler={() => dispatch(clearSavePhoneNumberApiResponse())}
            />
          </>
        )}
      </OSContainer>
    </Layout>
  );
};

export default PhoneNumber;
