import React, { useEffect, useState } from "react";
import { Flex, Box, Image, Text, Spinner } from "@theme-ui/components";
import { FacadeMockup } from "../RestMockup";
import { useDispatch, useSelector } from "react-redux";
import { getItems as getDevices } from "../../../config/redux/slices";

var accessories = [
  {
    sku: 1507742,
    category: "ACCESSORY",
    media:
      "https://www.pure-gear.com/media/catalog/product/cache/a68cd95282f629852e6b9e296b7a5618/6/3/63636pg_iphone13promax-hdtg_00.jpg",
    shortDescription: "Ultra HD Glass",
    skuDescription: "Pure Gear Ultra HD Glass w Tray Iphone 12/Pro",
    prices: [
      {
        price: 40,
        type: "ONE_TIME",
      },
    ],
  },
  {
    sku: 1202331,
    category: "ACCESSORY",
    media:
      "http://devmagento.mindcti.com/pub/media/catalog/product/s/m/smart_led_view_cover_1_1.jpg",
    shortDescription: "Otterbox Symmetry Case",
    skuDescription: "Otterbox Symmetry Risk Tiger IPhone 11.",
    prices: [
      {
        price: 70,
        type: "ONE_TIME",
      },
    ],
  },
  {
    sku: 1507742,
    category: "ACCESSORY",
    media: "http://devmagento.mindcti.com/pub/media/catalog/product/g/a/galaxy_buds_black_1_1.png",
    shortDescription: "Jlab JBuds Pro Earbuds Black",
    skuDescription:
      "Jlab JBuds Pro Earbuds Black. See all the features, specs, ... Device information provided by the manufacturer.",
    prices: [
      {
        price: 10,
        type: "ONE_TIME",
      },
    ],
  },
  {
    sku: 1011307,
    category: "ACCESSORY",
    media:
      "https://cdn.shopify.com/s/files/1/0553/5232/8357/products/62786pg_24wwallcharger_00_1200x1200.jpg?v=1616773393",
    shortDescription: "PG Dual Wall Chrgr I USB 4.8",
    skuDescription:
      "PG Dual Wall Chrgr I USB 4.8. See all the features, specs, ... Device information provided by the manufacturer.",
    prices: [
      {
        price: 15,
        type: "ONE_TIME",
      },
    ],
  },
];

const AccessoryMockup = () => {
  const { getItems } = useSelector((state) => state.items);

  const [cartId, setCartId] = useState(null);
  const [deviceId, setDeviceId] = useState(null);
  const dispatch = useDispatch();
  const [currentDevice, setCurrentDevice] = useState(null);

  const changeDevice = async (index) => {
    const newItem = {
      ...currentDevice,
      ...accessories[index],
      //   sku: currentDevice.sku === "TESTDEVICE1" ? "IP11BK64G" : "TESTDEVICE1",
    };

    await FacadeMockup.POST("/checkout", {
      cartId: cartId,
      items: [newItem],
    });

    window.top.postMessage(newItem, "*");
  };

  useEffect(() => {
    const parseParams = () => {
      const temp = window.location.search.split("&");

      setCartId(temp[0].split("=")[1]);
      setDeviceId(temp[1].split("=")[1]);
    };

    if (window.location.search) parseParams();
  }, []);

  useEffect(() => {
    if (getItems.items.length === 0 && !getItems.loading) {
      dispatch(getDevices());
    }

    if (deviceId) {
      const found = getItems.items.find((item) => item.itemId == deviceId);
      setCurrentDevice(found);
    }
  }, [deviceId, dispatch, getItems]);

  if (!currentDevice) {
    return (
      <Flex
        sx={{
          width: "100%",
          height: "20rem",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}>
        <Spinner />
      </Flex>
    );
  }
  return (
    <Flex
      px="10rem"
      bg="WhiteSmoke"
      sx={{
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column",
        borderRadius: "10px",
      }}>
      <Flex my="4rem" sx={{ width: "100%", alignItems: "center" }}>
        <Text ml="auto" mr="auto" sx={{ fontSize: "4xl", fontWeight: "medium" }}>
          Choose a different accessory
        </Text>
      </Flex>

      <Flex sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2rem" }}>
          {accessories.map((accessory, index) => (
            <Flex
              onClick={() => changeDevice(index)}
              bg="white"
              key={index}
              sx={{
                border: "1px solid",
                borderColor: "border",
                borderRadius: "0.5rem",
                width: "17rem",
                height: "22rem",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
                cursor: "pointer",
                ":hover": {
                  borderColor: "cardBorderHoverColor",
                },
              }}>
              <Flex
                key={index}
                sx={{
                  width: "11rem",
                  height: "15rem",
                  justifyContent: "center",
                }}>
                <Image
                  src={accessory.media}
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Flex>
              <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
                <Text sx={{ fontSize: "sm" }}>{accessory.shortDescription}</Text>
                <Text mt="0.5rem" sx={{ fontWeight: "semibold", fontSize: "xl" }}>
                  ${accessory.prices[0].price.toFixed(2)}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
    </Flex>
  );
};

export default AccessoryMockup;
