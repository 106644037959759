import React from "react";
import { useIntl } from "react-intl";
import { Flex, Text } from "@theme-ui/components";
import { PRICE_TERMS_UNIT, PRICE_TYPE } from "../../../common/Constants";
import Amount from "../../amount";
import { Heading6 } from "../../heading";
import { getNoOfDeferredMonths } from "../../plan-price-offer/PlanPriceOffer";
/**
 * @typedef {Object} ItemAttribute
 * @property {Number} id                          - Item attribute id.
 * @property {String} code                        - Item attribute code.
 * @property {String} value                       - Item attribute value.
 */
/**
 * @typedef {Object} ItemPrice
 * @property {Number} price                        - The price.
 * @property {String} type                         - Price type.
 * @property {Number} termsAmount                  - Price terms - amount.
 * @property {String} termsUnit                    - Price terms - unit.
 */
/**
 * @typedef {Object} SessionItem
 * @property {Number} id                          - Item's id.
 * @property {String} sku                         - Item's sku.
 * @property {String} category                    - Item's category.
 * @property {String} thumbnailURL                - Item's thumbnail URL.
 * @property {String} shortDescription            - Item's short description.
 * @property {ItemPrice[]} prices                 - List of item's prices.
 * @property {ItemAttribute[]} attributes         - List of item's attributes.
 */
/**
 * Renders a plan item.
 *
 * @param {Object} props Component props.
 * @param {SessionItem} props.item Cart item.
 */
const Plan = ({ item, plusTax, showPrices }) => {
  const intl = useIntl();

  const recurringPrice = item.prices.find((price) => price.type === PRICE_TYPE.PLAN_RECURRING);
  const oneTimePrice = item.prices.find((price) => price.type === PRICE_TYPE.PLAN_ONE_TIME);
  const recurringAdditionalPrice = item.prices.find(
    (price) => price.type === PRICE_TYPE.PLAN_ADDITIONAL_RECURRING
  );
  const oneTimeAdditionalPrice = item.prices.find(
    (price) => price.type === PRICE_TYPE.PLAN_ADDITIONAL_ONE_TIME
  );
  const connectionPrice = item.prices.find(
    (price) => price.type === PRICE_TYPE.PLAN_CONNECTION_FEE
  );
  const e911Price = item.prices.find((price) => price.type === PRICE_TYPE.PLAN_E911);
  const planServiceDiscountPrices = item.prices
    .filter(
      (price) =>
        (price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
          price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING) &&
        price.price !== 0
    )
    .filter((price) => getNoOfDeferredMonths(price) == 0);

  const showRecurringPrice = recurringPrice && recurringPrice.termsUnit === PRICE_TERMS_UNIT.MONTHS;
  const showRecurringAdditionalPrice =
    recurringAdditionalPrice && recurringAdditionalPrice.price > 0;
  const showOneTimeAdditionalPrice = oneTimeAdditionalPrice && oneTimeAdditionalPrice.price > 0;
  const showConnectionPrice = connectionPrice && connectionPrice.price > 0;
  const showe911Price = e911Price && e911Price.price > 0;
  const displayPlanServiceDiscountPrices =
    planServiceDiscountPrices && planServiceDiscountPrices.length > 0;
  const showTax =
    (showe911Price ||
      showConnectionPrice ||
      displayPlanServiceDiscountPrices ||
      showRecurringAdditionalPrice ||
      showOneTimeAdditionalPrice) &&
    (plusTax ||
      e911Price?.tax > 0 ||
      connectionPrice?.tax > 0 ||
      recurringAdditionalPrice?.tax > 0 ||
      oneTimeAdditionalPrice?.tax > 0 ||
      planServiceDiscountPrices?.find((price) => price.tax !== 0) !== undefined);

  const DescriptionRow = ({ text, ...props }) => {
    return (
      <Flex {...props} sx={{ height: "2.5rem", alignItems: "center" }}>
        <Text py={0} color="ternary" variant="summaryLabel">
          {text}
        </Text>
      </Flex>
    );
  };

  const AmountRow = ({ ...props }) => {
    return (
      <Flex sx={{ height: "2.5rem", alignItems: "center" }}>
        <Amount type="normal_large" {...props} />
      </Flex>
    );
  };

  return (
    (item.planCode !== null || item.packageCode !== null) && (
      <Flex sx={{ flexDirection: "column" }} mt={4}>
        <Flex mb={2}>
          <Flex mt={2} sx={{ flexDirection: "column", flex: 1 }}>
            <Heading6 color="ternary">{item.planShortDescription}</Heading6>
          </Flex>

          {showPrices && (
            <Flex sx={{ flexDirection: "column", justifyContent: "center" }} ml={2} mr={[8, 8, 0]}>
              {showRecurringPrice && (
                <Amount
                  type="semibold_large"
                  amount={recurringPrice.price}
                  description={
                    recurringPrice.termsAmount > 0 &&
                    intl.formatMessage(
                      { id: "lbl.for-x-months" },
                      { noOfMonths: recurringPrice.termsAmount }
                    )
                  }
                  isMonthly
                />
              )}

              {oneTimePrice && oneTimePrice.price > 0 && (
                <Amount
                  type="semibold_large"
                  amount={oneTimePrice.price}
                  description={intl.formatMessage({ id: "lbl.one-time" })}
                  mt={showRecurringPrice && 4}
                />
              )}
              {plusTax && (
                <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
              )}
            </Flex>
          )}
        </Flex>

        <Flex mb={2} ml={4}>
          <Flex mt={2} sx={{ flexDirection: "column", flex: 1 }}>
            {showRecurringAdditionalPrice && (
              <DescriptionRow text={intl.formatMessage({ id: "lbl.additional-services" })} />
            )}
            {showOneTimeAdditionalPrice && (
              <DescriptionRow text={intl.formatMessage({ id: "lbl.additional-services" })} />
            )}
            {showe911Price && <DescriptionRow text={e911Price.description} />}
            {showConnectionPrice && <DescriptionRow text={connectionPrice.description} />}

            {displayPlanServiceDiscountPrices &&
              planServiceDiscountPrices.map((price, index) => (
                <DescriptionRow key={index} text={price.description} />
              ))}
          </Flex>

          {showPrices && (
            <Flex sx={{ flexDirection: "column" }} mt={2} ml={2} mr={[8, 8, 0]}>
              {showRecurringAdditionalPrice && (
                <AmountRow amount={recurringAdditionalPrice.price} isMonthly />
              )}
              {showOneTimeAdditionalPrice && (
                <AmountRow
                  amount={oneTimeAdditionalPrice.price}
                  description={intl.formatMessage({ id: "lbl.one-time" })}
                />
              )}
              {showe911Price && <AmountRow amount={e911Price.price} isMonthly />}
              {showConnectionPrice && (
                <AmountRow
                  amount={connectionPrice.price}
                  description={intl.formatMessage({ id: "lbl.one-time" })}
                />
              )}

              {displayPlanServiceDiscountPrices &&
                planServiceDiscountPrices.map((price, index) => (
                  <AmountRow
                    key={index}
                    amount={price.price}
                    isMonthly={price.termsUnit === PRICE_TERMS_UNIT.MONTHS}
                    description={
                      price.termsUnit === PRICE_TERMS_UNIT.NONE
                        ? intl.formatMessage({ id: "lbl.one-time" })
                        : null
                    }
                  />
                ))}

              {showTax && (
                <Text variant="taxDescription">{intl.formatMessage({ id: "lbl.plus-tax" })}</Text>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  );
};

export default Plan;
