import React, { forwardRef } from "react";
import { Switch, Flex } from "@theme-ui/components";

const OSSwitch = forwardRef(
  (
    { checked, onChange, smaller, disabled, variant = "switch", id, className, name, ...props },
    ref
  ) => {
    return (
      <Flex sx={{ justifyContent: "flex-end" }} id={id} {...props}>
        <Switch
          className={className}
          id={id}
          ref={ref}
          name={name}
          variant={variant}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          sx={{
            transform: !smaller && [0, "scale(1.375,1.375)", "scale(1.375,1.375)"],
          }}
        />
      </Flex>
    );
  }
);

export default OSSwitch;
