import React, { useState } from "react";
import { Box, Flex, Text } from "@theme-ui/components";

const NumberSpinner = ({
  defaultValue,
  minValue = 0,
  maxValue = 99,
  onNumberChange,
  disabled,
  className,
  id,
  ...props
}) => {
  const [state, setState] = useState({
    number: defaultValue,
    minValue: minValue <= -1 ? 0 : minValue,
    maxValue: maxValue === -1 ? 99 : maxValue,
  });

  const doNumberChange = (number) => {
    if (onNumberChange) onNumberChange(number);
  };

  const increase = () => {
    if (disabled) return;

    if (state.maxValue > state.number) {
      let number = state.number + 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  const decrease = () => {
    if (disabled) return;

    if (state.minValue < state.number) {
      let number = state.number - 1;
      setState({ ...state, number: number });

      doNumberChange(number);
    }
  };

  return (
    <Flex
      {...props}
      sx={{
        backgroundColor: "secondary",
        borderRadius: "full",
        width: "4.5rem",
        height: "1.75rem",
      }}>
      <Box
        className={className}
        id={id}
        sx={{
          flex: 1,
          alignItems: "flex-start",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => decrease()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.1875rem",
            color: "#F8F8F8",
            fontSize: "1.75rem",
            lineHeight: "solid",
            fontWeight: "bold",
            userSelect: "none",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          -
        </Text>
      </Box>

      <Box
        sx={{
          marginY: "0.125rem",
          backgroundColor: "#F8F8F8",
          borderRadius: "2lg",
          alignItems: "center",
          justifyContent: "center",
          height: "1.5rem",
          width: "1.25rem",
          textAlign: "center",
        }}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "0.125rem",
            color: "secondary",
            fontSize: "note",
            userSelect: "none",
            lineHeight: "solid",
            fontWeight: "700",
          }}>
          {state.number}
        </Text>
      </Box>

      <Box
        className={className}
        id={id}
        sx={{
          flex: 1,
          alignItems: "flex-end",
          justifyContent: "center",
          textAlign: "center",
        }}
        onClick={() => increase()}>
        <Text
          as="span"
          sx={{
            position: "relative",
            top: "-0.1875rem",
            color: "#F8F8F8",
            fontSize: "1.75rem",
            lineHeight: "solid",
            userSelect: "none",
            fontWeight: "bold",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}>
          +
        </Text>
      </Box>
    </Flex>
  );
};
export default NumberSpinner;
