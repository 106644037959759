import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import Storage from "store2";
import { Button, Heading, Flex, Text, Box, Image, Link } from "@theme-ui/components";
import _ from "lodash";
import TagManager from "gtm-for-react";

import Layout from "../../components/layout";
import { OSContainer, OSLink } from "../../components/base";
import { Heading1, Heading3 } from "../../components/heading";
import { OSIcon } from "../../components/icons";
import { CHECKOUT_STEPS } from "../../components/header/Constants";
import {
  clearGetDraftContractResponse,
  clearGetReceiptResponse,
  getDraftContract,
  getReceipt,
  getPosParameter,
  clearGetAdditionalDocumentsResponse,
  getAdditionalDocuments,
  getStaticDocumentContent,
  doLogout,
} from "../../config/redux/slices";
import { StyledModalMessage } from "../../components/modals";
import GetErrorDescription from "../../components/get-error-description";
import { ITEM_CATEGORY, POS_PARAMETER, PRICE_TERMS_UNIT, PRICE_TYPE } from "../../common/Constants";
import el_app_logo from "../../resources/images/logos/eastlink_app_logo.svg";
import app_store_logo from "../../resources/images/logos/app_store.svg";
import play_store_logo from "../../resources/images/logos/play_store.svg";
import { BaseSkeleton, DocumentSkeleton } from "../../components/skeletons";
import { storefrontURLs } from "../../config/security/Deployment";
import { getNoOfDeferredMonths } from "../../components/plan-price-offer/PlanPriceOffer";

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const emailAddress = useSelector((state) => state.personalInformation.emailAddress);
  const documentsState = useSelector((state) => state.documents);
  const orderNumber = useSelector((state) => state.session.orderNumber);
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);
  const brandingData = useSelector((state) => state.branding.data);
  const [urls, setUrls] = useState([]);
  const documentsList = useSelector((state) => {
    let documents = [];
    documents.push({
      label: "lbl.receipt",
      icon: "receipt",
      documentState: state.documents.getReceipt,
    });
    documents.push({
      label: "lbl.service-agreements",
      icon: "document",
      documentState: state.documents.getDraftContract,
    });
    return documents;
  });

  const staticDocumentsList = useSelector((state) => {
    let staticDocuments = [];
    if (state.documents.getAdditionalDocuments.response) {
      for (var i = 0; i < state.documents.getAdditionalDocuments.response.length; i++) {
        staticDocuments.push({
          label: state.documents.getAdditionalDocuments.response[i].repositoryDocName,
          docName: state.documents.getAdditionalDocuments.response[i].name,
          docType: state.documents.getAdditionalDocuments.response[i].documentType,
          icon: "document",
          documentState: state.documents.getAdditionalDocuments,
        });
      }
    }
    return staticDocuments;
  });

  const externalCartId = useSelector((state) => state.session.externalCartId);
  const itemsState = useSelector((state) => state.items.getItems.items);

  //compute analytics data
  const transactionObj = useMemo(() => {
    let totalPrice = 0;
    let totalTax = 0;
    let shippingPrice = 0;
    let products = [];

    itemsState.map((item) => {
      let deviceMonthlyPrice = 0;
      let planMonthlyPrice = 0;
      let deviceName = item.shortDescription;

      if (item.category === ITEM_CATEGORY.BUNDLE) {
        //device upfront
        item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => {
            totalPrice += priceObj.price;
            totalTax += priceObj.tax;
          });

        //device easyTab monthly
        item.prices
          .filter(
            (price) =>
              PRICE_TYPE.RECURRING === price.type && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .map((priceObj) => {
            deviceMonthlyPrice += priceObj.price;
            totalPrice += priceObj.price * 24;
            totalTax += priceObj.tax * 24;
          });

        //oldEasyTabPrice
        item.prices
          .filter((price) => PRICE_TYPE.OLD_INSTALLMENT === price.type)
          .map((priceObj) => {
            totalPrice += priceObj.price;
            totalTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_RECURRING,
                PRICE_TYPE.PLAN_ADDITIONAL_RECURRING,
                PRICE_TYPE.PLAN_E911,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.MONTHS
          )
          .map((priceObj) => {
            planMonthlyPrice += priceObj.price;
            totalPrice += priceObj.price * 24;
            totalTax += priceObj.tax * 24;
          });

        item.prices
          .filter(
            (price) =>
              [
                PRICE_TYPE.PLAN_ONE_TIME,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT,
                PRICE_TYPE.PLAN_SERVICE_DISCOUNT_EXISTING,
              ].includes(price.type) && price.termsUnit === PRICE_TERMS_UNIT.NONE
          )
          .map((priceObj) => {
            totalPrice += priceObj.price;
            totalTax += priceObj.tax;
          });

        item.prices
          .filter(
            (price) =>
              PRICE_TYPE.PLAN_SERVICE_IVD === price.type ||
              PRICE_TYPE.PLAN_SERVICE_IVD_EXISTING === price.type
          )
          .filter((price) => getNoOfDeferredMonths(price) == 0)
          .map((priceObj) => {
            deviceName = `${deviceName} with ${priceObj.description}`;
          });

        products.push({
          item_id: item.sku ? item.sku : "BYOD",
          item_name: deviceName,
          item_category: "Phone",
          price: deviceMonthlyPrice,
          quantity: 1,
        });
        products.push({
          item_id: item.planCode ? item.planCode : item.packageCode,
          item_name: item.planShortDescription,
          item_category: "Plan",
          price: planMonthlyPrice,
          quantity: 1,
        });
      }
      if (item.category === ITEM_CATEGORY.SHIPPING) {
        item.prices
          .filter((price) => PRICE_TYPE.ONE_TIME === price.type)
          .map((priceObj) => {
            totalPrice += priceObj.price;
            totalTax += priceObj.tax;
            shippingPrice += priceObj.price + priceObj.tax;
          });
      }
    });
    return {
      event: "purchase",
      ecommerce: {
        transaction_id: externalCartId,
        value: _.round(totalPrice, 2),
        tax: _.round(totalTax, 2),
        shipping: _.round(shippingPrice, 2),
        currency: "CAD",
        items: products,
      },
    };
  }, [itemsState, externalCartId]);

  const [staticDocumentContent, setStaticDocumentContent] = useState("");

  useEffect(() => {
    console.log("Analytics data: " + JSON.stringify(transactionObj));
    TagManager.dataLayer({ dataLayer: { ecommerce: null } });
    TagManager.dataLayer({ dataLayer: transactionObj });

    dispatch(getReceipt());
    dispatch(getDraftContract());
    dispatch(getAdditionalDocuments());
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
    dispatch(doLogout());
  }, [dispatch, transactionObj]);

  const onShopMore = async () => {
    Storage.remove("session_id");
    Storage.remove("tabindex");
    window.location.replace(storefrontURLs.base);
  };

  const retryLoadDocuments = useCallback(() => {
    if (documentsState.getDraftContract.error) {
      dispatch(clearGetDraftContractResponse());
      dispatch(getDraftContract());
    }
    if (documentsState.getReceipt.error) {
      dispatch(clearGetReceiptResponse());
      dispatch(getReceipt());
    }
    if (documentsState.getAdditionalDocuments.error) {
      dispatch(clearGetAdditionalDocumentsResponse());
      dispatch(getAdditionalDocuments());
    }
  }, [
    dispatch,
    documentsState.getDraftContract.error,
    documentsState.getReceipt.error,
    documentsState.getAdditionalDocuments.error,
  ]);

  const clearErrResponses = useCallback(() => {
    if (documentsState.getDraftContract.error) {
      dispatch(clearGetDraftContractResponse());
    }
    if (documentsState.getReceipt.error) {
      dispatch(clearGetReceiptResponse());
    }
    if (documentsState.getAdditionalDocuments.error) {
      dispatch(clearGetAdditionalDocumentsResponse());
    }
  }, [
    dispatch,
    documentsState.getDraftContract.error,
    documentsState.getReceipt.error,
    documentsState.getAdditionalDocuments.error,
  ]);

  const DocumentsCard = ({ documentState, label, icon }) => {
    const hiddenPdfIframe = useRef();

    return (
      <>
        <OSContainer variant="action-section-order-confirmation" sx={{ gap: [0, "3rem"] }}>
          <Flex
            mx={0}
            my={2}
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: ["center", "normal"],
            }}>
            <OSIcon name={icon} />
            <Heading3
              mx={3.5}
              color="primary"
              sx={{ textAlign: "center", overflow: "hidden", textOverflow: "ellipsis" }}>
              <FormattedMessage id={label} />
            </Heading3>
          </Flex>
          {documentState.loading && (
            <Flex py={1} sx={{ gap: 4 }}>
              <BaseSkeleton width="5rem" height="2rem" bg="#fff" rounded />
              <BaseSkeleton width="5rem" height="2rem" bg="#fff" rounded />
            </Flex>
          )}

          {documentState.success && (
            <Flex sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="simple-action-order-confirmation"
                margin={1}
                onClick={() => window.open(documentState.response)}>
                <FormattedMessage id="btn.view" />
              </Button>

              <Button
                sx={{ display: ["none", "none", "block"] }}
                variant="simple-action-order-confirmation"
                margin={1}
                onClick={() => {
                  hiddenPdfIframe.current.contentWindow.print();
                }}>
                <FormattedMessage id="btn.print" />
              </Button>
              <iframe
                width="0"
                title="hiddenPdfIframe"
                ref={hiddenPdfIframe}
                style={{ visibility: "hidden", height: 0 }}
                src={documentState.response}
              />
            </Flex>
          )}
        </OSContainer>
      </>
    );
  };

  const StaticDocumentsCard = ({ documentState, label, docName, docType, icon, urls }) => {
    return (
      <>
        <OSContainer variant="action-section-order-confirmation" sx={{ gap: [0, "3rem"] }}>
          <Flex
            mx={0}
            my={2}
            sx={{
              alignItems: "center",
              width: "100%",
              justifyContent: ["center", "normal"],
              textAlign: "center",
            }}>
            <OSIcon name={icon} />
            <Heading3
              mx={3.5}
              color="primary"
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {label}
            </Heading3>
          </Flex>

          {documentState.success && (
            <Flex sx={{ justifyContent: "flex-end" }}>
              <Button
                variant="simple-action-order-confirmation"
                margin={1}
                onClick={() => {
                  if (urls[docName]) {
                    window.open(urls[docName]);
                  }
                }}>
                <FormattedMessage id="btn.view" />
              </Button>

              <Button
                sx={{ display: ["none", "none", "block"] }}
                variant="simple-action-order-confirmation"
                margin={1}
                onClick={async () => {
                  const { payload } = await dispatch(
                    getStaticDocumentContent({ docName, docType })
                  );
                  setStaticDocumentContent(payload);
                  let frame = document.getElementById("pdfFrame").contentWindow;
                  frame.focus();
                  frame.print();
                }}>
                <FormattedMessage id="btn.print" />
              </Button>
              <iframe
                width="0"
                id="pdfFrame"
                title="pdfFrame"
                style={{ visibility: "hidden", height: 0 }}
                src={staticDocumentContent}
              />
            </Flex>
          )}
        </OSContainer>
      </>
    );
  };
  useEffect(() => {
    let docs = {};

    if (staticDocumentsList && staticDocumentsList.length > 0) {
      staticDocumentsList.forEach(async (doc) => {
        let { payload } = await dispatch(getStaticDocumentContent(doc));
        docs[doc.docName] = payload;
      });
      setUrls(docs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staticDocumentsList.length]);

  return (
    <Layout
      showSteps={true}
      showCart={false}
      checkoutStep={CHECKOUT_STEPS.PAYMENT_INFORMATION.index}
      paymentSuccess={true}
      checkSessionStatus={false}>
      {/* generate error */}
      <StyledModalMessage
        isOpen={documentsState.getDraftContract.error}
        onRequestClose={clearErrResponses}
        message={<GetErrorDescription error={documentsState.getDraftContract.error} />}>
        <Button variant="simple-action" onClick={retryLoadDocuments}>
          <FormattedMessage id="btn.retry" />
        </Button>
      </StyledModalMessage>

      <OSContainer variant="center" width="100%">
        <OSIcon name="tickOrderConfirmation" />

        <Heading1 mt={4} mb={3.5}>
          <FormattedMessage id="header.thank_you" />
        </Heading1>

        <Heading mx={3.5} variant="heading3normal" color="ternary" sx={{ textAlign: "center" }}>
          {orderNumber !== "0" && (
            <>
              <FormattedMessage id="lbl.order_placed" />{" "}
            </>
          )}
          <FormattedMessage
            id={
              orderNumber !== "0" ? "lbl.confirmation_sent" : "lbl.shipping-not-needed-confirmation"
            }
            values={{
              email: emailAddress,
              b: (chunks) => (
                <Text display="inline" fontWeight="bold">
                  {chunks}
                </Text>
              ),
            }}
          />
        </Heading>
        {orderNumber !== "0" && (
          <Heading3 color="primary" mb={5} mt={1}>
            <FormattedMessage id="lbl.order_number" values={{ orderNo: orderNumber }} />
          </Heading3>
        )}
        <Box
          sx={{
            width: "100%",
            display: ["grid", "flex"],
            justifyContent: "space-between",
            flexWrap: "wrap",
            gridTemplateColumns: "repeat(1, 1fr)",
            gridAutoRows: ["1fr", "min-content"],
            gridGap: 2,
          }}>
          {documentsList &&
            documentsList.length > 0 &&
            documentsList.map((document, index) => (
              <DocumentsCard
                documentState={document.documentState}
                key={index}
                label={document.label}
                icon={document.icon}
              />
            ))}
          {documentsState.getAdditionalDocuments.loading &&
            [...Array(4).keys()].map((index) => <DocumentSkeleton key={index} />)}
          {staticDocumentsList &&
            staticDocumentsList.length > 0 &&
            staticDocumentsList.map((document, index) => (
              <StaticDocumentsCard
                documentState={document.documentState}
                key={index}
                label={document.label}
                icon={document.icon}
                docName={document.docName}
                docType={document.docType}
                urls={urls}
              />
            ))}
        </Box>

        <Flex
          mt={6}
          sx={{
            width: "100%",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Text
            variant="description"
            color="primary"
            sx={{ mx: [0, 0, 9, 52], lineHeight: "relaxed", textAlign: "" }}>
            <FormattedMessage id="lbl.orderConf_message1_1" />
            <OSLink
              url={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
              labelId="lbl.contactUs"
              variant="chat-link"
            />
            <FormattedMessage id="lbl.orderConf_message1_2" />
          </Text>

          <Text sx={{ lineHeight: "relaxed" }} variant="description" color="primary">
            <FormattedMessage id="lbl.orderConf_message2" />
          </Text>

          <ul>
            <li>
              <Text variant="attribute" color="primary" mb={2} sx={{ lineHeight: "loose" }}>
                <FormattedMessage id="lbl.orderConf_message3" />
              </Text>
            </li>
            <li>
              <Text variant="attribute" color="primary" mb={2} sx={{ lineHeight: "loose" }}>
                <FormattedMessage id="lbl.orderConf_message4" />
              </Text>
            </li>
            <li>
              <Text variant="attribute" color="primary" mb={2} sx={{ lineHeight: "loose" }}>
                <FormattedMessage id="lbl.orderConf_message5" />
              </Text>
            </li>
            <li>
              <Text variant="attribute" color="primary" mb={2} sx={{ lineHeight: "loose" }}>
                <FormattedMessage id="lbl.orderConf_message6" />
              </Text>
            </li>
            <li>
              <Text variant="attribute" color="primary" mb={2} sx={{ lineHeight: "loose" }}>
                <FormattedMessage id="lbl.orderConf_message7" />
              </Text>
            </li>
          </ul>
        </Flex>

        {(brandingData.appleStoreLink || brandingData.googlePlayLink) && (
          <OSContainer variant="center" sx={{ width: "100%" }}>
            <Text variant="attribute" color="primary" mb={3}>
              <FormattedMessage
                id="lbl.register_for_selfcare"
                values={{
                  a: (chunks) => (
                    <Link variant="chat-link" href={"https://" + chunks} target="_blank">
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Text>

            <Flex
              sx={{
                width: "100%",
                display: ["grid", "flex"],
                flexDirection: ["column", "row", "row", "row"],
                justifyContent: "space-evenly",
                alignItems: "center",
                gridTemplateColumns: ["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(3, 1fr)"],
                gridAutoRows: "min-content",
                gridGap: 2,
              }}>
              <Flex
                sx={{
                  flexDirection: ["column", "column", "row", "row"],
                  justifyItems: "space-evenly",
                }}>
                <Flex id="logo-cntainer" sx={{ justifyContent: "center" }} mx={3}>
                  <Image src={el_app_logo} id="elLogo" sx={{ height: "3.4rem" }} />
                </Flex>
                <Text
                  variant="heading4"
                  sx={{
                    textAlign: "center",
                    alignSelf: "center",
                    color: "primary",
                    mx: [1.5, 1.5, 6.5, 6.5],
                    lineHeight: "relaxed",
                  }}>
                  <FormattedMessage id="lbl.download_app" />
                </Text>
                <Flex id="Stores-Container">
                  {brandingData.appleStoreLink && (
                    <Box mx={3} onClick={() => window.open(brandingData.appleStoreLink)}>
                      <Image
                        src={app_store_logo}
                        sx={{
                          boxShadow: "sm",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  )}
                  {brandingData.googlePlayLink && (
                    <Box
                      mx={3}
                      sx={{
                        boxShadow: "sm",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(brandingData.googlePlayLink)}>
                      <Image src={play_store_logo} />
                    </Box>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </OSContainer>
        )}
        <Button variant="default-next" my={6} onClick={onShopMore}>
          <FormattedMessage id="btn.shop-more" />
        </Button>
      </OSContainer>
    </Layout>
  );
};

export default OrderConfirmation;
