import React, { useState } from "react";
import { Flex, Text, Input } from "@theme-ui/components";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { OSIcon } from "../../../../components/icons";

const SearchStore = ({ isLoading, onInputChange, disabled, ...props }) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState("");
  const shippingDetailsState = useSelector((state) => state.shippingDetails);

  const onSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    onInputChange(event.target.value, false);
  };

  const onUseMyAddressClick = () => {
    // skip filtering stores by my address if stores were not loaded yet
    if (shippingDetailsState.getStores.loading) return;

    setSearchValue("");
    onInputChange("", true);
  };

  return (
    <Flex
      bg="secondaryBg"
      pb={8}
      px={5}
      mr={[0, 5]}
      mb={[2, 0]}
      sx={{
        flexDirection: "column",
        width: "100%",
        minWidth: "16rem",
        maxWidth: ["100%", "16.625rem"],
        alignItems: "center",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: "default",
      }}
      {...props}>
      <Text
        variant="heading3"
        color={disabled ? "button.disabledColor" : "primary"}
        sx={{ textAlign: "center" }}>
        {intl.formatMessage({ id: "lbl.pick-up-in-store" })}
      </Text>
      <Flex
        sx={{
          position: "relative",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}>
        <Input
          autoComplete="off"
          disabled={isLoading}
          mt={2.5}
          name="searchPickUpStore"
          value={searchValue}
          onChange={onSearchInputChange}
          placeholder={disabled ? "" : intl.formatMessage({ id: "lbl.search-postal-code" })}
          px={5}
          type="input"
          variant="search"
        />
        <Flex sx={{ position: "absolute", right: 3, mt: 2 }}>
          <OSIcon name="search" />
        </Flex>
      </Flex>
      <Flex mb={["none", 4]} mt={4} onClick={onUseMyAddressClick} sx={{ alignItems: "center" }}>
        <Text
          variant="headerStep"
          color={"ternary"}
          sx={{
            textAlign: "center",
            cursor: "pointer",
            fontStretch: "normal",
            textDecoration: "underline",
          }}>
          {intl.formatMessage({ id: "lbl.use-my-address" })}
        </Text>
        <OSIcon name="chevron-right" ml={1} color={"svgStrokePrimary"} />
      </Flex>
    </Flex>
  );
};

export default SearchStore;
