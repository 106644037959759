import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { OSIcon } from "../../components/icons";
import { useWindowSize } from "../../hooks";

import { SHIPPING_FLOW } from "./Constants";
import HRTriangle from "../../components/HRTriangle";
import Tooltip from "../../components/tooltip";
import { useDispatch, useSelector } from "react-redux";
import { isSkippedFraudCheckAndInvoiceIsNotPaid } from "../../config/redux/slices";
import { PERSONAL_INFORMATION_FLOW } from "../../common/Constants";

const Option = forwardRef(({ title, icon, selected, disabled, id, className, ...props }, ref) => {
  return (
    <Flex
      className={className}
      id={id}
      ref={ref}
      bg={disabled ? "button.disabledBackground" : selected ? "selectedCardBg" : "cardBg"}
      p={5}
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: ["100%", "100%", "17.625rem"],
        border: 1,
        borderColor: disabled ? "greyish" : selected ? "secondary" : "cardBorderColor",
        borderRadius: "2lg",
        boxShadow: "sm",
        cursor: disabled ? "default" : "pointer",
        ":hover": !selected &&
          !disabled && {
            borderColor: "cardBorderHoverColor",
          },
      }}
      {...props}>
      <OSIcon name={icon} mb={4} />
      <Text
        variant="heading2"
        sx={{ textAlign: "center" }}
        color={disabled ? "button.disabledColor" : selected ? "selectedCardTextColor" : "ternary"}>
        {title}
      </Text>
    </Flex>
  );
});

const PageHeader = ({ selectedShippingFlow, onChangeShippingFlow }) => {
  const intl = useIntl();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const headerContainer = useRef(null);
  const homeDeliveryOption = useRef(null);
  const pickInStoreOption = useRef(null);

  const [hrTrianglePosition, setHrTrianglePosition] = useState(459);
  const [hrWidth, setHrWidth] = useState(0);
  const personalInformationData = useSelector((state) => state.personalInformation);
  const isHomeDeliveryAllowed =
    !personalInformationData.isSkippedFraudCheckAndInvoiceIsNotPaid.value;
  const { planFlags } = useSelector((state) => state.items);
  const easyTabFlexReturn = planFlags.easyTabFlexReturn;
  const needsFulfillment = planFlags.needsFulfillment;
  const homeDeliveryDisabled = easyTabFlexReturn || !needsFulfillment;

  useEffect(() => {
    if (
      personalInformationData.loaded &&
      personalInformationData.flow !== PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER &&
      !personalInformationData.isSkippedFraudCheckAndInvoiceIsNotPaid.success
    ) {
      dispatch(isSkippedFraudCheckAndInvoiceIsNotPaid(personalInformationData.accountId));
    }

    if (planFlags.needsFulfillment) {
      if (!isHomeDeliveryAllowed) {
        onChangeShippingFlow(SHIPPING_FLOW.PICK_IN_STORE);
      } else if (selectedShippingFlow === SHIPPING_FLOW.NOT_NEEDED) {
        onChangeShippingFlow(SHIPPING_FLOW.HOME_DELIVERY);
      }
    } else {
      onChangeShippingFlow(SHIPPING_FLOW.NOT_NEEDED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    personalInformationData.loaded,
    personalInformationData.isSkippedFraudCheckAndInvoiceIsNotPaid.success,
  ]);

  useLayoutEffect(() => {
    if (selectedShippingFlow === SHIPPING_FLOW.HOME_DELIVERY && homeDeliveryOption.current) {
      setHrTrianglePosition(
        homeDeliveryOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          homeDeliveryOption.current.offsetWidth / 2
      );
    }

    if (selectedShippingFlow === SHIPPING_FLOW.PICK_IN_STORE) {
      setHrTrianglePosition(
        pickInStoreOption.current.offsetLeft -
          headerContainer.current.offsetLeft +
          pickInStoreOption.current.offsetWidth / 2
      );
    }
    setHrWidth(
      headerContainer && headerContainer.current ? headerContainer.current.offsetWidth : 0
    );
  }, [selectedShippingFlow, windowSize.width]);

  return (
    <>
      <Flex
        mb={selectedShippingFlow && 9.5}
        px={[8, 8, 9]}
        ref={headerContainer}
        sx={{
          alignItems: ["flex-start", "flex-start", "center"],
          flexDirection: ["column", "column", "row"],
        }}>
        <Flex
          sx={{
            alignItems: "center",
            height: "100%",
            justifyContent: "flex-start",
            maxWidth: ["auto", "auto", "15.875rem"],
          }}>
          <Text variant="heading3normal">
            {intl.formatMessage({ id: "lbl.how-to-receive-your-device" })}
          </Text>
        </Flex>

        <Flex ml={["none", "none", 17]} mt={[5, 5, "none"]} sx={{ width: "100%" }}>
          {isHomeDeliveryAllowed && (
            <Tooltip
              display="flex"
              position="top"
              value={
                easyTabFlexReturn && intl.formatMessage({ id: "lbl.home-delivery-flex-disabled" })
              }
              sx={{
                width: "15rem",
              }}>
              <Option
                id="homeDelivery"
                className="track"
                ref={homeDeliveryOption}
                icon={homeDeliveryDisabled ? "home-delivery-disabled" : "home-delivery"}
                title={intl.formatMessage({ id: "lbl.home-delivery" })}
                onClick={() =>
                  selectedShippingFlow !== SHIPPING_FLOW.HOME_DELIVERY &&
                  !homeDeliveryDisabled &&
                  onChangeShippingFlow(SHIPPING_FLOW.HOME_DELIVERY)
                }
                selected={selectedShippingFlow === SHIPPING_FLOW.HOME_DELIVERY}
                disabled={homeDeliveryDisabled}
              />
            </Tooltip>
          )}

          <Option
            id="pickInStore"
            className="track"
            ref={pickInStoreOption}
            icon={needsFulfillment ? "pick-in-store" : "pick-in-store-disabled"}
            title={intl.formatMessage({ id: "lbl.pick-in-store" })}
            onClick={() =>
              needsFulfillment &&
              selectedShippingFlow !== SHIPPING_FLOW.PICK_IN_STORE &&
              onChangeShippingFlow(SHIPPING_FLOW.PICK_IN_STORE)
            }
            selected={selectedShippingFlow === SHIPPING_FLOW.PICK_IN_STORE}
            disabled={!needsFulfillment}
            ml={6}
          />
        </Flex>
      </Flex>

      {selectedShippingFlow &&
        selectedShippingFlow !== SHIPPING_FLOW.NONE &&
        selectedShippingFlow !== SHIPPING_FLOW.NOT_NEEDED && (
          <HRTriangle trianglePosition={hrTrianglePosition} width={hrWidth} />
        )}
    </>
  );
};

export default PageHeader;
