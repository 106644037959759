import React from "react";
import { Flex, Text } from "@theme-ui/components";

import { CHECKOUT_STEP_STATE } from "./Constants";

/**
 * Checkout Step Component.
 *
 * @param {*} param0.number Checkout step number.
 * @param {*} param0.state Checkout step state. One of: ACTIVE, COMPLETED, NOT_COMPLETED.
 * @param {*} param0.name Checkout step name.
 */
const CheckoutStep = ({ number, state, name }) => {
  /**
   * Decide checkout step fill color based on step state.
   *
   * @param {*} state The checkout step state.
   *
   * @returns Fill color for svg element.
   */
  const getFillColor = (state) => {
    if (state === CHECKOUT_STEP_STATE.ACTIVE) return "bulletBgPrimary";
    if (state === CHECKOUT_STEP_STATE.COMPLETED) return "bulletBgPrimary";
    return "transparent";
  };

  /**
   * Decide what should be the checkout step number based on checkout step state.
   *
   * @param {*} number Checkout step number. Will be returned if checkout step state is ACTIVE or NOT_COMPLETED.
   * @param {*} state Checkout step state. One of: ACTIVE, COMPLETED, NOT_COMPLETED.
   *
   * @returns Checkout step number or "CHECKED" symbol if the checkout step state is COMPLETED.
   */
  const getStepNumber = (number, state) => {
    if (state === CHECKOUT_STEP_STATE.COMPLETED) return "\u2713";
    if (state === CHECKOUT_STEP_STATE.ACTIVE)
      return (
        <Flex
          bg="windowBg"
          sx={{
            width: ["none", "none", "1rem", "1.25rem"],
            heigh: ["none", "none", "1rem", "1.25rem"],
            minWidth: ["none", "none", "1rem", "1.25rem"],
            minHeight: ["none", "none", "1rem", "1.25rem"],
            borderRadius: "full",
            userSelect: "none",
          }}
        />
      );
    return "";
  };

  return (
    <Flex
      sx={{
        flexDirection: ["column", "column", "row"],
        justifyContent: "flex-start",
        alignItems: "center",
        flexShrink: "1",
      }}>
      <Flex
        bg={getFillColor(state)}
        sx={{
          width: ["none", "none", "2.25rem", "2.75rem"],
          height: ["none", "none", "2.25rem", "2.75rem"],
          minWidth: ["none", "none", "2.25rem", "2.75rem"],
          minHeight: ["none", "none", "2.25rem", "2.75rem"],
          justifyContent: "center",
          alignItems: "center",
          border: state === CHECKOUT_STEP_STATE.NOT_COMPLETED && "1",
          borderColor: state === CHECKOUT_STEP_STATE.NOT_COMPLETED && "invertedTextColor",
          borderRadius: "full",
          userSelect: "none",
        }}>
        <Text
          variant="headerStep"
          color={state === CHECKOUT_STEP_STATE.NOT_COMPLETED ? "invertedTextColor" : "windowBg"}>
          {getStepNumber(number, state)}
        </Text>
      </Flex>
      {/** DESKTOP */}
      <Text
        variant="subtitle1"
        mt={0}
        mx={2.5}
        color="invertedTextColor"
        sx={{
          width: "auto",
          display: ["none", "none", "block"],
          textAlign: "left",
        }}>
        {name}
      </Text>
      {/** MOBILE/TABLET */}
      {state === CHECKOUT_STEP_STATE.ACTIVE && (
        <Text
          variant="headerStepLight"
          mt="2"
          color="windowBg"
          sx={{ display: ["none", "block", "none"], textAlign: "center" }}>
          {name}
        </Text>
      )}
    </Flex>
  );
};

export default CheckoutStep;
