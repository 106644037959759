import React from "react";
import { Box, Heading, Text, Flex } from "@theme-ui/components";
import Modal from "./Modal";

const StyledModal = Modal.styled`
  height: fit-content;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #F0F0F0;
  border-radius: 10px;
  width: 610px;
  box-shadow: 0px 6px 6px #02257528;
  overflow: auto;
  transition: opacity ease 500ms;
`;

const StyledModalMessage = ({
  children,
  isOpen,
  onRequestClose,
  isCloseDisabled,
  message,
  listItems,
  verticalDirection,
  ...props
}) => {
  return (
    <StyledModal
      {...props}
      isOpen={isOpen}
      onBackgroundClick={onRequestClose}
      onEscapeKeydown={onRequestClose}
      opacity={1}
      width={[300, 500, 610]}>
      {!isCloseDisabled && (
        <Text
          as="span"
          sx={{
            color: "primary",
            float: "right",
            fontSize: "5xl",
            fontWeight: "normal",
            marginTop: -1,
            marginRight: 3,
            opacity: "100%",
            ":hover": {
              cursor: "pointer",
              opacity: "70%",
            },
          }}
          onClick={onRequestClose}>
          &times;
        </Text>
      )}
      <Box sx={{ py: [10, 12, 16], px: 6, textAlign: "center" }}>
        {message && (
          <Heading
            variant="heading3normal"
            sx={{
              textAlign: "center",
            }}>
            {message}
          </Heading>
        )}
        {listItems && listItems.length > 0 && (
          <Heading variant="heading3normal" sx={{ textAlign: "left" }}>
            <ul>
              {listItems.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Heading>
        )}
        {children && (
          <Flex
            sx={{
              pt: 6,
              textAlign: "center",
              justifyContent: "center",
              flexDirection: verticalDirection ? "column" : "row",
            }}>
            {children}
          </Flex>
        )}
      </Box>
    </StyledModal>
  );
};

export default StyledModalMessage;
