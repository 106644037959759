import React from "react";
import { Box } from "@theme-ui/components";

const DialogBubble = ({
  /*fill_1 = "#807878", fill_2 = "#e2e2e2", fill_3 = "#c2c2c2",*/ color = "svgStrokePrimary",
  size = "iconMedium",
  ...props
}) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 44.848 44.848"
    sx={{
      width: size,
      height: size,
      flexShrink: 0,
      stroke: color,
    }}
    {...props}>
    <g transform="translate(1 1)">
      <path
        d="M49.148,22.454v5.47a16.376,16.376,0,0,1-5.242,11.852l-.912.684c-.912.684-14.587,8.889-14.587,8.889v-5.47H22.254A15.856,15.856,0,0,1,6.3,27.924v-5.47A15.856,15.856,0,0,1,22.254,6.5H33.422A15.955,15.955,0,0,1,49.148,22.454Z"
        transform="translate(-6.3 -6.5)"
        fill="#fff"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <g transform="translate(12.08 12.08)">
        <line
          x2="17.322"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <line
          x2="17.322"
          transform="translate(0 14.131)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <line
          x2="21.88"
          transform="translate(0 7.065)"
          fill="#fff"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </g>
  </Box>
);

export default DialogBubble;
