import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Storage from "store2";

import { FacadeAPI } from "../../../rest/RestClient";
import { doLogout } from "./AuthSlice";
import { updatePersonaInformation } from "./PersonalInformationSlice";
import { ErrorCode } from "../../../common/Constants";

const INITIAL_STATE = {
  sessionId: Storage.get("session_id"),
  status: "",
  posCartId: null,
  posCartPaymentId: null,
  externalCartId: null,
  cartStatus: null,
  // API Responses
  getSession: {
    loading: false,
    success: false,
    offline: false,
    error: null,
  },
  finishSession: {
    loading: false,
    success: false,
    error: null,
  },
  deleteSession: {
    loading: false,
    success: false,
    error: null,
  },
  logoutSession: {
    loading: false,
    success: false,
    error: null,
  },
  performCartPayment: {
    loading: false,
    success: false,
    error: null,
    response: null,
  },
};

export const getSession = createAsyncThunk(
  "session/get",
  async (args, { getState, rejectWithValue }) => {
    const sessionId = args && args.sessionId ? args.sessionId : getState().session.sessionId;
    try {
      return await FacadeAPI.GET(`/session/${sessionId}`, null, { noAuthorization: true });
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const finishSession = createAsyncThunk(
  "session/finish",
  async (deferInStorePickupFlow = false, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      return await FacadeAPI.PUT(
        `/session/${sessionId}/finish?deferInStorePickupFlow=${deferInStorePickupFlow}`,
        {}
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const deleteSession = createAsyncThunk(
  "session/delete",
  async (args, { getState, rejectWithValue }) => {
    const sessionId = args && args.sessionId ? args.sessionId : getState().session.sessionId;
    try {
      return await FacadeAPI.DELETE(
        `/session/${sessionId}/delete`,
        {},
        {
          expectedResponse: "",
        }
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logoutSession = createAsyncThunk(
  "session/logout",
  async (args, { getState, dispatch, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    var flow = getState().personalInformation.flow;
    try {
      await FacadeAPI.PUT(
        `/session/${sessionId}/logout`,
        {},
        {
          expectedResponse: "",
        }
      );
      // clear personal information from local store except flow
      dispatch(
        updatePersonaInformation({
          loaded: false,
          flow: flow,
          username: "",
          password: "",
          firstName: "",
          lastName: "",
        })
      );
      // logout also on client side
      dispatch(doLogout());
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const performCartPayment = createAsyncThunk(
  "session/performCartPayment",
  async ({ amountToPay }, { getState, rejectWithValue }) => {
    const sessionId = getState().session.sessionId;
    try {
      await FacadeAPI.PUT(
        `/session/${sessionId}/performPayment?amount=${amountToPay}`,
        {},
        {
          expectedResponse: "",
        }
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const sessionSlice = createSlice({
  name: "session",
  initialState: INITIAL_STATE,
  reducers: {
    clearSession() {
      Storage.remove("session_id");
      return {
        ...INITIAL_STATE,
        sessionId: null,
        status: "",
      };
    },
    clearGetSessionResponse(state) {
      return {
        ...state,
        getSession: INITIAL_STATE.getSession,
      };
    },
    clearFinishSession(state) {
      return {
        ...state,
        finishSession: INITIAL_STATE.finishSession,
      };
    },
    clearLogoutSession(state) {
      return {
        ...state,
        logoutSession: INITIAL_STATE.logoutSession,
      };
    },
    clearPerformCartPayment(state) {
      return {
        ...state,
        performCartPayment: INITIAL_STATE.performCartPayment,
      };
    },
  },
  extraReducers: {
    [getSession.pending]: (state) => {
      state.getSession.loading = true;
      state.getSession.success = false;
      state.getSession.error = null;
    },
    [getSession.fulfilled]: (state, action) => {
      state.getSession.loading = false;
      state.getSession.success = true;
      state.sessionId = action.payload.sessionId;
      state.status = action.payload.status;
      state.posCartId = action.payload.posCartId;
      state.orderNumber = action.payload.orderNumber;
      state.externalCartId = action.payload.externalCartId;
      state.posCartPaymentId = action.payload.posCartPaymentId;
      state.cartStatus = action.payload.cartStatus;
      Storage.set("session_id", state.sessionId, true);
    },
    [getSession.rejected]: (state, action) => {
      state.getSession.loading = false;
      state.getSession.success = false;
      let offline = action.payload?.errorCode === ErrorCode.API_OFFLINE;
      state.getSession.offline = offline;
      state.getSession.error = offline ? action.payload?.errorCode : action.payload;
    },

    [finishSession.pending]: (state) => {
      state.finishSession.loading = true;
      state.finishSession.success = false;
      state.finishSession.error = null;
    },
    [finishSession.fulfilled]: (state, action) => {
      state.finishSession.loading = false;
      state.finishSession.success = true;
      state.status = action.payload.status;
    },
    [finishSession.rejected]: (state, action) => {
      state.finishSession.loading = false;
      state.finishSession.success = false;
      state.finishSession.error = action.payload;
    },

    [deleteSession.pending]: (state) => {
      state.deleteSession.loading = true;
      state.deleteSession.success = false;
      state.deleteSession.error = null;
    },
    [deleteSession.fulfilled]: (state) => {
      state.deleteSession.loading = false;
      state.deleteSession.success = true;
    },
    [deleteSession.rejected]: (state, action) => {
      state.deleteSession.loading = false;
      state.deleteSession.success = false;
      state.deleteSession.error = action.payload;
    },

    [logoutSession.pending]: (state) => {
      state.logoutSession.loading = true;
      state.logoutSession.success = false;
      state.logoutSession.error = null;
    },
    [logoutSession.fulfilled]: (state) => {
      state.logoutSession.loading = false;
      state.logoutSession.success = true;
    },
    [logoutSession.rejected]: (state, action) => {
      state.logoutSession.loading = false;
      state.logoutSession.success = false;
      state.logoutSession.error = action.payload;
    },
    [performCartPayment.pending]: (state) => {
      state.performCartPayment.loading = true;
      state.performCartPayment.success = false;
      state.performCartPayment.error = null;
      state.performCartPayment.response = null;
    },
    [performCartPayment.fulfilled]: (state, action) => {
      state.performCartPayment.loading = false;
      state.performCartPayment.success = true;
      state.performCartPayment.response = action.payload;
    },
    [performCartPayment.rejected]: (state, action) => {
      state.performCartPayment.loading = false;
      state.performCartPayment.success = false;
      state.performCartPayment.error = action.payload;
    },
  },
});

export const {
  clearSession,
  clearFinishSession,
  clearLogoutSession,
  clearGetSessionResponse,
  clearPerformCartPayment,
} = sessionSlice.actions;

export default sessionSlice.reducer;
