import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Switch, useLocation } from "react-router-dom";
import TagManager from "gtm-for-react";
import Storage from "store2";

import {
  DeviceAndPlan,
  UpdateDevice,
  Error,
  OrderConfirmation,
  PaymentInformation,
  PersonalInformation,
  PhoneNumber,
  ShippingDetails,
  Start,
  PaymentCallback,
} from "../../pages";

import PublicRoute from "./PublicRoute";
import OrderFailed from "../../pages/order-failed";
import PrivateRoute from "./PrivateRoute";
import Summary from "../../pages/summary/Summary";
import DeviceMockup from "../../pages/IFrameMockup/DeviceMockup";
import PlanMockup from "../../pages/IFrameMockup/PlanMockup";
import AccessoryMockup from "../../pages/IFrameMockup/AccessoryMockup";
import IFrameMockup from "../../pages/IFrameMockup";
import ValidateIdentity from "../../pages/personal-information/validate-identity";
import PINValidation from "../../pages/personal-information/pin-validation";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    TagManager.dataLayer({ dataLayer: { event: "PageView", pagePath: pathname } });
    Storage.session.transact(
      "kwExtraData",
      (obj) => {
        obj.path = pathname;
      },
      {}
    );
  }, [pathname]);

  return null;
};

const OSRouter = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <PublicRoute exact path="/" component={Start} />
        <PublicRoute exact path="/personal-information" component={PersonalInformation} />
        <PublicRoute exact path="/validate-identity" component={ValidateIdentity} />
        <PublicRoute exact path="/iFrameMockup/cart/device-list" component={DeviceMockup} />
        <PublicRoute exact path="/iFrameMockup/cart/plan-builder" component={PlanMockup} />
        <PublicRoute exact path="/iFrameMockup/cart/accessory" component={AccessoryMockup} />
        <PublicRoute exact path="/iFrameMockup" component={IFrameMockup} />
        <PrivateRoute exact path="/validate-pin" component={PINValidation} />
        <PrivateRoute exact path="/device-and-plan" component={DeviceAndPlan} />
        <PrivateRoute exact path="/update-device" component={UpdateDevice} />
        <PrivateRoute exact path="/phone-number" component={PhoneNumber} />
        <PrivateRoute exact path="/shipping-details" component={ShippingDetails} />
        <PrivateRoute exact path="/summary" component={Summary} />
        <PrivateRoute exact path="/payment-information" component={PaymentInformation} />
        <PublicRoute exact path="/order-confirmation" component={OrderConfirmation} />
        <PrivateRoute exact path="/order-failed" component={OrderFailed} />
        <PrivateRoute exact path="/payment-callback" component={PaymentCallback} />
        <PublicRoute path="/error" component={Error} />
        <Redirect to={{ pathname: "/error" }} />
      </Switch>
    </Router>
  );
};

export default OSRouter;
