import React from "react";
import { Text, Label } from "@theme-ui/components";

import Tooltip from "../../tooltip/Tooltip";
import useOverFlow from "../../../hooks/useOverFlow";

const OSLabel = ({ children, variant = "labelLarge", isMandatory, ...props }) => {
  const [isOverflow, overflow] = useOverFlow();

  return (
    <Tooltip position="top-noflex" value={children} isOverflow={isOverflow}>
      <Label variant={variant} {...props}>
        <Text
          ref={overflow}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}>
          {children}
        </Text>

        {isMandatory && (
          <Text
            color="error"
            sx={{
              fontSize: "sm",
            }}>
            *
          </Text>
        )}
      </Label>
    </Tooltip>
  );
};
export default OSLabel;
