import React from "react";
import { Box } from "@theme-ui/components";

const ErrorMessage = ({ children, ...props }) => {
  return (
    <Box
      p={1}
      {...props}
      color="error"
      sx={{
        fontSize: "sm",
        wordWrap: "normal",
        textAlign: "right",
        whiteSpace: ["normal", "pre"],
        overFlowWrap: "break-word",
        ...props.sx,
      }}>
      {children}
    </Box>
  );
};

export default ErrorMessage;
