import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Text } from "@theme-ui/components";
import Amount from "../amount";
import Separator from "../separator";
import { PERSONAL_INFORMATION_FLOW } from "../../common/Constants";

const LineHeader = ({ description, lineTitle, ...props }) => {
  const intl = useIntl();

  return (
    <Flex
      sx={{
        ...props,
        alignItems: "center",
      }}>
      <Text
        variant="heading2"
        color="primary"
        sx={{
          overflowWrap: "break-word",
        }}>
        {description}
        {lineTitle && (
          <Text variant="heading2" color="primary" pl={2}>
            {`${intl.formatMessage({ id: "lbl.for" })} ${lineTitle}`}
          </Text>
        )}
      </Text>
    </Flex>
  );
};

const LineDescription = ({
  description,
  labelId,
  lineTitle,
  amount,
  isTotal,
  plusTax,
  includeTax,
  showZeroAmounts = false,
  isSpecialDiscount = false,
}) => {
  const intl = useIntl();

  return (
    ((showZeroAmounts || amount) && (
      <Flex
        sx={{
          alignContent: "center",
          justifyContent: "space-between",
        }}
        ml={4}
        mt={1}>
        {(!isTotal && (
          <>
            <Text variant="largeLabel">
              {labelId ? (
                <FormattedMessage
                  textComponent="span"
                  id={labelId}
                  values={{
                    sup: (chunks) => (
                      <Text
                        as="sup"
                        mr={0}
                        sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              ) : (
                description
              )}
              {plusTax && ` (${intl.formatMessage({ id: "lbl.plus-tax" })})`}
              {includeTax && ` (${intl.formatMessage({ id: "lbl.tax-included" })})`}
            </Text>
            <Amount type="normal_large" amount={amount} color="ternary" />
          </>
        )) || (
          <>
            <Text variant="largeBoldLabel">
              {labelId ? (
                <FormattedMessage
                  textComponent="span"
                  id={labelId}
                  values={{
                    sup: (chunks) => (
                      <Text
                        as="sup"
                        mr={0}
                        sx={{ display: "inline", fontSize: "45%", color: "inherit" }}>
                        {chunks}
                      </Text>
                    ),
                  }}
                />
              ) : (
                description
              )}
              {plusTax && ` (${intl.formatMessage({ id: "lbl.plus-tax" })})`}
              {includeTax && ` (${intl.formatMessage({ id: "lbl.tax-included" })})`}
            </Text>
            <Amount type="bold_large" amount={amount} color="ternary" />
          </>
        )}
      </Flex>
    )) ||
    (!amount && isSpecialDiscount && (
      <Flex
        sx={{
          alignContent: "center",
          justifyContent: "space-between",
        }}
        ml={4}
        mt={1}>
        <Text variant="largeLabel">
          {lineTitle && (
            <Text variant="heading2" color="primary" pl={2}>
              {`${lineTitle}: `}
            </Text>
          )}
          {description}
        </Text>
      </Flex>
    )) ||
    null
  );
};

const TotalPrices = ({
  showTopBorder = true,
  lineTitle,
  dueNowPrices,
  monthlyPrices,
  oneTimePrices,
  showTax,
  showShipping = false,
  specialDiscounts,
  flow,
  ...props
}) => {
  const intl = useIntl();
  const hasValue = (obj) => {
    return Object.values(obj)
      .filter((value) => value.amount)
      .find((amount) => amount !== 0);
  };

  const showDueNowCharges = dueNowPrices && hasValue(dueNowPrices);
  const showMonthlyCharges = monthlyPrices && hasValue(monthlyPrices);
  const showOneTimeCharges = oneTimePrices && hasValue(oneTimePrices);

  return (
    <Flex
      mb={5}
      sx={{
        flexDirection: "column",
        alignItems: "flex-end",
      }}
      {...props}>
      {showTopBorder && <Separator variant="card-border" my={10} />}
      <Flex
        px={[8, 8, 10]}
        sx={{
          cursor: "default",
          width: ["100%", "100%", "36rem"],
          flexDirection: "column",
        }}>
        {showDueNowCharges && (
          <>
            <LineHeader
              description={intl.formatMessage({ id: "lbl.total-to-pay-now" })}
              lineTitle={lineTitle}
            />
            <LineDescription
              labelId={"lbl.accessories"}
              amount={dueNowPrices.accessoriesPrice?.amount}
              includeTax={!dueNowPrices.accessoriesPrice?.isTaxFree}
            />
            <LineDescription
              labelId={
                flow === PERSONAL_INFORMATION_FLOW.EXISTING_UPGRADE
                  ? "lbl.new-device"
                  : "lbl.device"
              }
              amount={dueNowPrices.devicePrice?.amount}
              includeTax={!dueNowPrices.devicePrice?.isTaxFree}
            />
            <LineDescription
              labelId={"lbl.old-device"}
              amount={dueNowPrices.oldEasyTabPrice?.amount}
              includeTax={!dueNowPrices.oldEasyTabPrice?.isTaxFree}
            />
            <LineDescription
              labelId={"lbl.shipping"}
              amount={dueNowPrices.shippingPrice?.amount}
              plusTax={!showTax}
              showZeroAmounts={showShipping}
            />
            {showTax && <LineDescription labelId={"lbl.tax"} amount={dueNowPrices.tax} />}
            <LineDescription labelId={"lbl.total"} amount={dueNowPrices.total} isTotal />
          </>
        )}

        {showOneTimeCharges && (
          <>
            <LineHeader
              mt={4}
              description={intl.formatMessage({ id: "lbl.one-time-billed-charges" })}
              lineTitle={lineTitle}
            />
            <LineDescription
              labelId={"lbl.onetime"}
              amount={oneTimePrices.planOneTime?.amount}
              plusTax={!showTax && !oneTimePrices.planOneTime?.isTaxFree}
            />
            <LineDescription
              description={oneTimePrices.connectionFee?.description}
              amount={oneTimePrices.connectionFee?.amount}
              plusTax={!showTax && !oneTimePrices.connectionFee?.isTaxFree}
            />
            <LineDescription
              labelId={"lbl.additional-services"}
              amount={oneTimePrices.additionalOneTime?.amount}
              plusTax={!showTax && !oneTimePrices.additionalOneTime?.isTaxFree}
            />
            <LineDescription
              labelId={"lbl.discounts"}
              amount={oneTimePrices.discountsPrice?.amount}
              plusTax={!showTax && !oneTimePrices.discountsPrice?.isTaxFree}
            />
            {showTax && <LineDescription labelId={"lbl.tax"} amount={oneTimePrices.tax} />}
            <LineDescription labelId={"lbl.total"} amount={oneTimePrices.total} isTotal />
          </>
        )}

        {showMonthlyCharges && (
          <>
            <LineHeader
              mt={4}
              description={intl.formatMessage({ id: "lbl.monthly-charges" })}
              lineTitle={lineTitle}
            />
            <LineDescription
              labelId={"lbl.rate-plan-and-features"}
              amount={monthlyPrices.ratePlanFeaturesPrice?.amount}
              plusTax={!showTax && !monthlyPrices.ratePlanFeaturesPrice?.isTaxFree}
            />
            <LineDescription
              labelId={"lbl.discounts"}
              amount={monthlyPrices.discountsPrice?.amount}
              plusTax={!showTax && !monthlyPrices.discountsPrice?.isTaxFree}
            />
            {showTax && <LineDescription labelId={"lbl.tax"} amount={monthlyPrices.tax} />}
            <LineDescription
              labelId={"lbl.easytab-contribution"}
              amount={monthlyPrices.easyTabPrice?.amount}
              includeTax={!monthlyPrices.easyTabPrice?.isTaxFree}
            />
            <LineDescription labelId={"lbl.total"} amount={monthlyPrices.total} isTotal />
          </>
        )}
        {specialDiscounts && specialDiscounts.length > 0 && (
          <>
            <LineHeader mt={4} description={intl.formatMessage({ id: "lbl.special-discounts" })} />
            {specialDiscounts.map((discount, index) => (
              <LineDescription
                key={index}
                description={discount}
                lineTitle={lineTitle}
                isSpecialDiscount={true}
              />
            ))}
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default TotalPrices;
