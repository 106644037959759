import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Box, Flex, Text, Button } from "@theme-ui/components";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { OSSelect, OSLink, OSLabel } from "../../../components/base";
import {
  getPools,
  getRandomPhoneNumbers,
  savePhoneNumber,
  setPhoneNumberState,
  getDefaultStates,
  getProvincesPools,
  getPosParameter,
  clearGetRandomPhoneNumbersApiResponse,
} from "../../../config/redux/slices";

import { FLOW } from "../Constants";
import { NONE_CODE, POS_PARAMETER, PERSONAL_INFORMATION_FLOW } from "../../../common/Constants";
import { PORTIN_TYPE } from "../../../components/portin-type/Constants";
import _ from "lodash";

const PickPhoneNumber = forwardRef(({ activeTabIndex, setPhoneNumberBundle, itemId }, ref) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const phoneNumberData = useSelector((state) => state.phoneNumber);
  const phoneNumberState = useSelector((state) => state.phoneNumber[activeTabIndex]);
  const personalInformationData = useSelector((state) => state.personalInformation);
  const statesData = useSelector((state) => state.states);
  const provincesPoolsData = useSelector((state) => state.configuration.getProvincesPools);
  const posParameters = useSelector((state) => state.configuration.getPosParameter.posParameters);

  const [poolCode, setPoolCode] = useState(phoneNumberState?.poolCode);
  const [phoneNumber, setPhoneNumber] = useState(phoneNumberState?.phoneNumber);
  const [province, setProvince] = useState(phoneNumberState?.province);
  const [pools, setPools] = useState(phoneNumberData?.pools);
  const [phoneNumbersList, setPhoneNumbersList] = useState(phoneNumberState.randomPhoneNumbers);
  // const [flag, setFlag] = useState(true);

  useEffect(() => {
    const fetchNumbersForCompleted = async () => {
      if (
        phoneNumberData.getPhoneNumberObj.success &&
        phoneNumberState.phoneNumber &&
        phoneNumberState.stepCompleted
      ) {
        const { payload } = await dispatch(
          getRandomPhoneNumbers({ poolCode: phoneNumberState.poolCode })
        );
        if (payload && !payload.errorCode) {
          let numbers = [...payload];
          numbers.splice(0, 1, phoneNumberState.phoneNumber);
          setPhoneNumbersList(numbers);
        }
      }
    };
    fetchNumbersForCompleted();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberData.getPhoneNumberObj.success, activeTabIndex]);

  useEffect(() => {
    const loadStates = async () => {
      if (!statesData.getDefaultStates.states || statesData.getDefaultStates.states.length === 0) {
        dispatch(getDefaultStates());
      }
    };

    const loadProvincesPools = async () => {
      if (
        !provincesPoolsData.provincesPools ||
        Object.keys(provincesPoolsData.provincesPools).length === 0
      ) {
        dispatch(getProvincesPools());
      }
    };
    const fetchData = async () => {
      if (!phoneNumberData?.pools || (phoneNumberData?.pools.length ?? 0) === 0) {
        dispatch(getPools());
      }
    };
    dispatch(getPosParameter(POS_PARAMETER.ONLINE_STORE_CHAT_URL));
    loadProvincesPools();
    loadStates();
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (phoneNumberData?.getPools.success && provincesPoolsData.success) {
      let poolProvince = phoneNumberState?.province;
      if (!province && poolCode !== NONE_CODE && provincesPoolsData.provincesPools) {
        poolProvince = _.findKey(provincesPoolsData.provincesPools, (value) =>
          _.includes(value, poolCode)
        );
      } else if (poolCode === NONE_CODE) {
        poolProvince =
          PERSONAL_INFORMATION_FLOW.NEW_CUSTOMER === personalInformationData.flow
            ? personalInformationData.regProvince
            : personalInformationData.province;
        poolProvince = poolProvince ? poolProvince : NONE_CODE;
      }
      let provinceFromList = statesData.getDefaultStates.states.find(
        (state) => state.iso === poolProvince
      );
      if (!provinceFromList) {
        provinceFromList = statesData.getDefaultStates.states.find(
          (state) => state.description === poolProvince
        );
      }
      if (provinceFromList) {
        setProvince(provinceFromList.iso);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberData.getPools.success, provincesPoolsData.success]);

  useEffect(() => {
    if (province) {
      setFilteredData(province);
    }
    dispatch(clearGetRandomPhoneNumbersApiResponse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [province]);

  const resetPhoneNumber = () => {
    setPhoneNumber("");
    setPhoneNumbersList([]);
    setPhoneNumberBundle(null);
  };

  const onChangeProvince = (selectedProvince) => {
    resetPhoneNumber();
    setPoolCode(NONE_CODE);
    setProvince(selectedProvince);
  };

  const setFilteredData = (selectedProvince) => {
    let poolList = provincesPoolsData.provincesPools[selectedProvince];
    if (!poolList) {
      return setPools([]);
    }
    const filteredPools = phoneNumberData?.pools.filter((pool) => poolList.includes(pool.code));
    setPools(filteredPools);
  };

  const onChangePoolCode = async (selectedPoolCode) => {
    resetPhoneNumber();
    if (selectedPoolCode !== "-1") {
      const { payload } = await dispatch(getRandomPhoneNumbers({ poolCode: selectedPoolCode }));
      if (payload && !payload.errorCode) {
        setPoolCode(selectedPoolCode);
        setPhoneNumbersList(payload);
      }
    } else {
      setPoolCode(selectedPoolCode);
    }
  };

  const onPhoneNumberSet = (phNumber) => {
    setPhoneNumber(phNumber);
    let currentState = {
      ...phoneNumberState,
      flow: FLOW.PICK,
      province: province,
      poolCode: poolCode,
      phoneNumber: phNumber,
      randomPhoneNumbers: phoneNumbersList,
      stepCompleted: true,
    };
    setPhoneNumberBundle(currentState);
  };

  useImperativeHandle(ref, () => ({
    onSavePhoneNumber: onNext,
  }));

  const onNext = () => {
    let newState = {
      flow: FLOW.PICK,
      province: province,
      poolCode: poolCode,
      phoneNumber: phoneNumber,
      randomPhoneNumbers: phoneNumbersList,
      stepCompleted: true,
    };
    dispatch(setPhoneNumberState({ activeTabIndex, newState }));
    // Save details to session.
    const request = {
      sessionItemId: itemId,
      flow: FLOW.PICK,
      portInType: PORTIN_TYPE.NONE,
      poolCode: poolCode,
      number: phoneNumber,
      province: province,
    };
    dispatch(savePhoneNumber(request));
  };

  return (
    <>
      <Box mt={7.5} px={[8, 8, 9]} sx={{ width: "100%" }}>
        <Text variant="description">
          {intl.formatMessage({ id: "lbl.phone-number-based-on-location" })}
        </Text>

        <Flex sx={{ flexDirection: ["column", "column", "column", "row"], gap: 5 }} mt={5}>
          <Flex sx={{ flexDirection: "column", width: "100%" }}>
            <Flex sx={{ flexDirection: ["column", "row"], width: "100%", gap: 2 }}>
              <Flex
                sx={{
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: ["100%", "20rem"],
                }}>
                <OSLabel variant="labelLarge">{intl.formatMessage({ id: "lbl.province" })}</OSLabel>
                <OSSelect
                  mt={2}
                  onChange={(e) => onChangeProvince(e.target.value)}
                  name="provincePhoneNo"
                  value={province}
                  variant="select">
                  {[
                    {
                      countryISO2: NONE_CODE,
                      iso: NONE_CODE,
                      description: intl.formatMessage({ id: "lbl.none" }),
                    },
                  ]
                    .concat(statesData.getDefaultStates.states)
                    .filter(
                      (state) =>
                        Object.keys(provincesPoolsData.provincesPools).includes(state.iso) ||
                        state.iso === NONE_CODE
                    )
                    .map((state) => (
                      <option key={state.iso} value={state.iso}>
                        {state.iso === NONE_CODE ? state.description : state.iso}
                      </option>
                    ))}
                </OSSelect>
              </Flex>
              <Flex sx={{ flexDirection: "column", width: "100%", maxWidth: ["100%", "20rem"] }}>
                <OSLabel variant="labelLarge">
                  {intl.formatMessage({ id: "lbl.service-location" })}
                </OSLabel>
                <OSSelect
                  mt={2}
                  name="poolCode"
                  onChange={(e) => onChangePoolCode(e.target.value)}
                  value={poolCode}
                  variant="select">
                  {province &&
                    [
                      {
                        code: NONE_CODE,
                        description: intl.formatMessage({ id: "lbl.none" }),
                      },
                    ]
                      .concat(pools)
                      .sort((pool1, pool2) => (pool1.description > pool2.description ? 1 : -1))
                      .map((pool, index) => (
                        <option key={index} value={pool.code}>
                          {pool.description}
                        </option>
                      ))}
                </OSSelect>
              </Flex>
            </Flex>
            <Flex mt={5} sx={{ width: "100%" }}>
              <Text variant="description" color="button.disabledColor">
                {intl.formatMessage({ id: "lbl.help-number-location-chat" })}
                <OSLink
                  url={posParameters[POS_PARAMETER.ONLINE_STORE_CHAT_URL]}
                  labelId="lbl.contactUsLink"
                  variant="chat-link"
                />
                {intl.formatMessage({ id: "lbl.for-help" })}
              </Text>
            </Flex>
          </Flex>
          <Flex
            mt={[5, 5, 5, 0]}
            sx={{
              flexDirection: "column",
              minWidth: ["100%", "25rem"],
              maxWidth: ["100%", "25rem"],
              minHeight: "11rem",
              width: "100%",
            }}>
            <OSLabel variant="labelLarge">
              {intl.formatMessage({ id: "lbl.select-number" })}
            </OSLabel>
            <Box
              sx={{
                display: "grid",
                rowGap: 5,
                columnGap: 7.5,
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
                mt: 2,
              }}>
              {phoneNumbersList &&
                phoneNumbersList.map((phNumber) => (
                  <Flex key={phNumber}>
                    <Button
                      className="track"
                      id={"selectPhoneNo-" + phNumber}
                      variant="select-phonenumber"
                      onClick={() => onPhoneNumberSet(phNumber)}
                      type="button"
                      bg="secondaryBg"
                      color="secondary"
                      px={2}
                      py={2}
                      sx={{
                        borderColor: phoneNumber === phNumber ? "secondary" : "border",
                      }}>
                      {phNumber}
                    </Button>
                  </Flex>
                ))}
            </Box>
            {phoneNumberData.getRandomPhoneNumbers.error && (
              <Flex
                bg="cardBg"
                py={11}
                px={8}
                sx={{
                  maxWidth: ["100%", "23.375rem"],
                  width: "100%",
                  height: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  border: 1,
                  borderColor: "border",
                  borderRadius: "2lg",
                  boxShadow: "sm",
                }}>
                <Text variant="subtitle1" mt={0} color="noResultsTextColor">
                  {intl.formatMessage({ id: "lbl.phone-number-selection-error" })}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    </>
  );
});

export default PickPhoneNumber;
