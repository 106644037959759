import React from "react";
import ContentLoader from "react-content-loader";
import { Box } from "@theme-ui/components";

const ShoppingCartSkeleton = () => (
  <>
    <Box
      sx={{
        borderRadius: "xl",
        boxShadow: "sm",
        border: 1,
        borderColor: "secondaryBg",
        backgroundColor: "secondaryBg",
        display: ["none", "none", "flex"],
      }}>
      <ContentLoader
        speed={2}
        height="550"
        viewBox="0 0 404 550"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="36" y="36" rx="10" ry="10" width="332" height="36" />
        <rect x="36" y="94" rx="10" ry="10" width="332" height="36" />
        <rect x="36" y="146" rx="10" ry="10" width="332" height="100" />
        <rect x="36" y="286" rx="10" ry="10" width="332" height="36" />
        <rect x="36" y="336" rx="10" ry="10" width="332" height="60" />
        <rect x="36" y="436" rx="10" ry="10" width="332" height="70" />
      </ContentLoader>
    </Box>
    <Box
      sx={{
        display: ["none", "flex", "none"],
        width: "100%",
      }}>
      <ContentLoader
        speed={2}
        height="80"
        width="100%"
        viewBox="0 0 1000 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" rx="10" ry="10" width="1000" height="80" />
      </ContentLoader>
    </Box>
    <Box
      sx={{
        display: ["flex", "none", "none"],
        width: "100%",
      }}>
      <ContentLoader
        speed={2}
        height="80"
        width="100%"
        viewBox="0 0 640 80"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <rect x="0" y="0" rx="10" ry="10" width="640" height="80" />
      </ContentLoader>
    </Box>
  </>
);

export default ShoppingCartSkeleton;
