import { useRef, useContext } from "react";
import { useDay } from "@datepicker-react/hooks";
import { useThemeUI } from "@theme-ui/core";
import { Button } from "@theme-ui/components";
import dayjs from "dayjs";
import { DatepickerContext, getColor } from "./DatePickerUtilities";

function Day({ dayLabel, date, id }) {
  const dayRef = useRef(null);
  const { theme } = useThemeUI();
  const {
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateSelect,
    onDateFocus,
    onDateHover,
  } = useContext(DatepickerContext);
  const {
    isSelected,
    isSelectedStartOrEnd,
    isWithinHoverRange,
    disabledDate,
    onClick,
    onKeyDown,
    onMouseEnter,
    tabIndex,
  } = useDay({
    date,
    focusedDate,
    isDateFocused,
    isDateSelected,
    isDateHovered,
    isDateBlocked,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateSelect,
    onDateHover,
    dayRef,
  });

  if (!dayLabel) {
    return <div />;
  }

  const getColorFn = getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate);

  return (
    <Button
      className="track"
      id={id + "-" + dayjs(date).format("LL")}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      tabIndex={tabIndex}
      type="button"
      ref={dayRef}
      sx={{
        fontFamily: "body",
        fontSize: "base",
        padding: 2,
        border: 0,
        color: getColorFn({
          selectedFirstOrLastColor: theme.colors.headerBg,
          normalColor: theme.colors.primary,
          selectedColor: theme.colors.textLight,
          rangeHoverColor: theme.colors.textLight,
          disabledColor: theme.colors.inactive,
        }),
        background: getColorFn({
          selectedFirstOrLastColor: theme.colors.secondary,
          normalColor: theme.colors.headerBg,
          selectedColor: theme.colors.primaryLight,
          rangeHoverColor: theme.colors.primaryLight,
          disabledColor: theme.colors.contentBg,
        }),
        ":hover": {
          backgroundColor: !isSelectedStartOrEnd && "button.disabledBackground",
        },
      }}>
      {dayLabel}
    </Button>
  );
}

export default Day;
