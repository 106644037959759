import { Flex, Text } from "@theme-ui/components";
import { PRICE_TYPE } from "../../../common/Constants";
import { getNoOfDeferredMonths } from "../../../components/plan-price-offer/PlanPriceOffer";
import _ from "lodash";
import { FormattedMessage } from "react-intl";

const DeferredDiscounts = ({ prices }) => {
  let pricesToBeReturned = [];

  const deferredServiceDiscountPricesList = prices
    .filter(
      (price) =>
        price.type === PRICE_TYPE.PLAN_SERVICE_DISCOUNT ||
        price.type === PRICE_TYPE.PLAN_SERVICE_IVD
    )
    .filter((price) => getNoOfDeferredMonths(price) != 0);

  const deferredServiceDiscountPricesMap = _.groupBy(deferredServiceDiscountPricesList, (price) =>
    getNoOfDeferredMonths(price)
  );

  for (const key of Object.keys(deferredServiceDiscountPricesMap)) {
    const discountPrices = deferredServiceDiscountPricesMap[key];
    if (discountPrices && discountPrices.length === 1) {
      pricesToBeReturned.push(
        <Text key={key} variant="summaryLabel">
          <FormattedMessage
            id={"lbl.deferred-discounts-message"}
            values={{
              desc: discountPrices[0].description,
              number: +key + 1,
            }}
          />
        </Text>
      );
    } else if (discountPrices && discountPrices.length) {
      const descriptions = discountPrices.map((price) => price.description).join(", ");

      pricesToBeReturned.push(
        <Text key={key} variant="summaryLabel">
          <FormattedMessage
            id={"lbl.deferred-discounts-message"}
            values={{
              desc: descriptions,
              number: +key + 1,
            }}
          />
        </Text>
      );
    }
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
      }}>
      {pricesToBeReturned}
    </Flex>
  );
};

export default DeferredDiscounts;
