import React, { forwardRef, useState } from "react";
import { useDatepicker, START_DATE } from "@datepicker-react/hooks";
import { Box, Input } from "@theme-ui/components";
import dayjs from "dayjs";

import Month from "./Month.js";
import { DatepickerContext, OutsideClickHandler } from "./DatePickerUtilities";

const Datepicker = forwardRef(({ id, date, setDate, error, onBlur }, ref) => {
  const [calendarVisibility, setCalendarVisiblity] = useState(false);

  const handleDateChange = (data) => {
    setDate(data.startDate);
    setCalendarVisiblity(!calendarVisibility);
  };

  const {
    firstDayOfWeek,
    activeMonths,
    isDateSelected,
    isDateHovered,
    isFirstOrLastSelectedDate,
    isDateBlocked,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateSelect,
    onDateFocus,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    exactMinBookingDays: true,
    numberOfMonths: 1,
    startDate: date,
    focusedInput: START_DATE,
    onDatesChange: handleDateChange,
    firstDayOfWeek: 0, //configure
  });

  return (
    <>
      <Input
        ref={ref}
        readOnly
        variant={error ? "inputError" : "input"}
        onFocus={() => {
          setCalendarVisiblity(true);
        }}
        onMouseDown={() => {
          if (!calendarVisibility) setCalendarVisiblity(true);
        }}
        value={date === null ? "" : dayjs(date).format("LL")}
      />
      {calendarVisibility && (
        <OutsideClickHandler
          onClickOutside={() => {
            setCalendarVisiblity(false);
            onBlur();
          }}>
          <Box sx={{ position: "absolute", zIndex: 2 }}>
            <DatepickerContext.Provider
              value={{
                focusedDate,
                isDateFocused,
                isDateSelected,
                isDateHovered,
                isDateBlocked,
                isFirstOrLastSelectedDate,
                onDateSelect,
                onDateFocus,
                onDateHover,
              }}>
              <Box
                sx={{
                  mt: 1,
                  overflow: "hidden",
                  backgroundColor: "ternaryBg",
                  borderRadius: "2lg",
                  border: 1,
                  borderColor: "border",
                  display: "grid",
                  gridTemplateColumns: `repeat(${activeMonths.length})`,
                  boxShadow: "lg",
                }}>
                {activeMonths.map((month, index) => (
                  <Month
                    id={id}
                    setCalendarVisiblity={setCalendarVisiblity}
                    value={{
                      goToNextMonths,
                      goToPreviousMonths,
                      onDateSelect,
                    }}
                    key={index}
                    year={month.year}
                    month={month.month}
                    date={date}
                    firstDayOfWeek={firstDayOfWeek}
                  />
                ))}
              </Box>
            </DatepickerContext.Provider>
          </Box>
        </OutsideClickHandler>
      )}
    </>
  );
});

export default Datepicker;
