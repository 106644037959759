import React from "react";
import { Flex, Text } from "@theme-ui/components";
import { useIntl } from "react-intl";

import { PORTIN_TYPE } from "./Constants";

const PortinType = ({ type, onChange, ...props }) => {
  const intl = useIntl();

  return (
    <Flex>
      <Flex
        sx={{
          border: 1,
          borderLeftColor: type === PORTIN_TYPE.MOBILE ? "secondary" : "border",
          borderTopColor: type === PORTIN_TYPE.MOBILE ? "secondary" : "border",
          borderRightColor: "border",
          borderBottomColor: type === PORTIN_TYPE.MOBILE ? "secondary" : "border",
          borderRadius: "45px 0px 0px 45px",
          ":hover": type === PORTIN_TYPE.LANDLINE && {
            borderLeftColor: "cardBorderHoverColor",
            borderTopColor: "cardBorderHoverColor",
            borderRightColor: "border",
            borderBottomColor: "cardBorderHoverColor",
          },
          height: "100%",
          minWidth: "8rem",
          alignItems: "center",
        }}
        {...props}>
        <Text
          onClick={() => onChange(PORTIN_TYPE.MOBILE)}
          variant={type === PORTIN_TYPE.LANDLINE ? "phoneNumberType" : "heading2"}
          sx={{
            px: 11.5,
            py: 4.5,
            cursor: "pointer",
            color: type === PORTIN_TYPE.MOBILE ? "secondary" : "primary",
          }}>
          {intl.formatMessage({ id: "lbl.mobile" })}
        </Text>
      </Flex>
      <Flex
        sx={{
          height: "100%",
          alignItems: "center",
          minWidth: "10rem",
          border: 1,
          borderLeftColor: "border",
          borderTopColor: type === PORTIN_TYPE.LANDLINE ? "secondary" : "border",
          borderRightColor: type === PORTIN_TYPE.LANDLINE ? "secondary" : "border",
          borderBottomColor: type === PORTIN_TYPE.LANDLINE ? "secondary" : "border",
          borderRadius: "0px 45px 45px 0px",
          ":hover": type === PORTIN_TYPE.MOBILE && {
            borderLeftColor: "border",
            borderTopColor: "cardBorderHoverColor",
            borderRightColor: "cardBorderHoverColor",
            borderBottomColor: "cardBorderHoverColor",
          },
        }}
        {...props}
        ml={["none", "none"]}>
        <Text
          onClick={() => onChange(PORTIN_TYPE.LANDLINE)}
          variant={type === PORTIN_TYPE.LANDLINE ? "heading2" : "phoneNumberType"}
          sx={{
            px: 11.5,
            py: 4.5,
            cursor: "pointer",
            color: type === PORTIN_TYPE.LANDLINE ? "secondary" : "primary",
          }}>
          {intl.formatMessage({ id: "lbl.landline" })}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PortinType;
